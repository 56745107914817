import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetNotificationTemplatesQuery } from 'services/notification-template/notificationTemplateApiService';
import { GetNotificationTemplatesPropsType } from 'services/notification-template/notificationTemplateApiService.types';

import { Form } from 'gazprom-ui-lib';

const useGetData = () => {
  const form = Form.useFormInstance();

  const [getNotificationTemplates, { data, isFetching, isLoading, isUninitialized }] =
    useLazyGetNotificationTemplatesQuery();

  const requestBody = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody?.page) {
      const search = debouncedRequestBody.search || undefined;
      const defaultBody: GetNotificationTemplatesPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        search,
      };

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        defaultBody.sort = [
          { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
        ];
      }

      getNotificationTemplates(defaultBody);
    }
  }, [debouncedRequestBody, getNotificationTemplates]);

  return {
    data,
    isLoading: isUninitialized || isLoading,
    isFetching: isDebouncing || isFetching,
  };
};

export default useGetData;
