import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { TEMPLATES_TAB_KEY } from './useTemplateTab.hook';

interface UsePopoverHookProps {
  activeTab: string;
}

const POPOVER_TIMEOUT = 4000;

const usePopoverHook = (props: UsePopoverHookProps) => {
  const { activeTab } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const popoverHandler = () => setPopoverIsOpen(!popoverIsOpen);

  useEffect(() => {
    if (searchParams.get('popover') === 'true') {
      setPopoverIsOpen(true);

      setTimeout(() => {
        setPopoverIsOpen(false);
      }, POPOVER_TIMEOUT);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (activeTab === TEMPLATES_TAB_KEY && popoverIsOpen) {
      setPopoverIsOpen(false);
    }
  }, [activeTab, popoverIsOpen]);

  return {
    popoverIsOpen,
    popoverHandler,
  };
};

export default usePopoverHook;
