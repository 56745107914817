import { GetEmployersResponse } from 'services/employer/employerApiService.types';

export const selectFromResult = ({ data, ...other }: { data?: { items: string[] } }) => ({
  data: data?.items?.map((name) => ({ value: name, label: name })) ?? [],
  ...other,
});

export const selectEmployersListFromResult = ({ data }: { data?: GetEmployersResponse }) => ({
  employersOptions: data?.content.map(({ id, name }) => ({ value: id, label: name })) ?? [],
});

export const simpleSelectFromResult = ({ data }: { data?: { id: string; name: string }[] }) => ({
  data: data?.map(({ name, id }) => ({ value: id, label: name })) ?? [],
});

export const selectFromResultWithFilter = ({ data }: { data?: { id: string; name: string }[] }) => {
  const uniqueIds = new Set();

  return {
    data:
      data?.reduce((acc: { value: string; label: string }[], { id, name }) => {
        if (!uniqueIds.has(id)) {
          uniqueIds.add(id);
          acc.push({ value: id, label: name });
        }
        return acc;
      }, []) ?? [],
  };
};
