import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetFeedbacksQuery } from 'services/thank-you/thankYouApiService';
import { GetFeedbacksPropsType } from 'services/thank-you/thankYouApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType, fieldsAssociationsForSort, mapFeedBacks } from './thank-you.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: Props) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const [getFeedbacks, { data, isLoading, isFetching, isUninitialized }] =
    useLazyGetFeedbacksQuery();
  console.log(debouncedRequestBody);

  useEffect(() => {
    if (debouncedRequestBody) {
      const { order, page, property, size, search } = debouncedRequestBody;

      const filters = debouncedRequestBody.filters || {};

      const preparedBody: GetFeedbacksPropsType = {
        ...filters,
        page,
        size,
      };

      if (property && order) {
        preparedBody.sort = [
          {
            order,
            property: fieldsAssociationsForSort[property],
          },
        ];
      }

      if (search) {
        preparedBody.text = search;
      }

      getFeedbacks(preparedBody);
    }
  }, [debouncedRequestBody, getFeedbacks]);

  return {
    data: data ? { ...data, feedbacks: mapFeedBacks(data?.feedbacks) } : undefined,
    isFetching: isDebouncing || isFetching,
    isLoading: isUninitialized || isLoading,
  };
};

export default useGetData;
