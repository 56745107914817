import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

type SetStateTypes<T> = Dispatch<SetStateAction<T>>;
export const useLocalStorage = <T>(key: string, initValue: T): [T, SetStateTypes<T>] => {
  const readValue = useCallback((): T => {
    if (!window) {
      return initValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (parseJSON(item) as T) : initValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initValue;
    }
  }, [initValue, key]);

  const setValue = useCallback<SetStateTypes<T>>(
    (newValue) => {
      try {
        const valueToSet = newValue ?? initValue;
        window.localStorage.setItem(key, JSON.stringify(valueToSet));
        setStoredValue(valueToSet);
        window.dispatchEvent(new Event(`local-storage-${key}`));
      } catch {
        console.error(`Can not save ${key} `);
      }
    },
    [initValue, key],
  );

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const handleStorageChange = useCallback(() => {
    setStoredValue(readValue());
  }, [readValue]);

  useEffect(() => {
    window.addEventListener(`local-storage-${key}`, handleStorageChange);

    return () => {
      window.removeEventListener(`local-storage-${key}`, handleStorageChange);
    };
  }, [handleStorageChange, key]);

  return [storedValue, setValue];
};

const parseJSON = <T>(value: string | null): T | undefined => {
  try {
    if (!value) {
      return undefined;
    } else {
      return JSON.parse(value);
    }
  } catch {
    return undefined;
  }
};

export default useLocalStorage;
