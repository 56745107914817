import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiService from 'services/1c/cApiService';
import {
  GetSynclogByIdPropsType,
  GetSynclogByIdResponseType,
} from 'services/synclog/synclogService.types';

import { createSynclogUrl } from './synclog.utils';

const synclogService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSynclogById: builder.query<GetSynclogByIdResponseType, GetSynclogByIdPropsType>({
      query: (params) => ({
        url: createSynclogUrl(`${prepareQueryParams(params)}`),
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetSynclogByIdQuery } = synclogService;
