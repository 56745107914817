import { FC, MouseEventHandler, useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useRemoveAttributeMutation } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Button, Icon, ModalDefault, Typography } from 'gazprom-ui-lib';

import s from './DocumentsDeclarationsAdditionalLabel.module.scss';

interface DocumentsDeclarationsAdditionalLabelProps {
  attributeId?: string;
  title?: string;
  onClick: () => void;
}

const DocumentsDeclarationsAdditionalLabel: FC<DocumentsDeclarationsAdditionalLabelProps> = (
  props,
) => {
  const { title, attributeId, onClick } = props;
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [removeSection] = useRemoveAttributeMutation();

  const mutationRemoveHandler = () => {
    if (attributeId) {
      removeSection({ id: id!, attributeId });
    }
  };

  const onModalCancel: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setIsModalOpen(!isModalOpen);
  };

  const onClickModalHandler: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();

    onClick();
    mutationRemoveHandler();
  };

  return (
    <>
      <div className={s.container}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <button
          type="button"
          className={s.remove}
          onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(!isModalOpen);
          }}>
          <Icon name="binFilled" />
          {t('common_delete')}
        </button>
      </div>
      <ModalDefault open={isModalOpen} onCancel={onModalCancel} width={320} closable>
        <ModalDefault.Icon type="danger" name="bin" />
        <ModalDefault.Title>{t('common_accept_remove')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('documents_declarations_add_modal_description')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button
            fullWidth
            type="secondary"
            onClick={onClickModalHandler}
            className={s.dangerButton}>
            {t('common_remove')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default DocumentsDeclarationsAdditionalLabel;
