import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetPersonByIdPropsType,
  GetPersonByIdResponseType,
  GetPersonsPropsType,
  GetPersonsResponseType,
} from './personApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createPersonUrl } from './utils';

const personApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getPersons: builder.query<GetPersonsResponseType, GetPersonsPropsType>({
      query: (params) => ({
        url: createPersonUrl(prepareQueryParams(params)),
      }),
      providesTags: [SERVICE_TAGS.GET_PERSONS],
    }),
    getPersonById: builder.query<GetPersonByIdResponseType, GetPersonByIdPropsType>({
      query: (personId) => ({
        url: createPersonUrl(`/${personId}`),
      }),
      providesTags: [SERVICE_TAGS.GET_PERSON_BY_ID],
    }),
  }),
});

export const { useGetPersonByIdQuery, useLazyGetPersonsQuery, useLazyGetPersonByIdQuery } =
  personApiService;
