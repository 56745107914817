import { SORT_ENUM } from 'types/sort.types';

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  search: '',
};

export type FormFiltersType = {
  roleIds?: string[];
  status?: string;
  employerIds?: string[];
};

export type FormResponseType = {
  page: number;
  size: number;
  property?: string;
  order?: SORT_ENUM;
  search: string;
  filters?: FormFiltersType;
};

export enum USER_STATUS_ENUM {
  ALL = 'ALL',
  REGISTERED = 'REGISTERED',
  NOT_REGISTERED = 'NOT_REGISTERED',
}

export const MAX_EMPLOYERS_PER_PAGE = 500;

export const formatStateFilters = ([name, value]: [
  string,
  number | string | FormFiltersType | SORT_ENUM,
]) => ({
  name,
  value,
});
