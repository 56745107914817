import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import s from './WithBackground.module.scss';

interface WithBackgroundProps extends PropsWithChildren {
  className?: string;
  id?: string;
}

const WithBackground: FC<WithBackgroundProps> = (props) => {
  const { className, id, children } = props;

  return (
    <div id={id} className={cn(s.wrapper, className)}>
      {children}
    </div>
  );
};

export default WithBackground;
