import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetFeedbacksPropsType,
  GetFeedbacksResponseType,
  RemoveFeedbacksProps,
  UpdateFeedbackByIdRequest,
} from './thankYouApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createThankYouUrl } from './utils';

const feedbackApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbacks: builder.query<GetFeedbacksResponseType, GetFeedbacksPropsType>({
      query: (params) => ({
        url: createThankYouUrl(`/items${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_FEEDBACKS],
    }),
    updateFeedbackById: builder.mutation<void, UpdateFeedbackByIdRequest>({
      query: ({ id, status }) => ({
        url: createThankYouUrl(`/item/${id}`),
        method: 'PUT',
        body: {
          status: status,
        },
      }),
      invalidatesTags: [SERVICE_TAGS.GET_FEEDBACKS],
    }),
    removeFeedbacks: builder.mutation<void, RemoveFeedbacksProps>({
      query: (body) => ({
        url: createThankYouUrl(`/items`),
        method: 'DELETE',
        body: body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_FEEDBACKS],
    }),
  }),
});

export const {
  useGetFeedbacksQuery,
  useLazyGetFeedbacksQuery,
  useUpdateFeedbackByIdMutation,
  useRemoveFeedbacksMutation,
} = feedbackApiService;
