import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, Flex } from 'gazprom-ui-lib';

import { FAQ_QUESTION_FORM } from 'containers/faq-question-form/faqQuestionForm.utils';

import s from './Actions.module.scss';

interface ActionsProps {
  isLoading: boolean;
}

const Actions: FC<ActionsProps> = (props) => {
  const { isLoading } = props;

  return (
    <Flex className={s.wrapper} gap="16">
      <Button
        form={FAQ_QUESTION_FORM}
        htmlType="submit"
        fullWidth
        leftIcon="ok"
        loading={isLoading}>
        {t('common_save')}
      </Button>
      <Button form={FAQ_QUESTION_FORM} htmlType="reset" fullWidth ghost loading={isLoading}>
        {t('common_close')}
      </Button>
    </Flex>
  );
};

export default Actions;
