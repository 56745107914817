import { useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { t } from 'tools/i18n';

import { Button, Icon, Typography } from 'gazprom-ui-lib';

import s from './NoPermission.module.scss';

const NoPermission = () => {
  const [signOutLoading, setSignOutLoading] = useState<boolean>(false);

  const { signoutSilent } = useAuth();

  const onLogout = () => {
    setSignOutLoading(true);
    signoutSilent();
  };

  return (
    <div>
      <div className={s.title}>
        <Typography.Title level={3}>{t('common_access_denied')}</Typography.Title>
      </div>
      <div className={s.content}>
        <div>
          <Icon name="accountClosed" />
        </div>
        <Typography.Title level={4}>{t('common_access_denied')}</Typography.Title>
        <Typography.Title level={5} type="secondary" strong={false}>
          {t('common_access_denied_description')}
        </Typography.Title>
        <Button ghost onClick={onLogout} loading={signOutLoading}>
          {t('common_logout')}
        </Button>
      </div>
    </div>
  );
};

export default NoPermission;
