import { FC } from 'react';

import downloadHandler from 'utils/downloadHandler';

import { Flex, Icon } from 'gazprom-ui-lib';

import s from './FileDownload.module.scss';

interface FileDownloadProps {
  isSigned?: boolean;
  fileName: string;
  fileDownloadUrl?: string;
  body?: BodyInit;
  method?: 'GET' | 'POST';
}

const FileDownload: FC<FileDownloadProps> = (props) => {
  const { isSigned, fileName, fileDownloadUrl, ...rest } = props;

  return (
    <Flex align="center" gap="12">
      {isSigned && <Icon name="okFilled" color="var(--color-success)" />}
      {fileDownloadUrl && (
        <button
          className={s.downloadButton}
          onClick={() => downloadHandler({ url: fileDownloadUrl, fileName, ...rest })}>
          <Icon name="download" />
        </button>
      )}
    </Flex>
  );
};

export default FileDownload;
