import { t } from 'i18next';

import {
  Button,
  Form,
  FormInstance,
  Icon,
  Modal,
  Typography,
  Upload,
  UploadProps,
} from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import ImageModalUploadLoader from 'containers/image-modal/image-modal-upload/editor-image-modal-upload-loader';
import WithLoader from 'containers/wrappers/with-loader';

import { CoverType, ModalType, normFile } from '../../uploadActions.utils';
import s from './ModalUpload.module.scss';

interface Props extends Pick<UploadProps, 'beforeUpload' | 'customRequest'> {
  modalConfig: null | ModalType;
  handleModalClose: () => void;
  handleUpdate: () => void;
  isLoading: boolean;
  form: FormInstance;
  cover: null | CoverType;
  resetImage: () => void;
}

const ModalUpload = (props: Props) => {
  const {
    modalConfig,
    handleModalClose,
    handleUpdate,
    isLoading,
    beforeUpload,
    customRequest,
    form,
    cover,
    resetImage,
  } = props;

  return (
    <Modal open={!!modalConfig} onCancel={handleModalClose} closable width={420}>
      <Typography.Title level={4} className={s.title}>
        {modalConfig?.title}
      </Typography.Title>
      {cover ? (
        <ImgPreview
          iconName="docTypePdf"
          className={modalConfig?.type === 'memo' ? s.preview : ''}
          description={`${(Number(cover?.size) / (1024 * 1024)).toFixed(2)} MB`}
          resetImage={resetImage}
          type="icon"
          url={cover?.url ?? ''}
          title={cover?.name}
        />
      ) : (
        <Form form={form}>
          <Form.Item name="upload">
            <Form.Item valuePropName="fileList" getValueFromEvent={normFile} noStyle>
              <Upload.Dragger
                className={s.uploadWrapper}
                disabled={isLoading}
                beforeUpload={beforeUpload}
                accept={modalConfig?.availableTypes.join(', ')}
                showUploadList={false}
                multiple={false}
                customRequest={customRequest}>
                <WithLoader isLoading={isLoading} loader={<ImageModalUploadLoader />}>
                  <div className={s.dragger}>
                    <Icon name="upload" />
                    <div>
                      <Typography.Text size="14" type="primary">
                        {t('common_upload_a_file')}
                      </Typography.Text>
                      <Typography.Text weight="500" size="12" type="secondary">
                        {modalConfig?.description}
                      </Typography.Text>
                    </div>
                  </div>
                </WithLoader>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        </Form>
      )}
      <div className={s.actions}>
        <Button leftIcon="ok" fullWidth onClick={handleUpdate}>
          {t('common_accept')}
        </Button>
        <Button onClick={handleModalClose} ghost fullWidth>
          {t('common_close')}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalUpload;
