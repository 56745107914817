import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';

import { SaveModalContext } from 'app.utils';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import {
  useAssignKedoReviewTaskMutation,
  useCancelKedoReviewTaskMutation,
  usePatchKedoReviewTaskMutation,
  useUpdateKedoReviewTaskMutation,
} from 'services/kedo-review-tasks/kedoReviewTasksApiService';
import { UpdateKedoReviewTaskPropsType } from 'services/kedo-review-tasks/kedoReviewTasksApiService.types';

import { Button, Form } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';
import KedoReviewTaskForm from 'containers/kedo-review-task-form';
import {
  KEDO_REVIEW_TASK_FORM_ENUM,
  KEDO_REVIEW_TASK_FORM_NAME,
  KedoReviewTaskFormResponse,
} from 'containers/kedo-review-task-form/kedoReviewTaskForm.utils';

import { KedoReviewTaskType, REVIEW_TASK_TYPES_ENUM } from 'types/review-task.types';

import s from './DocumentsKedoReviewTaskDetails.module.scss';
import DocumentsKedoReviewTaskAssignments from './documents-kedo-review-task-assignments';
import DocumentsKedoReviewTaskEmployees from './documents-kedo-review-task-employees';
import {
  KEDO_REVIEW_TASK_ASSIGNMENTS_TAB,
  KEDO_REVIEW_TASK_BASE_INFO_TAB,
  hasUnsavedChanges,
  prepareKedoReviewFile,
} from './documentsKedoReviewTaskDetails.utils';

interface UseTabItemsHook {
  kedoReviewTask?: KedoReviewTaskType;
  handleGoBack: () => void;
  status?: REVIEW_TASK_TYPES_ENUM;
  setAssignmentsCount: Dispatch<SetStateAction<number>>;
}

const useTabItemsHook = (props: UseTabItemsHook) => {
  const {
    kedoReviewTask,
    handleGoBack,
    status = REVIEW_TASK_TYPES_ENUM.REVIEW_CREATED,
    setAssignmentsCount,
  } = props;

  const params = useParams();

  const [form] = Form.useForm<KedoReviewTaskFormResponse>();

  const [updateKedoReviewTask, { isLoading }] = useUpdateKedoReviewTaskMutation();
  const [assignKedoReviewTask, { isLoading: assignKedoReviewTaskIsLoading }] =
    useAssignKedoReviewTaskMutation();
  const [cancelKedoReviewTask, { isLoading: cancelKedoReviewTaskIsLoading }] =
    useCancelKedoReviewTaskMutation();
  const [patchKedoReviewTask, { isLoading: isPatchKedoReviewTaskLoading }] =
    usePatchKedoReviewTaskMutation();

  const [kedoReviewTaskToAssign, setKedoReviewTaskToAssign] = useState<null | string>(null);
  const [kedoReviewTaskToCancel, setKedoReviewTaskToCancel] = useState<null | string>(null);

  const setModalConfig = useContext(SaveModalContext);

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });

  const handleSubmit = useCallback(
    (form: KedoReviewTaskFormResponse) => {
      const requestBody: UpdateKedoReviewTaskPropsType = {
        id: params.id!,
        name: form[KEDO_REVIEW_TASK_FORM_ENUM.NAME],
        docName: form[KEDO_REVIEW_TASK_FORM_ENUM.DOC_NAME],
        needEmployeeSign: form[KEDO_REVIEW_TASK_FORM_ENUM.SIGN_REQUIRED],
        employeeGroupIds: kedoReviewTask?.instance1c
          ? kedoReviewTask.employeeGroupIds
          : form[KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEE_GROUPS],
      };

      if (kedoReviewTask?.instance1c) {
        requestBody.instanceId = kedoReviewTask.instance1c.id;
      }

      if (form[KEDO_REVIEW_TASK_FORM_ENUM.FILE]) {
        const preparedFile = prepareKedoReviewFile(form[KEDO_REVIEW_TASK_FORM_ENUM.FILE]);

        requestBody.files = [preparedFile];
      }

      updateKedoReviewTask(requestBody).then(handleUpdate);
    },
    [
      handleUpdate,
      kedoReviewTask?.employeeGroupIds,
      kedoReviewTask?.instance1c,
      params.id,
      updateKedoReviewTask,
    ],
  );

  const handleAssignRequest = useHandleRequestWithModal({
    onSuccess: () => setKedoReviewTaskToAssign(null),
    callOnSuccessWithoutData: true,
    onError: () => setKedoReviewTaskToAssign(null),
    onSuccessModalConfig: {
      title: {
        children: t('common_appointed'),
      },
    },
    onErrorModalConfig: {
      title: {
        children: t('common_error'),
      },
      icon: {
        name: 'error',
        type: 'danger',
      },
    },
  });

  const handleAssign = useCallback(() => {
    if (params?.id) {
      if (kedoReviewTask?.instance1c) {
        patchKedoReviewTask({ id: params.id, status: REVIEW_TASK_TYPES_ENUM.LNA_VALID }).then(
          handleAssignRequest,
        );
      } else {
        assignKedoReviewTask(params.id).then(handleAssignRequest);
      }
    }
  }, [
    assignKedoReviewTask,
    handleAssignRequest,
    kedoReviewTask?.instance1c,
    params.id,
    patchKedoReviewTask,
  ]);

  const handleCancelAssignRequest = useHandleRequestWithModal({
    onSuccess: () => setKedoReviewTaskToCancel(null),
    callOnSuccessWithoutData: true,
    onError: () => setKedoReviewTaskToAssign(null),
    onSuccessModalConfig: {
      title: {
        children: t('common_canceled'),
      },
    },
    onErrorModalConfig: {
      title: {
        children: t('common_error'),
      },
      icon: {
        name: 'error',
        type: 'danger',
      },
    },
  });

  const handleCancelAssign = () => {
    if (params?.id) {
      if (kedoReviewTask?.instance1c) {
        patchKedoReviewTask({ id: params.id, status: REVIEW_TASK_TYPES_ENUM.LNA_CANCELED }).then(
          handleCancelAssignRequest,
        );
      } else {
        cancelKedoReviewTask(params.id).then(handleCancelAssignRequest);
      }
    }
  };

  const handleAssignTask = () => {
    const hasChanges = hasUnsavedChanges({
      kedoReviewTask,
      form,
    });
    const showSaveChangesModal = hasChanges && setModalConfig;

    if (showSaveChangesModal) {
      setModalConfig({
        icon: {
          name: 'info',
          type: 'warning',
        },
        title: {
          children: t('kedo_review_tasks_unsaved_changes_title'),
        },
        description: {
          children: t('kedo_review_tasks_unsaved_changes_description'),
        },
      });
    } else {
      form.validateFields().then(() => {
        setKedoReviewTaskToAssign(kedoReviewTask!.id);
      });
    }
  };

  const isBaseInfoTabFormDisabled = ![
    REVIEW_TASK_TYPES_ENUM.REVIEW_CREATED,
    REVIEW_TASK_TYPES_ENUM.LNA_CREATED,
  ].includes(status);

  const kedoReviewTaskActions = (
    <div className={s.actions}>
      {!isBaseInfoTabFormDisabled && (
        <>
          <Button
            fullWidth
            type="primary"
            leftIcon="ok"
            htmlType="submit"
            loading={isLoading}
            form={KEDO_REVIEW_TASK_FORM_NAME}>
            {t('common_save')}
          </Button>
          <Button
            fullWidth
            type="primary"
            leftIcon="checklist"
            className={s.appointButton}
            onClick={handleAssignTask}>
            {t('common_appoint')}
          </Button>
        </>
      )}
      {(status === REVIEW_TASK_TYPES_ENUM.REVIEW_ASSIGNED ||
        status === REVIEW_TASK_TYPES_ENUM.LNA_VALID) && (
        <Button
          fullWidth
          type="primary"
          leftIcon="delete"
          className={s.cancelAssignButton}
          onClick={() => setKedoReviewTaskToCancel(kedoReviewTask!.id)}>
          {t('common_cancel_for_all')}
        </Button>
      )}
      <Button
        className={s.closeButton}
        fullWidth={status !== REVIEW_TASK_TYPES_ENUM.REVIEW_CANCELED}
        type="secondary"
        onClick={handleGoBack}>
        {t('common_close')}
      </Button>
    </div>
  );

  return [
    {
      key: KEDO_REVIEW_TASK_BASE_INFO_TAB,
      children: (
        <div className={s.content}>
          <KedoReviewTaskForm
            form={form}
            disabled={isBaseInfoTabFormDisabled}
            onSubmit={handleSubmit}
            kedoReviewTask={kedoReviewTask}
          />
          {kedoReviewTaskActions}
          <RemoveConfirm
            icon="info"
            buttonProps={{
              type: 'primary',
              leftIcon: 'ok',
              size: 'large',
            }}
            isLoading={assignKedoReviewTaskIsLoading || isPatchKedoReviewTaskLoading}
            type="danger"
            title={t('kedo_review_tasks_confirm_assign')}
            subtitle={t('kedo_review_tasks_confirm_assign_description')}
            isOpen={!!kedoReviewTaskToAssign}
            buttonText={t('common_confirm')}
            onCancel={() => setKedoReviewTaskToAssign(null)}
            onConfirm={handleAssign}
          />
          <RemoveConfirm
            icon="info"
            buttonProps={{
              type: 'primary',
              leftIcon: 'ok',
              size: 'large',
            }}
            isLoading={cancelKedoReviewTaskIsLoading || isPatchKedoReviewTaskLoading}
            type="danger"
            title={t('kedo_review_tasks_cancel_assign')}
            subtitle={t('kedo_review_tasks_cancel_assign_description')}
            isOpen={!!kedoReviewTaskToCancel}
            buttonText={t('common_confirm')}
            onCancel={() => setKedoReviewTaskToCancel(null)}
            onConfirm={handleCancelAssign}
          />
        </div>
      ),
      label: t('common_settings'),
    },
    {
      key: KEDO_REVIEW_TASK_ASSIGNMENTS_TAB,
      children: (
        <>
          {status === REVIEW_TASK_TYPES_ENUM.REVIEW_CREATED ? (
            <DocumentsKedoReviewTaskEmployees
              groupIds={kedoReviewTask?.employeeGroupIds}
              setAssignmentsCount={setAssignmentsCount}
            />
          ) : (
            <DocumentsKedoReviewTaskAssignments
              taskStatus={kedoReviewTask?.status?.code as REVIEW_TASK_TYPES_ENUM}
              needEmployeeSign={kedoReviewTask?.needEmployeeSign}
              setAssignmentsCount={setAssignmentsCount}
            />
          )}
          <div className={s.assignmentsActions}>
            <Button fullWidth className={s.closeButton} type="secondary" onClick={handleGoBack}>
              {t('common_close')}
            </Button>
          </div>
        </>
      ),
      label: t('kedo_review_tasks_assign_list'),
    },
  ];
};

export default useTabItemsHook;
