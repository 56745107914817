import { FC, useEffect, useRef } from 'react';

import { formRules } from 'utils/formRules';

import { Form } from 'gazprom-ui-lib';

import Editor from 'containers/editor';

import { EVENT_FORM_ENUM, FORM_LABELS } from '../../../eventForm.utils';

const Description: FC = () => {
  const form = Form.useFormInstance();

  const editorRef = useRef<Editor>(null);
  const setRef = useRef<boolean>(false);

  const editorValue = Form.useWatch(EVENT_FORM_ENUM.DESCRIPTION);

  useEffect(() => {
    if (editorValue && editorRef && !setRef.current) {
      editorRef.current?.setEditorData(editorValue);
      setRef.current = true;
    }
  }, [editorRef, editorValue]);

  const handleSetEditorValue = (v: string) => {
    setRef.current = true;
    form.setFieldValue(EVENT_FORM_ENUM.DESCRIPTION, v);
  };

  return (
    <Form.Item
      name={EVENT_FORM_ENUM.DESCRIPTION}
      rules={[formRules.required]}
      label={FORM_LABELS[EVENT_FORM_ENUM.DESCRIPTION]}>
      <Editor onChange={handleSetEditorValue} ref={editorRef} />
    </Form.Item>
  );
};

export default Description;
