import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { Icon, TableProps, Typography } from 'gazprom-ui-lib';

import NotificationsSendType from 'components/notifications-send-type';
import TableDate from 'components/table/table-date';

import { NOTIFICATION_ENUM, NotificationNewsletterType } from 'types/notification.types';

import s from './NotificationNewsletterTable.module.scss';

const useColumns = (): TableProps<NotificationNewsletterType>['columns'] => {
  const newsletterNameRenderer = (name: string, row: NotificationNewsletterType) => (
    <div className={s.nameWrapper}>
      <Typography.Text size="12" weight="500">
        {name}
      </Typography.Text>
      <Typography.Text size="12" weight="500" type="secondary">
        {t('notification_id', { id: row.id })}
      </Typography.Text>
    </div>
  );

  const typeRenderer = ({ sendApp, sendEmail }: NotificationNewsletterType) => (
    <div className={s.transports}>
      {sendApp && <NotificationsSendType type={NOTIFICATION_ENUM.PUSH} />}
      {sendEmail && <NotificationsSendType type={NOTIFICATION_ENUM.EMAIL} />}
    </div>
  );

  const viewCountRenderer = (viewCount: number) => (
    <Typography.Text
      type={viewCount > 0 ? 'primary' : 'secondary'}
      weight="500"
      size="12"
      className={s.stats}>
      <Icon name="eye" />
      {viewCount}
    </Typography.Text>
  );

  const feedbackCountRenderer = (feedbackCount: number) => (
    <Typography.Text
      type={feedbackCount > 0 ? 'primary' : 'secondary'}
      weight="500"
      size="12"
      className={s.stats}>
      <Icon name="comment" />
      {feedbackCount}
    </Typography.Text>
  );

  const sendCountRenderer = (sendCount: number) => (
    <Typography.Text
      type={sendCount > 0 ? 'primary' : 'secondary'}
      weight="500"
      size="12"
      className={s.stats}>
      <Icon name="phoneFilled" />
      {sendCount}
    </Typography.Text>
  );

  const sendDateRenderer = (dateToSend: string) => <TableDate date={dateToSend} />;

  return useMemo(() => {
    return [
      {
        title: t('common_newsletter_name'),
        dataIndex: 'name',
        sorter: true,
        render: newsletterNameRenderer,
      },
      {
        title: t('common_type'),
        render: typeRenderer,
      },
      {
        title: t('common_read'),
        dataIndex: 'viewCount',
        sorter: true,
        width: '100px',
        render: viewCountRenderer,
      },
      {
        title: t('common_feedback'),
        dataIndex: 'feedbackCount',
        sorter: true,
        width: '140px',
        render: feedbackCountRenderer,
      },
      {
        title: t('common_callback'),
        dataIndex: 'sendCount',
        sorter: true,
        width: '150px',
        render: sendCountRenderer,
      },
      {
        title: t('thank_you_data'),
        align: 'center',
        sorter: true,
        dataIndex: 'dateToSend',
        render: sendDateRenderer,
        defaultSortOrder: 'descend',
      },
    ];
  }, []);
};

export default useColumns;
