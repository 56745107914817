import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetSuggestionDecisionsQuery } from 'services/suggestion/suggestionApiService';
import { GetSuggestionDecisionsPropsType } from 'services/suggestion/suggestionApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './suggestionsDecisions.utils';

interface UseRequestProps {
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: UseRequestProps) => {
  const { form } = props;

  const [getDecisions, { data: decisions, isLoading, isFetching }] =
    useLazyGetSuggestionDecisionsQuery();

  const requestBody: FormResponseType = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    const { page, size, search, order, property, filters } = debouncedRequestBody ?? {};

    const initialRequestBody: GetSuggestionDecisionsPropsType = {
      page,
      size,
    };

    if (search) {
      initialRequestBody.text = search;
    }

    if (property && order) {
      if (debouncedRequestBody.property === 'createdAt') {
        initialRequestBody.sort = [
          {
            property: `Suggestion.createdAt`,
            order,
          },
        ];
      }
    }

    if (filters?.category) {
      initialRequestBody.categoryId = filters.category;
    }

    if (filters?.status) {
      initialRequestBody.status = filters.status;
    }

    getDecisions(initialRequestBody);
  }, [debouncedRequestBody, getDecisions]);

  return {
    decisions,
    isLoading,
    isFetching: isDebouncing || isFetching,
  };
};

export default useRequest;
