import { FC, ReactNode } from 'react';

import { getFiltersCount } from 'utils/filter.utils';

import { GetArticlesPropsType } from 'services/article/articleApiService.types';

import Filters from 'containers/filters';
import FilterCollapse from 'containers/filters/filter-collapse';

import s from 'pages/news/news-tables/news-tables-filters/NewsTablesFilters.module.scss';
import useFilterItems from 'pages/news/news-tables/news-tables-filters/useFilterItems.hook';

interface NewsFiltersProps<T = Pick<GetArticlesPropsType, 'categoryIds'>> {
  actions: ReactNode;
  filters: T | null;
  onFiltersSubmit: (value: T | null) => void;
}

const EventsFilters: FC<NewsFiltersProps> = (props) => {
  const { actions, filters, onFiltersSubmit } = props;

  const filterItems = useFilterItems();

  return (
    <div className={s.wrapper}>
      {actions}
      <Filters
        filtersCount={getFiltersCount(filters)}
        initialValues={filters}
        handleApply={onFiltersSubmit}
        overlay={<FilterCollapse items={filterItems} />}
        buttonProps={{ size: 'small' }}
      />
    </div>
  );
};

export default EventsFilters;
