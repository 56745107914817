import dayjs from 'dayjs';

export const formatMilliseconds = (ms: string) => {
  if (!ms) {
    return '';
  }

  const dur = dayjs.duration(ms);
  return dur.format('HH:mm:ss:SSS');
};
