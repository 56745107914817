import Faq from 'pages/faq';
import FaqAdd from 'pages/faq/faq-add';
import FaqEdit from 'pages/faq/faq-edit';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import { FAQ_ADD_ROUTE, FAQ_EDIT_ROUTE, FAQ_ROUTE } from './list';

export const faqRoutes: RouteConfig[] = [
  {
    path: FAQ_ROUTE,
    element: <Faq />,
    roles: [GLOBAL_ROLES.ADMIN],
  },
  {
    path: FAQ_ADD_ROUTE,
    element: <FaqAdd />,
    roles: [GLOBAL_ROLES.ADMIN],
  },
  {
    path: FAQ_EDIT_ROUTE,
    element: <FaqEdit />,
    roles: [GLOBAL_ROLES.ADMIN],
  },
];
