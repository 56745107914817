import { t } from 'tools/i18n';

import { useGetTelegramChannelsQuery } from 'services/telegram-channel/telegramChannelApiService';
import { GetTelegramChannelResponseType } from 'services/telegram-channel/telegramChannelApiService.types';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import { HANDBOOK_BASE_FORM_ENUM } from '../handbookBaseForm.utils';

export const DEFAULT_PAGE_DATA = { page: 1, size: 500 };

const HandbookBaseFormTgChanel = () => {
  const { data } = useGetTelegramChannelsQuery(DEFAULT_PAGE_DATA, { selectFromResult });

  return (
    <>
      <Typography.Text strong size="16">
        {t('common_tg_chanel')}
      </Typography.Text>
      <Form.Item name={HANDBOOK_BASE_FORM_ENUM.TG_CHANEL}>
        <Select options={data} placeholder={t('common_select')} />
      </Form.Item>
    </>
  );
};

const selectFromResult = ({ data, ...other }: { data?: GetTelegramChannelResponseType }) => ({
  data: data?.items.map(({ telegramTitle, id }) => ({ value: id, label: telegramTitle })) ?? [],
  ...other,
});
export default HandbookBaseFormTgChanel;
