import {
  ImportChannelPropsType,
  ImportChannelResponseType,
} from 'services/import/importApiService.types';
import { createImportUrl } from 'services/import/utils';
import initialApiService from 'services/initialApiService';

const importApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    importChannelByLinks: builder.mutation<ImportChannelResponseType, ImportChannelPropsType>({
      query: (body) => ({
        url: createImportUrl(``),
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useImportChannelByLinksMutation } = importApiService;
