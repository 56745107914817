import { FC } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import { DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import s from './DocumentsDeclarationsFormType.module.scss';
import { OPTIONS } from './documentsDeclarationsFormType.utils';

interface DocumentsDeclarationsFormTypeProps {
  id: string;
  defaultValue?: string;
}

const DocumentsDeclarationsFormType: FC<DocumentsDeclarationsFormTypeProps> = (props) => {
  const { id, defaultValue } = props;
  return (
    <>
      <div className={s.titleContainer}>
        <Typography.Title level={5}>{t('common_field_type')}</Typography.Title>
      </div>
      <Form.Item
        name={`${DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM.TYPE}.${id}`}
        initialValue={defaultValue}
        rules={[formRules.required]}>
        <Select
          placeholder={t('documents_declarations_edo_type_placeholder')}
          options={OPTIONS}
          showSearch
          filterOption
          optionFilterProp="label"
        />
      </Form.Item>
    </>
  );
};

export default DocumentsDeclarationsFormType;
