import { ACCESS_CONTROL_USER_ROLES_ROUTE } from 'routes/access-control/list';
import { t } from 'tools/i18n';

import { Button, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import LastChange from 'containers/last-change';

import { ShortCreatorEditorType } from 'types/creatorEditor.types';

import { EDIT_ROLE_FORM_NAME } from '../../accessControlRolesDetails.utils';

interface AccessControlRolesDetailsHeaderProps {
  code?: string;
  updatedAt?: string | null;
  editor?: ShortCreatorEditorType | null;
  isLoading: boolean;
}

const Header = (props: AccessControlRolesDetailsHeaderProps) => {
  const { updatedAt, editor, code, isLoading } = props;

  const description = (
    <LastChange
      date={updatedAt}
      editorId={editor?.id}
      editorName={`${editor?.lastName} ${editor?.firstName}`}
    />
  );

  return (
    <ContentHeader align="flex-start">
      <ContentHeader.Title description={description} goBackLink={ACCESS_CONTROL_USER_ROLES_ROUTE}>
        <Typography.Title level={3}>
          {t('access_control_role_edit', {
            code,
          })}
        </Typography.Title>
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Button htmlType="submit" form={EDIT_ROLE_FORM_NAME} loading={isLoading} leftIcon="ok">
          {t('common_save')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
