import { t } from 'tools/i18n';

import SettingsTelegram from './settings-telegram';
import SettingsTelegramAuthorization from './settings-telegram-authorization';

export const SETTINGS_TELEGRAM_AUTHORIZATION_TAB = 'SETTINGS_TELEGRAM_AUTHORIZATION_TAB';
export const SETTINGS_TELEGRAM_CHANNELS_TAB = 'SETTINGS_TELEGRAM_CHANNELS_TAB';

export const TABS = [
  {
    key: SETTINGS_TELEGRAM_AUTHORIZATION_TAB,
    children: <SettingsTelegramAuthorization />,
    label: t('common_authorization'),
  },
  {
    key: SETTINGS_TELEGRAM_CHANNELS_TAB,
    children: <SettingsTelegram />,
    label: t('telegram_channels'),
  },
];
