import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetNewsCategoriesQuery } from 'services/category/categoryApiService';
import { GetNewsCategoriesResponseType } from 'services/category/categoryApiService.types';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterHeader from 'containers/filters/filter-header';

const useFilterItems = () => {
  const [searchCategory, setSearchCategory] = useState<string>();

  const { isDebouncing } = useDebounce(searchCategory);

  const { categoriesOptions } = useGetNewsCategoriesQuery(
    { page: 1, size: 500, name: searchCategory, type: 'meet' },
    {
      selectFromResult,
      skip: isDebouncing,
    },
  );

  return useMemo(
    () => [
      {
        key: '0',
        label: <FilterHeader valueKey="categoryIds">{t('news_filters_category')}</FilterHeader>,
        children: (
          <FilterCheckboxGroup
            valueKey="categoryIds"
            options={categoriesOptions}
            showSearch
            searchProps={{
              placeholder: t('news_filters_category'),
              value: searchCategory,
              onChange: (e) => setSearchCategory(e.target.value || undefined),
            }}
          />
        ),
      },
    ],
    [categoriesOptions, searchCategory],
  );
};

const selectFromResult = ({ data }: { data?: GetNewsCategoriesResponseType }) => ({
  categoriesOptions: data?.items.map(({ id, name }) => ({ value: id, label: name })) ?? [],
});

export default useFilterItems;
