import { FC, useState } from 'react';

import { Trans, t } from 'tools/i18n';

import { Form, Progress, Typography } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import {
  NotificationNewsletterType,
  NotificationType,
  NotificationTypeWithPerson,
} from 'types/notification.types';

import s from './NotificationNewsletterEditStats.module.scss';
import Filters from './containers/filters';
import NotificationNewsletterEditStatsModal from './notification-newsletter-edit-stats-modal';
import { DEFAULT_FORM_STATE, FormResponseType } from './notificationNewsletterEditStats.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

interface NotificationNewsletterEditStatsProps {
  currentNewsletter?: NotificationNewsletterType;
}

const NotificationNewsletterEditStats: FC<NotificationNewsletterEditStatsProps> = (props) => {
  const { currentNewsletter } = props;

  const [form] = Form.useForm<FormResponseType>();

  const [currentNotification, setCurrentNotification] = useState<NotificationType | null>(null);

  const columns = useColumns();

  const onRow = (notification: NotificationType) => ({
    onClick: () => {
      setCurrentNotification(notification);
    },
  });

  const handleModalClose = () => setCurrentNotification(null);

  const { isFetching, data, totalCount } = useGetData({ form });

  const total = totalCount ?? 1;
  const sendPercent = ((currentNewsletter?.sendCount ?? 1) / total) * 100;
  const viewPercent = ((currentNewsletter?.viewCount ?? 1) / total) * 100;
  const feedbackPercent = ((currentNewsletter?.feedbackCount ?? 1) / total) * 100;

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.progress}>
          <div>
            <Progress type="circle" size={80} percent={Math.ceil(sendPercent)} />
            <div>
              <Typography.Title level={5}>{t('notification_sent')}</Typography.Title>
              <Trans
                i18nKey="common_stats_trans"
                values={{ current: currentNewsletter?.sendCount, total }}>
                <Typography.Text strong size="14" type="primary"></Typography.Text>
                <Typography.Text strong size="14"></Typography.Text>
              </Trans>
            </div>
          </div>
          <div>
            <Progress type="circle" size={80} percent={Math.ceil(viewPercent)} />
            <div>
              <Typography.Title level={5}>{t('common_read')}</Typography.Title>
              <Trans
                i18nKey="common_stats_trans"
                values={{ current: currentNewsletter?.viewCount, total }}>
                <Typography.Text strong size="14" type="primary"></Typography.Text>
                <Typography.Text strong size="14"></Typography.Text>
              </Trans>
            </div>
          </div>
          <div>
            <Progress type="circle" size={80} percent={Math.ceil(feedbackPercent)} />
            <div>
              <Typography.Title level={5}>{t('common_respond')}</Typography.Title>
              <Trans
                i18nKey="common_stats_trans"
                values={{ current: currentNewsletter?.feedbackCount, total }}>
                <Typography.Text strong size="14" type="primary"></Typography.Text>
                <Typography.Text strong size="14"></Typography.Text>
              </Trans>
            </div>
          </div>
        </div>
        <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
          <Filters />
          <FiltersV2.Table<NotificationTypeWithPerson, FormResponseType>
            className={s.table}
            dataSource={data}
            columns={columns}
            showSorterTooltip={false}
            onRow={onRow}
            loading={isFetching}
          />
        </FiltersV2>
      </div>
      <NotificationNewsletterEditStatsModal
        handleClose={handleModalClose}
        notification={currentNotification}
      />
    </>
  );
};

export default NotificationNewsletterEditStats;
