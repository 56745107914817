import { t } from 'tools/i18n';

import DocumentsDeclarationsAdditional from '../documents-declarations-additional';
import Common from './containers/common';

export const COMMON_TAB = 'COMMON_TAB';
export const ADDITIONAL_TAB = 'ADDITIONAL_TAB';

export const INITIAL_TAB = {
  key: COMMON_TAB,
  label: t('common_common_description'),
  children: <Common />,
};

export const TABS = [
  INITIAL_TAB,
  {
    key: ADDITIONAL_TAB,
    label: t('common_additional_fields'),
    children: <DocumentsDeclarationsAdditional />,
  },
];
