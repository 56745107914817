import { GUIDE_FORM_ENUM } from 'pages/guide/guide-contacts/guideContacts.utils';

import { GroupedDataType, GuideHotContactByIdType } from 'types/guide.types';

export const getSortedValues = (values: Record<string, string>) => {
  const groupedData: GroupedDataType = {};
  let email = '';

  Object.keys(values).forEach((key) => {
    const [id, prop] = key.split('.');
    const num = Number(id);

    if (id === GUIDE_FORM_ENUM.MAIL) {
      email = values[id];
    } else {
      if (!groupedData[id as keyof typeof groupedData]) {
        groupedData[id] = { id, num, internalPhone: '', phone: '' };
      }
      groupedData[id][prop] = values[key];
    }
  });

  const result = Object.values(groupedData);
  const mappedResult = result.map(({ id, ...rest }) => rest).filter((el) => el.phone);

  if (mappedResult.length > 0) {
    mappedResult[0].email = email;
  }

  return mappedResult;
};

export const mapComponents = (contactInfo: GuideHotContactByIdType['contacts'][0]) => {
  const id = contactInfo.num;
  return {
    id,
    initialPhone: contactInfo.phone,
    initialAdditionalPhone: contactInfo.internalPhone,
  };
};

export const getMaxNum = (hotContacts: GuideHotContactByIdType['contacts']) => {
  return hotContacts.reduce((max, obj) => (obj.num > max ? obj.num : max), 0);
};
