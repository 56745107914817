import { ReactNode } from 'react';

import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import WithGzpTopLogo from 'containers/wrappers/with-gzp-top-logo';

import s from './Error.module.scss';
import useErrorConfig from './useErrorConfig.hook';

interface Props {
  initialActions?: ReactNode;
}

const Error = (props: Props) => {
  const { initialActions } = props;

  const { icon, title, text, actions: configActions, showSupport } = useErrorConfig();

  const actions = initialActions || configActions;

  return (
    <WithGzpTopLogo className={s.logoWrapper}>
      <div className={s.wrapper}>
        <div>
          <div className={s.iconWrapper}>
            <Icon name={icon} />
          </div>
          <Typography.Title level={3}>{title}</Typography.Title>
          <Typography.Title level={5} type="secondary" strong={false}>
            {text}
          </Typography.Title>
        </div>
        <div className={s.actionsWrapper}>
          {showSupport && (
            <div className={s.supportWrapper}>
              <a className={s.support} href="tel:+78126650535">
                <div>
                  <Typography.Text type="secondary">{t('common_tech_support')}</Typography.Text>
                  <Typography.Title level={4}>8 (812) 665 05 35</Typography.Title>
                  <Typography.Text weight="500" type="primary">
                    {`${t('common_inner_number')} – 9000`}
                  </Typography.Text>
                </div>
                <div className={s.supportIconWrapper}>
                  <Icon name="phoneFilled" />
                </div>
              </a>
            </div>
          )}
          {actions}
        </div>
      </div>
    </WithGzpTopLogo>
  );
};

export default Error;
