import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, Input } from 'gazprom-ui-lib';

import { EXPERT_COMMISSION_FORM_ENUM } from '../../expertCommissionForm.utils';

const Name = () => {
  return (
    <Form.Item
      label={t('suggestions_commission_name')}
      name={EXPERT_COMMISSION_FORM_ENUM.NAME}
      rules={[formRules.required]}>
      <Input showCount placeholder={t('common_enter_text')} maxLength={80} />
    </Form.Item>
  );
};

export default Name;
