import { FC } from 'react';

import { t } from 'tools/i18n';

import { Form, Radio } from 'gazprom-ui-lib';

import { EVENT_GENDER } from 'types/event.types';

import { EVENT_FORM_ENUM, FORM_LABELS } from '../../../eventForm.utils';

interface GenderProps {
  disabled?: boolean;
}

const Gender: FC<GenderProps> = (props) => {
  const { disabled } = props;

  return (
    <Form.Item
      name={EVENT_FORM_ENUM.TARGET_GROUP_GENDER}
      label={FORM_LABELS[EVENT_FORM_ENUM.TARGET_GROUP_GENDER]}
      initialValue={EVENT_GENDER.BOTH}>
      <Radio.Group disabled={disabled}>
        <Radio value={EVENT_GENDER.BOTH}>{t('common_all')}</Radio>
        <Radio value={EVENT_GENDER.FEMALE}>{t('common_women_only')}</Radio>
        <Radio value={EVENT_GENDER.MALE}>{t('common_men_only')}</Radio>
      </Radio.Group>
    </Form.Item>
  );
};

export default Gender;
