import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { Icon, TableProps, Tag } from 'gazprom-ui-lib';

import Cover from 'components/cover';
import TableDate from 'components/table/table-date';
import TableText from 'components/table/table-text';

import { ArticleType } from 'types/article.types';

import s from './NewsTable.module.scss';
import NewsTablePin from './news-table-pin';

interface UseColumnsProps {
  canPinNews?: boolean;
}

const useColumns = (props: UseColumnsProps): TableProps<ArticleType>['columns'] => {
  const { canPinNews } = props;

  return useMemo(
    () => [
      {
        title: t('news_news'),
        dataIndex: 'title',
        sorter: true,
        render: (title: string, row: ArticleType) => (
          <div className={s.newsCell}>
            <Cover src={row.cover?.link} alt="event cover" />
            <div>
              <TableText size="12" weight="500" strong>
                {title}
              </TableText>
              {row.categories.map((category) => (
                <Tag type="primary" key={category.id}>
                  {category.name}
                </Tag>
              ))}
            </div>
          </div>
        ),
      },
      {
        title: t('news_type'),
        dataIndex: 'type',
        sorter: true,
        render: () => <Icon name="news" color="var(--color-primary-6)" />,
      },
      {
        title: t('news_moderation'),
        sorter: true,
        align: 'center' as const,
        dataIndex: 'isNeedModerate',
        render: (isNeedModerate: boolean) =>
          isNeedModerate && <Icon name="error" color="var(--color-error)" />,
      },
      {
        title: t('news_publication'),
        align: 'center' as const,
        width: '80px',
        dataIndex: 'isPublish',
        render: (isPublish: boolean) => {
          return (
            <Icon
              name="okFilled"
              color={`var(${isPublish ? '--color-success' : '--color-placeholder-25'})`}
            />
          );
        },
      },
      {
        title: t('news_updated'),
        sorter: true,
        align: 'center' as const,
        dataIndex: 'updatedAt',
        key: 'updated_at',
        render: (date: ArticleType['updatedAt']) => date && <TableDate date={date} />,
        defaultSortOrder: 'descend',
      },
      {
        width: '80px',
        render: (article: ArticleType) => <NewsTablePin article={article} canPinned={canPinNews} />,
      },
    ],
    [canPinNews],
  );
};

export default useColumns;
