import { ChangeEvent, FC, useCallback, useState } from 'react';

import { t } from 'i18next';

import { useUpdateSocialProgramPayoutAmountMutation } from 'services/social-program-category/socialProgramCategoryApiService';

import { Form, Modal } from 'gazprom-ui-lib';

import ModalBody from '../components/modal-body';
import ModalButtons from '../components/modal-buttons';
import ModalHeader from '../components/modal-header';

interface ModalAddPaymentProps {
  open: boolean;
  onClose: () => void;
  onFinish: (id: string) => void;
}

const ModalAddPayment: FC<ModalAddPaymentProps> = (props) => {
  const [form] = Form.useForm();
  const { onClose, open, onFinish } = props;
  const [value, setValue] = useState('');
  const [mutation, result] = useUpdateSocialProgramPayoutAmountMutation();

  const onValueChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  }, []);

  const handleFinish = () => {
    mutation({ name: value })
      .unwrap()
      .then((res) => onFinish(res.id))
      .then(() => form.resetFields());
  };

  return (
    <Modal open={open} onCancel={onClose}>
      <ModalHeader text={t('social_program_modal_add_new_payment')} onClose={onClose} />
      <Form form={form} onFinish={handleFinish}>
        <ModalBody
          onChange={onValueChange}
          value={value}
          text={t('social_program_modal_payment_name')}
        />
        <ModalButtons disabled={!value.length} onClose={onClose} loading={result.isLoading} />
      </Form>
    </Modal>
  );
};

export default ModalAddPayment;
