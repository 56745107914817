import { FC, useContext } from 'react';

import { SaveModalContext } from 'app.utils';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useDeleteSocialProgramMutation } from 'services/social-program/socialProgramApiService';

import { Button } from 'gazprom-ui-lib';

import s from './Actions.module.scss';

interface ActionsProps {
  saveButtonIsLoading: boolean;
}

const Actions: FC<ActionsProps> = (props) => {
  const { saveButtonIsLoading } = props;

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const setModalConfig = useContext(SaveModalContext);

  const [deleteSocialProgram, { isLoading: deleteIsLoading }] = useDeleteSocialProgramMutation();
  const closeModalHandler = () => {
    if (setModalConfig) {
      setModalConfig(null);
    }

    navigate(-1);
  };

  const handleDelete = () => {
    if (id) {
      deleteSocialProgram({ ids: [id] }).finally(closeModalHandler);
    }
  };

  const handleDeleteModalOpen = () => {
    if (setModalConfig) {
      setModalConfig({
        icon: {
          name: 'delete',
          type: 'danger',
        },
        title: {
          children: t('common_accept_remove'),
        },
        description: {
          children: t('social_program_will_be_deleted'),
        },
        actions: (
          <Button type="secondary" onClick={handleDelete} loading={deleteIsLoading}>
            {t('common_remove')}
          </Button>
        ),
      });
    }
  };

  const handleCloseModalOpen = () => {
    if (setModalConfig) {
      setModalConfig({
        icon: {
          name: 'unpublishFilled',
          type: 'danger',
        },
        title: {
          children: t('social_program_close_without_save'),
        },
        description: {
          children: t('common_all_changes_will_be_lost'),
        },
        actions: (
          <Button type="secondary" onClick={closeModalHandler}>
            {t('common_confirm')}
          </Button>
        ),
      });
    }
  };

  return (
    <div className={s.buttons}>
      <Button
        fullWidth
        type="primary"
        disabled={saveButtonIsLoading}
        leftIcon="ok"
        htmlType="submit"
        loading={saveButtonIsLoading}>
        {t('common_save')}
      </Button>

      {id && (
        <Button
          fullWidth
          type="primary"
          onClick={handleDeleteModalOpen}
          className={s.delete}
          leftIcon="binFilled">
          {t('common_remove')}
        </Button>
      )}

      <Button fullWidth type="secondary" onClick={handleCloseModalOpen}>
        {t('common_close')}
      </Button>
    </div>
  );
};

export default Actions;
