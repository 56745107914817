import cn from 'classnames';

import { Icon, Tag, Typography } from 'gazprom-ui-lib';

import { SURVEY_ACTIVE_ENUM, SURVEY_STATUSES_ENUM } from 'types/surveys.types';

import s from './Status.module.scss';
import { getStatus } from './status.utils';

interface Props {
  status: SURVEY_STATUSES_ENUM | SURVEY_ACTIVE_ENUM | null;
  className?: string;
  size?: 'small' | 'default';
}

const Status = (props: Props) => {
  const { status, className, size = 'default' } = props;
  const currentStatus = getStatus(status);

  if (!currentStatus) {
    return null;
  }

  const { icon, label, type } = currentStatus;

  return (
    <Tag type={type} className={cn(s.container, s[size], className)}>
      <Icon name={icon} size={14} />
      <Typography.Text size="14" weight="500">
        {label}
      </Typography.Text>
    </Tag>
  );
};

export default Status;
