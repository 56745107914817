import { FC, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { DOCUMENTS_SINGS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { useSignFileMutation } from 'services/sign/signApiService';

import { Button, Form, Icon, Modal, Radio, Steps, Typography, handleRequest } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';
import WithLoader from 'containers/wrappers/with-loader';

import s from './Sign.module.scss';
import './sign.utils';
import {
  checkCryptoProvider,
  checkExtension,
  checkPlugin,
  getCertificates,
  handleDownloadAsBlob,
  signFile,
} from './sign.utils';

type CertificateType = {
  validTo: Date;
  validFrom: Date;
  subjectName: string;
  issuerName: string;
  algoName: string;
  valid: boolean;
};

const Sign: FC = () => {
  const { state } = useLocation();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isSignLoading, setIsSignLoading] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [signFileRequest] = useSignFileMutation();
  const [certificates, setCertificates] = useState<CertificateType[]>([]);
  const [activeStep, setActiveStep] = useState<{
    number: number;
    state: 'pending' | 'done' | 'disabled' | 'error';
  }>({
    number: 0,
    state: 'pending',
  });

  const handleSign = async () => {
    setIsSignLoading(true);
    const blob = await handleDownloadAsBlob(state.assignmentId, state.fileKey);
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const sign = await signFile(selectedCertificate, event.target?.result as string);
        console.log('sign', sign);
        if (sign) {
          const signFileProps = {
            id: state.assignmentId,
            fileKey: state.fileKey,
            signature: sign,
          };

          console.log('start request', sign, state);

          signFileRequest(signFileProps).then(
            handleRequest({
              onSuccess: () =>
                navigate(`${DOCUMENTS_SINGS_ROUTE}/${state.assignmentId}/${state.executorId}`),
              onError: (err) => {
                console.log(err, 'err');
                setIsErrorModalVisible(true);
              },
            }),
          );
        } else {
          setIsErrorModalVisible(true);
        }
      } catch (e) {
        setIsErrorModalVisible(true);
      }
      setIsSignLoading(false);
    };
    reader.onerror = () => {
      setIsSignLoading(false);
    };
    reader.readAsDataURL(blob);
  };

  const selectedCertificate = Form.useWatch('certificate', form);

  const steps = useMemo(() => {
    return [
      {
        title: t('sign_step0_pending_title'),
        state: activeStep.number === 0 ? activeStep.state : 'done',
      },
      {
        title: t('sign_step1_pending_title'),
        state:
          activeStep.number === 1 ? activeStep.state : activeStep.number > 1 ? 'done' : 'disabled',
      },
      {
        title: t('sign_step2_pending_title'),
        state:
          activeStep.number === 2 ? activeStep.state : activeStep.number > 2 ? 'done' : 'disabled',
      },
      {
        title: t('sign_step3_pending_title'),
        state:
          activeStep.number === 3 ? activeStep.state : activeStep.number > 3 ? 'done' : 'disabled',
      },
    ];
  }, [activeStep]);

  const extensionStep = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const isExtensionInstalled = checkExtension();

        if (isExtensionInstalled) {
          setActiveStep({
            number: 1,
            state: 'pending',
          });
          resolve(true);
        } else {
          setActiveStep({
            number: 0,
            state: 'error',
          });
          resolve(false);
        }
      }, 1000);
    });
  };

  const pluginStep = async () => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const isPluginInstalled = await checkPlugin();

        if (isPluginInstalled) {
          setActiveStep({
            number: 2,
            state: 'pending',
          });
          resolve(true);
        } else {
          setActiveStep({
            number: 1,
            state: 'error',
          });
          resolve(false);
        }
      }, 1000);
    });
  };

  const cryptoProviderStep = async () => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const isCryptoProviderInstalled = await checkCryptoProvider();

        if (isCryptoProviderInstalled) {
          setActiveStep({
            number: 3,
            state: 'pending',
          });
          resolve(true);
        } else {
          setActiveStep({
            number: 2,
            state: 'error',
          });
          resolve(false);
        }
      }, 1000);
    });
  };

  useEffect(() => {
    const run = async () => {
      const isExtensionStepSuccess = await extensionStep();
      if (isExtensionStepSuccess) {
        const isPluginStepSuccess = await pluginStep();

        if (isPluginStepSuccess) {
          const isCryptoProviderSuccess = await cryptoProviderStep();

          if (isCryptoProviderSuccess) {
            try {
              const certs = (await getCertificates()) as CertificateType[];
              setCertificates(certs);
            } catch (e) {
              setActiveStep({
                number: 3,
                state: 'error',
              });
            }
          }
        }
      }
    };

    run();
  }, []);

  const renderCertificate = (certificate: CertificateType) => {
    const splitSubject = certificate.subjectName.split(', ');
    const title = splitSubject.find((part) => part.includes('CN='));
    return (
      <div className={s.radio}>
        <Radio.Button
          disabled={!certificate.valid}
          value={`${certificate.validFrom.toString()}.${certificate.validTo.toString()}.${
            certificate.subjectName
          }`}>
          <span className={s.radioTitle}>{title}</span>
        </Radio.Button>
        <div className={s.certificate}>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_issuer')}</Typography.Text>
            <Typography.Text type="secondary">{certificate.issuerName}</Typography.Text>
          </div>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_owner')}</Typography.Text>
            <Typography.Text type="secondary">{certificate.subjectName}</Typography.Text>
          </div>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_date_from')}</Typography.Text>
            <Typography.Text type="secondary">
              {dayjs(certificate.validFrom).format('YYYY-MM-DD HH:mm:ss')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_date_to')}</Typography.Text>
            <Typography.Text type="secondary">
              {dayjs(certificate.validTo).format('YYYY-MM-DD HH:mm:ss')}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_algo')}</Typography.Text>
            <Typography.Text type="secondary">{certificate.algoName.toString()}</Typography.Text>
          </div>
          <div>
            <Typography.Text type="primary">{t('sign_certificate_valid')}</Typography.Text>
            <Typography.Text type="secondary">
              {certificate.valid ? t('common_yes') : t('common_no')}
            </Typography.Text>
          </div>
        </div>
      </div>
    );
  };

  return (
    <WithLoader isLoading={false}>
      <div className={s.header}>
        <Button
          size="small"
          htmlType="button"
          type="link"
          leftIcon="arrowLeft"
          className={s.goBack}
          onClick={() => navigate(-1)}>
          {t('common_go_back')}
        </Button>
        <Typography.Title level={3}>{t('sign_title')}</Typography.Title>
      </div>
      <div className={s.content}>
        <div className={s.left}>
          <Typography.Title level={4}>
            {t(`sign_step${activeStep.number}_${activeStep.state}_title`)}
          </Typography.Title>
          <Typography.Text size="14" type="secondary">
            {t(`sign_step${activeStep.number}_${activeStep.state}_description`)}
          </Typography.Text>

          {activeStep.state !== 'error' && activeStep.number !== 3 && (
            <div className={s.waiting}>
              <UniversalLoader />
              <Typography.Text size="14" type="secondary">
                {t('sign_waiting_title')}
              </Typography.Text>
            </div>
          )}

          {activeStep.number === 3 && certificates.length > 0 && (
            <div>
              <Form form={form}>
                <div />
                <Form.Item name="certificate">
                  <Radio.Group className={s.radioGroup}>
                    {certificates.map(renderCertificate)}
                  </Radio.Group>
                </Form.Item>
                {selectedCertificate && (
                  <Button className={s.button} onClick={handleSign} loading={isSignLoading}>
                    {t('common_sign')}
                  </Button>
                )}
              </Form>
            </div>
          )}
        </div>
        <div className={s.right}>
          <Typography.Title level={5} className={s.title}>
            {t('sign_steps_title')}
          </Typography.Title>
          <Steps className="s" items={steps} hideLeft />
        </div>
      </div>
      <Modal
        width={320}
        closable
        open={isErrorModalVisible}
        onCancel={() => setIsErrorModalVisible(false)}>
        <div className={s.modalContent}>
          <div className={s.icon}>
            <Icon name="error" size={40} color="var(--color-neutral-1)" />
          </div>
          <Typography.Title level={3}>{t('common_error')}</Typography.Title>
          <Typography.Title level={5} strong={false} type="secondary">
            {t('errors_sign_documents')}
          </Typography.Title>
        </div>
        <Button fullWidth onClick={() => setIsErrorModalVisible(false)}>
          {t('common_ok')}
        </Button>
      </Modal>
    </WithLoader>
  );
};

export default Sign;
