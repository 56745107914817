import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  DeleteCommentPropsType,
  DeleteCommentResponseType,
  GetArticlesPropsType,
  GetArticlesResponseType,
} from './commentApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createCommentUrl } from './utils';

const commentApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query<GetArticlesResponseType, GetArticlesPropsType>({
      query: (params) => ({
        url: createCommentUrl(prepareQueryParams(params)),
      }),
      providesTags: [SERVICE_TAGS.GET_COMMENTS],
    }),
    deleteComment: builder.mutation<DeleteCommentResponseType, DeleteCommentPropsType>({
      query: ({ id }) => ({
        url: createCommentUrl(`/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_COMMENTS],
    }),
  }),
});

export const { useLazyGetCommentsQuery, useDeleteCommentMutation } = commentApiService;
