import { ChangeEvent, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { FiltersResponseType } from 'containers/filters/filters.utils';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import Header from './containers/header';
import Table from './containers/table';
import { DEFAULT_REQUEST_BODY, RequestBodyType } from './faq.utils';
import useDataHook from './useData.hook';

const Faq = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state, pathname } = location || {};
  const [requestBody, setRequestBody] = useState<RequestBodyType>(DEFAULT_REQUEST_BODY);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prevState) => ({ ...prevState, search: event.target.value }));
  };

  const handleSetFilters = (filters: FiltersResponseType) => {
    setRequestBody((prevState) => ({ ...prevState, filters }));
  };

  const { combinedTreeData, isLoading, isFetching } = useDataHook({ requestBody });

  useEffect(() => {
    const stateFromNavigationExists = state && (state.requestBody || state.role);

    if (stateFromNavigationExists) {
      let bodyToSet: RequestBodyType = {
        ...DEFAULT_REQUEST_BODY,
      };
      if (state.requestBody) {
        bodyToSet = state.requestBody as RequestBodyType;
      }

      setRequestBody(bodyToSet);
      navigate(pathname, { replace: true });
    }
  }, [navigate, pathname, state]);

  return (
    <WithOverflow>
      <Header />
      <Filters
        search={requestBody.search}
        handleSearchChange={handleSearchChange}
        filters={requestBody.filters}
        setFilters={handleSetFilters}
      />
      <WithLoader isLoading={isLoading}>
        <Table data={combinedTreeData} isFetching={isFetching} requestBody={requestBody} />
      </WithLoader>
    </WithOverflow>
  );
};

export default Faq;
