import { StepsProps } from 'gazprom-ui-lib';

export const prepareHistoryForSteps = (history?: StepsProps['items']): StepsProps['items'] => {
  if (!history) return [];

  return history.map((item, index) => ({
    ...item,
    description: index === history?.length - 1 ? item.description : '',
  }));
};
