import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useGetEmployeeGroupByIdQuery } from 'services/employee-groups-new/employeeGroupsNewApiService';
import { setInitialState, setIsChanged } from 'slices/employeeGroupsSlice';

import { Form, Tabs } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import s from './EmployeeGroupsCreate.module.scss';
import Header from './containers/header';
import SaveModal from './containers/save-modal';
import { EmployeeGroupsFormResponse, TABS } from './employeeGroupsCreate.utils';
import useEmployeeGroupsCreate from './useEmployeeGroupsCreate.hook';

const EmployeeGroupsCreate = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [activeTab, setActiveTab] = useState(TABS[0].key);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { data, isLoading } = useGetEmployeeGroupByIdQuery(id ?? skipToken);

  const { handleCreateGroup, handleUpdateGroup } = useEmployeeGroupsCreate({
    title: data?.title ?? '',
  });

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, [dispatch]);

  const handleChanges = () => {
    setIsButtonDisabled(true);
    dispatch(setIsChanged(false));
  };

  const onFinishHandler = (resultData: EmployeeGroupsFormResponse) => {
    if (id) {
      handleUpdateGroup();
      handleChanges();
      return;
    }

    handleCreateGroup(resultData);
    handleChanges();
  };

  const onChangeHandler = () => form.isFieldsTouched() && setIsButtonDisabled(false);
  const handleModalClose = () => setModalTitle('');

  return (
    <WithLoader isLoading={isLoading}>
      <Form form={form} onFinish={onFinishHandler} onChange={onChangeHandler}>
        <Header
          groupData={data}
          setModalTitle={setModalTitle}
          isButtonDisabled={isButtonDisabled}
          setIsButtonDisabled={setIsButtonDisabled}
        />
        <Tabs
          items={TABS}
          type="card"
          onTabClick={setActiveTab}
          activeKey={activeTab}
          className={s.tabs}
        />
        <SaveModal
          setModalTitle={setModalTitle}
          modalTitle={modalTitle}
          handleModalClose={handleModalClose}
        />
      </Form>
    </WithLoader>
  );
};

export default EmployeeGroupsCreate;
