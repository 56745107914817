import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { StatementType } from 'types/documents.types';

const useColumns = (): ColumnsType<StatementType> => {
  const employeeRenderer = (employee: StatementType['employee']) => {
    const { person, employer } = employee;
    const { firstName, lastName } = person;
    const { name } = employer;

    return <TablePerson firstName={firstName} lastName={lastName} department={name} />;
  };

  const statementRenderer = (statement: StatementType['statement']) => {
    const { name } = statement;

    return (
      <Typography.Text size="12" weight="500">
        {name}
      </Typography.Text>
    );
  };

  const statusRenderer = (currentKedoStatus: StatementType['currentKedoStatus']) => {
    return <KedoStatusTag currentKedoStatus={currentKedoStatus} size="24" />;
  };

  const dateRenderer = (date: StatementType['createdAt'] | StatementType['updatedAt']) => (
    <TableDate date={date} />
  );

  return [
    {
      title: t('common_employee'),
      dataIndex: 'employee',
      render: employeeRenderer,
    },
    {
      title: t('common_inquiries_type'),
      dataIndex: 'statement',
      render: statementRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'currentKedoStatus',
      render: statusRenderer,
    },
    {
      title: t('common_created'),
      dataIndex: 'createdAt',
      align: 'center',
      render: dateRenderer,
      width: 130,
      sorter: true,
    },
    {
      title: t('common_updated'),
      dataIndex: 'updatedAt',
      align: 'center',
      render: dateRenderer,
      width: 130,
      sorter: true,
      defaultSortOrder: 'descend',
    },
  ];
};

export default useColumns;
