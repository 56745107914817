import { jwtDecode } from 'jwt-decode';

export const isTokenValid = (token: string | null): boolean => {
  if (!token) {
    return false;
  }

  const decodedToken = jwtDecode(token);

  if (!decodedToken) {
    return false;
  }

  return decodedToken.exp! * 1000 > Date.now();
};
