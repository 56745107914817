import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

const keycloakConfig: AuthProviderProps = {
  authority: process.env.https://sso.dev.tockl.ru/realms/tockl as string,
  client_id: process.env.admin-gsp as string,
  redirect_uri: `${window.location.protocol}//${window.location.host}`,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userManager = new UserManager(keycloakConfig);

export { userManager };
