import { useSearchParams } from 'react-router-dom';

import { Tabs } from 'gazprom-ui-lib';

import WithOverflow from 'containers/wrappers/with-overflow';

import s from './SurveysDetails.module.scss';
import Header from './containers/header';
import { SURVEYS_STATS, items } from './surveysDetails.utils';

const SurveysDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <WithOverflow className={s.wrapper}>
      <Header />
      <Tabs
        type="card"
        items={items}
        className={s.tabs}
        defaultActiveKey={searchParams.get('tabId') ?? SURVEYS_STATS}
        onChange={(tabName) => setSearchParams({ tabId: tabName })}
      />
    </WithOverflow>
  );
};

export default SurveysDetails;
