import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import { Flex } from 'gazprom-ui-lib';

import s from './WithOverflow.module.scss';

interface WithOverflowProps extends PropsWithChildren {
  className?: string;
}

const WithOverflow: FC<WithOverflowProps> = (props) => {
  const { children, className } = props;

  return (
    <Flex className={cn(s.wrapper, className)} vertical>
      {children}
    </Flex>
  );
};

export default WithOverflow;
