import { FC, useEffect, useRef } from 'react';

import { formRules } from 'utils/formRules';

import { Form, Typography } from 'gazprom-ui-lib';

import Editor from 'containers/editor';
import { EVENT_FORM_ENUM } from 'containers/event-form/eventForm.utils';

import { NOTIFICATION_TEMPLATE_FORM_ENUM } from '../notificationTemplateForm.utils';

interface NotificationTemplateFormTextProps {
  title: string;
}

const NotificationTemplateFormText: FC<NotificationTemplateFormTextProps> = (props) => {
  const { title } = props;

  const editorRef = useRef<Editor>(null);
  const setRef = useRef<boolean>(false);

  const form = Form.useFormInstance();

  const editorValue = Form.useWatch(NOTIFICATION_TEMPLATE_FORM_ENUM.TEXT);

  useEffect(() => {
    if (editorValue && editorRef && !setRef.current) {
      editorRef.current?.setEditorData(editorValue);
      setRef.current = true;
    }
  }, [editorRef, editorValue]);

  const handleSetEditorValue = (v: string) => {
    setRef.current = true;
    form.setFieldValue(EVENT_FORM_ENUM.DESCRIPTION, v);
  };

  return (
    <div>
      <Typography.Text strong size="16">
        {title}
      </Typography.Text>
      <Form.Item name={NOTIFICATION_TEMPLATE_FORM_ENUM.TEXT} rules={[formRules.required]}>
        <Editor onChange={handleSetEditorValue} ref={editorRef} />
      </Form.Item>
    </div>
  );
};

export default NotificationTemplateFormText;
