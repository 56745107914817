import { FC, ReactNode } from 'react';

import { EVENTS_ROUTE } from 'routes/events/list';

import ContentHeader from 'containers/content-header';

import { EventType } from 'types/event.types';

interface HeaderProps {
  event?: EventType;
  headerButton: ReactNode;
}

const Header: FC<HeaderProps> = (props) => {
  const { headerButton, event } = props;
  return (
    <ContentHeader align="flex-start">
      <ContentHeader.Title goBackLink={EVENTS_ROUTE}>{event?.article?.title}</ContentHeader.Title>
      <ContentHeader.Actions>{headerButton}</ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
