import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, TextArea } from 'gazprom-ui-lib';

import { FORM_ENUM } from '../../faqQuestionForm.utils';
import s from './Title.module.scss';

const Title = () => {
  return (
    <Form.Item
      name={FORM_ENUM.TITLE}
      label={t('common_question_title')}
      className={s.wrapper}
      rules={[formRules.required]}>
      <TextArea placeholder={t('common_enter_text')} maxLength={255} showCount />
    </Form.Item>
  );
};

export default Title;
