import { useCallback, useEffect, useRef } from 'react';

import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';

import { LAST_PATH } from './useLastPath.hook';
import { LAST_ACTION_TIMESTAMP } from './useLastUserAction.hook';
import useLocalStorage from './useLocalStorage.hook';

export const PIN_CODE_TOKEN = 'pinCodeToken';

const PIN_CODE_EXPIRATION_TIME = 1000 * 60 * 15;
const ACTIVITY_CHECK_INTERVAL = 1000 * 5;

const usePinCodeAuthorizeHook = () => {
  const { signoutSilent } = useAuth();
  const location = useLocation();

  const [, setStorageLastPath] = useLocalStorage(LAST_PATH, '');
  const [lastActionTime] = useLocalStorage(LAST_ACTION_TIMESTAMP, Date.now());
  const timerRef = useRef<null | ReturnType<typeof setTimeout>>(null);

  const handlePinCodeAuthorization = useCallback(() => {
    const isUserInactive = Date.now() - lastActionTime > PIN_CODE_EXPIRATION_TIME;

    if (isUserInactive) {
      signoutSilent();
      setStorageLastPath(location.pathname);

      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  }, [lastActionTime, signoutSilent, setStorageLastPath, location.pathname]);

  const startInactivityTimer = useCallback(() => {
    const isAuthorizedWithPinCode = window.location.search.includes('?state');

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (!isAuthorizedWithPinCode) {
      handlePinCodeAuthorization();
      timerRef.current = setInterval(handlePinCodeAuthorization, ACTIVITY_CHECK_INTERVAL);
    }
  }, [handlePinCodeAuthorization]);

  useEffect(() => {
    startInactivityTimer();
  }, [lastActionTime, startInactivityTimer]);
};

export default usePinCodeAuthorizeHook;
