import { kedoRoles } from 'roles/documents.roles';

import SignPage from 'pages/sign';

import { RouteConfig } from '../routes';
import { SIGN_ROUTE } from './list';

export const signRoutes: RouteConfig[] = [
  {
    path: SIGN_ROUTE,
    element: <SignPage />,
    ...kedoRoles,
  },
];
