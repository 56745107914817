import { t } from 'i18next';

import { GetEmployees1cPropsType } from 'services/1c/cApiService.types';

import FiltersWrapper from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import s from './Filters.module.scss';
import useFilters from './useFilters';

interface Props {
  filters?: Pick<GetEmployees1cPropsType, 'citizenships'> | null;
  setFilters: (values: Record<string, string[]> | null) => void;
}

const Filters = (props: Props) => {
  const { filters, setFilters } = props;

  const {
    employersResult,
    positionsResult,
    divisionsResult,
    citizenshipOptions,
    positionsSearchHandler,
    employersSearchHandler,
    divisionsSearchHandler,
  } = useFilters();

  const items = [
    {
      key: 0,
      label: <FilterHeader valueKey="employerIds">{t('common_organization')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="employerIds"
          optionFilterProp="value"
          options={employersResult.employersOptions}
          showSearch
          searchProps={{
            placeholder: t('common_organization_search'),
            onChange: employersSearchHandler,
          }}
        />
      ),
    },
    {
      key: 1,
      label: (
        <FilterHeader valueKey="positionIds">{t('employee_group_column_position')}</FilterHeader>
      ),
      children: (
        <FilterCheckboxGroup
          valueKey="positionIds"
          optionFilterProp="value"
          options={positionsResult.data}
          showSearch
          searchProps={{
            placeholder: t('employee_group_column_position'),
            onChange: positionsSearchHandler,
          }}
        />
      ),
    },
    {
      key: 2,
      label: (
        <FilterHeader valueKey="divisionIds">{t('employee_group_column_division')}</FilterHeader>
      ),
      children: (
        <FilterCheckboxGroup
          valueKey="divisionIds"
          optionFilterProp="value"
          options={divisionsResult.data}
          showSearch
          searchProps={{
            placeholder: t('common_organization_search'),
            onChange: divisionsSearchHandler,
          }}
        />
      ),
    },
  ];

  return (
    // техдолг нужно поменять таблицу на новые компоненты
    <div className={s.wrapper}>
      <FiltersV2.TableFilters>
        <FiltersWrapper
          initialValues={filters}
          handleApply={setFilters}
          showClearAll
          overlay={
            <>
              <FilterCollapse items={items} />
              <FilterCustomWrapper>
                <FilterHeader valueKey="citizenships">{t('common_citizenship')}</FilterHeader>
                <FilterCheckboxGroup
                  usePadding={false}
                  valueKey="citizenships"
                  options={citizenshipOptions}
                />
              </FilterCustomWrapper>
            </>
          }
        />
      </FiltersV2.TableFilters>
    </div>
  );
};

export default Filters;
