import { useEffect, useState } from 'react';

import { useGetNotificationTemplatesQuery } from 'services/notification-template/notificationTemplateApiService';

import { NotificationTemplateType } from 'types/notification.types';

interface Props {
  templateName: string;
  currentTemplate?: NotificationTemplateType;
}

const INITIAL_REQUEST_BODY = {
  page: 1,
  size: 500,
  search: '',
};

const useTemplateNameAvailable = (props: Props) => {
  const { templateName, currentTemplate } = props;

  const [isTemplateNameAvailable, setIsTemplateNameAvailable] = useState<boolean>(true);

  const {
    data: templates,
    isLoading,
    isFetching,
  } = useGetNotificationTemplatesQuery({
    ...INITIAL_REQUEST_BODY,
    search: templateName,
  });

  useEffect(() => {
    const templateNameChanged = currentTemplate?.name !== templateName;
    const shouldVerifyTemplateName = templateNameChanged && !!templates && !!templateName;

    if (shouldVerifyTemplateName) {
      const foundTemplate = templates.items.find((template) => template.name === templateName);

      setIsTemplateNameAvailable(!foundTemplate);
    } else {
      setIsTemplateNameAvailable(true);
    }
  }, [templates, templateName, currentTemplate]);

  return { isTemplateNameAvailable, isLoading: isLoading || isFetching };
};

export default useTemplateNameAvailable;
