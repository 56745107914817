import { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { EMPLOYEE_GROUPS_ROUTE } from 'routes/employee-groups/list';
import { t } from 'tools/i18n';

import { useLazyGetEmployeeGroupsQuery } from 'services/employee-groups-new/employeeGroupsNewApiService';

import { Button, Typography } from 'gazprom-ui-lib';

import MultiTagsSelect from 'components/form/multi-tags-select';

import {
  DEFAULT_PAGE_DATA,
  KEDO_REVIEW_TASK_FORM_ENUM,
  prepareEmployeeGroupsOption,
} from '../kedoReviewTaskForm.utils';
import s from './Recipient.module.scss';

const Recipient: FC = () => {
  const navigate = useNavigate();

  const [getEmployeeGroups, { data: employeeGroupsResult }] = useLazyGetEmployeeGroupsQuery();

  useEffect(() => {
    getEmployeeGroups(DEFAULT_PAGE_DATA);
  }, [getEmployeeGroups]);

  const handleNavigateToEmployeeGroups = () => {
    navigate(EMPLOYEE_GROUPS_ROUTE);
  };

  return (
    <div className={s.additional}>
      <Typography.Title level={5}>{t('documents_task_for')}</Typography.Title>
      <div>
        <MultiTagsSelect
          name={KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEE_GROUPS}
          title={t('surveys_add_group')}
          placeholder={t('surveys_select_group')}
          options={prepareEmployeeGroupsOption(employeeGroupsResult?.content ?? [])}
          className={s.receiversContainer}
          isRequired={true}
        />
        <Button
          type="link"
          leftIcon="groups"
          className={s.receiversLink}
          onClick={handleNavigateToEmployeeGroups}>
          {t('surveys_groups_management')}
        </Button>
      </div>
    </div>
  );
};

export default Recipient;
