import { FC } from 'react';

import { formRules } from 'utils/formRules';

import { DatePicker, Flex, Form, TimePicker } from 'gazprom-ui-lib';

import { EVENT_FORM_ENUM, FORM_LABELS } from '../../../eventForm.utils';
import s from './DateAndTime.module.scss';

const DateAndTime: FC = () => {
  return (
    <Flex gap="16" className={s.wrapper}>
      <Form.Item
        name={EVENT_FORM_ENUM.EVENT_DATE}
        rules={[formRules.required]}
        label={FORM_LABELS[EVENT_FORM_ENUM.EVENT_DATE]}>
        <DatePicker />
      </Form.Item>
      <Form.Item
        name={EVENT_FORM_ENUM.EVENT_TIME}
        rules={[formRules.required]}
        label={FORM_LABELS[EVENT_FORM_ENUM.EVENT_TIME]}>
        <TimePicker format="HH:mm" placeholder="00:00" />
      </Form.Item>
    </Flex>
  );
};

export default DateAndTime;
