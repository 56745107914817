import { ButtonProps } from 'gazprom-ui-lib';

import { NEWS_FORM_NAME } from 'containers/news-form/newsForm.utils';

export const EDIT_KEY = 'EDIT_KEY';
export const COMMENTS_KEY = 'COMMENTS_KEY';

export const saveEditButtonConfig: ButtonProps = {
  htmlType: 'submit',
  form: NEWS_FORM_NAME,
};
export const saveCommentsButtonConfig: ButtonProps = {
  disabled: true,
};
export const resetEditButtonConfig: ButtonProps = {
  htmlType: 'reset',
  form: NEWS_FORM_NAME,
};
export const resetCommentsButtonConfig: ButtonProps = {
  disabled: true,
};
