export enum SERVICE_TAGS {
  GET_FEEDBACKS = 'GET_FEEDBACKS',
  GET_ARTICLES = 'GET_ARTICLES',
  GET_ARTICLES_CATEGORIES = 'GET_ARTICLES_CATEGORIES',
  GET_SOCIAL_PROGRAMS = 'GET_SOCIAL_PROGRAMS',
  GET_SOCIAL_PROGRAM_BY_ID = 'GET_SOCIAL_PROGRAM_BY_ID',
  GET_SOCIAL_PROGRAM_EMPLOYEE_REQUIREMENTS = 'GET_SOCIAL_PROGRAM_EMPLOYEE_REQUIREMENTS',
  GET_SOCIAL_PROGRAM_DOCUMENTS = 'GET_SOCIAL_PROGRAM_DOCUMENTS',
  GET_SOCIAL_PROGRAM_DOCUMENTS_PAYOUT_AMOUNT = 'GET_SOCIAL_PROGRAM_DOCUMENTS_PAYOUT_AMOUNT',
  GET_COMMENTS = 'GET_COMMENTS',
  GET_SURVEYS = 'GET_SURVEYS',
  GET_SURVEYS_SETTINGS_BY_ID = 'GET_SURVEYS_SETTINGS_BY_ID',
  GET_NOTIFICATION_TEMPLATES = 'GET_NOTIFICATION_TEMPLATES',
  GET_NOTIFICATION_TEMPLATE_BY_ID = 'GET_NOTIFICATION_TEMPLATE_BY_ID',
  GET_NOTIFICATION_NEWSLETTER = 'GET_NOTIFICATION_NEWSLETTER',
  GET_NOTIFICATION_NEWSLETTER_BY_ID = 'GET_NOTIFICATION_NEWSLETTER_BY_ID',
  GET_EMPLOYEE_GROUPS = 'GET_EMPLOYEE_GROUPS',
  GET_EMPLOYEE = 'GET_EMPLOYEE',
  GET_EMPLOYEES_BY_GROUP_ID = 'GET_EMPLOYEES_BY_GROUP_ID',
  GET_EVENTS = 'GET_EVENTS',
  GET_EVENT_BY_ID = 'GET_EVENT_BY_ID',
  GET_GUIDE_CATEGORIES = 'GET_GUIDE_CATEGORIES',
  GET_GUIDE_HOT_CONTACTS = 'GET_GUIDE_HOT_CONTACTS',
  GET_GUIDE_HOT_CONTACTS_BY_ID = 'GET_GUIDE_HOT_CONTACTS_BY_ID',
  GET_HANDBOOK_INSTRUCTIONS = 'GET_HANDBOOK_INSTRUCTIONS',
  GET_GUIDE_HANDBOOKS = 'GET_GUIDE_HANDBOOKS',
  GET_GUIDE_HANDBOOK_BY_ID = 'GET_GUIDE_HANDBOOK_BY_ID',
  GET_SURVEY_FREE_STATS = 'GET_SURVEY_FREE_STATS',
  GET_DECLARATION_ATTRIBUTES = 'GET_DECLARATION_ATTRIBUTES',
  GET_DECLARATION_TYPES = 'GET_DECLARATION_TYPES',
  GET_DECLARATION_TYPE_BY_ID = 'GET_DECLARATION_TYPE_BY_ID',
  GET_ROLES = 'GET_ROLES',
  GET_ROLES_BY_CODE = 'GET_ROLES_BY_CODE',
  GET_PERSONS = 'GET_PERSONS',
  GET_PERSON_BY_ID = 'GET_PERSON_BY_ID',
  GET_KEDO_CATEGORIES = 'GET_KEDO_CATEGORIES',
  GET_TELEGRAM_CHANNELS = 'GET_TELEGRAM_CHANNELS',
  GET_TELEGRAM_CHANNEL_BY_ID = 'GET_TELEGRAM_CHANNEL_BY_ID',
  GET_TELEGRAM_AUTHORIZATION_INFO = 'GET_TELEGRAM_AUTHORIZATION_INFO',
  GET_FAQS = 'GET_FAQS',
  GET_FAQ_BY_ID = 'GET_FAQ_BY_ID',
  GET_FAQ_CATEGORIES = 'GET_FAQ_CATEGORIES',
  GET_SURVEY_STATUS = 'GET_SURVEY_STATUS',
  GET_SUGGESTION_DECISIONS = 'GET_SUGGESTION_DECISIONS',
  GET_SUGGESTION_DECISION_BY_ID = 'GET_SUGGESTION_DECISION_BY_ID',
}
export enum C_SERVICE_TAGS {
  ASSIGNMENT_BY_ID = 'ASSIGNMENT_BY_ID',
  SOCIAL_REQUEST_BY_ID = ' SOCIAL_REQUEST_BY_ID',
  STATEMENT_TASK_BY_ID = 'STATEMENT_TASK_BY_ID',
  C_INSTANCES = 'C_INSTANCES',
  C_INSTANCE_BY_ID = 'C_INSTANCE_BY_ID',
  GET_KEDO_REVIEW_TASKS = 'GET_KEDO_REVIEW_TASKS',
  GET_KEDO_REVIEW_TASK_ASSIGNMENTS = 'GET_KEDO_REVIEW_TASK_ASSIGNMENTS',
  GET_KEDO_REVIEW_TASK_BY_ID = 'GET_KEDO_REVIEW_TASK_BY_ID',
  GET_EMPLOYERS = 'GET_EMPLOYERS',
  GET_RENEW_TERM_DETAILS = 'GET_RENEW_TERM_DETAILS',
  GET_RENEW_TERMS = 'GET_RENEW_TERMS',
  GET_AVAILABLE_RENEW_TERMS_POSITIONS = 'GET_AVAILABLE_RENEW_TERMS_POSITIONS',
  GET_RENEW_TERMS_FORMS = 'GET_RENEW_TERMS_FORMS',
  GET_RENEW_TERMS_FORM_BY_ID = 'GET_RENEW_TERMS_FORM_BY_ID',
}

export enum SERVICE_API_V2_TAGS {
  GET_EMPLOYEE_GROUP = 'GET_EMPLOYEE_GROUP',
  GET_EMPLOYEE_GROUP_BY_ID = 'GET_EMPLOYEE_GROUP_BY_ID',
  GET_NOTIFICATION_NEWSLETTER = 'GET_NOTIFICATION_NEWSLETTER',
  GET_NOTIFICATION_NEWSLETTER_BY_ID = 'GET_NOTIFICATION_NEWSLETTER_BY_ID',
}

export const SERVICE_TAGS_LIST = Object.values(SERVICE_TAGS);
export const C_SERVICE_TAGS_LIST = Object.values(C_SERVICE_TAGS);
export const SERVICE_API_V2_TAGS_LIST = Object.values(SERVICE_API_V2_TAGS);
