import { FC } from 'react';

import { Table as TableLib } from 'gazprom-ui-lib';

import { BusinessTripApprovalItemType } from 'types/businessTrip.types';

import useColumns from './useColumns.hook';

interface TableProps {
  dataSource: BusinessTripApprovalItemType[];
}

const Table: FC<TableProps> = (props) => {
  const { dataSource } = props;

  const columns = useColumns();

  return <TableLib rowKey="num" columns={columns} dataSource={dataSource} pagination={false} />;
};

export default Table;
