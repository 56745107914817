import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetSocialProgramsQuery } from 'services/social-program/socialProgramApiService';
import { GetSocialProgramsPropsType } from 'services/social-program/socialProgramApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { SOCIAL_PROGRAM_STATUS_ENUM } from 'types/socialProgram.types';

import { DEFAULT_FORM_STATE, FormResponseType } from './socialProgram.utils';

interface UseRequestProps {
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: UseRequestProps) => {
  const { form } = props;

  const [
    getSocialPrograms,
    { data: socialPrograms, isLoading: isSocialProgramsLoading, isFetching, isUninitialized },
  ] = useLazyGetSocialProgramsQuery();

  const formValues = Form.useWatch([], form);

  const { debouncedValue: debouncedFormValues } = useDebounce(formValues);

  useEffect(() => {
    const { page, size, search, filters, property, order } =
      debouncedFormValues ?? DEFAULT_FORM_STATE;
    const { categories, organizations, status } = filters ?? {};

    const shouldApplyStatusFilter = status && status.length === 1;

    const preparedBody: GetSocialProgramsPropsType = {
      page,
      size,
      nameProgram: search,
    };

    if (property && order) {
      preparedBody.sort = [{ order, property }];
    }

    if (categories) {
      preparedBody.categoryId = categories;
    }

    if (organizations) {
      preparedBody.organizationId = organizations;
    }

    if (shouldApplyStatusFilter) {
      preparedBody.isPublished = !!status.includes(SOCIAL_PROGRAM_STATUS_ENUM.PUBLISHED);
    }

    getSocialPrograms(preparedBody);
  }, [debouncedFormValues, getSocialPrograms]);

  return {
    isLoading: isSocialProgramsLoading || isUninitialized,
    isFetching,
    data: socialPrograms,
  };
};

export default useRequest;
