import { t } from 'tools/i18n';

import SettingsTab from './containers/settings-tab';
import StatsTab from './containers/stats-tab';

export const SURVEYS_STATS = `stats`;
export const SURVEYS_SETTINGS = `settings`;
export const DEFAULT_POLLING_INTERVAL = 5000;

export const items = [
  {
    key: SURVEYS_STATS,
    label: t('surveys_stats'),
    children: <StatsTab />,
  },
  {
    key: SURVEYS_SETTINGS,
    label: t('surveys_settings'),
    children: <SettingsTab />,
  },
];
