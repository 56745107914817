import { FC } from 'react';

import cn from 'classnames';

import { Collapse, CollapseProps } from 'gazprom-ui-lib';

import s from './FilterCollapse.module.scss';

interface FilterCollapseProps {
  items: CollapseProps['items'];
  className?: string;
}

const FilterCollapse: FC<FilterCollapseProps> = (props) => {
  const { className, items } = props;

  return <Collapse accordion className={cn(s.wrapper, className)} items={items} noLeftBorder />;
};

export default FilterCollapse;
