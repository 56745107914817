import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetSurveysQuery } from 'services/surveys/surveysApiService';
import {
  CONDITION_SURVEY_ENUM,
  GetSurveysPropsType,
} from 'services/surveys/surveysApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './surveysOnce.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: Props) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const [getSurveys, { data, isLoading, isFetching, isUninitialized }] = useLazyGetSurveysQuery();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody?.page) {
      const initialRequestBody: GetSurveysPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        condition: CONDITION_SURVEY_ENUM.ONCE,
      };

      if (debouncedRequestBody.search) {
        initialRequestBody.name = debouncedRequestBody.search;
      }

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        initialRequestBody.sort = [
          {
            order: debouncedRequestBody.order,
            property: `ANKETA.${debouncedRequestBody.property}`,
          },
        ];
      }

      getSurveys(initialRequestBody);
    }
  }, [debouncedRequestBody, getSurveys]);

  return {
    data,
    isLoading: isUninitialized || isLoading,
    isFetching: isDebouncing || isFetching,
  };
};

export default useGetData;
