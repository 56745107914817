import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useLazyGetNotificationNewslettersByIdQuery } from 'services/notificationV2/notificationV2ApiService';

const useGetNewsletter = () => {
  const params = useParams<{ newsletterId: string }>();

  const [getNewsletterById, { data: currentNewsletter, isLoading }] =
    useLazyGetNotificationNewslettersByIdQuery();

  useEffect(() => {
    if (params.newsletterId) {
      getNewsletterById(params.newsletterId);
    }
  }, [getNewsletterById, params.newsletterId]);

  return { isLoading, currentNewsletter };
};

export default useGetNewsletter;
