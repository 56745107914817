import { FC, useEffect } from 'react';

import { Checkbox, Form } from 'gazprom-ui-lib';

interface CommonCheckProps {
  name: string;
  checkboxText: string;
  initialValue?: boolean;
  title?: string;
}

const CommonCheck: FC<CommonCheckProps> = (props) => {
  const { title, name, checkboxText, initialValue } = props;
  const form = Form.useFormInstance();

  useEffect(() => {
    initialValue && form.setFieldValue(name, initialValue);
  }, [form, initialValue, name]);

  return (
    <Form.Item name={name} valuePropName="checked" label={title}>
      <Checkbox>{checkboxText}</Checkbox>
    </Form.Item>
  );
};

export default CommonCheck;
