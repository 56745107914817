import { Divider, Typography } from 'gazprom-ui-lib';

import s from './DocumentsDeclarationsTemplate.module.scss';

export const renderAttribute = (attributesObj: Record<string, string>) => (current: string) =>
  (
    <div key={current}>
      <Divider />
      <div className={s.cardText}>
        <Typography.Text size="12" type="primary">
          {current}
        </Typography.Text>
        <Typography.Text size="12" type="secondary">
          {attributesObj[current]}
        </Typography.Text>
      </div>
    </div>
  );
