import { t } from 'tools/i18n';

import { TIconsLiteral } from 'gazprom-ui-lib';

export enum STATUSES {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  CONTINUE = 'CONTINUE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  DEFAULT = 'DEFAULT',
  DONE = 'DONE',
  NEUTRAL = 'NEUTRAL',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  ACCEPT = 'ACCEPT',
  DISABLED = 'DISABLED',
  FAILURE = 'FAILURE',
  IN_PROGRESS = 'IN_PROGRESS',
}

export const getStatusData = (
  status: STATUSES,
): { color: string; label: string | null; icon: TIconsLiteral } => {
  switch (status) {
    case STATUSES.ACCEPT:
      return {
        color: 'success',
        label: t('common_single_confirmed'),
        icon: 'ok',
      };
    case STATUSES.SENT:
    case STATUSES.IN_PROGRESS:
      return {
        color: 'warning',
        label: t('common_under_inspection'),
        icon: 'time',
      };
    case STATUSES.DRAFT:
      return {
        color: 'neutral',
        label: t('common_draft'),
        icon: 'refresh',
      };
    case STATUSES.APPROVED:
    case STATUSES.DONE:
      return {
        color: 'success',
        label: t('thank_you_approved'),
        icon: 'ok',
      };
    case STATUSES.SUCCESS:
      return {
        color: 'success',
        label: t('common_success'),
        icon: 'time',
      };
    case STATUSES.PENDING:
      return {
        color: 'warning',
        label: t('thank_you_pending'),
        icon: 'time',
      };
    case STATUSES.CONTINUE:
      return {
        color: 'continue',
        label: t('common_resent'),
        icon: 'refresh',
      };
    case STATUSES.ERROR:
    case STATUSES.FAILURE:
      return {
        color: 'danger',
        label: t('common_error'),
        icon: 'deleteFilled',
      };
    case STATUSES.DECLINED:
      return {
        color: 'danger',
        label: t('thank_you_declined'),
        icon: 'deleteFilled',
      };
    case STATUSES.NEUTRAL:
      return {
        color: 'neutral',
        label: null,
        icon: 'ok',
      };
    case STATUSES.DISABLED:
      return {
        color: 'neutral',
        label: null,
        icon: 'time',
      };
    default:
      return {
        color: 'warning',
        label: null,
        icon: 'time',
      };
  }
};
