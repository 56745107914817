import { FC, useMemo, useState } from 'react';

import {
  Button,
  Flex,
  Table as TableLib,
  TableProps as TablePropsLib,
  Typography,
} from 'gazprom-ui-lib';

import FaqCategoryModal from 'containers/faq-category-modal';

import { FaqTreeDataType, RequestBodyType } from '../../faq.utils';
import DeleteModal from '../delete-modal';
import s from './Table.module.scss';
import useColumns from './useColumns.hook';
import useSearch from './useSearch';

interface TableProps {
  isFetching: boolean;
  data?: FaqTreeDataType[];
  requestBody: RequestBodyType;
}

const Table: FC<TableProps> = (props) => {
  const { isFetching, data, requestBody } = props;

  const [deleteData, setDeleteData] = useState<FaqTreeDataType | null>(null);
  const [editData, setEditData] = useState<FaqTreeDataType | null>(null);

  const columns = useColumns({ setDeleteData, setEditData, requestBody });
  const { expandedRowKeys, handleExpand } = useSearch({ data, requestBody });

  const titleRenderer = (rowData: FaqTreeDataType) => {
    if (rowData.type === 'faq') {
      return (
        <Typography.Text weight="500" size="14">
          {rowData.title}
        </Typography.Text>
      );
    }

    return <Typography.Title level={5}>{rowData.title}</Typography.Title>;
  };

  const expandable: TablePropsLib<FaqTreeDataType>['expandable'] = {
    expandIcon: (row) => {
      const { record, expandable, expanded } = row;
      const isExpandable = Array.isArray(expandable);

      let action = <div className={s.plug} />;
      if (isExpandable) {
        action = (
          <Button
            size="small"
            type="link"
            onlyIcon
            onClick={(e) => row.onExpand(record, e)}
            leftIcon={expanded ? 'down' : 'forward'}
          />
        );
      }

      return (
        <Flex align="center">
          {action}
          {titleRenderer(record)}
        </Flex>
      );
    },
    expandedRowKeys,
    onExpand: handleExpand,
  };

  const handleClose = () => {
    setDeleteData(null);
    setEditData(null);
  };

  const editCategoryData = useMemo(
    () =>
      editData
        ? {
            id: editData.key,
            name: editData.title,
            isPublish: editData.isPublish,
            parent: editData.parentId,
          }
        : undefined,
    [editData],
  );

  return (
    <>
      <TableLib<FaqTreeDataType>
        className={s.wrapper}
        expandable={expandable}
        loading={isFetching}
        dataSource={data}
        columns={columns}
        rowKey="key"
        scroll={{ y: 800 }}
        pagination={false}
      />
      <DeleteModal data={deleteData} onClose={handleClose} />
      <FaqCategoryModal open={!!editData} onClose={handleClose} data={editCategoryData} />
    </>
  );
};

export default Table;
