import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import {
  useGetDeclarationTypeQuery,
  useRemoveTemplateMutation,
  useUploadTemplateMutation,
} from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Form, Icon, Tooltip, Typography, UploadProps, handleRequest } from 'gazprom-ui-lib';

import File from 'components/file';
import { FORM_IMAGE_ENUM } from 'containers/image-modal/imageModal.utils';

import { FileDataType } from 'types/documents.types';

import s from './DocumentsDescriptionsFormUpload.module.scss';
import {
  AVAILABLE_TYPES,
  beforeUpload,
  getDownloadUrl,
  initialFileValues,
} from './documentsDescriptionsFormUpload.utils';

const DocumentsDescriptionsFormUpload = () => {
  const { id } = useParams<{ id: string }>();
  const form = Form.useFormInstance();
  const [fileData, setFileData] = useState<FileDataType>(initialFileValues);

  const { data: declarationData } = useGetDeclarationTypeQuery(id!);
  const [uploadFile, { isLoading, isError, reset: uploadMutationReset }] =
    useUploadTemplateMutation();
  const [removeTemplateMutation] = useRemoveTemplateMutation();

  useEffect(() => {
    if (declarationData?.docTemplate) {
      const title = declarationData.docTemplate.name;
      const date = dayjs(declarationData.docTemplate.createdAt).format('YYYY-MM-DD HH:mm:ss');

      setFileData((prev) => ({
        ...prev,
        title,
        description: date,
        icon: 'documents',
        isLoaded: true,
      }));
    }
  }, [declarationData]);

  const setErrors = (errors: string[]) => form.setFields([{ name: FORM_IMAGE_ENUM.URL, errors }]);

  const customRequest: UploadProps['customRequest'] = (options) => {
    const file = (options as { file: File }).file;
    const formData = new FormData();
    formData.append('template', file);

    const onUploadError = () => {
      setFileData({
        title: file.name,
        description: t('common_template_has_error'),
        icon: 'error',
        isLoaded: true,
      });
    };
    const onUploadSuccess = () => {
      setFileData({
        title: file.name,
        description: `${(file.size / (1024 * 1024)).toFixed(2)} Мб.`,
        icon: 'documents',
        isLoaded: true,
      });
    };

    uploadFile({ id: id!, file: formData }).then(
      handleRequest({
        onSuccess: onUploadSuccess,
        onError: onUploadError,
      }),
    );
  };

  const onClickResetHandler = () => {
    const documentId = declarationData?.docTemplate?.id;

    form.resetFields(['file']);
    setFileData(initialFileValues);

    if (isError) {
      uploadMutationReset();
    }

    if (documentId) {
      removeTemplateMutation({ id: id! });
    }
  };

  return (
    <div className={s.container}>
      <div className={s.titleContainer}>
        <div className={s.title}>
          <Typography.Title level={5}>{t('documents_declarations_template')}</Typography.Title>
          <Tooltip
            overlayClassName={s.tooltip}
            arrow={false}
            trigger="hover"
            align={{ offset: [20, 16] }}
            placement="bottomLeft"
            title={
              <Typography.Text size="12" weight="400" className={s.tooltipTitle}>
                {t('documents_declarations_template_help_text')}
              </Typography.Text>
            }>
            <button className={s.tooltipIcon}>
              <Icon name="info" />
            </button>
          </Tooltip>
        </div>

        <a
          href="/templateExample.docx"
          download={t('documents_declarations_download_template')}
          className={s.download}>
          <Icon name="download" />
          {t('common_download_example')}
        </a>
      </div>
      <Form.Item valuePropName="fileList" noStyle>
        {fileData.isLoaded ? (
          <File.Download
            {...fileData}
            isError={isError}
            deleteHandler={onClickResetHandler}
            resetHandler={onClickResetHandler}
            handleDownload={() =>
              downloadHandler({
                url: getDownloadUrl(id!),
                fileName: declarationData?.docTemplate?.name!,
              })
            }
          />
        ) : (
          <File.Upload
            {...fileData}
            isError={isError}
            isLoading={isLoading}
            availableFileTypes={AVAILABLE_TYPES}
            beforeUpload={(file) => beforeUpload(file, setErrors)}
            customRequest={customRequest}
            resetHandler={onClickResetHandler}
          />
        )}
      </Form.Item>
    </div>
  );
};

export default DocumentsDescriptionsFormUpload;
