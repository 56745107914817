import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import {
  useGetGroupCountDraftMutation,
  useLazyGetGroupCountQuery,
} from 'services/employee-groups-new/employeeGroupsNewApiService';
import {
  GetEmployeeGroupByIdResponse,
  GetGroupCountDraftPropsType,
  GetGroupCountResponseType,
} from 'services/employee-groups-new/employeeGroupsNewApiService.types';
import { useAppSelector } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import { getResultNode } from '../../employeeGroupsCreate.utils';

interface UseHeaderProps {
  groupData?: GetEmployeeGroupByIdResponse;
}

const useHeader = (props: UseHeaderProps) => {
  const { groupData } = props;

  const { id } = useParams<{ id: string }>();

  const [personsValue, setPersonsValue] = useState<number>(0);

  const [getGroupCountDraft] = useGetGroupCountDraftMutation();
  const [getGroupCount] = useLazyGetGroupCountQuery();

  const { isChanged, checkedNodes, partialChecked, employees } = useAppSelector(
    (store) => store.employeeGroupsSlice,
  );

  useEffect(() => {
    const onSuccess = (res?: GetGroupCountResponseType) => setPersonsValue(res?.count ?? 0);

    if (isChanged) {
      const mappedChecked = checkedNodes.map(getResultNode);
      const mappedPartial = partialChecked.map(getResultNode);

      const body: GetGroupCountDraftPropsType = {
        employersData: {
          checked: mappedChecked,
          partialChecked: mappedPartial,
        },
        employeesData: employees,
      };

      getGroupCountDraft(body).then(
        handleRequest({
          onSuccess,
        }),
      );
      return;
    }

    if (id) {
      getGroupCount({ groupId: id }).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  }, [checkedNodes, employees, getGroupCount, getGroupCountDraft, id, isChanged, partialChecked]);

  const initialTitle = groupData?.title;
  const date = groupData?.updatedAt || groupData?.createdAt;
  const editorId = groupData?.editor.id || groupData?.creator.id;
  const editorName = `${groupData?.editor?.lastName || groupData?.creator?.lastName} ${
    groupData?.editor?.firstName || groupData?.creator?.firstName
  }`;
  const title = id ? groupData?.title : t('employee_groups_create');

  return {
    countValue: personsValue,
    initialTitle,
    title,
    date,
    editorId,
    editorName,
  };
};

export default useHeader;
