import { FC } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { getTimezone } from 'utils/date.utils';
import { getFiltersCount } from 'utils/filter.utils';

import { useDownloadPersonReportMutation } from 'services/report/reportApiService';
import { DownloadPersonReportPropsType } from 'services/report/reportApiService.types';

import { Button, Flex, Form } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';
import FilterRadioGroup from 'containers/filters/filter-radio-group';
import { FiltersResponseType } from 'containers/filters/filters.utils';

import { FormFiltersType, USER_STATUS_ENUM } from '../../accessControlUsers.utils';
import { STATUS_OPTIONS } from './filters.utils';
import useGetCollapseItems from './useGetOptions.hook';

const Filters: FC = () => {
  const form = Form.useFormInstance();

  const [downloadPersonReport, { isLoading }] = useDownloadPersonReportMutation();

  const handleDownload = () => {
    const filters = form.getFieldValue('filters') ?? {};

    const requestBody: DownloadPersonReportPropsType = {
      filename: `Список пользователей ${dayjs().format()}`,
      timeZone: getTimezone(),
      personsFilterParam: {
        text: form.getFieldValue('search'),
        employerIds: filters.employerIds,
        roleIds: filters.roleIds,
      },
    };

    if (filters.status && filters.status !== USER_STATUS_ENUM.ALL) {
      requestBody.personsFilterParam.isRegistered = filters.status === USER_STATUS_ENUM.REGISTERED;
    }

    downloadPersonReport(requestBody);
  };

  const items = useGetCollapseItems();
  const filters = Form.useWatch('filters');

  return (
    <FiltersV2.TableFilters>
      <Flex align="items">
        <FiltersV2.Search />
        <Button
          size="small"
          type="link"
          leftIcon="download"
          onClick={handleDownload}
          loading={isLoading}>
          {t('common_download_report')}
        </Button>
      </Flex>
      <FiltersContainer<FormFiltersType>
        showClearAll
        initialValues={filters as FiltersResponseType | null}
        overlay={
          <>
            <FilterCollapse items={items} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
              <FilterRadioGroup usePadding={false} valueKey="status" options={STATUS_OPTIONS} />
            </FilterCustomWrapper>
          </>
        }
        filtersCount={getFiltersCount(filters)}
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
