import { ChangeEventHandler, Dispatch, Key, ReactNode, SetStateAction } from 'react';

import { t } from 'tools/i18n';
import { handleTableChange } from 'utils/table.utils';

import { CEmployeeType } from 'services/1c/cApiService.types';
import { EmployeeFormData } from 'services/employee-groups-new/employeeGroupsNewApiService.types';

import { Table as ATable, ButtonProps, Flex, Icon, Input, Typography } from 'gazprom-ui-lib';

import TableSelectedActions from 'components/table/table-selected-actions';

import { EmployeeType } from 'types/employee.types';
import { PaginationPropsType } from 'types/pagination.types';

import s from './Table.module.scss';
import useColumns from './useColumns.hook';

type CommonRequestBodyType = {
  body?: EmployeeFormData;
  text?: string;
  filterParam?: string;
} & PaginationPropsType;

interface TableProps {
  tableData: EmployeeType[] | CEmployeeType[];
  setRequestBody: Dispatch<SetStateAction<CommonRequestBodyType>>;
  searchValue: string;
  handleSearchChange: ChangeEventHandler<HTMLInputElement> | undefined;
  hasSelected?: boolean;
  buttons?: ButtonProps[];
  pagination: {
    defaultCurrent: number;
    defaultPageSize: number;
    total: number | undefined;
  };
  loading: boolean;
  rowSelection?: {
    selectedRowKeys: Key[];
    onChange: (selected: Key[]) => void;
    preserveSelectedRowKeys: boolean;
  };
  filters?: ReactNode;
}

export const Table = (props: TableProps) => {
  const {
    tableData,
    searchValue,
    handleSearchChange,
    hasSelected,
    buttons,
    pagination,
    loading,
    rowSelection,
    setRequestBody,
    filters,
  } = props;

  const columns = useColumns();

  const showActions = hasSelected && buttons?.length;

  return (
    <>
      <Flex align="center" justify="space-between" className={s.searchContainer}>
        <Flex gap="32" align="center">
          <Input
            size="small"
            prefix={<Icon name="search" />}
            placeholder={t('common_search_by_name_placeholder')}
            allowClear
            value={searchValue}
            onChange={handleSearchChange}
            className={s.search}
          />
          {showActions && (
            <Flex gap="32" align="center">
              <TableSelectedActions buttons={buttons} className={s.actionButtons} />
              <Typography.Text type="secondary" className={s.counter}>
                {t('common_table_selected', {
                  count: rowSelection?.selectedRowKeys?.length,
                })}
              </Typography.Text>
            </Flex>
          )}
        </Flex>
        {filters}
      </Flex>
      <ATable
        dataSource={tableData}
        columns={columns}
        pagination={pagination}
        onChange={handleTableChange(setRequestBody)}
        loading={loading}
        rowKey="id"
        rowSelection={rowSelection}
        className={s.table}
      />
    </>
  );
};

export default Table;
