import { t } from 'tools/i18n';

import { SOCIAL_PROGRAM_REQUEST_ENUM } from 'types/socialProgram.types';

export const FILTER_STATUSES = [
  {
    label: t('common_docs_checking'),
    value: SOCIAL_PROGRAM_REQUEST_ENUM.DOCS_CHECKING,
  },
  {
    label: t('common_commission_review'),
    value: SOCIAL_PROGRAM_REQUEST_ENUM.IN_REVIEW,
  },
  {
    label: t('common_delivering'),
    value: SOCIAL_PROGRAM_REQUEST_ENUM.DELIVERING,
  },
  {
    label: t('common_program_provided'),
    value: SOCIAL_PROGRAM_REQUEST_ENUM.PROVIDED,
  },
  {
    label: t('common_rejected'),
    value: SOCIAL_PROGRAM_REQUEST_ENUM.REJECTED,
  },
  {
    label: t('common_need_correction'),
    value: SOCIAL_PROGRAM_REQUEST_ENUM.NEED_CORRECTION,
  },
];
