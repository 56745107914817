import { NotificationNewsletterFormResponse } from 'containers/notification-newsletter-form/notificationNewsletterForm.utils';

interface UseSubmitProps {
  handleGoBack: () => void;
}
const useSubmit = (props: UseSubmitProps) => {
  // @ts-ignore
  const { handleGoBack } = props;

  const handleSubmit = (form: NotificationNewsletterFormResponse) => {
    console.log(form);
  };

  const updateNewsletterIsFetching = false;
  return { handleSubmit, updateNewsletterIsFetching };
};

export default useSubmit;
