import { Form } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import FormBody from '../formBoody';
import useFormSaveHandler from '../useFormSaveHandler.hook';
import useSurveyFormData from '../useSurveyFormData.hook';
import s from './SurveyFormEvent.module.scss';
import Buttons from './container/buttons';
import StartModal from './container/start-modal';
import StopModal from './container/stop-modal';
import useStartSurvey from './useStartSurvey.hook';

const SurveyFormEvent = () => {
  const [form] = Form.useForm();

  const { isLoading, isSurveyActive, isReadyToAssign } = useSurveyFormData();
  const { onFormFinishHandler, isLoading: isSaveLoading } = useFormSaveHandler();
  const {
    isLoading: isStartLoading,
    modalOpen,
    cancelModalHandler,
    startButtonHandler,
    stopButtonHandler,
    acceptStartButtonHandler,
    acceptStopButtonHandler,
  } = useStartSurvey({ form });

  return (
    <WithLoader isLoading={isLoading}>
      <Form form={form} className={s.form} onFinish={onFormFinishHandler} disabled={isSurveyActive}>
        <FormBody />

        <Buttons
          isStartLoading={isStartLoading}
          isSaveLoading={isSaveLoading}
          isSurveyActive={isSurveyActive}
          isReadyToAssign={isReadyToAssign}
          stopButtonHandler={stopButtonHandler}
          startButtonHandler={startButtonHandler}
        />
      </Form>

      <StartModal
        modalOpen={modalOpen}
        onCancel={cancelModalHandler}
        onActionClick={acceptStartButtonHandler}
      />

      <StopModal
        modalOpen={modalOpen}
        onCancel={cancelModalHandler}
        onActionClick={acceptStopButtonHandler}
      />
    </WithLoader>
  );
};

export default SurveyFormEvent;
