import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  getSettingsDeclarationsEdoEditRoute,
  getSettingsDeclarationsKedoEditRoute,
  getSettingsDeclarationsSocialEditRoute,
} from 'routes/settings/list';
import { handleTableChange } from 'utils/table.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetDeclarationTypesQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { GetDeclarationsPropsTypes } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService.types';

import { InputProps, Table } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { DeclarationsTypes } from 'types/documents.types';
import { KEDO_TYPE_ENUM } from 'types/kedo.types';
import { SORT_ENUM } from 'types/sort.types';

import DocumentsDeclarationsFilters from './documents-declarations-filters';
import DocumentsDeclarationsHeader from './documents-declarations-header';
import useColumns from './useColumns.hook';

export const DEFAULT_REQUEST_BODY: GetDeclarationsPropsTypes = {
  page: 1,
  size: 10,
  order: SORT_ENUM.DESC,
  property: 'create_date',
};

const DocumentsDeclarations = () => {
  const navigate = useNavigate();

  const [requestBody, setRequestBody] = useState<GetDeclarationsPropsTypes>(DEFAULT_REQUEST_BODY);
  const [request, { data, isLoading, isFetching }] = useLazyGetDeclarationTypesQuery();
  const [filters, setFilters] = useState<Pick<
    GetDeclarationsPropsTypes,
    'categoryId' | 'isPublished' | 'type'
  > | null>(null);

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  useEffect(() => {
    const initialRequestBody: GetDeclarationsPropsTypes = {
      ...filters,
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
    };

    if (debouncedRequestBody.name) {
      initialRequestBody.name = debouncedRequestBody.name;
    }

    if (debouncedRequestBody.property && debouncedRequestBody.order) {
      if (debouncedRequestBody.property === 'createdAt') {
        initialRequestBody.sort = [
          {
            order: debouncedRequestBody.order,
            property: `kedo_statement.create_date`,
          },
        ];
      } else {
        initialRequestBody.sort = [
          {
            order: debouncedRequestBody.order,
            property: `kedo_statement.${debouncedRequestBody.property}`,
          },
        ];
      }
    }

    request(initialRequestBody);
  }, [debouncedRequestBody, filters, request]);

  const columns = useColumns();

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({ ...prevState, name: e.target.value, page: 1 }));
  };

  const onFiltersSubmit = (values: Record<string, string[]> | null) => {
    setFilters(values);
    setRequestBody((prevState) => ({ ...prevState, page: 1 }));
  };

  const handleOnRow = (rowData: DeclarationsTypes) => ({
    onClick: () => {
      const { type, id } = rowData ?? {};

      switch (type) {
        case KEDO_TYPE_ENUM.SOCIAL: {
          navigate(getSettingsDeclarationsSocialEditRoute(id));
          break;
        }
        case KEDO_TYPE_ENUM.KEDO: {
          navigate(getSettingsDeclarationsKedoEditRoute(id));
          break;
        }
        case KEDO_TYPE_ENUM.BPMN:
        default: {
          navigate(getSettingsDeclarationsEdoEditRoute(id));
        }
      }
    },
  });

  return (
    <>
      <DocumentsDeclarationsHeader />
      <DocumentsDeclarationsFilters
        filters={filters}
        setFilters={onFiltersSubmit}
        search={requestBody.name}
        handleSearchChange={handleSearchChange}
      />
      <WithLoader isLoading={isLoading}>
        <Table
          onRow={handleOnRow}
          dataSource={data?.items}
          columns={columns}
          pagination={{
            defaultCurrent: debouncedRequestBody.page,
            defaultPageSize: debouncedRequestBody.size,
            total: data?.totalCount,
          }}
          loading={isLoading || isFetching}
          rowKey="id"
          onChange={handleTableChange(setRequestBody)}
        />
      </WithLoader>
    </>
  );
};

export default DocumentsDeclarations;
