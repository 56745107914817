import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, Form, TIconsLiteral } from 'gazprom-ui-lib';

import { GUIDE_FORM_ENUM } from 'pages/guide/guide-contacts/guideContacts.utils';

import s from './ModalButtons.module.scss';

interface ModalButtonsProps {
  onClose: () => void;
  loading?: boolean;
  actionButtonText?: string;
  actionButtonIcon?: TIconsLiteral;
}

const ModalButtons: FC<ModalButtonsProps> = (props) => {
  const form = Form.useFormInstance();
  const textField = Form.useWatch(GUIDE_FORM_ENUM.TEXT, form);
  const { onClose, loading, actionButtonText, actionButtonIcon } = props;

  return (
    <div className={s.buttons}>
      <Button
        type="primary"
        leftIcon={actionButtonIcon ?? 'plus'}
        disabled={!textField?.length}
        htmlType="submit"
        loading={loading}>
        {actionButtonText ?? t('common_add')}
      </Button>
      <Button type="secondary" onClick={onClose}>
        {t('common_close')}
      </Button>
    </div>
  );
};

export default ModalButtons;
