import { useNavigate, useParams } from 'react-router-dom';
import { SURVEYS_EVENT_ROUTE, SURVEYS_ONCE_ROUTE } from 'routes/surveys/list';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import {
  useCreateSurveyMutation,
  useGetEmployerSurveysQuery,
  useGetSurveysEmployeeGroupsQuery,
  useGetSurveysListQuery,
  useUpdateSurveyMutation,
} from 'services/surveys/surveysApiService';
import { CreateSurveyResponseType } from 'services/surveys/surveysApiService.types';

import { SURVEYS_SETTINGS } from 'pages/surveys/surveys-details/surveysDetails.utils';
import { DEFAULT_PAGE_DATA, getReceivers } from 'pages/surveys/surveys.utils';

import { NewSurveyFinishFormType, NewSurveyType } from 'types/surveys.types';

import useMatchOnceRoute from './useMatchOnceRoute';

const useFormSaveHandler = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const isOnceSurveysRoute = useMatchOnceRoute();

  const { data: employeeGroupsResult } = useGetSurveysEmployeeGroupsQuery(DEFAULT_PAGE_DATA);
  const { data: organizationOptions } = useGetEmployerSurveysQuery(DEFAULT_PAGE_DATA);
  const { data: surveysList } = useGetSurveysListQuery();

  const [updateSurvey, { isLoading: isUpdateSurveyLoading }] = useUpdateSurveyMutation();
  const [createSurvey, { isLoading: isCreateSurveyLoading }] = useCreateSurveyMutation();

  const isLoading = isUpdateSurveyLoading || isCreateSurveyLoading;

  const onSuccessCreate = (data?: CreateSurveyResponseType) => {
    const { id, anketologId, revisionId } = data || {};

    if (!id) {
      return;
    }
    const searchParams = new URLSearchParams();
    searchParams.set('tabId', SURVEYS_SETTINGS);

    const route = isOnceSurveysRoute ? SURVEYS_ONCE_ROUTE : SURVEYS_EVENT_ROUTE;

    navigate({
      pathname: `${route}/${id}/${anketologId}/${revisionId}`,
      search: searchParams.toString(),
    });
  };

  const handleSave = useHandleRequestWithModal({
    onSuccess: onSuccessCreate,
    onSuccessModalConfig: {
      description: {
        children: t('surveys_settings_saved'),
      },
    },
  });

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {
      description: {
        children: t('surveys_settings_saved'),
      },
    },
  });

  const onFormFinishHandler = (values: NewSurveyFinishFormType) => {
    const { name, receivers, organization, ...rest } = values;

    const result = {
      ...surveysList?.surveysInfo[name],
      ...rest,
      receivers: getReceivers(receivers, employeeGroupsResult!, organization, organizationOptions!),
    } as NewSurveyType;

    if (id) {
      updateSurvey({ id, body: result }).then(handleUpdate);

      return;
    }

    createSurvey(result).then(handleSave);
  };

  return {
    isLoading,
    onFormFinishHandler,
  };
};

export default useFormSaveHandler;
