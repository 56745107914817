import { FC, PropsWithChildren } from 'react';

import { Flex } from 'gazprom-ui-lib';

import s from './FilterCustomWrapper.module.scss';

interface FilterCustomProps extends PropsWithChildren {}

const FilterCustomWrapper: FC<FilterCustomProps> = (props) => {
  const { children } = props;

  return (
    <Flex gap="12" vertical className={s.wrapper}>
      {children}
    </Flex>
  );
};

export default FilterCustomWrapper;
