import { DataNode } from 'gazprom-ui-lib';

import {
  CustomTreeType,
  GetEmployeeGroupByIdResponse,
  GetEmployeeGroupTreeInitialResponse,
  TreeType,
} from './employeeGroupsNewApiService.types';
import { EmployerRoot } from 'types/employeeGroups.types';

export const getEmployeeGroupByIdTransform = (resp: GetEmployeeGroupByIdResponse) => {
  let checked: string[] = [];
  const transformTreeData = (data: TreeType[]) => {
    return data.map((el) => {
      const newKey = `${el.id}.${el.type}`;

      if (resp.employersData.checked.includes(el.id)) {
        checked.push(newKey);
      }

      const newNode: CustomTreeType = {
        title: el.name,
        key: newKey,
        type: el.type,
        // isLeaf	Determines if this is a leaf node(effective when loadData is specified).
        // false will force trade TreeNode as a parent node	boolean
        isLeaf: !el.hasChildren,
      };

      if (el.children && el.children.length > 0) {
        newNode.children = transformTreeData(el.children);
      } else {
        newNode.children = null;
      }

      return newNode;
    });
  };
  const transformedData = transformTreeData(resp.employersData.treeData as TreeType[]);

  resp.employersData.treeData = transformedData;
  resp.employersData.checked = checked;

  return resp;
};

export const getEmployerRootTransform = (resp: EmployerRoot[]) => {
  const type = 'EMPLOYER';
  return resp.map((el) => ({
    title: el.name,
    key: `${el.id}.${type}`,
    type,
    ...el,
  }));
};

export const getEmployeeGroupTreeTransform = (resp: GetEmployeeGroupTreeInitialResponse) =>
  resp.map((el) => ({
    ...el,
    title: el.name,
    key: `${el.id}.${el.type}`,
    type: el.type,
    isLeaf: !el.hasChildren,
    children: el.hasChildren ? null : [],
  })) as DataNode[];
