import { FC, ReactNode } from 'react';

import {
  NOTIFICATION_MAILING_MANAGEMENT_ROUTE,
  NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE,
} from 'routes/notification/list';
import { t } from 'tools/i18n';

import ContentHeader from 'containers/content-header';

import { NOTIFICATION_NEWSLETTER_TAB } from 'pages/notification/notification.utils';

interface HeaderProps {
  saveButton: ReactNode;
  isSystemNotification?: boolean;
}

const Header: FC<HeaderProps> = (props) => {
  const { saveButton, isSystemNotification } = props;

  return (
    <ContentHeader align="flex-start">
      <ContentHeader.Title
        goBackLink={
          isSystemNotification
            ? NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE
            : NOTIFICATION_MAILING_MANAGEMENT_ROUTE
        }
        goBackState={{
          activeTab: NOTIFICATION_NEWSLETTER_TAB,
        }}>
        {t('notification_view_newsletter')}
      </ContentHeader.Title>
      <ContentHeader.Actions>{saveButton}</ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
