import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import s from './Empty.module.scss';

const Empty = () => {
  return (
    <Flex gap="16" vertical align="center" className={s.emptyContainer}>
      <div className={s.icon}>
        <Icon name="notFound" size={40} />
      </div>
      <Typography.Title level={4}>{t('surveys_no_stats')}</Typography.Title>
    </Flex>
  );
};

export default Empty;
