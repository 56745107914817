import { FC, PropsWithChildren, ReactNode, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import s from './ContentHeaderTitle.module.scss';
import EditModal from './edit-modal';

interface ContentHeaderTitleProps extends PropsWithChildren {
  total?: string | number;
  goBackLink?: string | number;
  goBackState?: Record<string, unknown>;
  description?: ReactNode;
  editConfig?: {
    value: string;
    isLoading: boolean;
    handleSave: (value: string) => Promise<void>;
    title: string;
    label: string;
    length?: number;
  };
}

const ContentHeaderTitle: FC<ContentHeaderTitleProps> = (props) => {
  const { children, editConfig, goBackLink, description, goBackState, total } = props;
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const navigate = useNavigate();

  const handleGoBack = () => {
    if (goBackLink) {
      if (typeof goBackLink === 'string') {
        navigate(goBackLink, { state: goBackState });
      } else {
        navigate(goBackLink);
      }
    }
  };

  const showTotal = typeof total === 'string' || typeof total === 'number';

  return (
    <Flex vertical gap="8" justify={goBackLink ? 'flex-start' : 'center'}>
      {goBackLink && (
        <Button
          htmlType="button"
          type="link"
          onClick={handleGoBack}
          className={s.goBack}
          size="small"
          leftIcon="arrowLeft">
          {t('common_go_back')}
        </Button>
      )}
      <Flex align="center" gap="4" wrap="nowrap">
        <Typography.Title level={3}>{children}</Typography.Title>
        {showTotal && (
          <Typography.Text size="12" weight="500" type="primary">
            {total}
          </Typography.Text>
        )}
        {editConfig && (
          <button className={s.edit} type="button" onClick={() => setIsEditModalVisible(true)}>
            <Icon name="edit" />
          </button>
        )}
      </Flex>
      {description && (
        <Typography.Text type="secondary" size="14" className={s.description}>
          {description}
        </Typography.Text>
      )}
      {editConfig && (
        <EditModal
          isOpen={isEditModalVisible}
          handleClose={() => setIsEditModalVisible(false)}
          config={editConfig}
        />
      )}
    </Flex>
  );
};

export default ContentHeaderTitle;
