import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetEventParticipantsQuery } from 'services/pmeet/pmeetApiService';
import { GetEventParticipantsPropsType } from 'services/pmeet/pmeetApiService.types';

import { RequestBodyType } from './participants.utils';

interface UseRequestProps {
  requestBody: RequestBodyType;
}

const useRequest = (props: UseRequestProps) => {
  const { requestBody } = props;

  const params = useParams<{ eventId: string }>();
  const [
    getEventsParticipants,
    { data: eventParticipants, isFetching: eventParticipantsIsFetching },
  ] = useLazyGetEventParticipantsQuery();

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  useEffect(() => {
    if (params?.eventId) {
      const { order, property } = debouncedRequestBody;
      const preparedRequestBody: GetEventParticipantsPropsType = {
        id: params.eventId,
        filterParam: debouncedRequestBody.search,
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
      };

      if (property && order) {
        if (property === 'employer') {
          preparedRequestBody.sort = [
            {
              property: 'EMPLOYER.name',
              order,
            },
          ];
        } else if (property === 'personFio') {
          preparedRequestBody.sort = [
            {
              property: 'lastName',
              order,
            },
            {
              property: 'firstName',
              order,
            },
            {
              property: 'patronymic',
              order,
            },
          ];
        }
      }

      getEventsParticipants(preparedRequestBody);
    }
  }, [debouncedRequestBody, getEventsParticipants, params.eventId]);

  return {
    eventParticipants,
    eventParticipantsIsFetching,
  };
};

export default useRequest;
