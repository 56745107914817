import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import Header from './containers/header';
import Table from './containers/table';
import { DEFAULT_FORM_STATE, FormResponseType } from './suggestionsDecisions.utils';
import useRequest from './useRequest.hook';

const SuggestionsDecisions = () => {
  const [form] = Form.useForm<FormResponseType>();

  const { decisions, isFetching, isLoading } = useRequest({ form });

  const loading = isLoading || isFetching;

  return (
    <WithOverflow>
      <Header total={decisions?.items?.length ?? 0} />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <Table dataSource={decisions?.items} loading={loading} />
      </FiltersV2>
    </WithOverflow>
  );
};

export default SuggestionsDecisions;
