import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import NotificationNewsletterTable from 'containers/notification-newsletter-table';
import WithOverflow from 'containers/wrappers/with-overflow';

import { SENDER_ENUM } from 'types/notification.types';

import Header from './containers/header';
import { DEFAULT_FORM_STATE, FormResponseType } from './notificationSystem.utils';

const NotificationSystem = () => {
  const [form] = Form.useForm<FormResponseType>();

  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <FiltersV2.TableFilters>
          <FiltersV2.Search />
        </FiltersV2.TableFilters>
        <NotificationNewsletterTable senderType={SENDER_ENUM.APP} />
      </FiltersV2>
    </WithOverflow>
  );
};

export default NotificationSystem;
