import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetSuggestionsQuery } from 'services/suggestion/suggestionApiService';
import { GetSuggestionsPropsType } from 'services/suggestion/suggestionApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './suggestionsList.utils';

interface UseRequestProps {
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: UseRequestProps) => {
  const { form } = props;

  const [getSuggestions, { data: suggestions, isLoading, isFetching }] =
    useLazyGetSuggestionsQuery();

  const requestBody: FormResponseType = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    const { page, size, search, order, property, filters } = debouncedRequestBody ?? {};

    if (debouncedRequestBody) {
      const initialRequestBody: GetSuggestionsPropsType = {
        page,
        size,
      };

      if (search) {
        initialRequestBody.text = search;
      }

      if (property && order) {
        if (debouncedRequestBody.property === 'createdAt') {
          initialRequestBody.sort = [
            {
              property: `Suggestion.createdAt`,
              order,
            },
          ];
        }
      }

      if (filters?.categoryId) {
        initialRequestBody.categoryId = filters.categoryId;
      }

      if (filters?.curStatus) {
        initialRequestBody.curStatus = filters.curStatus;
      }

      getSuggestions(initialRequestBody);
    }
  }, [debouncedRequestBody, getSuggestions]);

  return {
    suggestions,
    isLoading,
    isFetching: isDebouncing || isFetching,
  };
};

export default useRequest;
