import ContentHeaderActions from './content-header-actions';
import ContentHeaderTitle from './content-header-title';
import InnerComponent from './contentHeader';

type CompoundedComponents = typeof InnerComponent & {
  Title: typeof ContentHeaderTitle;
  Actions: typeof ContentHeaderActions;
};

const ContentHeader = InnerComponent as CompoundedComponents;
ContentHeader.Title = ContentHeaderTitle;
ContentHeader.Actions = ContentHeaderActions;

export default ContentHeader;
