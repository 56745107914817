import { useState } from 'react';

import { t } from 'tools/i18n';
import { handleTableChange } from 'utils/table.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetArticlesQuery } from 'services/article/articleApiService';
import { GetArticlesPropsType } from 'services/article/articleApiService.types';

import { Icon, Input, InputProps } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import NewsActions from 'pages/news/news-actions';
import NewsTable from 'pages/news/news-table';
import NewsTablePinned from 'pages/news/news-table/news-table-pinned';
import { MAX_PINNED_NEWS } from 'pages/news/news-table/newsTable.utils';
import NewsTablesFilters from 'pages/news/news-tables/news-tables-filters';
import useGetNews from 'pages/news/news-tables/useGetNews.hook';

import { ArticleType } from 'types/article.types';

import { DEFAULT_REQUEST_BODY, GET_PINNED_NEWS_ARGS, RequestBody } from './newsTables.utils';

const NewsTables = () => {
  const { data: pinnedNews, isLoading: isLoadingPinned } =
    useGetArticlesQuery(GET_PINNED_NEWS_ARGS);

  const [selectedNewsCodes, setSelectedNewsCodes] = useState<Array<ArticleType['code']>>([]);

  const [requestBody, setRequestBody] = useState<RequestBody>(DEFAULT_REQUEST_BODY);
  const [filters, setFilters] = useState<Pick<GetArticlesPropsType, 'categoryIds'> | null>(null);

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody, 150);

  const { isLoading, unpinnedNews, isFetching } = useGetNews({ debouncedRequestBody, filters });

  const onFiltersSubmit = (values: Pick<GetArticlesPropsType, 'categoryIds'> | null) => {
    setFilters(values);
    setRequestBody((prevState) => ({ ...prevState, page: 1 }));
  };

  const handleSearch: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({
      ...prevState,
      title: e.target.value,
      page: DEFAULT_REQUEST_BODY.page,
    }));
  };
  const canPinNews = (pinnedNews?.items.length ?? 0) < MAX_PINNED_NEWS;
  const hasSelectedNews = selectedNewsCodes.length > 0;
  const filterActions = hasSelectedNews ? (
    <NewsActions
      selectedNewsCodes={selectedNewsCodes}
      onSelectedNewsCodesChange={setSelectedNewsCodes}
    />
  ) : (
    <Input
      size="small"
      prefix={<Icon name="search" />}
      placeholder={t('common_search')}
      allowClear
      value={requestBody?.title}
      onChange={handleSearch}
    />
  );
  return (
    <WithLoader isLoading={isLoading}>
      <NewsTablePinned dataSource={pinnedNews?.items} loading={isLoadingPinned} />
      <NewsTablesFilters
        filters={filters}
        onFiltersSubmit={onFiltersSubmit}
        actions={filterActions}
      />
      <NewsTable
        dataSource={unpinnedNews?.items}
        loading={isFetching}
        canPinNews={canPinNews}
        selectedRowKeys={selectedNewsCodes}
        onSelectedRowKeysChange={setSelectedNewsCodes}
        onChange={handleTableChange(setRequestBody)}
        paginationConfig={{
          defaultCurrent: requestBody.page,
          defaultPageSize: requestBody.size,
          current: requestBody.page,
          total: unpinnedNews?.totalCount,
        }}
      />
    </WithLoader>
  );
};

export default NewsTables;
