import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetKedoCategoriesQuery } from 'services/kedo/kedoApiService';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterHeader from 'containers/filters/filter-header';

import { KedoCategoryType } from 'types/documents.types';
import { KEDO_TYPE_ENUM } from 'types/kedo.types';

const useFilterItems = () => {
  const [categoryName, setCategoryName] = useState<string>();

  const { debouncedValue: debouncedCategory } = useDebounce(categoryName);

  const { socialProgramCategories } = useGetKedoCategoriesQuery(
    { type: KEDO_TYPE_ENUM.SOCIAL, name: debouncedCategory },
    { selectFromResult },
  );

  return useMemo(
    () => [
      {
        key: '0',
        label: <FilterHeader valueKey="categories">{t('common_categories')}</FilterHeader>,
        children: (
          <FilterCheckboxGroup
            valueKey="categories"
            options={socialProgramCategories}
            showSearch
            searchProps={{
              placeholder: t('common_categories_search'),
              value: categoryName,
              onChange: (e) => setCategoryName(e.target.value || undefined),
            }}
          />
        ),
      },
    ],
    [socialProgramCategories, categoryName],
  );
};

const selectFromResult = ({ data }: { data?: KedoCategoryType[] }) => ({
  socialProgramCategories: data?.map(({ name, id }) => ({ value: id, label: name })) ?? [],
});

export default useFilterItems;
