import Content from './containers/content';
import Employee from './containers/employee';
import History from './containers/history';
import Tabs from './containers/tabs';
import InnerComponent from './documentView';

type CompoundedComponents = typeof InnerComponent & {
  Content: typeof Content;
  Employee: typeof Employee;
  History: typeof History;
  Tabs: typeof Tabs;
};

const DocumentView = InnerComponent as CompoundedComponents;
DocumentView.Content = Content;
DocumentView.Employee = Employee;
DocumentView.History = History;
DocumentView.Tabs = Tabs;

export default DocumentView;
