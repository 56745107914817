import { FC } from 'react';

import { t } from 'tools/i18n';

import { Checkbox, DatePicker, Form, FormInstance, TimePicker, Typography } from 'gazprom-ui-lib';

import OptionalLabel from 'components/optional-label';

import { NEWS_FORM_ENUM } from '../newsForm.utils';
import s from './NewsFormPublication.module.scss';

interface NewsFormPublicationProps {
  form?: FormInstance;
}

const NewsFormPublication: FC<NewsFormPublicationProps> = () => {
  return (
    <>
      <OptionalLabel>
        <Typography.Text strong size="16">
          {t('news_publish_datetime')}
        </Typography.Text>
      </OptionalLabel>
      <div className={s.wrapper}>
        <Form.Item name={NEWS_FORM_ENUM.PUBLISH_FROM_DATE}>
          <DatePicker />
        </Form.Item>
        <Form.Item name={NEWS_FORM_ENUM.PUBLISH_FROM_TIME}>
          <TimePicker format="HH:mm" placeholder="00:00" />
        </Form.Item>
      </div>
      <Form.Item name={NEWS_FORM_ENUM.PUBLISH} valuePropName="checked">
        <Checkbox>
          <Typography.Text size="14">{t('common_published')}</Typography.Text>
        </Checkbox>
      </Form.Item>
    </>
  );
};

export default NewsFormPublication;
