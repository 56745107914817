import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  open: boolean;
  onCancel: () => void;
  onRetry: () => void;
}

const ModalSuccess = (props: Props) => {
  const { open, onCancel, onRetry } = props;

  return (
    <ModalDefault open={open} closable width={320} onCancel={onCancel}>
      <ModalDefault.Icon name="error" type="danger" />
      <ModalDefault.Title>{t('common_something_went_wrong')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('thank_you_download_error_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="secondary" onClick={onRetry} leftIcon="refresh">
          {t('common_retry_again')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ModalSuccess;
