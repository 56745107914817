import { FC, useState } from 'react';

import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';

import { Form, Typography } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import UploadImitation from 'components/upload-imitation';
import ImageModal from 'containers/image-modal';

import { NEWS_FORM_ENUM } from '../newsForm.utils';
import s from './NewsFormCover.module.scss';

const NewsFormCover: FC = () => {
  const form = Form.useFormInstance();

  const [showUploadModal, setShowUploadModal] = useState(false);

  const cover: string = Form.useWatch(NEWS_FORM_ENUM.COVER);

  const handleModalOpenClose = () => {
    setShowUploadModal((prevState) => !prevState);
  };
  const onImgUpload = (url: string) => {
    form.setFieldValue(NEWS_FORM_ENUM.COVER, url);
    handleModalOpenClose();
  };

  const resetImage = () => {
    form.resetFields([NEWS_FORM_ENUM.COVER]);
  };

  return (
    <>
      <Typography.Text strong size="16">
        {t('news_cover')}
      </Typography.Text>
      <Form.Item name={NEWS_FORM_ENUM.COVER}>
        {cover ? (
          <ImgPreview type="image" url={createMediaDownloadUrl(cover)} resetImage={resetImage} />
        ) : (
          <UploadImitation handleClick={handleModalOpenClose} className={s.upload} />
        )}
      </Form.Item>
      <ImageModal isOpen={showUploadModal} onSubmit={onImgUpload} onClose={handleModalOpenClose} />
    </>
  );
};

export default NewsFormCover;
