import { t } from 'tools/i18n';

import { RcFile } from 'gazprom-ui-lib';

import { BACKEND_V1_BASE_URL } from 'constants/apiUrls';
import { FileDataType } from 'types/documents.types';

const MAX_LIMIT_SIZE_MB = 5;
export const AVAILABLE_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const initialFileValues: FileDataType = {
  title: t('common_upload_document_file'),
  description: t('validation_upload_a_docx_restrictions'),
  icon: 'upload',
  isLoaded: false,
};

export const beforeUpload = (file: RcFile, setErrorsHandler: (errors: string[]) => void) => {
  const isCorrectFormat = AVAILABLE_TYPES.includes(file.type);
  const moreThanLimit = file.size / 1024 / 1024 > MAX_LIMIT_SIZE_MB;

  if (!isCorrectFormat) {
    setErrorsHandler([t('validation_file_incorrect_extension')]);
    return false;
  } else if (moreThanLimit) {
    setErrorsHandler([t('validation_file_max_size', { size: `${MAX_LIMIT_SIZE_MB} MB` })]);
    return false;
  } else {
    setErrorsHandler([]);
    return file;
  }
};

export const getDownloadUrl = (id: string) =>
  `${BACKEND_V1_BASE_URL}kedo/statement/${id}/template/download`;
