import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button, Flex } from 'gazprom-ui-lib';

interface Props {
  isStartLoading: boolean;
  isSaveLoading: boolean;
  isSurveyActive: boolean;
  isReadyToAssign: boolean;
  stopButtonHandler: () => void;
  startButtonHandler: () => void;
}

const Buttons = (props: Props) => {
  const {
    isSurveyActive,
    isReadyToAssign,
    isSaveLoading,
    isStartLoading,
    startButtonHandler,
    stopButtonHandler,
  } = props;

  const { id } = useParams();
  const navigate = useNavigate();

  const isLoading = isSaveLoading || isStartLoading;

  const buttonsDraft = (
    <>
      <Button fullWidth type="primary" leftIcon="ok" htmlType="submit" loading={isSaveLoading}>
        {t('common_save')}
      </Button>
      <Button fullWidth type="primary" leftIcon="checklist" disabled={true}>
        {t('common_start')}
      </Button>
    </>
  );

  const buttons = (
    <>
      <Button fullWidth type="primary" leftIcon="ok" htmlType="submit" loading={isLoading}>
        {t('common_save')}
      </Button>
      {isSurveyActive ? (
        <Button
          htmlType="button"
          fullWidth
          type="primary"
          leftIcon="checklist"
          onClick={stopButtonHandler}
          danger
          disabled={false}
          loading={isLoading}>
          {t('common_stop')}
        </Button>
      ) : (
        <Button
          htmlType="button"
          fullWidth
          type="primary"
          leftIcon="checklist"
          disabled={!isReadyToAssign}
          onClick={startButtonHandler}
          loading={isLoading}>
          {t('common_start')}
        </Button>
      )}
    </>
  );

  return (
    <Flex gap="8">
      {id ? buttons : buttonsDraft}

      <Button fullWidth type="secondary" onClick={() => navigate(-1)} disabled={false}>
        {t('common_close')}
      </Button>
    </Flex>
  );
};

export default Buttons;
