import initialApiService from 'services/initialApiService';
import { DownloadPersonReportPropsType } from 'services/report/reportApiService.types';
import { downloadFileUsingRtk } from 'services/utils';

import { createReportUrl } from './utils';

const reportApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    downloadPersonReport: builder.mutation<unknown, DownloadPersonReportPropsType>({
      query({ filename, ...body }) {
        return {
          url: createReportUrl('/person'),
          method: 'POST',
          body,
          responseHandler: downloadFileUsingRtk(filename),
          cache: 'no-cache',
        };
      },
    }),
  }),
});

export const { useDownloadPersonReportMutation } = reportApiService;
