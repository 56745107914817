import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import { Flex } from 'gazprom-ui-lib';

import s from './Content.module.scss';

interface ContentProps extends PropsWithChildren {
  className?: string;
}

const Content: FC<ContentProps> = (props) => {
  const { className, ...otherProps } = props;

  return (
    <Flex gap="32" justify="space-between" className={cn(s.wrapper, className)} {...otherProps} />
  );
};

export default Content;
