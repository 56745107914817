import { FC, PropsWithChildren } from 'react';

import { Flex } from 'gazprom-ui-lib';

import s from './TableFilters.module.scss';

interface TableFiltersProps extends PropsWithChildren {}

const TableFilters: FC<TableFiltersProps> = (props) => {
  const { children } = props;

  return (
    <Flex className={s.wrapper} align="center" justify="space-between" wrap="wrap" gap="32">
      {children}
    </Flex>
  );
};

export default TableFilters;
