import { FC } from 'react';

import { t } from 'tools/i18n';

import { Form, TextArea, Typography } from 'gazprom-ui-lib';

import { DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import s from './DocumentsDeclarationsFormText.module.scss';

interface DocumentsDeclarationsFormTextProps {
  id: string;
  defaultValue?: string;
}

const DocumentsDeclarationsFormText: FC<DocumentsDeclarationsFormTextProps> = (props) => {
  const { id, defaultValue } = props;
  const itemName = `${DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM.TOOLTIP}.${id}`;
  const form = Form.useFormInstance();
  const textField = Form.useWatch(itemName, form);

  return (
    <>
      <div className={s.titleContainer}>
        <Typography.Title level={5}>
          {t('documents_declarations_additional_help_text')}
        </Typography.Title>
        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_symbol_count', { current: textField?.length ?? 0, total: 255 })}
        </Typography.Text>
      </div>
      <Form.Item name={itemName} initialValue={defaultValue}>
        <TextArea placeholder={t('common_enter_text')} className={s.textArea} maxLength={255} />
      </Form.Item>
    </>
  );
};

export default DocumentsDeclarationsFormText;
