import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { SOCIAL_PROGRAM_ADD_ROUTE } from 'routes/social-program/list';

import FiltersV2 from 'containers/filters-v2';

import { SocialProgramType } from 'types/socialProgram.types';

import { FormResponseType } from '../../socialProgram.utils';
import s from './Table.module.scss';
import useColumns from './useColumns.hook';

interface TableProps {
  totalCount?: number;
  dataSource?: SocialProgramType[];
  loading: boolean;
  isInitialLoading: boolean;
}

const Table: FC<TableProps> = (props) => {
  const { totalCount, dataSource, loading, isInitialLoading } = props;
  const navigate = useNavigate();

  const columns = useColumns();

  const onRow = (record: SocialProgramType) => ({
    onClick: () => navigate(`${SOCIAL_PROGRAM_ADD_ROUTE}/${record.id}`),
  });

  return (
    <FiltersV2.Table<SocialProgramType, FormResponseType>
      isInitialLoading={isInitialLoading}
      className={s.table}
      dataSource={dataSource}
      columns={columns}
      rowKey="id"
      onRow={onRow}
      loading={loading}
      total={totalCount}
    />
  );
};

export default Table;
