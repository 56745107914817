import { useParams } from 'react-router-dom';

import { useRemovePositionMutation } from 'services/renew-term/renewApiService';
import { GetRenewTermByIdResponseType } from 'services/renew-term/renewApiService.types';

import FiltersV2 from 'containers/filters-v2';

import s from './Table.module.scss';
import { mapTableData } from './table.utils';
import useColumns from './useColumns.hook';

interface Props {
  dataSource?: GetRenewTermByIdResponseType;
  loading: boolean;
  isInitialLoading: boolean;
}

const Table = (props: Props) => {
  const { dataSource, loading, isInitialLoading } = props;

  const { employerId } = useParams<{ employerId: string }>();
  const [removePosition, { isLoading: isDeleting }] = useRemovePositionMutation();

  const handleDelete = (positionName: string) => {
    if (employerId) {
      removePosition([{ employerId, positionName }]);
    }
  };

  const columns = useColumns({ handleDelete });

  const data = mapTableData(dataSource?.items);

  const isLoading = loading || isDeleting;

  return (
    <FiltersV2.Table
      isInitialLoading={isInitialLoading}
      rowKey="positionName"
      className={s.table}
      dataSource={data}
      total={dataSource?.totalCount}
      columns={columns}
      loading={isLoading}
    />
  );
};

export default Table;
