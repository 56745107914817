import DefaultFileComponent from './file';
import FileDownload from './file-download';
import FileUpload from './file-upload';

type FileComponents = typeof DefaultFileComponent & {
  Upload: typeof FileUpload;
  Download: typeof FileDownload;
};

const File = DefaultFileComponent as FileComponents;

File.Upload = FileUpload;
File.Download = FileDownload;

export default File;
