import { Typography } from 'gazprom-ui-lib';

import s from './Receivers.module.scss';

interface Props {
  receivers: { id: string; name: string }[];
}

const Receivers = (props: Props) => {
  const { receivers } = props;
  return (
    <div className={s.container}>
      {receivers.map(({ id, name }) => (
        <Typography.Text className={s.tag} key={id}>
          {name}
        </Typography.Text>
      ))}
    </div>
  );
};

export default Receivers;
