import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useUpdateNotificationTemplateMutation } from 'services/notification-template/notificationTemplateApiService';
import { UpdateNotificationTemplatePropsType } from 'services/notification-template/notificationTemplateApiService.types';

import {
  NOTIFICATION_TEMPLATE_FORM_ENUM,
  NotificationTemplateFormResponse,
} from 'containers/notification-template-form/notificationTemplateForm.utils';

import { NEWSLETTER_RECEIVER_ENUM, NotificationTemplateType } from 'types/notification.types';

interface UseSubmitProps {
  currentTemplate?: NotificationTemplateType;
  pushTemplate?: NotificationTemplateType;
}

const useSubmit = (props: UseSubmitProps) => {
  const { currentTemplate } = props;

  const [updateTemplate, { isLoading }] = useUpdateNotificationTemplateMutation();

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });

  const handleSubmit = (form: NotificationTemplateFormResponse) => {
    if (currentTemplate) {
      const mainNotificationRequestBody: UpdateNotificationTemplatePropsType = {
        id: currentTemplate.id,
        name: form[NOTIFICATION_TEMPLATE_FORM_ENUM.NAME],
        title: form[NOTIFICATION_TEMPLATE_FORM_ENUM.TITLE],
        body: form[NOTIFICATION_TEMPLATE_FORM_ENUM.TEXT],
        receivers: [
          {
            receiverType: NEWSLETTER_RECEIVER_ENUM.EMPLOYEE_GROUP,
            receiverIds: form[NOTIFICATION_TEMPLATE_FORM_ENUM.RECEIVERS],
          },
        ],
        sendPush: !!form[NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_PUSH],
        sendApp: !!form[NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_APP],
        sendEmail: !!form[NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_EMAIL],
        allowFeedback: !!form[NOTIFICATION_TEMPLATE_FORM_ENUM.ALLOW_FEEDBACK],
        requiresFeedback: !!form[NOTIFICATION_TEMPLATE_FORM_ENUM.REQUIRES_FEEDBACK],
        allowCallback: !!form[NOTIFICATION_TEMPLATE_FORM_ENUM.ALLOW_CALLBACK],
      };

      updateTemplate(mainNotificationRequestBody).then(handleUpdate);
    }
  };

  return {
    handleSubmit,
    updateTemplateIsFetching: isLoading,
  };
};

export default useSubmit;
