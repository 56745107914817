export enum FORM_ENUM {
  NAME = 'nameCategory',
  PARENT = 'parentId',
  PUBLISHED = 'isPublish',
}

export type FormResponseType = {
  [FORM_ENUM.NAME]: string;
  [FORM_ENUM.PARENT]?: string;
  [FORM_ENUM.PUBLISHED]?: boolean;
};
