import { useDebounce } from 'utils/useDebounce.hook';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import Header from './containers/header';
import Table from './containers/table';
import { DEFAULT_FORM_STATE } from './suggestionsCommissions.utils';
import { FormResponseType } from './suggestionsCommissions.utils';
import useRequest from './useRequest.hook';

const SuggestionCommissions = () => {
  const [form] = Form.useForm<FormResponseType>();

  const requestBody: FormResponseType = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const { commissions, isLoading, isFetching } = useRequest({
    requestBody: debouncedRequestBody,
    isDebouncing,
  });

  const loading = isLoading || isFetching;

  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <WithLoader isLoading={isLoading}>
          <Table
            totalCount={commissions?.totalCount}
            dataSource={commissions?.items ?? []}
            loading={loading}
            pagination={{
              defaultCurrent: debouncedRequestBody?.size,
              defaultPageSize: debouncedRequestBody?.size,
              total: commissions?.totalCount,
            }}
          />
        </WithLoader>
      </FiltersV2>
    </WithOverflow>
  );
};

export default SuggestionCommissions;
