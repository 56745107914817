import { t } from 'tools/i18n';

import FiltersWrapper from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { THANK_YOU_STATUS_ENUM } from 'types/thankYou.types';

import useFilterItems from './useFilterItems.hook';

const Filters = () => {
  const filterItems = useFilterItems();

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <FiltersWrapper
        showClearAll
        overlay={
          <>
            <FilterCollapse items={filterItems} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="status"
                options={[
                  { label: t('thank_you_status_approved'), value: THANK_YOU_STATUS_ENUM.APPROVED },
                  { label: t('thank_you_status_declined'), value: THANK_YOU_STATUS_ENUM.DECLINED },
                  { label: t('thank_you_status_pending'), value: THANK_YOU_STATUS_ENUM.PENDING },
                ]}
              />
            </FilterCustomWrapper>
          </>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
