import { ChangeEvent, useEffect, useState } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useGetGroupCitizenshipQuery } from 'services/1c/cApiService';
import { useLazyGetDivisionsQuery } from 'services/division/divisionApiService';
import { GetDivisionsPropsType } from 'services/division/divisionApiService.types';
import { useLazyGetEmployersListQuery } from 'services/employer/employerApiService';
import { GetEmployersPropsType } from 'services/employer/employerApiService.types';
import { useLazyGetPositionsQuery } from 'services/position/positionApiService';
import { GetPositionsPropsType } from 'services/position/positionApiService.types';

import {
  selectEmployersListFromResult,
  selectFromResult,
  selectFromResultWithFilter,
  simpleSelectFromResult,
} from './filters.utils';

export type RequestBody = {
  employerName?: string | null;
  categoryName?: string | null;
  positionName?: string | null;
  divisionName?: string | null;
  page?: number;
  size?: number;
};

export const INITIAL_REQUEST_BODY: RequestBody = {
  employerName: null,
  categoryName: null,
  positionName: null,
  divisionName: null,
  page: 1,
  size: 20,
};

const useFilters = () => {
  const [requestBody, setRequestBody] = useState<RequestBody>(INITIAL_REQUEST_BODY);

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  const [getEmployers, employersResult] = useLazyGetEmployersListQuery({
    selectFromResult: selectEmployersListFromResult,
  });
  const [getDivisions, divisionsResult] = useLazyGetDivisionsQuery({
    selectFromResult: simpleSelectFromResult,
  });
  const [getPositions, positionsResult] = useLazyGetPositionsQuery({
    selectFromResult: selectFromResultWithFilter,
  });
  const { data: citizenshipOptions } = useGetGroupCitizenshipQuery(undefined, { selectFromResult });

  const employersSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev) => ({ ...prev, employerName: e.target.value }));
  };

  const divisionsSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev) => ({ ...prev, divisionName: e.target.value }));
  };

  const positionsSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev) => ({ ...prev, positionName: e.target.value }));
  };

  useEffect(() => {
    const defaultBody: GetEmployersPropsType = {
      page: debouncedRequestBody.page!,
      size: 500,
    };

    if (debouncedRequestBody.employerName) {
      defaultBody.name = debouncedRequestBody.employerName;
    }

    getEmployers(defaultBody);
  }, [debouncedRequestBody, getEmployers]);

  useEffect(() => {
    let defaultBody: GetPositionsPropsType = {};

    if (debouncedRequestBody.positionName) {
      defaultBody.name = debouncedRequestBody.positionName;
    }

    getPositions(defaultBody);
  }, [debouncedRequestBody, getPositions]);

  useEffect(() => {
    let defaultBody: GetDivisionsPropsType = {};

    if (debouncedRequestBody.divisionName) {
      defaultBody.name = debouncedRequestBody.divisionName;
    }

    getDivisions(defaultBody);
  }, [debouncedRequestBody, getDivisions]);

  return {
    employersResult,
    positionsResult,
    divisionsResult,
    citizenshipOptions,
    employersSearchHandler,
    positionsSearchHandler,
    divisionsSearchHandler,
  };
};

export default useFilters;
