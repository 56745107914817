import { useParams } from 'react-router-dom';
import { SOCIAL_PROGRAM_PROGRAMS_ROUTE } from 'routes/social-program/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import WithOverflow from 'containers/wrappers/with-overflow';

import { SOCIAL_PROGRAM_FORM_NAME } from 'pages/social-program/social-program-form/socialProgramForm.utils';

import SocialProgramForm from '../social-program-form';
import s from './SocialProgramAdd.module.scss';

const SocialProgramAdd = () => {
  const { id } = useParams();

  return (
    <WithOverflow>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title goBackLink={SOCIAL_PROGRAM_PROGRAMS_ROUTE}>
          {id ? t('social_program_edit_title') : t('social_program_create_title')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="ok" htmlType="submit" form={SOCIAL_PROGRAM_FORM_NAME}>
            {t('common_save')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <div className={s.container}>
        <SocialProgramForm />
      </div>
    </WithOverflow>
  );
};

export default SocialProgramAdd;
