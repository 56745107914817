import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './settings1cInstances.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useSetDefaultValues = (props: Props) => {
  const { form } = props;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.state?.requestBody) {
      form.setFieldsValue(location.state.requestBody);
      navigate(location.pathname, { replace: true });
    }
  }, [form, location, navigate]);
};

export default useSetDefaultValues;
