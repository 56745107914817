import { FC } from 'react';

import { SETTINGS_POSITION_DIRECTORY_ROUTE } from 'routes/settings/list';

import ContentHeader from 'containers/content-header';

interface HeaderProps {
  employerName?: string;
  totalCount?: number;
}

const Header: FC<HeaderProps> = (props) => {
  const { employerName, totalCount } = props;

  return (
    <ContentHeader>
      <ContentHeader.Title
        total={totalCount?.toString()}
        goBackLink={SETTINGS_POSITION_DIRECTORY_ROUTE}>
        {employerName}
      </ContentHeader.Title>
    </ContentHeader>
  );
};

export default Header;
