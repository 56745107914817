import { FC } from 'react';

import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Form, Input, TextArea } from 'gazprom-ui-lib';

import { EVENT_FORM_ENUM, FORM_LABELS } from '../../../eventForm.utils';

const Place: FC = () => {
  return (
    <>
      <Form.Item
        name={EVENT_FORM_ENUM.CITY}
        rules={[formRules.required]}
        label={FORM_LABELS[EVENT_FORM_ENUM.CITY]}
        normalize={normalizeTextField}>
        <Input placeholder={t('common_enter_text')} />
      </Form.Item>
      <Form.Item
        label={FORM_LABELS[EVENT_FORM_ENUM.LOCATION]}
        normalize={normalizeTextField}
        name={EVENT_FORM_ENUM.LOCATION}
        rules={[formRules.required, { max: 120 }]}>
        <TextArea placeholder={t('common_enter_text')} maxLength={120} showCount />
      </Form.Item>
    </>
  );
};

export default Place;
