import { FC } from 'react';

import { t } from 'tools/i18n';

import { Checkbox, Form, FormInstance, Typography } from 'gazprom-ui-lib';

import {
  NOTIFICATION_TEMPLATE_FORM_ENUM,
  NotificationTemplateFormResponse,
} from '../notificationTemplateForm.utils';
import s from './NotificationTemplateFormApp.module.scss';

const NotificationTemplateFormApp: FC = () => {
  const form = Form.useFormInstance();

  const isFeedbackAvailable = Form.useWatch(NOTIFICATION_TEMPLATE_FORM_ENUM.ALLOW_FEEDBACK, form);

  const shouldUpdate = (
    prevValues: Partial<NotificationTemplateFormResponse>,
    currentValues: Partial<NotificationTemplateFormResponse>,
  ) => {
    return (
      prevValues[NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_APP] !==
      currentValues[NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_APP]
    );
  };

  const normalizeFeedbackOn = (e: boolean) => {
    if (!e) {
      form.resetFields([NOTIFICATION_TEMPLATE_FORM_ENUM.REQUIRES_FEEDBACK]);
    }

    return e;
  };

  const normalizeNotificationOn = (e: boolean) => {
    if (!e) {
      form.resetFields([
        NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_PUSH,
        NOTIFICATION_TEMPLATE_FORM_ENUM.ALLOW_FEEDBACK,
        NOTIFICATION_TEMPLATE_FORM_ENUM.REQUIRES_FEEDBACK,
      ]);
    } else {
      form.setFields([{ name: NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_PUSH, value: true }]);
    }

    return e;
  };

  return (
    <>
      <Form.Item
        name={NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_APP}
        valuePropName="checked"
        normalize={normalizeNotificationOn}>
        <Checkbox>
          <Typography.Text size="14">{t('notification_inside_app')}</Typography.Text>
        </Checkbox>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={shouldUpdate}>
        {/*@ts-ignore*/}
        {({ getFieldValue }: FormInstance<NotificationTemplateFormResponse>) => {
          const sendApp = getFieldValue(NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_APP);

          if (!sendApp) return <></>;

          return (
            <div className={s.wrapper}>
              <Form.Item
                name={NOTIFICATION_TEMPLATE_FORM_ENUM.ALLOW_CALLBACK}
                valuePropName="checked"
                normalize={normalizeFeedbackOn}>
                <Checkbox>
                  <Typography.Text size="14">{t('notification_allow_callback')}</Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={NOTIFICATION_TEMPLATE_FORM_ENUM.ALLOW_FEEDBACK}
                valuePropName="checked"
                normalize={normalizeFeedbackOn}>
                <Checkbox>
                  <Typography.Text size="14">{t('notification_feedback_on')}</Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={NOTIFICATION_TEMPLATE_FORM_ENUM.REQUIRES_FEEDBACK}
                valuePropName="checked">
                <Checkbox disabled={!isFeedbackAvailable}>
                  <Typography.Text size="14">{t('notification_feedback_required')}</Typography.Text>
                </Checkbox>
              </Form.Item>
              <Form.Item name={NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_PUSH} valuePropName="checked">
                <Checkbox disabled>
                  <Typography.Text size="14">{t('notification_push_added')}</Typography.Text>
                </Checkbox>
              </Form.Item>
            </div>
          );
        }}
      </Form.Item>
    </>
  );
};

export default NotificationTemplateFormApp;
