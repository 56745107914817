import { Dispatch, SetStateAction, createContext } from 'react';

export type FiltersArrayValueType = (string | number | boolean)[];
export type FilterValueType = Record<string, unknown> | FiltersArrayValueType | null | string;
export type FiltersResponseType = Record<string, FilterValueType> | null;
export type FilterContextType<R = FiltersResponseType> = {
  filters: R;
  setFilters?: Dispatch<SetStateAction<R>>;
  clearFilter?: <R>(key: string, value?: R) => void;
};
export const FilterContext = createContext<FilterContextType>({
  filters: null,
  setFilters: undefined,
  clearFilter: undefined,
});

export const getFilterCount = (value: FilterValueType): null | number => {
  try {
    if (Array.isArray(value)) {
      return value.length;
    }

    if (value && typeof value === 'object') {
      return Object.keys(value).length;
    }

    return null;
  } catch {
    return null;
  }
};
