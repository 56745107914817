import { Dayjs } from 'dayjs';

import { SORT_ENUM } from 'types/sort.types';

export type FormFiltersType = {
  status?: string[];
  categories: string[];
};

export type FormResponseType = {
  page: number;
  size: number;
  property?: string;
  order: SORT_ENUM;
  search: string;
  filters?: FormFiltersType;
  range?: [Dayjs, Dayjs];
};

export const DEFAULT_SORT = {
  property: 'createdAt',
  order: SORT_ENUM.DESC,
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  search: '',
  ...DEFAULT_SORT,
};

export const transformBusinessTripPeriod = (dateFrom?: string, dateTo?: string) => {
  if (dateFrom && dateTo) {
    return `${dateFrom.replaceAll('-', '.')} - ${dateTo.replaceAll('-', '.')}`;
  }

  return '';
};
