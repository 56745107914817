import { useState } from 'react';

import { t } from 'tools/i18n';
import { createPersonalFileDownloadUrl } from 'utils/createDownloadUrl.utils';
import { formRules } from 'utils/formRules';

import { Form, Typography } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import UploadImitation from 'components/upload-imitation';
import ImageModal from 'containers/image-modal';
import { FORM_IMAGE_ENUM, FormImageResponse } from 'containers/image-modal/imageModal.utils';

import { KEDO_REVIEW_TASK_FORM_ENUM } from '../kedoReviewTaskForm.utils';
import s from './File.module.scss';

interface KedoReviewTaskFormFileProps {
  showDownload?: boolean;
  showDelete?: boolean;
  kedoReviewTaskId?: string;
}

const File = (props: KedoReviewTaskFormFileProps) => {
  const { showDelete, showDownload, kedoReviewTaskId } = props;

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const form = Form.useFormInstance();
  const cover = Form.useWatch(KEDO_REVIEW_TASK_FORM_ENUM.FILE);

  const handleModalOpenClose = () => {
    setShowUploadModal((prevState) => !prevState);
  };

  const onFileUpload = (_: string, data: FormImageResponse) => {
    form.setFieldValue(KEDO_REVIEW_TASK_FORM_ENUM.FILE, {
      ...data[FORM_IMAGE_ENUM.FULL_INFO],
      link: data[FORM_IMAGE_ENUM.FULL_INFO]?.url ?? data[FORM_IMAGE_ENUM.URL],
    });
    handleModalOpenClose();
  };

  const resetImage = () => {
    form.resetFields([KEDO_REVIEW_TASK_FORM_ENUM.FILE]);
  };

  const coverSize = cover?.size ? (Number(cover.size) / (1024 * 1024)).toFixed(2) : 0;

  return (
    <div className={s.wrapper}>
      <Typography.Text strong size="16" className={s.formItemLabel}>
        {t('kedo_review_tasks_documents')}
      </Typography.Text>
      <Form.Item name={KEDO_REVIEW_TASK_FORM_ENUM.FILE} rules={[formRules.required]}>
        {cover ? (
          <ImgPreview
            downloadWithAuth
            iconName="docTypePdf"
            className={s.preview}
            resetImage={resetImage}
            type="icon"
            showDelete={showDelete}
            showDownload={showDownload}
            url={createPersonalFileDownloadUrl(cover?.link)}
            title={cover?.name ?? createPersonalFileDownloadUrl(cover?.link)}
            description={t('common_mb', { count: coverSize })}
          />
        ) : (
          <UploadImitation
            title={t('common_upload_a_file')}
            handleClick={handleModalOpenClose}
            description={t('validation_upload_a_kedo_review_task_doc_restrictions')}
          />
        )}
      </Form.Item>
      <ImageModal
        kedoReviewTaskId={kedoReviewTaskId}
        maxLimitSizeMB={100}
        isOpen={showUploadModal}
        onSubmit={onFileUpload}
        onClose={handleModalOpenClose}
        uploadTitle={t('common_upload_a_file')}
        uploadDescription={t('validation_upload_a_kedo_review_task_doc_restrictions')}
        availableTypes={AVAILABLE_TYPES}
      />
    </div>
  );
};

const AVAILABLE_TYPES = [
  'application/pdf',
  'zip',
  'application/zip',
  'application/x-zip-compressed',
  'application/x-zip',
];

export default File;
