import { SURVEYS_EVENT_ROUTE, SURVEYS_ONCE_ROUTE } from 'routes/surveys/list';
import { t } from 'tools/i18n';

import ContentHeader from 'containers/content-header';

import SurveyFormEvent from '../containers/survey-form-event';
import SurveyFormOnce from '../containers/survey-form-once';
import useMatchOnceRoute from '../containers/useMatchOnceRoute';
import s from './SurveysAdd.module.scss';

const SurveysAdd = () => {
  const isOnceSurveysRoute = useMatchOnceRoute();

  return (
    <>
      <ContentHeader>
        <ContentHeader.Title
          goBackLink={isOnceSurveysRoute ? SURVEYS_ONCE_ROUTE : SURVEYS_EVENT_ROUTE}>
          {t('surveys_add')}
        </ContentHeader.Title>
      </ContentHeader>
      <div className={s.container}>
        {isOnceSurveysRoute ? <SurveyFormOnce /> : <SurveyFormEvent />}
      </div>
    </>
  );
};

export default SurveysAdd;
