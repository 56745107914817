import { FC, useState } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { SOCIAL_PROGRAM_REQUESTS_ROUTE } from 'routes/social-program/list';
import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import {
  useGetEmployees1cQuery,
  useSocialRequestAssignExecutor1cMutation,
} from 'services/1c/cApiService';
import { CEmployeeType, GetEmployees1cResponseType } from 'services/1c/cApiService.types';

import { Flex, Icon, Select, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { getStatus } from 'pages/social-program/social-program-requests/socialProgramRequests.utils';

import { SocialProgramRequestType } from 'types/socialProgram.types';

import s from './Header.module.scss';

interface SocialProgramRequestsDetailsHeaderProps {
  socialRequestData?: SocialProgramRequestType;
}
const Header: FC<SocialProgramRequestsDetailsHeaderProps> = (props) => {
  const { socialRequestData } = props;
  const params = useParams<{ id: string }>();

  const [search, setSearch] = useState<string>('');

  const [updateExecutor] = useSocialRequestAssignExecutor1cMutation();

  const { debouncedValue: debouncedSearch } = useDebounce(search);

  const { options } = useGetEmployees1cQuery(
    { fio: debouncedSearch, page: 1, size: 500 },
    { selectFromResult },
  );

  const handleChange = (e: string) => {
    if (params.id) {
      updateExecutor({
        taskId: params.id,
        executorId: e,
      });
    }
  };

  const description = (
    <Flex gap="4" align="center">
      <Flex gap="4" align="center">
        {`${t('common_last_change')}: `}
        <Icon name="calendar" />
        <Typography.Text type="secondary" size="14">
          {dayjs(socialRequestData?.updatedAt).format('DD MMMM YYYY, HH:MM')}
        </Typography.Text>
      </Flex>
      <Flex gap="4" align="center">
        <Typography.Text type="secondary" size="14">
          {t('common_executor')}:
        </Typography.Text>
        <Select
          options={options}
          className={s.select}
          size="small"
          searchValue={search}
          onSearch={setSearch}
          onChange={handleChange}
          value={socialRequestData?.executor?.id}
          showSearch
          filterOption
          optionFilterProp="label"
        />
      </Flex>
    </Flex>
  );

  return (
    <ContentHeader align="flex-start" showBorder>
      <ContentHeader.Title goBackLink={SOCIAL_PROGRAM_REQUESTS_ROUTE} description={description}>
        {t('common_view_request')}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        {getStatus(socialRequestData?.currentKedoStatus)}
      </ContentHeader.Actions>
    </ContentHeader>
  );
};
const selectFromResult = ({ data, ...other }: { data?: GetEmployees1cResponseType }) => ({
  ...other,
  options:
    data?.employees?.map(({ person, id }: CEmployeeType) => ({
      value: id,
      label: `${person.lastName} ${person.firstName} ${person.patronymic}`,
    })) ?? [],
});
export default Header;
