import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiService from 'services/1c/cApiService';
import {
  AcceptRenewFormPropsType,
  AcceptRenewFormResponseType,
  AddPositionPropsType,
  AddPositionsResponseType,
  GetAvailablePositionsPropsType,
  GetAvailablePositionsResponseType,
  GetRenewTermByIdPropsType,
  GetRenewTermByIdResponseType,
  GetRenewTermFormByIdPropsType,
  GetRenewTermFormByIdResponseType,
  GetRenewTermFormsPropsType,
  GetRenewTermFormsResponseType,
  GetRenewTermsPropsType,
  GetRenewTermsResponseType,
  RemovePositionPropsType,
  RemovePositionsResponseType,
} from 'services/renew-term/renewApiService.types';
import { createRenewTermUrl } from 'services/renew-term/utils';

import { C_SERVICE_TAGS } from 'constants/serviceTags';

const renewApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getRenewTerms: builder.query<GetRenewTermsResponseType, GetRenewTermsPropsType>({
      query: (params) => ({
        url: createRenewTermUrl(`/employer/by-renewal-logic${prepareQueryParams(params)}`),
      }),
      providesTags: [C_SERVICE_TAGS.GET_RENEW_TERMS],
    }),
    getRenewTermById: builder.query<GetRenewTermByIdResponseType, GetRenewTermByIdPropsType>({
      query: ({ employerId, ...params }) => ({
        url: createRenewTermUrl(`/${employerId}${prepareQueryParams(params)}`),
      }),
      providesTags: [C_SERVICE_TAGS.GET_RENEW_TERM_DETAILS],
    }),
    getAvailablePositions: builder.query<
      GetAvailablePositionsResponseType,
      GetAvailablePositionsPropsType
    >({
      query: (params) => ({
        url: createRenewTermUrl(`/position/by-renewal-logic${prepareQueryParams(params)}`),
      }),
      providesTags: [C_SERVICE_TAGS.GET_AVAILABLE_RENEW_TERMS_POSITIONS],
    }),
    addPosition: builder.mutation<AddPositionsResponseType, AddPositionPropsType>({
      query: (body) => ({
        url: createRenewTermUrl(''),
        method: 'POST',
        body,
      }),
      invalidatesTags: (_a, _b, [{ employerId }]) => [
        { type: C_SERVICE_TAGS.GET_RENEW_TERMS },
        { type: C_SERVICE_TAGS.GET_RENEW_TERM_DETAILS, employerId },
        { type: C_SERVICE_TAGS.GET_AVAILABLE_RENEW_TERMS_POSITIONS, employerId },
      ],
    }),
    removePosition: builder.mutation<RemovePositionsResponseType, RemovePositionPropsType>({
      query: (body) => ({
        url: createRenewTermUrl(''),
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (_a, _b, [{ employerId }]) => [
        { type: C_SERVICE_TAGS.GET_RENEW_TERMS },
        { type: C_SERVICE_TAGS.GET_RENEW_TERM_DETAILS, employerId },
        { type: C_SERVICE_TAGS.GET_AVAILABLE_RENEW_TERMS_POSITIONS, employerId },
      ],
    }),
    getRenewTermForms: builder.query<GetRenewTermFormsResponseType, GetRenewTermFormsPropsType>({
      query: (params) => ({
        url: createRenewTermUrl(`/form${prepareQueryParams(params)}`),
      }),
      providesTags: [C_SERVICE_TAGS.GET_RENEW_TERMS_FORMS],
    }),
    getRenewTermFormById: builder.query<
      GetRenewTermFormByIdResponseType,
      GetRenewTermFormByIdPropsType
    >({
      query: (id) => ({
        url: createRenewTermUrl(`/form/${id}`),
      }),
      providesTags: [C_SERVICE_TAGS.GET_RENEW_TERMS_FORM_BY_ID],
    }),
    acceptRenewForm: builder.mutation<AcceptRenewFormResponseType, AcceptRenewFormPropsType>({
      query: (id) => ({
        url: createRenewTermUrl(`/form/${id}/accept`),
        method: 'POST',
      }),
      invalidatesTags: [
        C_SERVICE_TAGS.GET_RENEW_TERMS_FORMS,
        C_SERVICE_TAGS.GET_RENEW_TERMS_FORM_BY_ID,
      ],
    }),
  }),
});

export const {
  useLazyGetRenewTermsQuery,
  useLazyGetRenewTermByIdQuery,
  useLazyGetAvailablePositionsQuery,
  useAddPositionMutation,
  useRemovePositionMutation,
  useLazyGetRenewTermFormsQuery,
  useGetRenewTermFormByIdQuery,
  useAcceptRenewFormMutation,
} = renewApiService;
