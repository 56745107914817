import { useMatch, useNavigate } from 'react-router-dom';
import {
  NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE,
  getNotificationNewsletterWithIdRoute,
  getSystemNotificationNewsletterWithIdRoute,
} from 'routes/notification/list';

import FiltersV2 from 'containers/filters-v2';

import { NotificationNewsletterType, SENDER_ENUM } from 'types/notification.types';

import s from './NotificationNewsletterTable.module.scss';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

interface Props {
  templateId?: string;
  senderType?: SENDER_ENUM;
}

const NotificationNewsletterTable = (props: Props) => {
  const { templateId, senderType = SENDER_ENUM.HUMAN } = props;

  const navigate = useNavigate();

  const isSystemNotification = !!useMatch(NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE);

  const columns = useColumns();

  const onRow = ({ id }: NotificationNewsletterType) => ({
    onClick: () => {
      if (isSystemNotification) {
        navigate(getSystemNotificationNewsletterWithIdRoute(id));
      } else {
        navigate(getNotificationNewsletterWithIdRoute(id));
      }
    },
  });

  const { data, isLoading, isFetching } = useGetData({ templateId, senderType });

  return (
    <FiltersV2.Table
      isInitialLoading={isLoading}
      className={s.table}
      rowKey="id"
      dataSource={data?.items}
      columns={columns}
      total={data?.totalCount}
      showSorterTooltip={false}
      onRow={onRow}
      loading={isFetching}
    />
  );
};

export default NotificationNewsletterTable;
