import { DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';

export const DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME =
  'DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME';

export enum DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM {
  IS_CANCELABLE = 'IS_CANCELABLE',
  HAS_DIGITAL_FORM = 'HAS_DIGITAL_FORM',
}

export const CANCEL_ORDERS_PROCESS = 'cancel-orders-process-bpmn';

export const EXCLUDED_PARAMETERS_KEYS = [
  DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS,
  DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.IS_CANCELABLE,
  DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.HAS_DIGITAL_FORM,
] as string[];
