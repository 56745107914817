import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiService from 'services/1c/cApiService';

import {
  GetBypassSheetByIdPropsType,
  GetBypassSheetByIdResponseType,
  GetBypassSheetsPropsType,
  GetBypassSheetsResponseType,
} from './bypassSheetApiService.types';

import { createBypassSheetUrl } from './utils';

const bypassSheetApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getBypassSheets: builder.query<GetBypassSheetsResponseType, GetBypassSheetsPropsType>({
      query: (params) => ({
        url: createBypassSheetUrl(prepareQueryParams(params)),
      }),
      providesTags: [],
    }),
    getBypassSheetById: builder.query<GetBypassSheetByIdResponseType, GetBypassSheetByIdPropsType>({
      query: (id) => ({
        url: createBypassSheetUrl(`/${id}`),
      }),
      providesTags: [],
    }),
  }),
});

export const { useGetBypassSheetsQuery, useGetBypassSheetByIdQuery } = bypassSheetApiService;
