import { FC, memo } from 'react';

import cn from 'classnames';

import { Form, FormProps } from 'gazprom-ui-lib';

import s from './FiltersV2.module.scss';

interface FiltersV2Props extends FormProps {}

const FiltersV2: FC<FiltersV2Props> = (props) => {
  const { children, className, ...otherProps } = props;

  return (
    <Form className={cn(s.wrapper, className)} {...otherProps}>
      {children}
    </Form>
  );
};

export default memo(FiltersV2);
