import { useContext, useState } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SaveModalContext } from 'app.utils';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useStartCInstanceSyncMutation } from 'services/1c-instances/cInstancesService';
import { StartCInstanceSyncPropsType } from 'services/1c-instances/cInstancesService.types';

import { CheckboxValueType } from 'gazprom-ui-lib';

import {
  CONNECTION_TYPES_ENUM,
  getStartSyncErrorConfig,
  getSyncTypeOptions,
} from './cInstanceSynchronizationModal.utils';

interface CInstanceConnectionModalProps {
  instanceId: string;
  syncTypes: string[];
  onCancel: () => void;
}

const useCInstanceConnection = (props: CInstanceConnectionModalProps) => {
  const { instanceId, syncTypes, onCancel } = props;
  const setModalConfig = useContext(SaveModalContext);

  const [startSync, { isLoading }] = useStartCInstanceSyncMutation();

  const syncTypeOptions = getSyncTypeOptions(syncTypes);

  const initialSelectedConnectionTypes = syncTypes.includes(CONNECTION_TYPES_ENUM.ORG_STRUCTURE)
    ? [CONNECTION_TYPES_ENUM.ORG_STRUCTURE]
    : [];

  const [selectedConnectionTypes, setSelectedConnectionTypes] = useState<CheckboxValueType[]>(
    initialSelectedConnectionTypes,
  );

  const handleConnectionTypeSelection = (checkedValues: CheckboxValueType[]) => {
    setSelectedConnectionTypes(checkedValues);
  };

  const handleResetConnectionTypes = () => {
    onCancel();
    setSelectedConnectionTypes([]);
  };

  const handleStartSync = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('1c_instance_sync_success'),
      },
      description: {
        children: t('1c_instance_sync_success_description'),
      },
    },
    onSuccess: handleResetConnectionTypes,
    callOnSuccessWithoutData: true,
    onError: (error?: SerializedError | FetchBaseQueryError) => {
      handleResetConnectionTypes();

      const { title, description } = getStartSyncErrorConfig(error);

      if (setModalConfig) {
        setModalConfig({
          icon: {
            name: 'info',
            type: 'warning',
          },
          title: {
            children: title,
          },
          description: {
            children: description,
          },
        });
      }
    },
  });

  const handleSubmit = () => {
    const requestBody: StartCInstanceSyncPropsType = {
      id: instanceId,
      typesToSync: selectedConnectionTypes,
    };

    startSync(requestBody).then(handleStartSync);
  };

  return {
    syncTypeOptions,
    selectedConnectionTypes,
    handleConnectionTypeSelection,
    handleSubmit,
    isLoading,
  };
};

export default useCInstanceConnection;
