import { FC } from 'react';

import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import s from './Header.module.scss';

interface HeaderProps {
  total: number;
}

const Header: FC<HeaderProps> = (props) => {
  const { total } = props;

  return (
    <>
      <ContentHeader>
        <ContentHeader.Title>
          <Flex vertical gap="8">
            {t('suggestions_employees_title')}
            <Flex gap="8" align="center" className={s.container}>
              <Icon name="idea" />
              <Typography.Text>{t('suggestions_list_counter', { value: total })}</Typography.Text>
            </Flex>
          </Flex>
        </ContentHeader.Title>
      </ContentHeader>
    </>
  );
};

export default Header;
