import { useMatch } from 'react-router-dom';
import {
  SURVEYS_ADD_ONCE_ROUTE,
  SURVEYS_ONCE_BY_ID_ROUTE,
  SURVEYS_ONCE_ROUTE,
} from 'routes/surveys/list';

const useMatchOnceRoute = () => {
  const matchOnceRoute = useMatch(SURVEYS_ONCE_ROUTE);
  const matchByIdRoute = useMatch(SURVEYS_ONCE_BY_ID_ROUTE);
  const matchAddOnceRoute = useMatch(SURVEYS_ADD_ONCE_ROUTE);

  const isOnceRoute = matchOnceRoute || matchByIdRoute || matchAddOnceRoute;

  return isOnceRoute;
};

export default useMatchOnceRoute;
