import cn from 'classnames';
import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import loupeImg from 'assets/icons/no-data.svg';

import s from './Empty.module.scss';

interface EmptyProps {
  title: string;
  description?: string;
  showIcon?: boolean;
  className?: string;
}

/**
 * Компонент для отображения пустых опросов/уведомлений и т.д.
 */

export default function Empty(props: EmptyProps) {
  const { title, description, showIcon = true, className } = props;
  return (
    <div className={cn(s.surveysContainer, className)}>
      {showIcon && <img src={loupeImg} alt="" />}
      <div className={s.text}>
        <Typography.Title level={4}>{t(title)}</Typography.Title>
        {description && (
          <Typography.Text size="16" type="secondary">
            {t(description)}
          </Typography.Text>
        )}
      </div>
    </div>
  );
}
