import { Dispatch, ReactNode, SetStateAction, createContext } from 'react';

import {
  ModalDefaultDescriptionProps,
  ModalDefaultIconProps,
  ModalDefaultTitleProps,
} from 'gazprom-ui-lib';

export type SaveModalConfigType = null | {
  icon?: ModalDefaultIconProps;
  onConfirm?: () => void;
  onClose?: () => void;
  actions?: ReactNode;
  title?: ModalDefaultTitleProps;
  description?: ModalDefaultDescriptionProps;
};
export type SaveModalContextType = Dispatch<SetStateAction<SaveModalConfigType>> | null;
export const SaveModalContext = createContext<SaveModalContextType>(null);
