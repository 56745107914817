import { ReactNode } from 'react';

import { TIconsLiteral, TagType } from 'gazprom-ui-lib';

import { CurrentKedoStatusType } from 'types/documents.types';

export const getKedoTagConfig = (
  status?: CurrentKedoStatusType,
): { type: TagType; children: ReactNode; icon: TIconsLiteral; description: string } => {
  let icon: TIconsLiteral = 'time';
  let type: TagType = 'outline';

  switch (status?.state) {
    case 'done': {
      icon = 'ok';
      type = 'success';
      break;
    }
    case 'error': {
      icon = 'delete';
      type = 'danger';
      break;
    }
    case 'pending': {
      icon = 'time';
      type = 'warning';
      break;
    }
    case 'continue': {
      icon = 'refresh';
      type = 'additional11';
      break;
    }
  }

  return {
    icon,
    children: status?.title ?? '',
    type,
    description: status?.description ?? '',
  };
};
