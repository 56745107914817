import { FC, useEffect } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';

import { Form } from 'gazprom-ui-lib';

import { ArticleType } from 'types/article.types';

import s from './NewsForm.module.scss';
import NewsFormCategories from './news-form-categories';
import NewsFormCover from './news-form-cover';
import NewsFormPublication from './news-form-publication';
import NewsFormText from './news-form-text';
import NewsFormTitle from './news-form-title';
import { NEWS_FORM_ENUM, NEWS_FORM_NAME, NewsFormResponse } from './newsForm.utils';

interface NewsFormProps {
  handleSubmit: (form: NewsFormResponse) => void;
  handleReset: () => void;
  className?: string;
  currentArticle?: ArticleType;
  type: 'add' | 'edit';
}

const NewsForm: FC<NewsFormProps> = (props) => {
  const { handleSubmit, handleReset, className, currentArticle } = props;

  const [form] = Form.useForm<NewsFormResponse>();

  useEffect(() => {
    if (currentArticle && !form.isFieldsTouched()) {
      form.setFieldsValue({
        [NEWS_FORM_ENUM.PUBLISH]: currentArticle?.isPublish,
        [NEWS_FORM_ENUM.TITLE]: currentArticle?.title,
        [NEWS_FORM_ENUM.TEXT]: currentArticle?.text,
        [NEWS_FORM_ENUM.COVER]: currentArticle?.cover?.link,
        [NEWS_FORM_ENUM.CATEGORIES]: currentArticle?.categories.length
          ? currentArticle?.categories.map(({ id }) => id)
          : [],
      });
      if (currentArticle?.publishFrom) {
        form.setFieldsValue({
          [NEWS_FORM_ENUM.PUBLISH_FROM_DATE]: dayjs(currentArticle?.publishFrom),
          [NEWS_FORM_ENUM.PUBLISH_FROM_TIME]: dayjs(currentArticle?.publishFrom),
        });
      }
    }
  }, [form, currentArticle]);

  return (
    <Form
      form={form}
      className={cn(s.wrapper, className)}
      onFinish={handleSubmit}
      onReset={handleReset}
      id={NEWS_FORM_NAME}>
      <NewsFormTitle />
      <NewsFormText />
      <NewsFormCover />
      <NewsFormCategories />
      <NewsFormPublication />
    </Form>
  );
};

export default NewsForm;
