import { t } from 'tools/i18n';
import { pluralEnding } from 'utils/declension.utils';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import s from './PersonsCounter.module.scss';

interface Props {
  value: number;
}

const PersonsCounter = (props: Props) => {
  const { value } = props;

  return (
    <Flex gap="8" align="center">
      <Icon name="groups" className={s.icon} />
      <Typography.Text size="14" type="primary">
        {t('common_employees_count', {
          current: value,
          ending: pluralEnding(value, t('common_employee').toLowerCase()),
        })}
      </Typography.Text>
    </Flex>
  );
};

export default PersonsCounter;
