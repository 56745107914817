import { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { GUIDE_CONTACTS_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import {
  useGetHotContactByIdQuery,
  usePostHotContactByIdMutation,
} from 'services/guide/guideApiService';
import { useLazyGetPersonByIdQuery } from 'services/person/personApiService';

import { Button } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';
import ContentHeader from 'containers/content-header';
import LastChange from 'containers/last-change';
import WithLoader from 'containers/wrappers/with-loader';

import { GuideHotContactByIdType } from 'types/guide.types';

import s from './GuideContactsEdit.module.scss';
import GuideContactsEditModalAdd from './guide-contacts-edit-modal-add';
import GuideContactsEditSection from './guide-contacts-edit-section';

const GuideContactsEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [modal, setModal] = useState('');
  const { data: hotContacts, isLoading } = useGetHotContactByIdQuery(
    { employerId: id! },
    { selectFromResult },
  );
  const [createHotContact, { isLoading: isCreateHotContactLoading }] =
    usePostHotContactByIdMutation();
  const [getPerson, personResult] = useLazyGetPersonByIdQuery();

  const lastUpdateInfo = useMemo(() => {
    let latestDate = dayjs(hotContacts?.[0]?.updatedAt);
    let latestDateObject = hotContacts?.[0];

    hotContacts?.forEach((contact) => {
      const currentDate = dayjs(contact.updatedAt);
      if (currentDate.isAfter(latestDate)) {
        latestDate = currentDate;
        latestDateObject = contact;
      }
    });

    return latestDateObject;
  }, [hotContacts]);

  useEffect(() => {
    if (lastUpdateInfo?.editor) {
      getPerson(lastUpdateInfo?.editor);
    }
  }, [getPerson, lastUpdateInfo?.editor]);

  const addHandler = (value: string) => {
    createHotContact({
      employerId: id!,
      name: value,
      num: (hotContacts?.length ?? 0) + 1,
      contacts: [],
    }).then(() => setModal(''));
  };

  const renderComponents = (contact: GuideHotContactByIdType) => {
    const { id, name } = contact;

    return (
      <GuideContactsEditSection
        id={id}
        title={name}
        hotContacts={contact}
        setModal={setModal}
        key={`${id}__${name}`}
      />
    );
  };

  const showDescription = id && personResult;
  const description = showDescription && (
    <LastChange
      date={lastUpdateInfo?.updatedAt}
      editorId={lastUpdateInfo?.editor}
      editorName={`${personResult.data?.firstName} ${personResult.data?.lastName}`}
    />
  );

  return (
    <WithLoader isLoading={isLoading}>
      <ContentHeader align="flex-start">
        <ContentHeader.Title goBackLink={GUIDE_CONTACTS_ROUTE} description={description}>
          {hotContacts?.[0]?.employer.name}
        </ContentHeader.Title>
      </ContentHeader>
      <div className={s.container}>
        <div>{hotContacts?.map(renderComponents)}</div>
        <div className={s.buttonWrapper}>
          <Button type="text" leftIcon="plus" className={s.button} onClick={() => setModal('edit')}>
            {t('guide_hot_contacts_modal_title')}
          </Button>
        </div>
        <Button
          type="secondary"
          leftIcon="arrowLeft"
          className={s.backButton}
          onClick={() => navigate(-1)}>
          {t('common_go_back')}
        </Button>
        <GuideContactsEditModalAdd
          open={modal === 'edit'}
          onClose={() => setModal('')}
          onFinish={addHandler}
          isLoading={isCreateHotContactLoading}
        />
        <RemoveConfirm
          type="success"
          isOpen={modal === 'ok'}
          onCancel={() => setModal('')}
          title={t('common_successfully_removed')}
          onConfirm={() => setModal('')}
          buttonText={t('common_ok')}
          icon="ok"
        />
      </div>
    </WithLoader>
  );
};

const sortContactsByOrder = (a: { num: number }, b: { num: number }) => a.num - b.num;
const selectFromResult = <R,>({
  data = [],
  ...other
}: R & { data?: GuideHotContactByIdType[] }) => {
  return {
    data: [...data]?.sort(sortContactsByOrder),
    ...other,
  };
};

export default GuideContactsEdit;
