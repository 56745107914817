import { FC, useState } from 'react';

import { t } from 'tools/i18n';

import { useDeleteArticlesMutation } from 'services/article/articleApiService';

import { Icon, Typography, handleRequest } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';

import { ArticleType } from 'types/article.types';

import s from './newsActions.module.scss';

interface NewsActionsProps {
  selectedNewsCodes: Array<ArticleType['code']>;
  onSelectedNewsCodesChange: (value: Array<ArticleType['code']>) => void;
}

const NewsActions: FC<NewsActionsProps> = (props) => {
  const { selectedNewsCodes, onSelectedNewsCodesChange } = props;

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);

  const [deleteArticles] = useDeleteArticlesMutation();
  const handleClear = () => onSelectedNewsCodesChange([]);

  const handleDeleteSelectedNews = () => {
    deleteArticles({ ids: selectedNewsCodes }).then(
      handleRequest({
        onSuccess: handleClear,
      }),
    );
  };

  const handleModalOpenClose = () => setIsRemoveModalOpen((prevState) => !prevState);

  return (
    <>
      <RemoveConfirm
        type="danger"
        isOpen={isRemoveModalOpen}
        onCancel={handleModalOpenClose}
        title={t('common_accept_remove')}
        subtitle={t('news_selected_will_be_removed')}
        onConfirm={handleDeleteSelectedNews}
        buttonText={t('common_remove')}
      />
      <div className={s.container}>
        <button className={s.button} onClick={handleClear}>
          <Icon name="delete" size={12} color="var(--color-primary-6)" />
          <Typography.Text size="14" weight="500" type="primary">
            {t('news_deselect')}
          </Typography.Text>
        </button>
        <button className={s.button} onClick={handleModalOpenClose}>
          <Icon name="binFilled" size={16} color="var(--color-error)" />
          <Typography.Text size="14" weight="500" type="danger">
            {t('common_remove')}
          </Typography.Text>
        </button>
      </div>
    </>
  );
};

export default NewsActions;
