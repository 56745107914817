import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { NEWS_ADD_ROUTE } from 'routes/news/list';
import { t } from 'tools/i18n';

import { Button, Tabs } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './News.module.scss';
import { TABS } from './news.utils';

const News = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isSetAvailable = useRef<boolean>(true);

  const [activeTab, setActiveTab] = useState<string>('');

  const handleTabChange = (e: string) => {
    setActiveTab(e);
    navigate(location.pathname, { state: {} });
  };
  const handleAddNews = () => {
    navigate(NEWS_ADD_ROUTE);
  };

  useEffect(() => {
    if (isSetAvailable.current) {
      if (location?.state?.activeTab) {
        setActiveTab(location?.state?.activeTab);
      } else {
        setActiveTab(TABS[0].key);
      }
      isSetAvailable.current = false;
    }
  }, [activeTab, location?.state]);

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('news_title')}</ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="plus" onClick={handleAddNews}>
            {t('news_add')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <Tabs
        items={TABS}
        type="card"
        onTabClick={handleTabChange}
        activeKey={activeTab}
        className={s.tabs}
      />
    </WithOverflow>
  );
};

export default News;
