import { SUGGESTIONS_COMMISSION_ROUTE } from 'routes/suggestions/list';

import ContentHeader from 'containers/content-header';
import LastChange from 'containers/last-change';

interface Props {
  name?: string;
  updatedAt?: string;
  editorId?: string;
}

const Header = (props: Props) => {
  const { name, updatedAt, editorId } = props;

  return (
    <ContentHeader>
      <ContentHeader.Title
        goBackLink={SUGGESTIONS_COMMISSION_ROUTE}
        description={<LastChange date={updatedAt} editorId={editorId} />}>
        {name}
      </ContentHeader.Title>
    </ContentHeader>
  );
};

export default Header;
