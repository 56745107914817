import { useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { SETTINGS_TELEGRAM_CHANNELS_ROUTE } from 'routes/settings/list';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import {
  useDeleteTelegramChannelMutation,
  useEditTelegramChannelMutation,
  useLazyGetTelegramChannelByIdQuery,
} from 'services/telegram-channel/telegramChannelApiService';
import { EditTelegramChannelPropsType } from 'services/telegram-channel/telegramChannelApiService.types';

import { Button, Typography } from 'gazprom-ui-lib';

import LastChange from 'containers/last-change';
import TelegramChannelForm from 'containers/telegram-channel-form/telegramChannelForm';
import {
  TELEGRAM_CHANNEL_FORM_ENUM,
  TELEGRAM_CHANNEL_FORM_NAME,
  TelegramChannelFormResponse,
} from 'containers/telegram-channel-form/telegramChannelForm.utils';

import { SETTINGS_TELEGRAM_CHANNELS_TAB } from '../../settingsTelegramChannels.utils';
import DeleteModal from '../containers/delete-modal';
import s from './SettingsTelegramDetails.module.scss';

const SettingsTelegramDetails = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const [getTelegramChannel, { data: telegramChannel, isFetching: telegramChannelIsFetching }] =
    useLazyGetTelegramChannelByIdQuery();
  const [editTelegramChannel, { isLoading: updateTelegramChannelIsLoading }] =
    useEditTelegramChannelMutation();
  const [deleteTelegramChannel, { isLoading: deleteTelegramChannelIsLoading }] =
    useDeleteTelegramChannelMutation();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteModalOpenClose = () => setDeleteModalOpen(!deleteModalOpen);

  const editor = telegramChannel?.editor;
  const creator = telegramChannel?.creator;

  const lastEditorId = editor?.id ?? creator?.id;
  const lastEditorName = `${editor?.name ?? creator?.name} ${editor?.surname ?? creator?.surname}`;

  const handleGoBack = useCallback(
    () =>
      navigate(SETTINGS_TELEGRAM_CHANNELS_ROUTE, {
        state: {
          activeTab: SETTINGS_TELEGRAM_CHANNELS_TAB,
        },
      }),
    [navigate],
  );

  useEffect(() => {
    if (params.id) {
      getTelegramChannel(params.id);
    }
  }, [getTelegramChannel, params.id]);

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('common_saved_successfully'),
      },
    },
    onErrorModalConfig: {
      icon: {
        name: 'error',
        type: 'warning',
      },
      title: {
        children: t('common_something_went_wrong'),
      },
      description: {
        children: t('telegram_edit_error_description'),
      },
    },
  });

  const onSubmit = useCallback(
    (form: TelegramChannelFormResponse) => {
      const requestBody: EditTelegramChannelPropsType = {
        id: params.id!,
        telegramTitle: form[TELEGRAM_CHANNEL_FORM_ENUM.TELEGRAM_TITLE],
        link: form[TELEGRAM_CHANNEL_FORM_ENUM.LINK],
        categories: form[TELEGRAM_CHANNEL_FORM_ENUM.CATEGORIES],
        active: form[TELEGRAM_CHANNEL_FORM_ENUM.ACTIVE],
      };

      editTelegramChannel(requestBody).then(handleUpdate);
    },
    [editTelegramChannel, handleUpdate, params.id],
  );

  const handleDelete = () => {
    if (params.id) {
      deleteTelegramChannel([params.id!]).then(handleGoBack);
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.actionsHeader}>
        <div>
          <Button htmlType="button" type="link" leftIcon="arrowLeft" onClick={handleGoBack}>
            {t('common_go_back')}
          </Button>
          <Typography.Title level={3}>{telegramChannel?.telegramTitle}</Typography.Title>
          <LastChange
            date={telegramChannel?.updatedAt}
            editorId={lastEditorId}
            editorName={lastEditorName}
          />
        </div>
        <Button
          leftIcon="ok"
          htmlType="submit"
          loading={telegramChannelIsFetching}
          form={TELEGRAM_CHANNEL_FORM_NAME}>
          {t('common_save')}
        </Button>
      </div>
      <div className={s.content}>
        <TelegramChannelForm onSubmit={onSubmit} telegramChannel={telegramChannel} />

        <div className={s.actions}>
          <Button
            leftIcon="ok"
            loading={updateTelegramChannelIsLoading}
            fullWidth
            type="primary"
            htmlType="submit"
            form={TELEGRAM_CHANNEL_FORM_NAME}>
            {t('common_save')}
          </Button>
          <Button
            loading={deleteTelegramChannelIsLoading}
            fullWidth
            type="primary"
            danger
            leftIcon="binFilled"
            onClick={handleDeleteModalOpenClose}>
            {t('common_delete')}
          </Button>
          <Button fullWidth type="secondary" htmlType="button" onClick={handleGoBack}>
            {t('common_cancel')}
          </Button>
        </div>
      </div>
      <DeleteModal
        open={deleteModalOpen}
        handleOpenClose={handleDeleteModalOpenClose}
        loading={deleteTelegramChannelIsLoading}
        handleClick={handleDelete}
      />
    </div>
  );
};

export default SettingsTelegramDetails;
