import { useMemo } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { Button, TableProps, Tooltip, Typography } from 'gazprom-ui-lib';

import TableIsPublish from 'components/table/table-is-publish';
import TableStatus from 'components/table/table-status';
import TableText from 'components/table/table-text';

import { ReviewTaskAssignmentType } from 'types/review-task.types';

import s from './DocumentsKedoReviewTaskAssignments.module.scss';
import {
  createSignDownloadUrl,
  getKedoReviewTaskAssigneeStatus,
} from './documentsKedoReviewAssignments.utils';

interface useColumnsProps {
  needEmployeeSign?: boolean;
}

const useColumns = (props: useColumnsProps): TableProps<ReviewTaskAssignmentType>['columns'] => {
  const { needEmployeeSign } = props;

  const handleDownload = (fileKey: string, fileName: string) => {
    downloadHandler({
      url: createSignDownloadUrl(fileKey),
      fileName: fileName.replace('.pdf', '.zip'),
    });
  };

  return useMemo(
    () => [
      {
        title: t('common_employee'),
        dataIndex: 'executor',
        key: 'person',
        width: 350,
        render: (executor: ReviewTaskAssignmentType['executor']) => {
          if (!executor) {
            return <></>;
          }

          const { lastName, firstName, patronymic } = executor?.person || {};

          return (
            <>
              <TableText size="12" type="secondary">
                {executor?.employer?.name}
              </TableText>
              <TableText size="12" weight="500">
                {`${lastName} ${firstName} ${patronymic}`}
              </TableText>
            </>
          );
        },
      },
      {
        title: t('common_staffName'),
        dataIndex: 'executor',
        key: 'position',
        width: 350,
        render: (executor: ReviewTaskAssignmentType['executor']) => {
          return (
            <TableText size="12" weight="500" type="secondary">
              {executor?.position?.name}
            </TableText>
          );
        },
      },
      {
        title: t('common_status'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        width: 200,
        render: (assigneeStatus: ReviewTaskAssignmentType['status']) => {
          const { status, label } = getKedoReviewTaskAssigneeStatus(assigneeStatus);

          return (
            <TableStatus hideIcon status={status}>
              {label}
            </TableStatus>
          );
        },
      },
      {
        title: t('common_signature'),
        dataIndex: 'files',
        key: 'ssoRegistered',
        width: 100,
        align: 'center',
        hidden: !needEmployeeSign,
        render: (files: ReviewTaskAssignmentType['files']) => {
          const kedoReviewFile = !!files?.length && files[0];
          const isSigned = kedoReviewFile && !!kedoReviewFile?.signs?.length;

          return <TableIsPublish isPublish={isSigned} />;
        },
      },
      {
        title: t('kedo_review_tasks_review_date'),
        dataIndex: 'finishDate',
        key: 'finishDate',
        width: 160,
        align: 'center',
        sorter: true,
        render: (finishDate: ReviewTaskAssignmentType['finishDate']) => {
          if (!finishDate) {
            return <></>;
          }

          return (
            <>
              <TableText weight="500">{dayjs(finishDate).format('DD.MM.YYYY')}</TableText>
              <TableText size="10" type="secondary">
                {dayjs(finishDate).format('HH:mm')}
              </TableText>
            </>
          );
        },
      },
      {
        title: '',
        key: 'download',
        hidden: !needEmployeeSign,
        width: 100,
        dataIndex: 'files',
        render: (files: ReviewTaskAssignmentType['files']) => {
          const kedoReviewFile = !!files?.length && files[0];
          const isSigned = kedoReviewFile && !!kedoReviewFile?.signs?.length;

          if (!isSigned) {
            return <></>;
          }

          const { fileName } = kedoReviewFile ?? {};
          const { key: fileKey } = kedoReviewFile?.signs[0] ?? {};

          const tooltipTitle = (
            <Typography.Text className={s.tooltipTitle} size="12">
              {t('kedo_review_tasks_docs_download')}
            </Typography.Text>
          );

          return (
            <Tooltip overlayClassName={s.tooltip} title={tooltipTitle} placement="bottom">
              <Button
                type="link"
                onlyIcon
                leftIcon="download"
                onClick={() => handleDownload(fileKey, fileName)}
              />
            </Tooltip>
          );
        },
      },
    ],
    [needEmployeeSign],
  );
};

export default useColumns;
