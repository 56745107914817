import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface ModalValidationProps {
  fields: string;
  handleClose: () => void;
}

const ModalValidation: FC<ModalValidationProps> = (props) => {
  const { handleClose, fields } = props;

  return (
    <ModalDefault open={!!fields} onCancel={handleClose} width={320} closable>
      <ModalDefault.Icon name="error" type="danger" />
      <ModalDefault.Title>{t('events_unable_to_save')}</ModalDefault.Title>
      <ModalDefault.Description>{t('events_fix_next_errors', { fields })}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button fullWidth type="secondary" onClick={handleClose}>
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ModalValidation;
