import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Tabs } from 'gazprom-ui-lib';

import './SettingsTelegramChannels.module.scss';
import Header from './containers/header';
import { TABS } from './settingsTelegramChannels.utils';

const SettingsTelegramChannels = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isSetAvailable = useRef<boolean>(true);

  const [activeTab, setActiveTab] = useState<string>();

  const handleTabChange = (e: string) => {
    setActiveTab(e);
    navigate(location.pathname, { state: {} });
  };

  useEffect(() => {
    if (location?.state?.authRequired && location?.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }

    if (isSetAvailable.current) {
      if (location?.state?.activeTab) {
        setActiveTab(location?.state?.activeTab);
      } else {
        setActiveTab(TABS[0].key);
      }
      isSetAvailable.current = false;
    }
  }, [activeTab, location?.state]);

  return (
    <>
      <Header />
      <Tabs items={TABS} type="card" onTabClick={handleTabChange} activeKey={activeTab} />
    </>
  );
};

export default SettingsTelegramChannels;
