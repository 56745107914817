import { FC } from 'react';

import { Icon } from 'gazprom-ui-lib';

import { NOTIFICATION_ENUM } from 'types/notification.types';

import s from './NotificationsSendType.module.scss';

interface NotificationsSendTypeProps {
  type: string;
}

const NotificationsSendType: FC<NotificationsSendTypeProps> = (props) => {
  const { type } = props;

  switch (type) {
    case NOTIFICATION_ENUM.PUSH: {
      return <Icon name="notification" className={s.icon} />;
    }
    case NOTIFICATION_ENUM.EMAIL_BATCH:
    case NOTIFICATION_ENUM.EMAIL: {
      return <Icon name="markEmailUnread" className={s.icon} />;
    }
    case NOTIFICATION_ENUM.APP: {
      return <Icon name="markUnreadChatAlt" className={s.icon} />;
    }
    default:
      return <></>;
  }
};

export default NotificationsSendType;
