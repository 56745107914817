import { Dayjs } from 'dayjs';

export const NOTIFICATION_NEWSLETTER_FORM_NAME = 'NOTIFICATION_NEWSLETTER_FORM_NAME';

export enum NOTIFICATION_NEWSLETTER_FORM_ENUM {
  NAME = 'NAME',
  TEMPLATE_NAME = 'TEMPLATE_NAME',
  TITLE = 'TITLE',
  TEXT = 'TEXT',
  APP_NOTIFICATION = 'APP_NOTIFICATION',
  APP_NOTIFICATION_ADDED = 'APP_NOTIFICATION_ADDED',
  NOTIFICATION_TEMPLATE = 'NOTIFICATION_TEMPLATE',
  CALLBACK_ON = 'CALLBACK_ON',
  FEEDBACK_ON = 'FEEDBACK_ON',
  FEEDBACK_IS_REQUIRED = 'FEEDBACK_IS_REQUIRED',
  EMAIL = 'EMAIL',
  RECEIVERS = 'RECEIVERS',
  DELAY = 'DELAY',
  PUBLISH_FROM_DATE = 'PUBLISH_FROM_DATE',
  PUBLISH_FROM_TIME = 'PUBLISH_FROM_TIME',
}

export interface NotificationNewsletterFormResponse {
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.NAME]: string;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.TEMPLATE_NAME]: string;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.TITLE]: string;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.TEXT]: string;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION]?: boolean;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION_ADDED]?: boolean;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.NOTIFICATION_TEMPLATE]: string;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.CALLBACK_ON]?: boolean;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_ON]?: boolean;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_IS_REQUIRED]?: string;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.EMAIL]?: boolean;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.RECEIVERS]: string[];
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.DELAY]?: boolean;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.PUBLISH_FROM_DATE]?: Dayjs;
  [NOTIFICATION_NEWSLETTER_FORM_ENUM.PUBLISH_FROM_TIME]?: Dayjs;
}
