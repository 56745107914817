import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import FileDownload from 'pages/documents/containers/file-download';

import { SuggestionFileType } from 'types/suggestion.types';

import s from './Files.module.scss';
import { getFileUrl } from './files.utils';

interface Props {
  files?: SuggestionFileType[];
}

const Files = (props: Props) => {
  const { files } = props;

  if (!files) {
    return <></>;
  }

  const renderFile = (file: SuggestionFileType) => {
    const { fileKey, fileName } = file;

    const fileUrl = getFileUrl(fileKey);

    return (
      <Flex key={fileKey} justify="space-between" align="center" gap="12" className={s.download}>
        <Flex align="center" gap="12">
          <Icon name="image" className={s.icon} />
          <Flex vertical gap="4" className={s.fileDescription}>
            <Typography.Text weight="500" size="14" type="primary">
              {fileName}
            </Typography.Text>
          </Flex>
        </Flex>
        <FileDownload fileName={fileName} fileDownloadUrl={fileUrl} />
      </Flex>
    );
  };

  return (
    <Flex vertical className={s.wrapper} gap="8">
      {[...files].map(renderFile)}
    </Flex>
  );
};

export default Files;
