import { FC } from 'react';

import cn from 'classnames';

import { Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import s from './FileContent.module.scss';

export interface FileContentProps {
  icon: TIconsLiteral;
  title: string;
  description: string;
  isError?: boolean;
}

const FileContent: FC<FileContentProps> = (props) => {
  const { icon, title, description, isError } = props;

  return (
    <div
      className={cn(s.content, {
        [s.error]: isError,
      })}>
      <Icon name={icon} />
      <div className={s.textContainer}>
        <Typography.Text size="14" weight="500" type={isError ? 'danger' : 'primary'}>
          {title}
        </Typography.Text>
        <Typography.Text weight="500" size="12" type="secondary">
          {description}
        </Typography.Text>
      </div>
    </div>
  );
};

export default FileContent;
