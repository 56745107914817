import { useCallback, useMemo } from 'react';

import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { Button, TableProps, Typography } from 'gazprom-ui-lib';

import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { RecruitDocumentType } from 'types/recruit.types';

const useColumnsHook = (): TableProps<RecruitDocumentType>['columns'] => {
  const handleDownload = useCallback((row: RecruitDocumentType) => {
    let url = `${process.env.https://admin-gateway.dev.tockl.ru/api/v1/}recruit/download/document?recruitDocumentId=${row.id}`;

    downloadHandler({
      url,
      fileName: '',
      headersFileNameKey: 'filename',
    });
  }, []);

  return useMemo(
    () => [
      {
        title: t('common_document_name'),
        dataIndex: '',
        render: (_, row: RecruitDocumentType) => (
          <Typography.Text weight="500" size="14">
            {row.document.name}
          </Typography.Text>
        ),
      },
      {
        title: t('documents_recruit_org_sign_status'),
        dataIndex: '',
        render: (_, row: RecruitDocumentType) => (
          <TableStatus status={row.orgSignStatus.state.toUpperCase() as STATUSES}>
            {row.orgSignStatus.title}
          </TableStatus>
        ),
      },
      {
        title: t('documents_recruit_employee_sign_status'),
        dataIndex: '',
        render: (_, row: RecruitDocumentType) => (
          <TableStatus status={row.employeeSignStatus.state.toUpperCase() as STATUSES}>
            {row.employeeSignStatus.title}
          </TableStatus>
        ),
      },
      {
        title: '',
        render: (_, row: RecruitDocumentType) =>
          row.document.files[0] ? (
            <Button leftIcon="download" onlyIcon type="link" onClick={() => handleDownload(row)} />
          ) : null,
      },
    ],
    [handleDownload],
  );
};

export default useColumnsHook;
