import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetSocialRequests1cQuery } from 'services/1c/cApiService';
import { GetSocialRequests1cPropsType } from 'services/1c/cApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { DEFAULT_FORM_STATE, FormResponseType } from './socialProgramRequests.utils';

interface UseRequestProps {
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: UseRequestProps) => {
  const { form } = props;

  const [getRequests, { isFetching, data, isLoading, isUninitialized }] =
    useLazyGetSocialRequests1cQuery();

  const formValues: FormResponseType | undefined = Form.useWatch([], form);

  const { debouncedValue: debouncedFormValues, isDebouncing } = useDebounce(formValues);

  useEffect(() => {
    if (debouncedFormValues?.page) {
      const { page, size, search, filters, property, order } = debouncedFormValues;
      const { categories, status } = filters ?? {};

      const preparedBody: GetSocialRequests1cPropsType = {
        page,
        size,
        search,
      };

      if (search) {
        preparedBody.search = search;
      }

      if (categories) {
        preparedBody.categoryIds = categories;
      }

      if (status) {
        preparedBody.statuses = status;
      }

      if (property && order) {
        preparedBody.sort = [{ order, property }];
      } else {
        preparedBody.sort = [
          { order: DEFAULT_FORM_STATE.order, property: DEFAULT_FORM_STATE.property },
        ];
      }

      getRequests(preparedBody);
    }
  }, [debouncedFormValues, getRequests]);

  return {
    isFetching: isDebouncing || isFetching,
    isLoading: isUninitialized || isLoading,
    data,
  };
};

export default useRequest;
