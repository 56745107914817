import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import { GetDivisionsPropsType, GetDivisionsResponseType } from './divisionApiService.types';

import { createDivisionUrl } from './utils';

const divisionApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getDivisions: builder.query<GetDivisionsResponseType, GetDivisionsPropsType>({
      query: (params) => ({
        url: createDivisionUrl(`${prepareQueryParams(params)}`),
      }),
    }),
  }),
});

export const { useLazyGetDivisionsQuery } = divisionApiService;
