import { useMatch } from 'react-router-dom';
import {
  SUGGESTIONS_DECISIONS_DETAILS_ROUTE,
  SUGGESTIONS_DECISIONS_ROUTE,
  SUGGESTIONS_LIST_ROUTE,
} from 'routes/suggestions/list';
import { t } from 'tools/i18n';

import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';
import ContentHeader from 'containers/content-header';
import CreationInfo from 'containers/creation-info';

import { SuggestionType } from 'types/suggestion.types';

interface Props {
  createdDate?: string;
  updatedDate?: string;
  status?: SuggestionType['curStatus'];
}

const Header = (props: Props) => {
  const { createdDate, status } = props;

  const isSuggestionDecision = !!useMatch(SUGGESTIONS_DECISIONS_DETAILS_ROUTE);

  const backLink = isSuggestionDecision ? SUGGESTIONS_DECISIONS_ROUTE : SUGGESTIONS_LIST_ROUTE;

  return (
    <ContentHeader align="flex-start">
      <ContentHeader.Title
        goBackLink={backLink}
        description={<CreationInfo createdDate={createdDate} />}>
        {t('suggestion_for_improvement')}
      </ContentHeader.Title>
      {!!status && (
        <ContentHeader.Actions>
          <TableStatus status={status.state.toUpperCase() as STATUSES}>{status.name}</TableStatus>
        </ContentHeader.Actions>
      )}
    </ContentHeader>
  );
};

export default Header;
