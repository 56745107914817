import { FC } from 'react';

import { useLocation } from 'react-router-dom';
import { FAQ_ROUTE } from 'routes/faq/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

interface HeaderProps {
  isLoading: boolean;
}

const Header: FC<HeaderProps> = (props) => {
  const { isLoading } = props;
  const location = useLocation();

  return (
    <ContentHeader align="flex-start" showBorder>
      <ContentHeader.Title goBackLink={FAQ_ROUTE} goBackState={{ ...location.state }}>
        {t('common_add_question')}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Button loading={isLoading} leftIcon="ok">
          {t('common_save')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
