import { FC, useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Checkbox, Form, FormInstance, Input, Select } from 'gazprom-ui-lib';

import File from 'containers/kedo-review-task-form/file';
import Recipient from 'containers/kedo-review-task-form/recipient';

import { KedoReviewTaskType } from 'types/review-task.types';

import s from './KedoReviewTaskFrom.module.scss';
import {
  KEDO_REVIEW_TASK_FORM_ENUM,
  KEDO_REVIEW_TASK_FORM_NAME,
  KedoReviewTaskFormResponse,
} from './kedoReviewTaskForm.utils';

interface KedoReviewTaskFormProps {
  onSubmit: (form: KedoReviewTaskFormResponse) => void;
  kedoReviewTask?: KedoReviewTaskType;
  kedoReviewTaskId?: string;
  disabled?: boolean;
  form?: FormInstance;
}

const KedoReviewTaskForm: FC<KedoReviewTaskFormProps> = (props) => {
  const { onSubmit, kedoReviewTask, kedoReviewTaskId, disabled, form: formFromProps } = props;

  const [form] = Form.useForm();

  const formToUse = formFromProps ?? form;

  useEffect(() => {
    if (kedoReviewTask) {
      formToUse.setFieldsValue({
        [KEDO_REVIEW_TASK_FORM_ENUM.NAME]: kedoReviewTask.name,
        [KEDO_REVIEW_TASK_FORM_ENUM.DOC_NAME]: kedoReviewTask.docName,
        [KEDO_REVIEW_TASK_FORM_ENUM.INSTANCE_1C]: kedoReviewTask.instance1c?.id,
        [KEDO_REVIEW_TASK_FORM_ENUM.SIGN_REQUIRED]: kedoReviewTask.needEmployeeSign,
        [KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEE_GROUPS]: kedoReviewTask.employeeGroupIds,
      });

      if (kedoReviewTask?.files?.length) {
        formToUse.setFieldsValue({
          [KEDO_REVIEW_TASK_FORM_ENUM.FILE]: {
            link: kedoReviewTask?.files[0]?.fileKey,
            name: kedoReviewTask?.files[0]?.fileName,
          },
        });
      }
    }
  }, [formToUse, kedoReviewTask]);

  const selectOptions = [
    {
      label: kedoReviewTask?.instance1c?.name,
      value: kedoReviewTask?.instance1c?.id,
    },
  ];

  return (
    <Form
      disabled={disabled}
      form={formToUse}
      name={KEDO_REVIEW_TASK_FORM_NAME}
      className={s.wrapper}
      onFinish={onSubmit}>
      <Form.Item
        label={t('kedo_review_tasks_name')}
        className={s.formItemWithLabel}
        normalize={normalizeTextField}
        name={KEDO_REVIEW_TASK_FORM_ENUM.NAME}
        rules={[formRules.required, { max: 80 }]}
        extra={t('kedo_review_tasks_name_subtitle')}>
        <Input disabled={disabled} placeholder={t('common_enter_text')} maxLength={80} showCount />
      </Form.Item>

      <Form.Item
        label={t('kedo_review_tasks_document_name')}
        className={s.formItemWithLabel}
        normalize={normalizeTextField}
        name={KEDO_REVIEW_TASK_FORM_ENUM.DOC_NAME}
        rules={[formRules.required, { max: 80 }]}
        extra={t('kedo_review_tasks_document_name_subtitle')}>
        <Input disabled={disabled} placeholder={t('common_enter_text')} maxLength={80} showCount />
      </Form.Item>

      <File
        kedoReviewTaskId={kedoReviewTaskId ?? kedoReviewTask?.id}
        showDelete={!disabled}
        showDownload
      />

      {kedoReviewTask?.instance1c ? (
        <Form.Item
          label={t('common_organization')}
          className={s.organization}
          name={KEDO_REVIEW_TASK_FORM_ENUM.INSTANCE_1C}>
          <Select disabled options={selectOptions} />
        </Form.Item>
      ) : (
        <Recipient />
      )}

      <Form.Item name={KEDO_REVIEW_TASK_FORM_ENUM.SIGN_REQUIRED} valuePropName="checked">
        <Checkbox>{t('kedo_review_tasks_sign_required')}</Checkbox>
      </Form.Item>
    </Form>
  );
};

export default KedoReviewTaskForm;
