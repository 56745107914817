import { useNavigate } from 'react-router-dom';
import { NOTIFICATION_MAILING_MANAGEMENT_ROUTE } from 'routes/notification/list';
import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { Button, Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import LastChange from 'containers/last-change';
import NotificationTemplateForm from 'containers/notification-template-form';
import {
  NOTIFICATION_TEMPLATE_FORM_ENUM,
  NOTIFICATION_TEMPLATE_FORM_NAME,
  NotificationTemplateFormResponse,
} from 'containers/notification-template-form/notificationTemplateForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import { NOTIFICATION_TEMPLATES_TAB } from 'pages/notification/notification.utils';

import s from './NotificationTemplateEdit.module.scss';
import useGetTemplate from './useGetTemplate.hook';
import useSubmit from './useSubmit.hook';
import useTemplateNameAvailable from './useTemplateNameAvailable.hook';

const NotificationTemplateEdit = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<NotificationTemplateFormResponse>();

  const handleGoBack = () => {
    navigate(NOTIFICATION_MAILING_MANAGEMENT_ROUTE, {
      state: {
        activeTab: NOTIFICATION_TEMPLATES_TAB,
      },
    });
  };
  const handleReset = () => {
    handleGoBack();
  };

  const { template, isFetching } = useGetTemplate();

  const templateName = Form.useWatch(NOTIFICATION_TEMPLATE_FORM_ENUM.NAME, form);
  const { debouncedValue: debouncedTemplateName } = useDebounce(templateName);

  const { isTemplateNameAvailable, isLoading: isTemplateNameAvailableLoading } =
    useTemplateNameAvailable({
      templateName: debouncedTemplateName,
      currentTemplate: template,
    });

  const { handleSubmit, updateTemplateIsFetching } = useSubmit({ currentTemplate: template });

  const currentTemplateEditor = template?.editor;

  const { middleName, name, surname } = currentTemplateEditor ?? {};

  const description = (
    <LastChange
      date={template?.updatedAt}
      editorId={currentTemplateEditor?.personId}
      editorName={`${name} ${middleName} ${surname}`}
    />
  );

  return (
    <>
      <ContentHeader align="flex-start">
        <ContentHeader.Title
          description={description}
          goBackLink={NOTIFICATION_MAILING_MANAGEMENT_ROUTE}
          goBackState={{
            activeTab: NOTIFICATION_TEMPLATES_TAB,
          }}>
          {template?.name}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button
            loading={isTemplateNameAvailableLoading}
            leftIcon="ok"
            htmlType="submit"
            form={NOTIFICATION_TEMPLATE_FORM_NAME}>
            {t('common_save')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={isFetching}>
        <div className={s.formWrapper}>
          <NotificationTemplateForm
            form={form}
            isTemplateNameAvailable={isTemplateNameAvailable}
            currentTemplate={template}
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            type="edit"
          />
        </div>
        <div className={s.actionsFooter}>
          <Button
            loading={updateTemplateIsFetching || isTemplateNameAvailableLoading}
            leftIcon="ok"
            fullWidth
            type="primary"
            htmlType="submit"
            form={NOTIFICATION_TEMPLATE_FORM_NAME}>
            {t('common_save')}
          </Button>
          <Button
            type="secondary"
            fullWidth
            htmlType="reset"
            form={NOTIFICATION_TEMPLATE_FORM_NAME}>
            {t('common_close')}
          </Button>
        </div>
      </WithLoader>
    </>
  );
};

export default NotificationTemplateEdit;
