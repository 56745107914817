import { Dispatch, FC, SetStateAction, useState } from 'react';

import { t } from 'tools/i18n';

import { ImportChannelPropsType } from 'services/import/importApiService.types';
import { useDeleteTelegramChannelMutation } from 'services/telegram-channel/telegramChannelApiService';

import { Flex, Icon, Typography, handleRequest } from 'gazprom-ui-lib';

import { TelegramChannelType } from 'types/telegramChannel.types';

import DeleteModal from '../delete-modal';
import s from './TableActions.module.scss';

interface NewsTelegramActionsProps {
  channels?: TelegramChannelType[];
  selectedChannels: Array<TelegramChannelType['id']>;
  setSelectedChannels: Dispatch<SetStateAction<Array<TelegramChannelType['id']>>>;
  handleStartImport: (links: ImportChannelPropsType) => Promise<void>;
}

const TableActions: FC<NewsTelegramActionsProps> = (props) => {
  const { channels, setSelectedChannels, selectedChannels, handleStartImport } = props;

  const [deleteChannels, { isLoading: deleteChannelsIsLoading }] =
    useDeleteTelegramChannelMutation();

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
  const handleRemoveModalOpenClose = () => setIsRemoveModalOpen(!isRemoveModalOpen);

  const handleClear = () => setSelectedChannels([]);

  const onRemoveSuccess = () => {
    setSelectedChannels([]);
    setIsRemoveModalOpen((prevState) => !prevState);
  };

  const handleDeleteSelectedChannels = () => {
    deleteChannels(selectedChannels).then(
      handleRequest({
        onSuccess: onRemoveSuccess,
      }),
    );
  };

  const handleImportClick = () => {
    if (channels?.length) {
      const channelsLinks = channels.reduce((acc: TelegramChannelType['link'][], channel) => {
        if (selectedChannels.includes(channel.id)) {
          acc.push(channel.link);
        }
        return acc;
      }, []);

      handleStartImport(channelsLinks);
    }
  };

  const isMultiple = selectedChannels.length >= 2;

  return (
    <>
      <Flex gap="32" align="center" className={s.container}>
        <button className={s.button} onClick={handleClear}>
          <Icon name="delete" size={12} color="var(--color-primary-6)" />
          <Typography.Text size="14" weight="500" type="primary">
            {t('news_deselect')}
          </Typography.Text>
        </button>
        <button className={s.button} onClick={handleImportClick}>
          <Icon name="publish" size={16} color="var(--color-success)" />
          <Typography.Text size="14" weight="500" type="success">
            {t('common_start_import')}
          </Typography.Text>
        </button>
        <button className={s.button} onClick={handleRemoveModalOpenClose}>
          <Icon name="binFilled" size={16} color="var(--color-error)" />
          <Typography.Text size="14" weight="500" type="danger">
            {t('common_remove')}
          </Typography.Text>
        </button>
        <Typography.Text type="secondary">
          {t('common_table_selected', {
            count: selectedChannels.length,
          })}
        </Typography.Text>
      </Flex>
      <DeleteModal
        open={isRemoveModalOpen}
        handleOpenClose={handleRemoveModalOpenClose}
        loading={deleteChannelsIsLoading}
        handleClick={handleDeleteSelectedChannels}
        multiple={isMultiple}
      />
    </>
  );
};

export default TableActions;
