import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { handleTableChange } from 'utils/table.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetEmployeeGroupsMembersByGroupsIdsQuery } from 'services/employee-groups-new/employeeGroupsNewApiService';

import { InputProps, Table } from 'gazprom-ui-lib';

import { FiltersResponseType } from 'containers/filters/filters.utils';
import WithLoader from 'containers/wrappers/with-loader';

import s from './DocumentsKedoReviewTaskEmployees.module.scss';
import Filters from './containers/filters';
import useColumns from './useColumnsHook';

export type RequestBodyType = {
  page: number;
  size: number;
  groupsIds?: string[];
  text?: string;
};

const DEFAULT_REQUEST_BODY: RequestBodyType = {
  page: 1,
  size: 10,
  groupsIds: [],
  text: '',
};

interface DocumentsKedoReviewTaskEmployeesProps {
  groupIds?: string[];
  setAssignmentsCount: Dispatch<SetStateAction<number>>;
}

const DocumentsKedoReviewTaskEmployees: FC<DocumentsKedoReviewTaskEmployeesProps> = (props) => {
  const { groupIds, setAssignmentsCount } = props;

  const columns = useColumns();

  const [requestBody, setRequestBody] = useState<RequestBodyType>(DEFAULT_REQUEST_BODY);
  const [filters, setFilters] = useState<FiltersResponseType>({});

  const onFiltersSubmit = (values: FiltersResponseType) => {
    setFilters(values);
    setRequestBody((prevState) => ({ ...prevState, page: 1 }));
  };

  const { debouncedValue } = useDebounce(requestBody);

  const [
    getKedoReviewTaskAssignmentEmployee,
    { data: kedoReviewTaskEmployees, isLoading, isFetching },
  ] = useLazyGetEmployeeGroupsMembersByGroupsIdsQuery();

  useEffect(() => {
    if (groupIds) {
      getKedoReviewTaskAssignmentEmployee({ ...debouncedValue, ...filters, groupIds });
    }
  }, [debouncedValue, getKedoReviewTaskAssignmentEmployee, groupIds, filters]);

  useEffect(() => {
    if (kedoReviewTaskEmployees) {
      setAssignmentsCount(kedoReviewTaskEmployees.totalCount);
    }
  }, [kedoReviewTaskEmployees, setAssignmentsCount]);

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({
      ...prevState,
      text: e.target.value,
      page: 1,
    }));
  };

  return (
    <WithLoader isLoading={isLoading}>
      <Filters
        filters={filters}
        setFilters={onFiltersSubmit}
        search={requestBody.text}
        handleSearchChange={handleSearchChange}
      />
      <Table
        className={s.table}
        loading={isFetching}
        columns={columns}
        dataSource={kedoReviewTaskEmployees?.employees}
        onChange={handleTableChange(setRequestBody)}
        pagination={{
          defaultCurrent: requestBody.page,
          defaultPageSize: requestBody.size,
          total: kedoReviewTaskEmployees?.totalCount,
        }}
      />
    </WithLoader>
  );
};

export default DocumentsKedoReviewTaskEmployees;
