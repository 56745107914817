export const EMPLOYEE_GROUPS_ROUTE = `/employee-groups`;
export const EMPLOYEE_GROUP_MEMBERS_ROUTE = `${EMPLOYEE_GROUPS_ROUTE}/add-members`;

export const EMPLOYEE_GROUPS_CREATE_ROUTE = `${EMPLOYEE_GROUPS_ROUTE}/create`;
export const EMPLOYEE_GROUPS_BY_ID_ROUTE = `${EMPLOYEE_GROUPS_ROUTE}/:id`;

export const EMPLOYEE_GROUPS_ADD_ROUTE = `${EMPLOYEE_GROUPS_ROUTE}/add`;
export const getEmployeeGroupWithIdRoute = (id: string) => `${EMPLOYEE_GROUPS_ROUTE}/${id}`;

export const EMPLOYEE_GROUPS_DETAILS_ROUTE = `${EMPLOYEE_GROUPS_ROUTE}/:id`;
