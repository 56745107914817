import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import Header from './containers/header';
import RequestsTable from './containers/requests-table';
import { DEFAULT_FORM_STATE, FormResponseType } from './socialProgramRequests.utils';
import useRequest from './useRequest.hook';

const SocialProgramRequests = () => {
  const [form] = Form.useForm<FormResponseType>();

  const { data, isFetching, isLoading } = useRequest({ form });

  return (
    <WithOverflow>
      <Header total={data?.totalCount} />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <RequestsTable data={data} loading={isFetching} isInitialLoading={isLoading} />
      </FiltersV2>
    </WithOverflow>
  );
};

export default SocialProgramRequests;
