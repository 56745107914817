import { FILE_UPLOAD_PREFIX } from 'utils/createDownloadUrl.utils';

import { FormInstance } from 'gazprom-ui-lib';

import {
  KEDO_REVIEW_TASK_FORM_ENUM,
  KedoReviewTaskFileType,
  KedoReviewTaskFormResponse,
} from 'containers/kedo-review-task-form/kedoReviewTaskForm.utils';

import { KedoReviewTaskType } from 'types/review-task.types';

export const KEDO_REVIEW_TASK_BASE_INFO_TAB = 'KEDO_REVIEW_TASK_BASE_INFO_TAB';
export const KEDO_REVIEW_TASK_ASSIGNMENTS_TAB = 'KEDO_REVIEW_TASK_ASSIGNMENTS_TAB';

export const prepareKedoReviewFile = (file: KedoReviewTaskFileType) => ({
  fileKey: file.link.includes(FILE_UPLOAD_PREFIX)
    ? file.link.replace(FILE_UPLOAD_PREFIX, '')
    : file.link,
  fileName: file.name,
});

interface Props {
  kedoReviewTask?: KedoReviewTaskType;
  form: FormInstance<KedoReviewTaskFormResponse>;
}

export const hasUnsavedChanges = (props: Props) => {
  const { kedoReviewTask, form } = props;

  const formValues = form.getFieldsValue();

  const currentName = formValues[KEDO_REVIEW_TASK_FORM_ENUM.NAME];
  const currentDocName = formValues[KEDO_REVIEW_TASK_FORM_ENUM.DOC_NAME];
  const currentNeedEmployeeSign = formValues[KEDO_REVIEW_TASK_FORM_ENUM.SIGN_REQUIRED];
  const currentFile = formValues[KEDO_REVIEW_TASK_FORM_ENUM.FILE];
  const currentEmployeeGroups = formValues[KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEE_GROUPS];

  const { name, docName, files, employeeGroupIds, needEmployeeSign, instance1c } =
    kedoReviewTask ?? {};

  if (
    currentName !== name ||
    currentDocName !== docName ||
    currentNeedEmployeeSign !== needEmployeeSign
  ) {
    return true;
  }

  if (
    files &&
    (currentFile?.name !== files[0]?.fileName || currentFile?.link !== files[0]?.fileKey)
  ) {
    return true;
  }

  if (employeeGroupIds && currentEmployeeGroups !== employeeGroupIds && !instance1c) {
    if (employeeGroupIds?.length !== currentEmployeeGroups?.length) {
      return true;
    }

    const sortedEmployeeGroupIds = [...employeeGroupIds].sort();
    const sortedCurrentEmployeeGroups = [...currentEmployeeGroups].sort();

    if (
      !sortedEmployeeGroupIds.every((value, index) => value === sortedCurrentEmployeeGroups[index])
    ) {
      return true;
    }
  }

  return false;
};
