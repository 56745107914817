import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetKedoReviewTasksQuery } from 'services/kedo-review-tasks/kedoReviewTasksApiService';
import { GetKedoReviewTasksPropsType } from 'services/kedo-review-tasks/kedoReviewTasksApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './documentsKedoReviewTasks.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: Props) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const [getKedoReviewTasks, { data, isLoading, isFetching, isUninitialized }] =
    useLazyGetKedoReviewTasksQuery();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody) {
      const initialRequestBody: GetKedoReviewTasksPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        type: 'MANUAL',
      };

      if (debouncedRequestBody.search) {
        initialRequestBody.name = debouncedRequestBody.search;
      }

      if (debouncedRequestBody.filters?.status?.length) {
        let statuses: string[] = [];

        debouncedRequestBody.filters?.status.forEach((status) => {
          if (status.includes(',')) {
            statuses = statuses.concat(status.split(','));
          } else {
            statuses.push(status);
          }
        });

        initialRequestBody.status = statuses;
      }

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        initialRequestBody.sort = [
          { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
        ];
      }

      getKedoReviewTasks(initialRequestBody);
    }
  }, [getKedoReviewTasks, debouncedRequestBody]);

  return {
    data,
    isLoading: isUninitialized || isLoading,
    isFetching: isDebouncing || isFetching,
  };
};

export default useGetData;
