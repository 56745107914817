import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetNotificationNewslettersQuery } from 'services/notificationV2/notificationV2ApiService';
import { GetNotificationNewslettersPropsType } from 'services/notificationV2/notificationV2ApiService.types';

import { Form } from 'gazprom-ui-lib';

import { SENDER_ENUM } from 'types/notification.types';

interface Props {
  senderType: SENDER_ENUM;
  templateId?: string;
}

const useGetData = (props: Props) => {
  const { senderType, templateId } = props;

  const form = Form.useFormInstance();

  const requestBody = Form.useWatch([], form);

  const [getNotificationTemplates, { data, isFetching, isLoading, isUninitialized }] =
    useLazyGetNotificationNewslettersQuery();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody?.page) {
      const search = debouncedRequestBody.search || undefined;
      const defaultBody: GetNotificationNewslettersPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        search,
        senderType,
      };

      if (templateId) {
        defaultBody.templateId = templateId;
      }

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        defaultBody.sort = [
          { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
        ];
      }

      getNotificationTemplates(defaultBody);
    }
  }, [debouncedRequestBody, getNotificationTemplates, senderType, templateId]);

  return {
    data,
    isFetching: isFetching || isDebouncing,
    isLoading: isUninitialized || isLoading,
  };
};

export default useGetData;
