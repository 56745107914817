import { FC, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useUpdateHotContactByIdMutation } from 'services/guide/guideApiService';

import { Button, Form, Input, Typography } from 'gazprom-ui-lib';

import TelephoneField from './components/telephone-field';

import { GUIDE_FORM_ENUM } from 'pages/guide/guide-contacts/guideContacts.utils';

import { GuideHotContactType, PhonesDynamicComponentType } from 'types/guide.types';

import s from './GuideContactsEditSectionForm.module.scss';
import { getMaxNum, getSortedValues, mapComponents } from './guideContactsEditSectionForm.utils';

interface GuideContactsEditSectionFormProps {
  hotContacts?: GuideHotContactType;
  name: string;
}

const GuideContactsEditSectionForm: FC<GuideContactsEditSectionFormProps> = (props) => {
  const { hotContacts, name } = props;
  const params = useParams<{ id: string }>();

  const [form] = Form.useForm();
  const [updateHotContact] = useUpdateHotContactByIdMutation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [phoneComponents, setPhoneComponents] = useState<PhonesDynamicComponentType[]>([]);
  const [currentNum, setCurrentNum] = useState(getMaxNum(hotContacts?.contacts ?? []));

  const removePhoneHandler = (id: string | number) => {
    const phoneName = `${id}.phone`;
    const additionalPhoneName = `${id}.internalPhone`;

    setPhoneComponents((prev) => prev.filter((phoneComponent) => phoneComponent.id !== id));
    form.setFieldValue(phoneName, undefined);
    form.setFieldValue(additionalPhoneName, undefined);
    setIsButtonDisabled(false);
  };

  const addPhoneHandler = () => {
    const id = currentNum + 1;
    setPhoneComponents((prev) => [...prev, { id: id }]);
    setCurrentNum(id);
  };

  useEffect(() => {
    if (hotContacts) {
      const initialMappedComponents = hotContacts.contacts.map(mapComponents);
      setPhoneComponents(initialMappedComponents);
    }
  }, [hotContacts]);

  const initialMail = useMemo(() => {
    return hotContacts?.contacts?.find((contact) => contact.email);
  }, [hotContacts]);

  const onChangeFormHandler = () => {
    form.validateFields().then(() => {
      isButtonDisabled && setIsButtonDisabled(false);
    });
  };

  const onFinishFormHandler = () => {
    const values = form.getFieldsValue(true);
    const newValues = getSortedValues(values);

    if (hotContacts && params.id) {
      updateHotContact({
        id: hotContacts.id,
        employerId: params.id,
        name: name,
        contacts: newValues,
        num: hotContacts.num,
      }).then(() => setIsButtonDisabled(true));
    }
  };

  const renderPhonesComponents = (params: PhonesDynamicComponentType) => {
    const { id, initialAdditionalPhone, initialPhone } = params;

    return (
      <TelephoneField
        id={id}
        key={id}
        initialPhone={initialPhone}
        onRemove={removePhoneHandler}
        initialAdditionalPhone={initialAdditionalPhone}
      />
    );
  };

  return (
    <Form
      form={form}
      className={s.formContainer}
      onFinish={onFinishFormHandler}
      onChange={onChangeFormHandler}>
      <div className={s.phonesContainer}>
        <Typography.Title level={5}>{t('common_phone')}</Typography.Title>
        {phoneComponents.map(renderPhonesComponents)}
        <Button type="text" leftIcon="plus" className={s.button} onClick={() => addPhoneHandler()}>
          {t('common_add_phone')}
        </Button>
      </div>
      <div className={s.mailContainer}>
        <div className={s.textContainer}>
          <Typography.Title level={5}>{t('common_e_mail')}</Typography.Title>
          <Typography.Text size="12" type="secondary">
            {t('common_optional')}
          </Typography.Text>
        </div>

        <Form.Item name={GUIDE_FORM_ENUM.MAIL} initialValue={initialMail?.email}>
          <Input placeholder={t('common_enter_address')} disabled={!phoneComponents.length} />
        </Form.Item>
      </div>
      <Button
        type="primary"
        leftIcon="ok"
        htmlType="submit"
        className={s.submitButton}
        disabled={isButtonDisabled}>
        {t('common_save')}
      </Button>
    </Form>
  );
};

export default GuideContactsEditSectionForm;
