import { useState } from 'react';

import { t } from 'tools/i18n';

import { useGetRolesQuery } from 'services/roles/rolesApiService';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2/filtersV2';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import AccessControlRolesTable from './access-control-roles-table';

const AccessControlRoles = () => {
  const [page, setPage] = useState(1);
  const { data: roles, isFetching, isLoading } = useGetRolesQuery();

  return (
    <WithOverflow>
      <ContentHeader showBorder>
        <ContentHeader.Title total={roles?.length}>{t('common_user_roles')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2>
        <WithLoader isLoading={isLoading}>
          <AccessControlRolesTable
            loading={isFetching}
            dataSource={roles}
            paginationConfig={{
              defaultCurrent: page,
              defaultPageSize: 10,
              total: roles?.length,
            }}
            onChange={(pagination) => {
              if (pagination.current) setPage(pagination.current);
            }}
          />
        </WithLoader>
      </FiltersV2>
    </WithOverflow>
  );
};

export default AccessControlRoles;
