import { RequestBodyPreviewType } from 'types/employeeGroups.types';

export const DEFAULT_REQUEST_BODY: RequestBodyPreviewType = {
  body: {
    title: '',
    employersData: {
      partialChecked: [],
      checked: [],
    },
    employeesData: [],
  },
  text: undefined,
  page: 1,
  size: 10,
};
