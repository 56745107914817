import { FC, SyntheticEvent } from 'react';

import { useNavigate } from 'react-router-dom';
import { GUIDE_CONTACTS_EDIT_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './GuideContactsRowLabel.module.scss';

interface GuideContactsRowLabelProps {
  id: string;
  name: string;
}

const GuideContactsRowLabel: FC<GuideContactsRowLabelProps> = (props) => {
  const { id, name } = props;
  const navigate = useNavigate();

  const handleClick = (event: SyntheticEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    navigate(`${GUIDE_CONTACTS_EDIT_ROUTE}/${id}`);
  };

  return (
    <div className={s.tableRowContainer}>
      <Typography.Title level={5}>{name}</Typography.Title>
      <button className={s.tableRowContainerButton} onClick={(e) => handleClick(e, id)}>
        <Icon name="edit" />
        <Typography.Text size="12" weight="500" type="primary">
          {t('common_edit_short')}
        </Typography.Text>
      </button>
    </div>
  );
};

export default GuideContactsRowLabel;
