import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateSocialProgramsPropsType,
  DeleteSocialProgramsPropsType,
  GetEmployersResponse,
  GetSocialProgramByIdPropsType,
  GetSocialProgramByIdResponseType,
  GetSocialProgramsPropsType,
  GetSocialProgramsResponseType,
  UpdateSocialProgramsPropsType,
} from './socialProgramApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';
import { PaginationPropsType } from 'types/pagination.types';

import { createSocialProgramUrl, employerUrl } from './utils';

const socialProgramApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSocialPrograms: builder.query<GetSocialProgramsResponseType, GetSocialProgramsPropsType>({
      query: (params) => ({
        url: createSocialProgramUrl(`s${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_SOCIAL_PROGRAMS],
    }),
    createSocialProgram: builder.mutation<void, CreateSocialProgramsPropsType>({
      query: (body) => ({
        url: createSocialProgramUrl(''),
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SOCIAL_PROGRAMS],
    }),
    updateSocialProgram: builder.mutation<void, UpdateSocialProgramsPropsType>({
      query: ({ id, body }) => ({
        url: createSocialProgramUrl(`/${id}`),
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_SOCIAL_PROGRAMS },
        { type: SERVICE_TAGS.GET_SOCIAL_PROGRAM_BY_ID, id },
      ],
    }),
    deleteSocialProgram: builder.mutation<void, DeleteSocialProgramsPropsType>({
      query: (body) => ({
        url: createSocialProgramUrl(''),
        method: 'DELETE',
        body: body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SOCIAL_PROGRAMS],
    }),
    getEmployers: builder.query<GetEmployersResponse, PaginationPropsType>({
      query: (params) => ({
        url: employerUrl(prepareQueryParams(params)),
      }),
    }),
    getSocialProgramById: builder.query<
      GetSocialProgramByIdResponseType,
      GetSocialProgramByIdPropsType
    >({
      query: (id) => ({
        url: createSocialProgramUrl(`/full/${id}`),
      }),
      providesTags: (_a, _b, id) => [{ type: SERVICE_TAGS.GET_SOCIAL_PROGRAM_BY_ID, id }],
    }),
  }),
});

export const {
  useDeleteSocialProgramMutation,
  useLazyGetSocialProgramsQuery,
  useGetSocialProgramByIdQuery,
  useGetEmployersQuery,
  useCreateSocialProgramMutation,
  useUpdateSocialProgramMutation,
} = socialProgramApiService;
