import { TIconsLiteral } from 'gazprom-ui-lib';

import { MediaType } from 'types/media.types';
import { CPersonType, PersonType } from 'types/person.types';

export type NotificationType = {
  id: string;
  title: string;
  body: string;
  createdAt: string;
  viewedAt: string;
  viewed: boolean;
  sender: SenderType;
  personId: string;
  feedback?: {
    body: string;
    createdAt: string;
  };
  media: MediaType[];
  icon?: TIconsLiteral;
  callBack?: {
    contacts: string;
    createdAt: string;
  };
  notificationType: NOTIFICATION_ENUM;
  requiresFeedback: boolean;
  allowFeedback: boolean;
  allowCallback: boolean;
  packageId: string;
  person?: CPersonType;
};
export type NotificationTypeWithPerson = NotificationType & {
  person?: CPersonType;
};
export type NotificationTemplateType = {
  body: string;
  createdAt: string;
  creator: PersonType;
  editor: PersonType;
  icon: string;
  id: string;
  media: MediaType[];
  name: string;
  title: string;
  updatedAt: string;
  receivers: {
    receiverType: NEWSLETTER_RECEIVER_ENUM;
    receiverIds: string[];
  }[];
  sendApp: boolean;
  sendPush: boolean;
  sendEmail: boolean;
  allowCallback: boolean;
  allowFeedback: boolean;
  requiresFeedback: boolean;
};

export enum NOTIFICATION_ENUM {
  APP = 'APP',
  PUSH = 'PUSH',
  EMAIL = 'EMAIL',
  EMAIL_BATCH = 'EMAIL_BATCH',
}

export type NotificationNewsletterType = {
  id: string;
  name: string;
  sender?: SenderType;
  createdAt?: string;
  receivers: {
    receiverType: NEWSLETTER_RECEIVER_ENUM;
    receiverIds: string[];
  }[];
  dateToSend?: string;
  viewCount: number;
  feedbackCount: number;
  sendCount: number;
  requiresFeedback: boolean;
  allowFeedback: boolean;
  allowCallback: boolean;
  body: string;
  callbackCount: number;
  sendApp: boolean;
  sendEmail: boolean;
  sendPush: boolean;
  title: string;
};

export type SenderType = {
  personId: string;
  senderName: string;
  senderType: SENDER_ENUM;
};

export enum NEWSLETTER_RECEIVER_ENUM {
  ALL = 'ALL',
  EMPLOYER = 'EMPLOYER',
  DIVISION = 'DIVISION',
  STAFF = 'STAFF',
  POSITION = 'POSITION',
  EMPLOYEE = 'EMPLOYEE',
  PERSON = 'PERSON',
  EMPLOYEE_GROUP = 'EMPLOYEE_GROUP',
}

export enum SENDER_ENUM {
  HUMAN = 'HUMAN',
  APP = 'APP',
}
