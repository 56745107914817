import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiService from 'services/1c/cApiService';

import {
  AssignKedoReviewTaskPropsType,
  AssignKedoReviewTaskResponseType,
  CancelKedoReviewTaskPropsType,
  CancelKedoReviewTaskResponseType,
  CreateKedoReviewTaskPropsType,
  CreateKedoReviewTaskResponseType,
  GetKedoReviewTaskAssignmentsPropsType,
  GetKedoReviewTaskAssignmentsResponseType,
  GetKedoReviewTaskByIdPropsType,
  GetKedoReviewTaskByIdResponseType,
  GetKedoReviewTasksPropsType,
  GetKedoReviewTasksResponseType,
  PatchKedoReviewTaskPropsType,
  PatchKedoReviewTaskResponseType,
  UpdateKedoReviewTaskPropsType,
  UpdateKedoReviewTaskResponseType,
} from './kedoReviewTasksApiService.types';
import { C_SERVICE_TAGS } from 'constants/serviceTags';

import { createKedoReviewTaskUrl } from './utils';

const kedoReviewTasksApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getKedoReviewTasks: builder.query<GetKedoReviewTasksResponseType, GetKedoReviewTasksPropsType>({
      query: (params) => ({
        url: createKedoReviewTaskUrl(prepareQueryParams(params)),
      }),
      providesTags: [C_SERVICE_TAGS.GET_KEDO_REVIEW_TASKS],
    }),
    createKedoReviewTask: builder.mutation<
      CreateKedoReviewTaskResponseType,
      CreateKedoReviewTaskPropsType
    >({
      query: (body) => ({
        url: createKedoReviewTaskUrl(''),
        method: 'POST',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_KEDO_REVIEW_TASKS],
    }),
    getKedoReviewTaskById: builder.query<
      GetKedoReviewTaskByIdResponseType,
      GetKedoReviewTaskByIdPropsType
    >({
      query: (id) => ({
        url: createKedoReviewTaskUrl(`/${id}`),
      }),
      providesTags: [C_SERVICE_TAGS.GET_KEDO_REVIEW_TASK_BY_ID],
    }),
    updateKedoReviewTask: builder.mutation<
      UpdateKedoReviewTaskResponseType,
      UpdateKedoReviewTaskPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createKedoReviewTaskUrl(`/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_KEDO_REVIEW_TASK_BY_ID],
    }),
    patchKedoReviewTask: builder.mutation<
      PatchKedoReviewTaskResponseType,
      PatchKedoReviewTaskPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createKedoReviewTaskUrl(`/${id}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        C_SERVICE_TAGS.GET_KEDO_REVIEW_TASK_BY_ID,
        C_SERVICE_TAGS.GET_KEDO_REVIEW_TASKS,
      ],
    }),
    assignKedoReviewTask: builder.mutation<
      AssignKedoReviewTaskResponseType,
      AssignKedoReviewTaskPropsType
    >({
      query: (id) => ({
        url: createKedoReviewTaskUrl(`/assign/${id}`),
        method: 'POST',
      }),
      invalidatesTags: [
        C_SERVICE_TAGS.GET_KEDO_REVIEW_TASKS,
        C_SERVICE_TAGS.GET_KEDO_REVIEW_TASK_BY_ID,
        C_SERVICE_TAGS.GET_KEDO_REVIEW_TASK_ASSIGNMENTS,
      ],
    }),
    cancelKedoReviewTask: builder.mutation<
      CancelKedoReviewTaskResponseType,
      CancelKedoReviewTaskPropsType
    >({
      query: (id) => ({
        url: createKedoReviewTaskUrl(`/cancel/${id}`),
        method: 'POST',
      }),
      invalidatesTags: [
        C_SERVICE_TAGS.GET_KEDO_REVIEW_TASKS,
        C_SERVICE_TAGS.GET_KEDO_REVIEW_TASK_BY_ID,
        C_SERVICE_TAGS.GET_KEDO_REVIEW_TASK_ASSIGNMENTS,
      ],
    }),
    getKedoReviewTaskAssignments: builder.query<
      GetKedoReviewTaskAssignmentsResponseType,
      GetKedoReviewTaskAssignmentsPropsType
    >({
      query: (params) => ({
        url: createKedoReviewTaskUrl(`/assignment${prepareQueryParams(params)}`),
      }),
      providesTags: [C_SERVICE_TAGS.GET_KEDO_REVIEW_TASK_ASSIGNMENTS],
    }),
  }),
});

export const {
  useLazyGetKedoReviewTasksQuery,
  useCreateKedoReviewTaskMutation,
  useGetKedoReviewTaskByIdQuery,
  useUpdateKedoReviewTaskMutation,
  useAssignKedoReviewTaskMutation,
  useCancelKedoReviewTaskMutation,
  useLazyGetKedoReviewTaskAssignmentsQuery,
  usePatchKedoReviewTaskMutation,
} = kedoReviewTasksApiService;
