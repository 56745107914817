import { FilterCheckboxGroupOptions } from 'containers/filters/filter-checkbox-group/filterCheckboxGroup';

import { KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM } from 'types/review-task.types';

import { getKedoReviewTaskAssigneeStatus } from '../../documentsKedoReviewAssignments.utils';

export const getAssigneeStatusOptions = (): FilterCheckboxGroupOptions[] => {
  const assigneeStatuses = Object.values(KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM);

  const modifyStatus = (status: KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM) => ({
    label: getKedoReviewTaskAssigneeStatus(status)['label'],
    value: status,
  });

  return assigneeStatuses.map(modifyStatus);
};
