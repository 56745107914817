import { FC, useEffect, useRef } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, Typography } from 'gazprom-ui-lib';

import Editor from 'containers/editor';

import { NOTIFICATION_NEWSLETTER_FORM_ENUM } from '../notificationNewsletterForm.utils';

interface NotificationNewsletterFormTextProps {
  disabled?: boolean;
}

const NotificationNewsletterFormText: FC<NotificationNewsletterFormTextProps> = (props) => {
  const { disabled } = props;

  const editorRef = useRef<Editor>(null);
  const setRef = useRef<boolean>(false);

  const form = Form.useFormInstance();

  const editorValue = Form.useWatch(NOTIFICATION_NEWSLETTER_FORM_ENUM.TEXT);

  useEffect(() => {
    if (editorValue && editorRef && !setRef.current) {
      editorRef.current?.setEditorData(editorValue);
      setRef.current = true;
    }
  }, [editorRef, editorValue]);

  const handleSetEditorValue = (v: string) => {
    form.setFieldValue(NOTIFICATION_NEWSLETTER_FORM_ENUM.TEXT, v);
  };

  return (
    <div>
      <Typography.Text strong size="16">
        {t('notification_message_text')}
      </Typography.Text>
      <Form.Item name={NOTIFICATION_NEWSLETTER_FORM_ENUM.TEXT} rules={[formRules.required]}>
        <Editor disabled={disabled} onChange={handleSetEditorValue} ref={editorRef} />
      </Form.Item>
    </div>
  );
};

export default NotificationNewsletterFormText;
