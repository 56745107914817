import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import {
  useGetExpertCommissionByIdQuery,
  useUpdateExpertCommissionMutation,
} from 'services/expert-commissions/expertCommissionsApiService';

import ExpertCommissionForm from 'containers/expert-commission-form';
import { ExpertCommissionFormResponseType } from 'containers/expert-commission-form/expertCommissionForm.utils';

import { prepareRequestBody } from 'pages/suggestions/suggestions-commissions/suggestions-commissions-create/suggestionsCommissionsCreate.utils';

import s from './CommissionDetails.module.scss';

const CommissionDetails = () => {
  const params = useParams<{ id: string }>();

  const { data: expertCommission } = useGetExpertCommissionByIdQuery(
    { id: params?.id! },
    {
      skip: !params?.id,
    },
  );

  const [updateCommission] = useUpdateExpertCommissionMutation();

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('common_created_updated'),
      },
      description: {
        children: t('suggestions_commission_update_success_description'),
      },
    },
    onErrorModalConfig: {
      icon: {
        type: 'warning',
        name: 'info',
      },
      title: {
        children: t('suggestions_commission_create_error_title'),
      },
      description: {
        children: t('suggestions_commission_create_error_description'),
      },
    },
  });

  const onSubmit = (values: ExpertCommissionFormResponseType) => {
    if (params?.id) {
      updateCommission({ id: params.id, ...prepareRequestBody(values) }).then(handleUpdate);
    }
  };

  return (
    <div className={s.wrapper}>
      <ExpertCommissionForm onSubmit={onSubmit} expertCommission={expertCommission} />
    </div>
  );
};

export default CommissionDetails;
