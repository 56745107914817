import { Dispatch, FC, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { useLogoutMutation } from 'services/authorization/authorizationApiService';

import { Button, Flex, Typography } from 'gazprom-ui-lib';

interface AuthorizationInfoProps {
  linkName?: string;
  setIsPhoneConfirmed: Dispatch<SetStateAction<boolean>>;
}

const AuthorizationInfo: FC<AuthorizationInfoProps> = (props) => {
  const { linkName, setIsPhoneConfirmed } = props;

  const [logout] = useLogoutMutation();

  const handleLogout = () => {
    logout().then(() => setIsPhoneConfirmed(false));
  };

  return (
    <>
      <Typography.Text size="16" weight="500">
        {t('common_authorized_as')}{' '}
        <Typography.Text size="16" weight="500" type="primary">
          {linkName}
        </Typography.Text>
      </Typography.Text>
      <Flex>
        <Button type="secondary" danger leftIcon="logout" onClick={handleLogout}>
          {t('common_logout')}
        </Button>
      </Flex>
    </>
  );
};

export default AuthorizationInfo;
