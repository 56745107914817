export type FormResponseType = {
  search: string;
  page: number;
  size: number;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  search: '',
  page: 1,
  size: 10,
};
