export type ModalType = {
  type: 'memo' | 'map';
  title: string;
  description: string;
  availableTypes: string[];
};
export type CoverType = { size?: number; name?: string; url?: string };
export const AVAILABLE_MEMO_TYPES = ['application/pdf', 'application/msword'];
export const AVAILABLE_MAP_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];
export const normFile = (e: File[] | { fileList: File[] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  if ('fileList' in e) {
    return e?.fileList;
  }
};

export const MAX_SIZE = 5;
