import { FC } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useGetCategoriesQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import { DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import s from './DocumentsDeclarationsFormCategory.module.scss';

interface DocumentsDeclarationsFormCategoryProps {
  defaultValue?: string;
  type?: KEDO_TYPE_ENUM;
}

const DocumentsDeclarationsFormCategory: FC<DocumentsDeclarationsFormCategoryProps> = (props) => {
  const { defaultValue, type } = props;

  const { data, isLoading } = useGetCategoriesQuery({ type });

  return (
    <>
      <div className={s.titleContainer}>
        <Typography.Title level={5}>{t('common_category')}</Typography.Title>
      </div>
      <Form.Item
        name={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.CATEGORY}
        initialValue={defaultValue}
        rules={[formRules.required]}>
        <Select
          placeholder={t('documents_declarations_edo_category_placeholder')}
          options={data}
          showSearch
          filterOption
          optionFilterProp="label"
          loading={isLoading}
        />
      </Form.Item>
    </>
  );
};

export default DocumentsDeclarationsFormCategory;
