import { FC, useCallback, useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { formRules } from 'utils/formRules';
import { treeNodeUtils, updateNode } from 'utils/treeNode.utils';

import {
  useGetEmployerRootQuery,
  useLazyGetEmployeeGroupTreeQuery,
} from 'services/employee-groups-new/employeeGroupsNewApiService';

import { DataNode, Flex, Form, Tree, handleRequest } from 'gazprom-ui-lib';

import Loader from 'components/loader';
import WithLoader from 'containers/wrappers/with-loader';

import {
  FirstArgumentOnCheckType,
  OnLoadArguments,
  SecondArgumentOnCheckType,
} from 'types/employeeGroups.types';
import { EventGroupTreeType } from 'types/event.types';

import { EVENT_FORM_ENUM } from '../../../eventForm.utils';
import s from './Groups.module.scss';

interface GenderProps {
  disabled?: boolean;
  treeIsLoading?: boolean;
}

const Groups: FC<GenderProps> = (props) => {
  const { disabled, treeIsLoading } = props;

  const form = Form.useFormInstance();

  const checkedKeys = Form.useWatch(EVENT_FORM_ENUM.TARGET_GROUP);
  const initialData = Form.useWatch(EVENT_FORM_ENUM.INITIAL_TARGET_TREE);

  const [treeData, setTreeData] = useState<DataNode[]>([]);

  const [getTree] = useLazyGetEmployeeGroupTreeQuery();
  const { data: employerRootData, isLoading: isEmployerRootDataLoading } = useGetEmployerRootQuery(
    initialData && skipToken,
  );

  useEffect(() => {
    if (initialData) {
      setTreeData(initialData.map(transformData));
    } else if (employerRootData) {
      setTreeData(employerRootData);
    }
  }, [initialData, employerRootData]);

  const onLoadData = useCallback(
    (treeNode: OnLoadArguments) =>
      new Promise<void>((resolve) => {
        const { key, hasChildren, type } = treeNode as OnLoadArguments & {
          hasChildren: boolean;
          type: string;
        };

        if (hasChildren) {
          const onSuccess = (res?: DataNode[]) => {
            if (res) {
              setTreeData((prevState) => updateNode(prevState, key, res));
            }
            return resolve();
          };

          return getTree({
            parentId: (key as string).split('.')[0],
            type,
          }).then(handleRequest({ onSuccess }));
        }

        return resolve();
      }),
    [getTree],
  );

  const onCheck = (_: FirstArgumentOnCheckType, info: SecondArgumentOnCheckType) => {
    const checked = info.checkedNodes.map((el) => el.key) as string[];
    const halfCheckedKeys = info.halfCheckedKeys as string[];
    const halfChecked = halfCheckedKeys.map((id) => treeNodeUtils(treeData, id));

    form.setFields([
      { name: EVENT_FORM_ENUM.TARGET_GROUP, value: { checked, halfChecked }, errors: [] },
    ]);
  };

  const groupValidator = (_: {}, value: { checked: unknown[]; halfChecked: unknown[] }) => {
    const { checked, halfChecked } = value ?? {};

    const isNotEmpty = !!checked?.length || !!halfChecked?.length;

    if (isNotEmpty) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(formRules.required.message));
  };

  return (
    <>
      <WithLoader
        isLoading={isEmployerRootDataLoading || treeIsLoading}
        loader={
          <Flex fullWidth className={s.loader}>
            <Loader />
          </Flex>
        }>
        <Form.Item
          name={EVENT_FORM_ENUM.TARGET_GROUP}
          rules={[() => ({ validator: groupValidator, message: formRules.required.message })]}
          className={s.wrapper}>
          <Tree
            disabled={disabled}
            defaultCheckedKeys={checkedKeys}
            checkedKeys={checkedKeys}
            checkable
            treeData={treeData}
            loadData={onLoadData}
            onCheck={onCheck}
            key="key"
            selectable={false}
            className={s.tree}
          />
        </Form.Item>
      </WithLoader>
      <Form.Item name={EVENT_FORM_ENUM.INITIAL_TARGET_TREE} />
    </>
  );
};

const transformData = (treeData: EventGroupTreeType): DataNode => {
  return {
    ...treeData,
    title: treeData.name,
    key: `${treeData.id}.${treeData.type}`,
    children: treeData?.children?.map(transformData),
  };
};

export default Groups;
