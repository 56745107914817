import { FC, ReactElement, memo, useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { SOCIAL_PROGRAM_REQUESTS_ROUTE } from 'routes/social-program/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import { SOCIAL_PROGRAM_REQUEST_ENUM, SocialProgramRequestType } from 'types/socialProgram.types';

import s from './Actions.module.scss';
import {
  APPROVE,
  COMMISSION,
  EXECUTE,
  REJECT,
  REJECT_AVAILABLE_TYPES,
  REVISION,
  renderAction,
} from './actions.utils';
import ApproveModal from './containers/approve-modal';
import CommissionModal from './containers/commission-modal';
import ExecuteModalProps from './containers/execute-modal';
import RejectModal from './containers/reject-modal';
import RevisionModal from './containers/revision-modal';

interface SocialProgramRequestsDetailsActionsProps {
  socialRequestData?: SocialProgramRequestType;
}

const Actions: FC<SocialProgramRequestsDetailsActionsProps> = (props) => {
  const { socialRequestData } = props;

  const navigate = useNavigate();

  const [openedModalType, setOpenedModalType] = useState<string>('');

  const handleModalClose = useCallback(() => {
    setOpenedModalType('');
  }, []);

  const actions: ReactElement[] = useMemo(() => {
    const initialActions: ReactElement[] = [];
    const status = socialRequestData?.currentKedoStatus?.status;

    if (status === SOCIAL_PROGRAM_REQUEST_ENUM.DOCS_CHECKING) {
      initialActions.push(
        <Button onClick={() => setOpenedModalType(REVISION)} ghost leftIcon="return" key={REVISION}>
          {t('social_program_return_to_revision')}
        </Button>,
      );
    }

    if (status === SOCIAL_PROGRAM_REQUEST_ENUM.DELIVERING) {
      initialActions.push(
        <Button onClick={() => setOpenedModalType(EXECUTE)} leftIcon="okFilled" key={EXECUTE}>
          {t('common_execute')}
        </Button>,
      );
    }

    if (status === SOCIAL_PROGRAM_REQUEST_ENUM.DOCS_CHECKING && socialRequestData?.hasCommission) {
      initialActions.push(
        <Button
          onClick={() => setOpenedModalType(COMMISSION)}
          leftIcon="resumeFilled"
          key={COMMISSION}>
          {t('social_program_assign_commission')}
        </Button>,
      );
    }

    if (
      (status === SOCIAL_PROGRAM_REQUEST_ENUM.DOCS_CHECKING && !socialRequestData?.hasCommission) ||
      (status === SOCIAL_PROGRAM_REQUEST_ENUM.IN_REVIEW && socialRequestData?.hasCommission)
    ) {
      initialActions.push(
        <Button onClick={() => setOpenedModalType(APPROVE)} leftIcon="ok" key={APPROVE}>
          {t('common_approve')}
        </Button>,
      );
    }

    if (status && REJECT_AVAILABLE_TYPES.includes(status as SOCIAL_PROGRAM_REQUEST_ENUM)) {
      initialActions.push(
        <Button
          onClick={() => setOpenedModalType(REJECT)}
          leftIcon="deleteFilled"
          danger
          key={REJECT}>
          {t('common_reject')}
        </Button>,
      );
    }

    if (initialActions.length < 3) {
      initialActions.push(
        <Button onClick={() => navigate(SOCIAL_PROGRAM_REQUESTS_ROUTE)} ghost key="CLOSE">
          {t('common_close')}
        </Button>,
      );
    }

    return initialActions;
  }, [navigate, socialRequestData]);

  return (
    <>
      <div className={s.wrapper}>{actions.map(renderAction)}</div>
      <ExecuteModalProps open={openedModalType === EXECUTE} onCancel={handleModalClose} />
      <ApproveModal open={openedModalType === APPROVE} onCancel={handleModalClose} />
      <RevisionModal open={openedModalType === REVISION} onCancel={handleModalClose} />
      <RejectModal open={openedModalType === REJECT} onCancel={handleModalClose} />
      <CommissionModal open={openedModalType === COMMISSION} onCancel={handleModalClose} />
    </>
  );
};

export default memo(Actions);
