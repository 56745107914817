import { FC, useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import {
  useDeleteHotContactByIdMutation,
  useUpdateHotContactByIdMutation,
} from 'services/guide/guideApiService';

import { Collapse, Icon, Typography } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';

import { GuideHotContactByIdType } from 'types/guide.types';

import GuideContactsEditModalAdd from '../guide-contacts-edit-modal-add';
import s from './GuideContactsEditSection.module.scss';
import GuideContactsEditSectionForm from './guide-contacts-edit-section-form';

interface GuideContactsEditSectionProps {
  id: string;
  title: string;
  hotContacts?: GuideHotContactByIdType;
  setModal: (type: string) => void;
}

const GuideContactsEditSection: FC<GuideContactsEditSectionProps> = (props) => {
  const { title: initialTitle, hotContacts, setModal: setModalProp } = props;

  const params = useParams<{ id: string }>();

  const [modal, setModal] = useState<'edit' | 'confirm' | ''>('');
  const [title, setTitle] = useState(initialTitle);

  const [deleteHotContact, { isLoading }] = useDeleteHotContactByIdMutation();
  const [updateHotContact] = useUpdateHotContactByIdMutation();

  const removeHandler = async () => {
    if (hotContacts?.id) {
      await deleteHotContact([hotContacts.id]);
    }

    setModalProp('ok');
  };

  const label = (
    <div className={s.headerContainer}>
      <Typography.Title level={4}>{title}</Typography.Title>
      <div className={s.headerIconsContainer}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setModal('edit');
          }}>
          <Icon name="edit" />
          {t('common_edit_short')}
        </button>
        <button
          className={s.remove}
          onClick={(e) => {
            e.stopPropagation();
            setModal('confirm');
          }}>
          <Icon name="binFilled" />
          {t('common_remove')}
        </button>
      </div>
    </div>
  );

  const handleEditFinish = (value: string) => {
    if (hotContacts && params.id) {
      updateHotContact({
        contacts: hotContacts?.contacts,
        employerId: params.id,
        id: hotContacts.id,
        name: value,
        num: hotContacts.num,
      });
    }
    setTitle(value);
    setModal('');
  };

  const children = (
    <div className={s.collapseBody}>
      <Typography.Title level={5}>{t('guide_hot_contacts_contact_info')}</Typography.Title>

      <GuideContactsEditSectionForm hotContacts={hotContacts} name={title} />
    </div>
  );

  return (
    <>
      <Collapse
        items={[
          {
            key: '1',
            label,
            children,
          },
        ]}
      />
      <GuideContactsEditModalAdd
        open={modal === 'edit'}
        onClose={() => setModal('')}
        onFinish={handleEditFinish}
        initialValue={title}
        actionButtonIcon="ok"
        actionButtonText={t('common_save')}
      />
      <RemoveConfirm
        isLoading={isLoading}
        type="danger"
        isOpen={modal === 'confirm'}
        onCancel={() => setModal('')}
        title={t('common_accept_remove')}
        subtitle={t('guide_hot_contects_remove_contact')}
        onConfirm={removeHandler}
        buttonText={t('common_remove')}
        icon="binFilled"
      />
    </>
  );
};

export default GuideContactsEditSection;
