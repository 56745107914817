import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateEventPropsType,
  CreateEventResponseType,
  DeleteEventsPropsType,
  DeleteEventsResponseType,
  GetEventByIdPropsType,
  GetEventByIdResponseType,
  GetEventsPropsType,
  GetEventsResponseType,
  PublishEventPropsType,
  PublishEventResponseType,
  UnpublishEventPropsType,
  UnpublishEventResponseType,
  UpdateEventPropsType,
  UpdateEventResponseType,
} from './meetApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createMeetUrl } from './utils';

const meetApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<GetEventsResponseType, GetEventsPropsType>({
      query: (params) => ({
        url: createMeetUrl(prepareQueryParams(params)),
      }),
      providesTags: [SERVICE_TAGS.GET_EVENTS],
    }),
    getEventById: builder.query<GetEventByIdResponseType, GetEventByIdPropsType>({
      query: (id) => ({
        url: createMeetUrl(`/${id}`),
      }),
      providesTags: [SERVICE_TAGS.GET_EVENT_BY_ID],
    }),
    createEvent: builder.mutation<CreateEventResponseType, CreateEventPropsType>({
      query: (body) => ({
        url: createMeetUrl(''),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_EVENTS],
    }),
    updateEvent: builder.mutation<UpdateEventResponseType, UpdateEventPropsType>({
      query: ({ id, ...body }) => ({
        url: createMeetUrl(`/${id}`),
        method: 'PUT',
        body,
      }),
    }),
    publishEvent: builder.mutation<PublishEventResponseType, PublishEventPropsType>({
      query: (id) => ({
        url: createMeetUrl(`/${id}/assign`),
        method: 'PUT',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_EVENT_BY_ID],
    }),
    unpublishEvent: builder.mutation<UnpublishEventResponseType, UnpublishEventPropsType>({
      query: (id) => ({
        url: createMeetUrl(`/${id}/unassign`),
        method: 'PUT',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_EVENT_BY_ID],
    }),
    deleteEvents: builder.mutation<DeleteEventsResponseType, DeleteEventsPropsType>({
      query: (body) => ({
        url: createMeetUrl(''),
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_EVENTS],
    }),
  }),
});

export const {
  usePublishEventMutation,
  useUnpublishEventMutation,
  useGetEventByIdQuery,
  useDeleteEventsMutation,
  useCreateEventMutation,
  useLazyGetEventsQuery,
  useUpdateEventMutation,
} = meetApiService;
