import { useState } from 'react';

import { t } from 'tools/i18n';

import { useDeleteCInstanceMutation } from 'services/1c-instances/cInstancesService';

import { Form } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import Header from './containers/header';
import Table from './containers/table';
import { DEFAULT_FORM_STATE } from './settings1cInstances.utils';
import useGet1cInstances from './useGetData.hook';
import useSetDefaultValues from './useSetDefaultValues.hook';

const Settings1cInstances = () => {
  const [cInstanceToDelete, setCInstanceToDelete] = useState<string[]>([]);
  const [showDeleteErrorModal, setShowDeleteErrorModal] = useState<boolean>(false);

  const [form] = Form.useForm();

  const [deleteCInstance, { isLoading: isDeletingInstance }] = useDeleteCInstanceMutation();
  const { cInstances, isFetching, isLoading } = useGet1cInstances({ form });

  const clearCInstanceToDelete = () => setCInstanceToDelete([]);

  const handleErrorModalOpenClose = () => {
    setShowDeleteErrorModal((prevState) => !prevState);
  };

  const handleDeleteCInstance = () => {
    const [instance] = cInstanceToDelete;

    deleteCInstance(instance).then((res) => {
      if ('error' in res) {
        handleErrorModalOpenClose();
      }
      clearCInstanceToDelete();
    });
  };

  useSetDefaultValues({ form });

  return (
    <>
      <WithOverflow>
        <Header />
        <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
          <FiltersV2.TableFilters>
            <FiltersV2.Search />
          </FiltersV2.TableFilters>
          <Table
            isInitialLoading={isLoading}
            setCInstanceToDelete={setCInstanceToDelete}
            dataSource={cInstances}
            loading={isFetching}
          />
        </FiltersV2>
      </WithOverflow>
      <RemoveConfirm
        icon="bin"
        buttonIcon="bin"
        isLoading={isDeletingInstance}
        type="danger"
        title={t('common_accept_remove')}
        subtitle={t('1c_instance_delete_description')}
        isOpen={!!cInstanceToDelete.length}
        buttonText={t('common_remove')}
        buttonProps={{ type: 'primary', danger: true, ghost: true }}
        onCancel={clearCInstanceToDelete}
        onConfirm={handleDeleteCInstance}
      />
      <RemoveConfirm
        icon="info"
        type="warning"
        isOpen={showDeleteErrorModal}
        onCancel={handleErrorModalOpenClose}
        title={t('common_error')}
        subtitle={t('1c_instance_delete_error')}
        onConfirm={handleErrorModalOpenClose}
        buttonText={t('common_close')}
        buttonProps={{ type: 'primary', ghost: true }}
      />
    </>
  );
};

export default Settings1cInstances;
