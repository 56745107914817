import { prepareQueryParams } from 'utils/prepareQueryParams';

import {
  GetEmployersPropsType,
  GetEmployersResponse,
} from 'services/employer/employerApiService.types';
import { createEmployerUrl } from 'services/employer/utils';
import initialApiService from 'services/initialApiService';

const employerApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getEmployersList: builder.query<GetEmployersResponse, GetEmployersPropsType>({
      query: (params) => ({
        url: createEmployerUrl(prepareQueryParams(params)),
      }),
    }),
  }),
});

export const { useGetEmployersListQuery, useLazyGetEmployersListQuery } = employerApiService;
