import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, TextArea, Typography } from 'gazprom-ui-lib';

import s from './CommonTextArea.module.scss';

interface CommonTextAreaProps {
  title: string;
  name: string;
  placeholder: string;
  totalLength: string;
  initialValue?: string;
}

const CommonTextArea: FC<CommonTextAreaProps> = (props) => {
  const { title, name, placeholder, totalLength, initialValue } = props;
  const [value, setValue] = useState('');
  const form = Form.useFormInstance();

  useEffect(() => {
    initialValue && form.setFieldValue(name, initialValue);
  }, [form, initialValue, name]);

  return (
    <div className={s.container}>
      <div className={s.header}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_symbol_count', { current: value.length, total: totalLength })}
        </Typography.Text>
      </div>

      <Form.Item name={name} rules={[formRules.required]} initialValue={initialValue}>
        <TextArea
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          maxLength={Number(totalLength)}
        />
      </Form.Item>
    </div>
  );
};

export default CommonTextArea;
