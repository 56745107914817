import { FC } from 'react';

import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Checkbox, Flex, Form, Icon, TextArea, Tooltip, Typography } from 'gazprom-ui-lib';

import { EVENT_FORM_ENUM, FORM_LABELS } from 'containers/event-form/eventForm.utils';

import s from './Notification.module.scss';

interface NotificationProps {
  disabled?: boolean;
}

const Notification: FC<NotificationProps> = (props) => {
  const { disabled } = props;

  const sendNotification = Form.useWatch(EVENT_FORM_ENUM.SEND_NOTIFICATION);

  return (
    <>
      <Flex gap="4">
        <Typography.Title level={5}>{t('events_notifications_settings')}</Typography.Title>
        <Flex align="center" justify="center" className={s.tooltip}>
          <Tooltip
            rootClassName={s.tooltipRoot}
            align={{ offset: [0, 60] }}
            placement="rightBottom"
            title={t('events_notifications_description')}
            arrow={false}>
            <button type="button">
              <Icon name="info" />
            </button>
          </Tooltip>
        </Flex>
      </Flex>
      <Form.Item name={EVENT_FORM_ENUM.SEND_NOTIFICATION} valuePropName="checked">
        <Checkbox disabled={disabled}>{FORM_LABELS[EVENT_FORM_ENUM.SEND_NOTIFICATION]}</Checkbox>
      </Form.Item>
      {sendNotification && (
        <>
          <Form.Item
            name={EVENT_FORM_ENUM.NOTIFICATION_TITLE}
            label={FORM_LABELS[EVENT_FORM_ENUM.NOTIFICATION_TITLE]}
            normalize={normalizeTextField}
            rules={[formRules.required, { max: 50 }]}>
            <TextArea
              disabled={disabled}
              placeholder={t('common_enter_text')}
              maxLength={50}
              showCount
              className={s.inputImitation}
            />
          </Form.Item>
          <Form.Item
            name={EVENT_FORM_ENUM.NOTIFICATION_TEXT}
            label={FORM_LABELS[EVENT_FORM_ENUM.NOTIFICATION_TEXT]}
            normalize={normalizeTextField}
            rules={[formRules.required, { max: 125 }]}>
            <TextArea
              placeholder={t('common_enter_text')}
              maxLength={125}
              showCount
              disabled={disabled}
            />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default Notification;
