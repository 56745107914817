import { FC } from 'react';

import cn from 'classnames';
import { getKedoTagConfig } from 'utils/kedo.utils';

import { Icon, Tag } from 'gazprom-ui-lib';

import { CurrentKedoStatusType } from 'types/documents.types';

import s from './KedoStatusTag.module.scss';

interface KedoStatusTagProps {
  size: '24' | '30';
  currentKedoStatus?: CurrentKedoStatusType;
  className?: string;
}

const KedoStatusTag: FC<KedoStatusTagProps> = (props) => {
  const { currentKedoStatus, className, size } = props;

  const { icon, children, type } = getKedoTagConfig(currentKedoStatus);

  return (
    <Tag
      type={type}
      icon={<Icon name={icon} />}
      className={cn(s.wrapper, s[`size${size}`], className)}>
      {children}
    </Tag>
  );
};

export default KedoStatusTag;
