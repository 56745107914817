import { FC } from 'react';

import { Table } from 'gazprom-ui-lib';

import { ExecutionType } from 'types/bypassSheet.types';

import useColumns from './useColumns.hook';

interface LnaTableProps {
  data: ExecutionType[];
  handleRowClick: (row: ExecutionType) => void;
}

const ExecutionsTable: FC<LnaTableProps> = (props) => {
  const { data, handleRowClick } = props;
  const columns = useColumns();

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      pagination={{
        current: 1,
        total: 1,
      }}
      onRow={(row) => ({
        onClick: () => handleRowClick(row),
      })}
    />
  );
};

export default ExecutionsTable;
