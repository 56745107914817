import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button, Flex } from 'gazprom-ui-lib';

interface Props {
  isSaveLoading: boolean;
  isAssignLoading: boolean;
  isFinished: boolean;
  isReadyToAssign: boolean;
  recallButtonHandler: () => void;
  appointButtonHandler: () => void;
}

const Buttons = (props: Props) => {
  const {
    isFinished,
    isReadyToAssign,
    isSaveLoading,
    isAssignLoading,
    appointButtonHandler,
    recallButtonHandler,
  } = props;

  const { id } = useParams();
  const navigate = useNavigate();

  const isLoading = isSaveLoading || isAssignLoading;

  const buttonsDraft = (
    <>
      <Button fullWidth type="primary" leftIcon="ok" htmlType="submit" loading={isSaveLoading}>
        {t('common_save')}
      </Button>
      <Button fullWidth type="primary" leftIcon="checklist" disabled={true}>
        {t('common_appoint')}
      </Button>
    </>
  );

  const buttons = (
    <>
      <Button fullWidth type="primary" leftIcon="ok" htmlType="submit" loading={isLoading}>
        {t('common_save')}
      </Button>
      {isFinished ? (
        <Button
          htmlType="button"
          fullWidth
          type="primary"
          leftIcon="checklist"
          onClick={recallButtonHandler}
          danger
          disabled={false}
          loading={isLoading}>
          {t('surveys_recall')}
        </Button>
      ) : (
        <Button
          htmlType="button"
          fullWidth
          type="primary"
          leftIcon="checklist"
          disabled={!isReadyToAssign}
          onClick={appointButtonHandler}
          loading={isLoading}>
          {t('common_appoint')}
        </Button>
      )}
    </>
  );

  return (
    <Flex gap="8">
      {id ? buttons : buttonsDraft}

      <Button fullWidth type="secondary" onClick={() => navigate(-1)} disabled={false}>
        {t('common_close')}
      </Button>
    </Flex>
  );
};

export default Buttons;
