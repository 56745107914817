import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
  isPublished: boolean;
}

const Modal = (props: Props) => {
  const { open, handleClose, handleSubmit, isLoading, isPublished } = props;

  return (
    <ModalDefault open={open} onCancel={handleClose} width={320} closable>
      <ModalDefault.Icon name="error" type="danger" />
      <ModalDefault.Title>
        {t(isPublished ? 'events_unpublish_accept' : 'events_publish_accept')}
      </ModalDefault.Title>
      <ModalDefault.Description>
        {t(
          isPublished ? 'events_unpublish_accept_description' : 'events_publish_accept_description',
        )}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button fullWidth leftIcon="bin" loading={isLoading} onClick={handleSubmit}>
          {t('common_confirm')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default Modal;
