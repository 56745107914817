import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetHandbooksQuery } from 'services/settlement/settlementApiService';
import { GetHandbooksPropsType } from 'services/settlement/settlementApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './guideHandbook.utils';

interface UseRequestHookProps {
  form: FormInstance;
}

const useRequestHook = (props: UseRequestHookProps) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);
  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const [getHandbooks, { data, isFetching, isLoading, isUninitialized }] =
    useLazyGetHandbooksQuery();

  useEffect(() => {
    if (debouncedRequestBody?.size) {
      const defaultBody: GetHandbooksPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        name: debouncedRequestBody.search,
      };

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        defaultBody.sort = [
          { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
        ];
      }
      getHandbooks(defaultBody);
    }
  }, [getHandbooks, debouncedRequestBody]);

  return {
    data,
    isFetching: isFetching || isDebouncing,
    isLoading: isLoading || isUninitialized,
  };
};

export default useRequestHook;
