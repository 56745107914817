import { t } from 'tools/i18n';

import { TIconsLiteral, TagType } from 'gazprom-ui-lib';

import { SURVEY_ACTIVE_ENUM, SURVEY_STATUSES_ENUM } from 'types/surveys.types';

export const getStatus = (
  status: SURVEY_STATUSES_ENUM | SURVEY_ACTIVE_ENUM | null,
): { type: TagType; label: string; icon: TIconsLiteral } | null => {
  switch (status) {
    case SURVEY_STATUSES_ENUM.FINISHED:
      return {
        type: 'success',
        label: t('surveys_column_appointed'),
        icon: 'ok',
      };
    case SURVEY_STATUSES_ENUM.IN_PROGRESS:
      return {
        type: 'warning',
        label: t('surveys_appointment_progress'),
        icon: 'loading',
      };
    case SURVEY_STATUSES_ENUM.READY:
      return {
        type: 'neutral',
        label: t('surveys_appointment_waiting'),
        icon: 'time',
      };
    case SURVEY_STATUSES_ENUM.CANCELED:
      return {
        type: 'danger',
        label: t('common_revoked'),
        icon: 'deleteFilled',
      };
    case SURVEY_ACTIVE_ENUM.ACTIVE:
      return {
        type: 'success',
        label: t('surveys_active'),
        icon: 'ok',
      };
    case SURVEY_ACTIVE_ENUM.NOT_ACTIVE:
      return {
        type: 'neutral',
        label: t('surveys_not_active'),
        icon: 'deleteFilled',
      };
    default:
      return null;
  }
};
