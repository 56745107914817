import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getHandbookWithIdRoute } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { HandbookType } from 'types/guide.types';

import Filters from './containers/filters';
import Header from './containers/header';
import { DEFAULT_FORM_STATE, FormResponseType } from './guideHandbook.utils';
import useColumns from './useColumns.hook';
import useRequest from './useRequest.hook';

const GuideHandbook = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<FormResponseType>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [showDeleteErrorModal, setShowDeleteErrorModal] = useState<boolean>(false);

  const { data, isFetching, isLoading } = useRequest({ form });

  const handleErrorModalOpenClose = () => {
    setShowDeleteErrorModal((prevState) => !prevState);
  };

  const columns = useColumns();

  const onRow = (row: HandbookType) => ({
    onClick: () => navigate(getHandbookWithIdRoute(row.id)),
  });

  const onRowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys: unknown[]) => {
      setSelectedRowKeys(selectedKeys as string[]);
    },
  };

  return (
    <>
      <WithOverflow>
        <Header />
        <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
          <Filters selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
          <FiltersV2.Table<HandbookType, FormResponseType>
            isInitialLoading={isLoading}
            columns={columns}
            showSorterTooltip={false}
            dataSource={data?.items}
            loading={isFetching}
            rowKey="id"
            onRow={onRow}
            rowSelection={onRowSelection}
            total={data?.totalCount}
          />
        </FiltersV2>
      </WithOverflow>
      <RemoveConfirm
        icon="info"
        type="warning"
        isOpen={showDeleteErrorModal}
        onCancel={handleErrorModalOpenClose}
        title={t('common_error')}
        subtitle={t('guide_handbook_delete_error')}
        onConfirm={handleErrorModalOpenClose}
        buttonText={t('common_close')}
        buttonProps={{ type: 'primary', ghost: true }}
      />
    </>
  );
};

export default GuideHandbook;
