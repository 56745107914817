import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { userManager } from 'utils/keycloak';

import { BACKEND_V1_BASE_URL } from 'constants/apiUrls';

const useDownloadPdf = () => {
  const params = useParams<{ eventId: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownload = async () => {
    setIsLoading(true);
    const user = await userManager.getUser();
    const token = user?.access_token as string;
    fetch(`${BACKEND_V1_BASE_URL}report/meeting-members`, {
      method: 'POST',
      body: JSON.stringify({ meetingId: params.eventId }),
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Set the appropriate content type
      },
    })
      .then(async (res) => {
        const contentDisposition =
          res.headers.get('Content-Disposition')?.split(/;(.+)/)[1]?.split(/=(.+)/)[1] || '';
        let filename = 'eventParticipants.xlsx';

        if (contentDisposition) {
          if (contentDisposition.toLowerCase().startsWith("utf-8''")) {
            filename = decodeURIComponent(filename.replace(/utf-8''/i, ''));
          } else filename = contentDisposition.replace(/['"]/g, '');
        }

        const blob = await res.blob();

        return {
          blob,
          filename,
        };
      })
      .then((res) => {
        const blobUrl = URL.createObjectURL(res.blob);
        const a = document.createElement('a');

        a.href = blobUrl;
        a.download = res.filename;
        a.click();

        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error('Request failed:', error);
        throw error;
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    handleDownload,
  };
};

export default useDownloadPdf;
