import { createApi } from '@reduxjs/toolkit/query/react';

import { SERVICE_API_V2_TAGS_LIST } from 'constants/serviceTags';

import { BASE_QUERY_WITH_AUTH_V2 } from './utils';

export const API_NAME = 'initialApiV2';

const initialApiServiceV2 = createApi({
  reducerPath: API_NAME,
  baseQuery: BASE_QUERY_WITH_AUTH_V2,
  endpoints: () => ({}),
  tagTypes: SERVICE_API_V2_TAGS_LIST,
  keepUnusedDataFor: 0,
});

export default initialApiServiceV2;
