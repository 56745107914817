import { useEffect } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { useGetEventByIdQuery } from 'services/meet/meetApiService';

import { FormInstance } from 'gazprom-ui-lib';

import { EVENT_FORM_ENUM } from 'containers/event-form/eventForm.utils';

import { EventGroupTreeType } from 'types/event.types';

interface UseSetInitialValues {
  form: FormInstance;
}

const useGetAndSetInitialValues = (props: UseSetInitialValues) => {
  const { form } = props;

  const params = useParams<{ eventId: string }>();

  const { data, isLoading, isFetching } = useGetEventByIdQuery(params.eventId!);

  useEffect(() => {
    if (data) {
      const { isNotify, gender, bodyNotice, titleNotice, group } = data.meeting;
      const { checked, partialChecked, treeData } = group;

      const checkedTargetGroup = checked.map(findObjectById(treeData));
      const halfCheckedTargetGroup = partialChecked.map(findObjectById(treeData));

      form.setFieldsValue({
        [EVENT_FORM_ENUM.TITLE]: data.article.title,
        [EVENT_FORM_ENUM.DESCRIPTION]: data.article.text,
        [EVENT_FORM_ENUM.COVER]: data.article.cover?.link,
        [EVENT_FORM_ENUM.CATEGORIES]: data.article.categories.length
          ? data.article.categories.map(getIdFromObject)
          : [],

        [EVENT_FORM_ENUM.CITY]: data.meeting.city,
        [EVENT_FORM_ENUM.LOCATION]: data.meeting.location,
        [EVENT_FORM_ENUM.REGISTRATION_AVAILABLE]: data.meeting.registrationActive,
        [EVENT_FORM_ENUM.DOCS]: data.meeting.requiresDocs,
        [EVENT_FORM_ENUM.PARTICIPANTS]: data.meeting.maxEmployees.toString() || undefined,
        [EVENT_FORM_ENUM.UNLIMITED_PARTICIPATION]: data.meeting.maxEmployees === 0,
        [EVENT_FORM_ENUM.EVENT_DATE]: dayjs(data.meeting.meetDate),
        [EVENT_FORM_ENUM.EVENT_TIME]: dayjs(data.meeting.meetDate),
        [EVENT_FORM_ENUM.TARGET_GROUP_GENDER]: gender,
        [EVENT_FORM_ENUM.NOTIFICATION_TITLE]: titleNotice,
        [EVENT_FORM_ENUM.NOTIFICATION_TEXT]: bodyNotice,
        [EVENT_FORM_ENUM.SEND_NOTIFICATION]: isNotify,
        [EVENT_FORM_ENUM.INITIAL_TARGET_TREE]: treeData,
        [EVENT_FORM_ENUM.TARGET_GROUP]: {
          checked: checkedTargetGroup,
          halfChecked: halfCheckedTargetGroup,
        },
      });
    }
  }, [data, form]);

  return { data, isLoading, isFetching };
};

const findObjectById =
  (array: null | EventGroupTreeType[]) =>
  (id: string): string | null => {
    if (array) {
      for (let i = 0; i < array.length; i++) {
        const obj = array[i];
        if (obj.id === id) {
          return `${obj.id}.${obj.type}`;
        }

        if (obj.children && obj.children.length > 0) {
          const found: string | null = findObjectById(obj.children)(id);
          if (found) {
            return found;
          }
        }
      }
    }
    return null;
  };

const getIdFromObject = ({ id }: { id: string }) => id;
export default useGetAndSetInitialValues;
