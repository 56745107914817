import SocialProgram from 'pages/social-program';
import SocialProgramAdd from 'pages/social-program/social-program-add';
import SocialProgramRequests from 'pages/social-program/social-program-requests';
import SocialProgramRequestsDetails from 'pages/social-program/social-program-requests/social-program-requests-details';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import {
  SOCIAL_PROGRAM_ADD_ROUTE,
  SOCIAL_PROGRAM_ADD_ROUTE_ID,
  SOCIAL_PROGRAM_PROGRAMS_ROUTE,
  SOCIAL_PROGRAM_REQUESTS_ROUTE,
  SOCIAL_PROGRAM_REQUEST_DETAILS_ROUTE,
} from './list';

export const socialProgramRoutes: RouteConfig[] = [
  {
    path: SOCIAL_PROGRAM_PROGRAMS_ROUTE,
    element: <SocialProgram />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: SOCIAL_PROGRAM_REQUESTS_ROUTE,
    element: <SocialProgramRequests />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: SOCIAL_PROGRAM_REQUEST_DETAILS_ROUTE,
    element: <SocialProgramRequestsDetails />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: SOCIAL_PROGRAM_ADD_ROUTE,
    element: <SocialProgramAdd />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: SOCIAL_PROGRAM_ADD_ROUTE_ID,
    element: <SocialProgramAdd />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
];
