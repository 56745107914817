import { useState } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';
import { sortDocumentsFiles } from 'utils/files.utils';

import { useGetTaskByIdQuery } from 'services/1c/cApiService';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import FileDownload from 'pages/documents/containers/file-download';
import FileInfo from 'pages/documents/containers/file-info';
import { renderSign } from 'pages/documents/documents.utils';

import { FileType } from 'types/file.types';

import s from './DocumentsViewFiles.module.scss';
import { archiveName, findKey, getArchiveUrl, getFileUrl } from './documentsViewFiles.utils';

const DocumentsViewFiles = () => {
  const { id } = useParams();
  const { data } = useGetTaskByIdQuery(id!);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  if (!data?.files.length) {
    return null;
  }

  const archiveUrl = getArchiveUrl(id!);

  const renderFile = (file: FileType) => {
    const { fileKey, fileName, signs, type, dateDoc, numberDocPrint } = file;
    const isSigned = !!signs?.length;
    const isOrderType = type === 'ORDER';

    const fileDownloadUrl = getFileUrl(id!, fileKey);
    const chiefSign = isOrderType && signs.find(findKey(data?.employee.person.id ?? ''));

    const addSpace = isOrderType && chiefSign;

    const renderSigns = () => {
      const signerId = data?.signer.id ?? '';

      if (isOrderType) {
        return signs?.filter(({ key }) => key.includes(signerId))?.map(renderSign);
      }

      return signs?.map(renderSign);
    };

    return (
      <Flex
        key={fileKey}
        justify="space-between"
        align="center"
        gap="12"
        className={cn(s.download, { [s.signed]: isSigned, [s.space]: addSpace })}>
        <Flex align="center" gap="12">
          <Icon name="image" className={s.icon} />
          <Flex vertical gap="4" className={s.fileDescription}>
            <Typography.Text weight="500" size="14" type={isSigned ? 'success' : 'primary'}>
              {fileName}
            </Typography.Text>
            <FileInfo dateDoc={dateDoc} numberDocPrint={numberDocPrint} isOrderType={isOrderType} />
            {renderSigns()}
          </Flex>
        </Flex>
        <FileDownload isSigned={isSigned} fileName={fileName} fileDownloadUrl={fileDownloadUrl} />
      </Flex>
    );
  };

  const handleFileDownload = async () => {
    setIsDownloading(true);
    downloadHandler({ url: archiveUrl, fileName: archiveName }).finally(() => {
      setIsDownloading(false);
    });
  };

  return (
    <div className={s.fileWrapper}>
      {[...data.files].sort(sortDocumentsFiles).map(renderFile)}
      <Button
        loading={isDownloading}
        onClick={handleFileDownload}
        leftIcon="download"
        className={s.archiveButton}>
        {t('common_download_archive')}
      </Button>
    </div>
  );
};

export default DocumentsViewFiles;
