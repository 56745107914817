export const NOTIFICATION_ROUTE = '/notification';

export const NOTIFICATION_MAILING_MANAGEMENT_ROUTE = `${NOTIFICATION_ROUTE}/management`;
export const NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE = `${NOTIFICATION_ROUTE}/system-notifications`;

export const NOTIFICATION_NEWSLETTER_CREATE_ROUTE = `${NOTIFICATION_MAILING_MANAGEMENT_ROUTE}/create`;
export const NOTIFICATION_NEWSLETTER_CREATE_WITH_TEMPLATE_ROUTE = `${NOTIFICATION_NEWSLETTER_CREATE_ROUTE}/:templateId`;

export const getNewsletterCreateWithTemplateRoute = (templateId: string): string =>
  `${NOTIFICATION_NEWSLETTER_CREATE_ROUTE}/${templateId}`;

export const NOTIFICATION_TEMPLATE_ROUTE = `${NOTIFICATION_MAILING_MANAGEMENT_ROUTE}/template`;

export const NOTIFICATION_TEMPLATE_EDIT_ROUTE = `${NOTIFICATION_TEMPLATE_ROUTE}/:templateId`;
export const getNotificationTemplateWithIdRoute = (templateId: string): string =>
  `${NOTIFICATION_TEMPLATE_ROUTE}/${templateId}`;

export const NOTIFICATION_NEWSLETTER_EDIT_ROUTE = `${NOTIFICATION_MAILING_MANAGEMENT_ROUTE}/:newsletterId`;
export const getNotificationNewsletterWithIdRoute = (newsletterId: string): string =>
  `${NOTIFICATION_MAILING_MANAGEMENT_ROUTE}/${newsletterId}`;

export const NOTIFICATION_SYSTEM_NEWSLETTER_EDIT_ROUTE = `${NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE}/:newsletterId`;
export const getSystemNotificationNewsletterWithIdRoute = (newsletterId: string): string =>
  `${NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE}/${newsletterId}`;
