import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useUpdateHandbookMutation } from 'services/settlement/settlementApiService';
import { UpdateHandbookPropsType } from 'services/settlement/settlementApiService.types';

import { FormInstance } from 'gazprom-ui-lib';

import {
  HANDBOOK_BASE_FORM_ENUM,
  HandbookBaseFormResponse,
} from 'containers/handbook-base-form/handbookBaseForm.utils';

import { HandbookType } from 'types/guide.types';

interface Props {
  form: FormInstance;
  handbook?: HandbookType;
  handleModalClose: () => void;
}

const useActions = (props: Props) => {
  const { form, handbook, handleModalClose } = props;

  const [updateHandbook, { isLoading: updateHandbookIsLoading }] = useUpdateHandbookMutation();

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });
  const handleBaseFormSubmit = (isPublish?: boolean) => (form: HandbookBaseFormResponse) => {
    if (handbook) {
      const [x, y] = form[HANDBOOK_BASE_FORM_ENUM.COORDINATES].split(',');

      const requestBody: UpdateHandbookPropsType = {
        id: handbook.id,
        name: form[HANDBOOK_BASE_FORM_ENUM.NAME],
        isPublish: !!form[HANDBOOK_BASE_FORM_ENUM.PUBLISH],
        telegramId: form[HANDBOOK_BASE_FORM_ENUM.TG_CHANEL],
        coordinates: {
          x,
          y,
        },
      };

      if (typeof isPublish === 'boolean') {
        requestBody.isPublish = isPublish;
      }

      if (form[HANDBOOK_BASE_FORM_ENUM.MAP]) {
        requestBody.fileMap = form[HANDBOOK_BASE_FORM_ENUM.MAP];
      }

      if (form[HANDBOOK_BASE_FORM_ENUM.MEMO]) {
        requestBody.fileInstruction = form[HANDBOOK_BASE_FORM_ENUM.MEMO];
      }

      updateHandbook(requestBody).then(handleUpdate);
    }
  };

  const handleSaveAndPublish = () => {
    form.validateFields().then(handleBaseFormSubmit(true));
  };

  const handleUnpublish = () => {
    if (handbook) {
      const { id, name, telegramId, coordinates, fileMap, fileInstruction } = handbook;
      const requestBody: UpdateHandbookPropsType = {
        id,
        name,
        isPublish: false,
        coordinates: {
          x: coordinates.x.toString(),
          y: coordinates.y.toString(),
        },
      };

      if (telegramId) {
        requestBody.telegramId = telegramId;
      }

      if (fileMap) {
        requestBody.fileMap = fileMap;
      }

      if (fileInstruction) {
        requestBody.fileInstruction = fileInstruction;
      }

      updateHandbook(requestBody).then(handleUpdate).finally(handleModalClose);
    }
  };

  return {
    handleBaseFormSubmit,
    handleSaveAndPublish,
    handleUnpublish,
    updateHandbookIsLoading,
  };
};

export default useActions;
