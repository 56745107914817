import { ReactElement, useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_SINGS_ROUTE } from 'routes/documents/list';
import { SIGN_ROUTE } from 'routes/sign/list';
import { t } from 'tools/i18n';
import { Trans } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';
import { getOrderFile } from 'utils/files.utils';

import { useGetTaskAssignmentByIdQuery } from 'services/1c/cApiService';

import { Button, Flex, Icon, StepsProps, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import ContentHeader from 'containers/content-header';
import DocumentView from 'containers/document-view';
import { TabsTypes } from 'containers/document-view/containers/tabs/tabs.utils';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import ApprovalHistory from 'pages/documents/documents-business-trips/documents-business-trips-details/containers/approval-history';
import Files from 'pages/documents/documents-business-trips/documents-business-trips-details/containers/files';
import Leaders from 'pages/documents/documents-business-trips/documents-business-trips-details/containers/leaders';
import { getComponentsParams } from 'pages/documents/documents-view/documents-view-files/documentsViewFiles.utils';
import { renderSign } from 'pages/documents/documents.utils';

import { CurrentKedoStatusType } from 'types/documents.types';
import { FileType } from 'types/file.types';
import { TASK_ASSIGNMENT_TYPE_ENUM } from 'types/taskAssignment.types';

import s from './DocumentsSignsDetails.module.scss';
import DocumentsSignsViewRejectModal from './documents-signs-view-reject-modal';
import DocumentsViewPerson from './documents-view-person';
import { ORDER_FILE_TYPES, getFileUrl } from './documentsSignsDetails.utils';

const DocumentsSignsDetails = () => {
  const { id, executorId } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();

  const [rejectModalVisible, setRejectModalVisible] = useState(false);

  const {
    data: taskData,
    isLoading,
    isError,
  } = useGetTaskAssignmentByIdQuery({
    id: id!,
    executorId: executorId!,
  });

  const description = (
    <>
      {`${t('common_created')}: `}
      <Flex gap="4" align="center">
        <Icon name="calendar" />
        <Typography.Text type="secondary">
          {dayjs.utc(taskData?.createdAt).local().format('DD MMMM YYYY, HH:mm')}
        </Typography.Text>
      </Flex>
    </>
  );

  // Натягивание сов на глобус
  const renderFile = (file: FileType | null | undefined) => {
    if (!file) {
      return null;
    }

    const { fileKey, fileName, signs, type, dateDoc, numberDocPrint } = file;
    const isSigned = !!signs?.length;
    const isOrderType = ORDER_FILE_TYPES.includes(type);
    const isDocNumAndDate = isOrderType && numberDocPrint && dateDoc;
    const fileDownloadUrl =
      getFileUrl(taskData?.id ?? '', fileKey) + (isSigned ? '&full=true' : '');

    const renderSigns = () => {
      const signerId = taskData?.executor.id ?? '';

      if (isOrderType) {
        return signs?.filter(({ key }) => key.includes(signerId))?.map(renderSign);
      }

      return signs?.map(renderSign);
    };

    const handleNavigate = () => {
      if (fileKey)
        navigate(SIGN_ROUTE, {
          state: {
            fileKey,
            assignmentId: taskData?.id,
            executorId: taskData?.executor.id,
          },
        });
    };

    const handleReject = () => {
      setRejectModalVisible(true);
    };

    console.log(signs, auth?.user?.profile);

    const signed = !!signs.find((sign) => sign.signerPersonId === auth.user?.profile.personId);

    const isRefuseUnavailable = taskData?.type === TASK_ASSIGNMENT_TYPE_ENUM.SIGN_HEAD_NO_REFUSE;

    const personProps = getComponentsParams({
      rejected: taskData?.status.code === 'REJECTED' || taskData?.status.code === 'REVISION',
      signed,
      onClick: handleNavigate,
      onReject: !isRefuseUnavailable ? handleReject : undefined,
    });

    return (
      <div key={fileKey}>
        <div className={cn(s.download, { [s.signed]: isSigned })}>
          <div>
            <Icon name="image" className={s.icon} />
            <div className={s.fileDescription}>
              <Typography.Text weight="500" size="14" type={isSigned ? 'success' : 'primary'}>
                {fileName}
              </Typography.Text>
              {isDocNumAndDate && (
                <div>
                  <Trans
                    i18nKey="sign_document_number"
                    values={{
                      docNum: numberDocPrint,
                      date: dayjs(dateDoc).local().format('DD.MM.YYYY'),
                    }}>
                    <Typography.Text size="12" type="secondary" />
                    <Typography.Text size="12" type="secondary" />
                  </Trans>
                </div>
              )}
              {renderSigns()}
            </div>
          </div>
          <div>
            {isSigned && <Icon name="okFilled" color="var(--color-success)" />}
            <button
              className={s.downloadButton}
              onClick={() =>
                downloadHandler({ url: fileDownloadUrl, fileName, headersFileNameKey: 'filename' })
              }>
              <Icon name="download" color="var(--color-primary-6)" />
            </button>
          </div>
        </div>
        {isOrderType && (
          <div className={s.personsContainer}>
            <DocumentsViewPerson
              firstName={taskData?.executor?.person.firstName ?? ''}
              lastName={taskData?.executor?.person.lastName ?? ''}
              {...personProps}
            />
          </div>
        )}
      </div>
    );
  };
  const currentKedoStatusImitation = {
    comment: null,
    createdAt: null,
    description: taskData?.status?.description,
    state: taskData?.status?.state,
    status: taskData?.status?.code,
    title: taskData?.status?.name,
    titleAlt: null,
  } as CurrentKedoStatusType;

  const handleClose = () => {
    navigate(DOCUMENTS_SINGS_ROUTE);
  };

  const handleModalClose = () => setRejectModalVisible(false);
  const orderNumber = getOrderFile(taskData?.workTour?.files)?.numberDoc ?? '';

  const tabs: { key: TabsTypes; label: string; children: ReactElement }[] = [
    {
      key: 'attached',
      label: t('common_attached_files'),
      children: <Files files={taskData?.workTour?.files} id={taskData?.workTour?.id} />,
    },
    {
      key: 'additional',
      label: t('common_process_participants'),
      children: (
        <Leaders
          firstLeader={taskData?.workTour?.firstLeader}
          approvedLeader={taskData?.workTour?.approvedLeader}
        />
      ),
    },
    {
      key: 'approval-history',
      label: t('documents_statements_approval_history'),
      children: (
        <ApprovalHistory
          workTourId={taskData?.workTour?.id}
          approvalMap={taskData?.workTour?.approvalMap}
          orderDate={taskData?.workTour?.createdAt}
          numberDoc={orderNumber}
        />
      ),
    },
  ];

  const showTaskHistory = taskData?.kedoTask && !taskData?.workTour;
  const taskHistory =
    showTaskHistory && (taskData?.kedoTask?.history ?? taskData?.workTour?.history);

  return (
    <WithOverflow>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title goBackLink={-1} description={description}>
          {t('common_view_document')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <KedoStatusTag currentKedoStatus={currentKedoStatusImitation} size="30" />
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={isLoading}>
        <DocumentView isError={isError}>
          <DocumentView.Content>
            <Flex vertical gap="24">
              <DocumentView.Employee employee={taskData?.employee} />
              {/* Да, говнокод, но мы это переделаем как ассайменты */}
              {taskData?.workTour ? (
                <>
                  <Flex vertical gap="8">
                    <Typography.Title level={5}>
                      {t('documents_business_trip_period')}
                    </Typography.Title>
                    <Typography.Text size="14" type="secondary">
                      {`${dayjs(taskData?.workTour?.dateFrom).format('YYYY.MM.DD')} - ${dayjs(
                        taskData?.workTour?.dateTo,
                      ).format('YYYY.MM.DD')}`}
                    </Typography.Text>
                  </Flex>
                  <Flex vertical gap="8">
                    <Typography.Title level={5}>{t('documents_destination')}</Typography.Title>
                    <Typography.Text size="14" type="secondary">
                      {taskData?.workTour?.destination}
                    </Typography.Text>
                  </Flex>
                  <Flex vertical gap="8">
                    <Typography.Title level={5}>{t('documents_target')}</Typography.Title>
                    <Typography.Text size="14" type="secondary">
                      {taskData?.workTour?.target}
                    </Typography.Text>
                  </Flex>
                  {taskData?.workTour?.targetComment && (
                    <Flex vertical gap="8">
                      <Typography.Title level={5}>{t('common_clarification')}</Typography.Title>
                      <Typography.Text size="14" type="secondary">
                        {taskData?.workTour?.targetComment}
                      </Typography.Text>
                    </Flex>
                  )}
                  <Flex vertical gap="8">
                    <Typography.Title level={5}>
                      {t('documents_source_of_finance')}
                    </Typography.Title>
                    <Typography.Text size="14" type="secondary">
                      {taskData?.workTour?.sourceFinancing}
                    </Typography.Text>
                  </Flex>
                </>
              ) : (
                <Flex vertical gap="8">
                  <Typography.Title level={5}>{t('common_document')}</Typography.Title>
                  <Typography.Text size="14" type="secondary">
                    {taskData?.title}
                  </Typography.Text>
                </Flex>
              )}
              <div className={s.fileWrapper}>{taskData?.files?.map(renderFile)}</div>
            </Flex>
            {showTaskHistory && (
              <DocumentView.History historyItems={taskHistory as StepsProps['items']} />
            )}
          </DocumentView.Content>
          {taskData?.workTour && <DocumentView.Tabs items={tabs} className={s.tabs} />}
          <Button type="secondary" className={s.closeButton} onClick={handleClose}>
            {t('common_close')}
          </Button>
        </DocumentView>
        <DocumentsSignsViewRejectModal
          type={taskData?.type}
          open={rejectModalVisible}
          taskId={id}
          employeeId={executorId}
          onCancel={handleModalClose}
        />
      </WithLoader>
    </WithOverflow>
  );
};

export default DocumentsSignsDetails;
