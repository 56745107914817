import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useLazyGetSocialRequestById1cQuery } from 'services/1c/cApiService';

import { Flex, Steps } from 'gazprom-ui-lib';

import WithOverflow from 'containers/wrappers/with-overflow';

import s from './SocialProgramRequestsDetails.module.scss';
import Actions from './containers/actions';
import Content from './containers/content';
import Header from './containers/header';

const SocialProgramRequestsDetails = () => {
  const params = useParams<{ id: string }>();

  const [getRequest, { data: socialRequestData }] = useLazyGetSocialRequestById1cQuery();

  useEffect(() => {
    if (params.id) {
      getRequest(params.id);
    }
  }, [getRequest, params.id]);

  return (
    <WithOverflow>
      <Header socialRequestData={socialRequestData} />
      <Flex vertical className={s.overflow}>
        <div className={s.content}>
          <Content socialRequestData={socialRequestData} />
          <Steps items={socialRequestData?.history ?? []} />
        </div>
        <Actions socialRequestData={socialRequestData} />
      </Flex>
    </WithOverflow>
  );
};

export default SocialProgramRequestsDetails;
