import { Key } from 'react';

import { DataNode } from 'gazprom-ui-lib';

export const treeNodeUtils = (tree: DataNode[], id: string): string => {
  for (const node of tree) {
    if ((node.key as string).includes(id)) {
      return node.key as string;
    }
    if (node.children && node.children?.length > 0) {
      const result = treeNodeUtils(node.children, id);
      if (result) {
        return result;
      }
    }
  }

  return id;
};

export const updateNode = (nodes: DataNode[], key: Key, children: DataNode[]): DataNode[] => {
  const modifyNodes = (node: DataNode) => {
    if (node.key === key) {
      return { ...node, children };
    } else if (node.children) {
      return { ...node, children: updateNode(node.children, key, children) };
    }
    return node;
  };

  return nodes.map(modifyNodes);
};
