import { useNavigate } from 'react-router-dom';
import { getSocialProgramRequestWithIdRoute } from 'routes/social-program/list';

import { GetSocialRequests1cResponseType } from 'services/1c/cApiService.types';

import FiltersV2 from 'containers/filters-v2';

import { SocialProgramRequestType } from 'types/socialProgram.types';

import { FormResponseType } from '../../socialProgramRequests.utils';
import s from './RequestsTable.module.scss';
import useColumns from './useColumns.hook';

interface Props {
  data?: GetSocialRequests1cResponseType;
  loading: boolean;
  isInitialLoading: boolean;
}

const RequestsTable = (props: Props) => {
  const { data, loading, isInitialLoading } = props;

  const navigate = useNavigate();

  const columns = useColumns();

  const onRow = (request: SocialProgramRequestType) => ({
    onClick: () => navigate(getSocialProgramRequestWithIdRoute(request.id)),
  });

  return (
    <FiltersV2.Table<SocialProgramRequestType, FormResponseType>
      isInitialLoading={isInitialLoading}
      className={s.wrapper}
      dataSource={data?.items}
      columns={columns}
      rowKey="id"
      onRow={onRow}
      total={data?.totalCount}
      loading={loading}
    />
  );
};

export default RequestsTable;
