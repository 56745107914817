import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { CEmployeeType } from 'services/1c/cApiService.types';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import { EmployeeType } from 'types/employee.types';

const useColumns = (): ColumnsType<EmployeeType | CEmployeeType> => {
  return useMemo(
    () => [
      {
        title: t('employee_group_column_fullname'),
        dataIndex: 'person',
        render: ({
          firstName,
          lastName,
          patronymic,
        }: {
          firstName: string;
          lastName: string;
          patronymic: string;
        }) => (
          <Typography.Text size="12" weight="500">
            {lastName} {firstName} {patronymic}
          </Typography.Text>
        ),
      },
      {
        title: t('common_staffName'),
        dataIndex: 'position',
        render: ({ name }: { name: string }) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {name}
          </Typography.Text>
        ),
      },
      {
        title: t('common_department'),
        dataIndex: 'division',
        render: ({ name }: { name: string }) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {name}
          </Typography.Text>
        ),
      },
      {
        title: t('common_organization'),
        dataIndex: 'employer',
        render: ({ name }: { name: string }) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {name}
          </Typography.Text>
        ),
      },
    ],
    [],
  );
};

export default useColumns;
