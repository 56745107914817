export enum HANDBOOK_INSTRUCTION_ENUM {
  TITLE = 'TITLE',
  OPERATION_MODE = 'OPERATION_MODE',
  LOCATION = 'LOCATION',
  SERVICE = 'SERVICE',
  PHONE = 'PHONE',
  PHONE_OUTER = 'PHONE_OUTER',
  PHONE_INNER = 'PHONE_INNER',
  EMAIL = 'EMAIL',
  FILE = 'FILE',
  FULL_INFO = 'FULL_INFO',
  URL = 'URL',
}

export type HandbookInstructionResponseType = {
  [HANDBOOK_INSTRUCTION_ENUM.TITLE]: string;
  [HANDBOOK_INSTRUCTION_ENUM.OPERATION_MODE]: string;
  [HANDBOOK_INSTRUCTION_ENUM.LOCATION]: string;
  [HANDBOOK_INSTRUCTION_ENUM.SERVICE]: string;
  [HANDBOOK_INSTRUCTION_ENUM.PHONE]: {
    [HANDBOOK_INSTRUCTION_ENUM.PHONE_OUTER]?: string;
    [HANDBOOK_INSTRUCTION_ENUM.PHONE_INNER]?: string;
  }[];
  [HANDBOOK_INSTRUCTION_ENUM.EMAIL]?: string;
  [HANDBOOK_INSTRUCTION_ENUM.FILE]?: {
    link: string;
    name: string;
    size: number;
  };
};

export const createPhoneOption = (phone: { phone?: string; internalPhone?: string }) => ({
  [HANDBOOK_INSTRUCTION_ENUM.PHONE_OUTER]: phone.phone,
  [HANDBOOK_INSTRUCTION_ENUM.PHONE_INNER]: phone.internalPhone,
});
