import { useAuth } from 'react-oidc-context';
import { t } from 'tools/i18n';
import { isTokenValid } from 'utils/tokenValidation.utils';
import { PIN_CODE_TOKEN } from 'utils/usePinCodeAuthorize.hook';

import { Button, Card, Typography } from 'gazprom-ui-lib';

import line from 'assets/icons/line-3.svg';
import logo from 'assets/logo/logo-short-name-row-blue.svg';

import s from './Login.module.scss';

const Login = () => {
  const { signinRedirect } = useAuth();

  const onClickHandler = () => {
    const pinCodeToken = localStorage.getItem(PIN_CODE_TOKEN);
    const pinCodeTokenValid = isTokenValid(pinCodeToken);

    if (pinCodeToken && pinCodeTokenValid) {
      signinRedirect({
        extraQueryParams: { pinCodeToken },
      });
    } else {
      signinRedirect();
    }
  };

  return (
    <div className={s.container}>
      <img src={line} alt="" className={s.line} />
      <Card className={s.card}>
        <img src={logo} alt="" />
        <Typography.Title level={3}>{t('login_text')}</Typography.Title>
        <Button leftIcon="logout" onClick={onClickHandler} fullWidth>
          {t('login_log_in')}
        </Button>
      </Card>
    </div>
  );
};

export default Login;
