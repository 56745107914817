import { FC } from 'react';

import { useParams } from 'react-router-dom';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import Header from './containers/header';
import Table from './containers/table';
import { DEFAULT_FORM_STATE, FormResponseType } from './settingsPositionDirectoryDetails.utils';
import useRequest from './useRequest.hook';

const SettingsPositionDirectoryDetails: FC = () => {
  const [form] = Form.useForm<FormResponseType>();

  const { employerId } = useParams<{ employerId: string }>();

  const { employerPositionsDetails, isLoading, isFetching } = useRequest({ employerId, form });

  const { employerName, totalCount } = employerPositionsDetails ?? {};

  return (
    <WithOverflow>
      <Header employerName={employerName} totalCount={totalCount} />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters employerId={employerId} />
        <Table
          dataSource={employerPositionsDetails}
          loading={isFetching}
          isInitialLoading={isLoading}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default SettingsPositionDirectoryDetails;
