import { useEffect } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import { SETTINGS_DECLARATIONS_SOCIAL_ROUTE } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { useGetDeclarationTypeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Form } from 'gazprom-ui-lib';

import DocumentsDeclarationsFormCategory from 'containers/documents-declarations-form/documents-declarations-form-category';
import DocumentsDeclarationsFormCheck from 'containers/documents-declarations-form/documents-declarations-form-check';
import DocumentsDeclarationsFormDefaultExecutor from 'containers/documents-declarations-form/documents-declarations-form-default-executor';
import DocumentsDeclarationsFormDescription from 'containers/documents-declarations-form/documents-declarations-form-description';
import DocumentsDeclarationsFormName from 'containers/documents-declarations-form/documents-declarations-form-name';
import { DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import DocumentsDeclarationsButtons from 'pages/documents/documents-declarations/documents-declarations-buttons';
import useCommonFormSubmitHandler from 'pages/documents/documents-declarations/useCommonFormSubmitHandler.hook';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import s from './Common.module.scss';
import { DOCUMENTS_DECLARATIONS_SOCIAL_COMMON_FORM_NAME } from './common.utils';

const Common = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const { data, isLoading } = useGetDeclarationTypeQuery(id ?? skipToken);

  useEffect(() => {
    if (data && form) {
      form.setFieldValue(DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ACCESS, data.isPublish);
    }
  }, [data, form]);

  const { isFetching, handleFormSubmit } = useCommonFormSubmitHandler({
    createRoute: SETTINGS_DECLARATIONS_SOCIAL_ROUTE,
    type: data?.type || KEDO_TYPE_ENUM.SOCIAL,
  });

  return (
    <WithLoader isLoading={isLoading}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        id={DOCUMENTS_DECLARATIONS_SOCIAL_COMMON_FORM_NAME}>
        <div className={s.container}>
          <DocumentsDeclarationsFormName defaultValue={data?.name} />
          <DocumentsDeclarationsFormCategory
            defaultValue={data?.categoryId}
            type={KEDO_TYPE_ENUM.SOCIAL}
          />
          <DocumentsDeclarationsFormDescription defaultValue={data?.description} />
          <DocumentsDeclarationsFormCheck
            defaultValue={data?.hasCommission}
            formName={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.COMMISSION_APPROVAL}
            title={t('documents_declarations_social_programs_comission_approve')}
            label={t('documents_declarations_social_programs_comission_approve_required')}
          />
          <DocumentsDeclarationsFormDefaultExecutor
            defaultValue={data?.defaultExecutor}
            formName={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DEFAULT_EXECUTOR}
            title={t('documents_declarations_social_programs_default_executor')}
          />
          <DocumentsDeclarationsFormCheck
            defaultValue={false}
            className={s.hidden}
            formName={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ACCESS}
            label=""
          />
        </div>
        <DocumentsDeclarationsButtons isFetching={isFetching} />
      </Form>
    </WithLoader>
  );
};

export default Common;
