export const SETTINGS_ROUTE = '/settings';

export const SETTINGS_DECLARATIONS_ROUTE = `${SETTINGS_ROUTE}/declarations`;
export const SETTINGS_DECLARATIONS_EDO_ROUTE = `${SETTINGS_DECLARATIONS_ROUTE}/edo`;
export const SETTINGS_DECLARATIONS_KEDO_ROUTE = `${SETTINGS_DECLARATIONS_ROUTE}/kedo`;
export const SETTINGS_DECLARATIONS_SOCIAL_ROUTE = `${SETTINGS_DECLARATIONS_ROUTE}/social`;
export const SETTINGS_DECLARATIONS_EDO_BY_ID_ROUTE = `${SETTINGS_DECLARATIONS_EDO_ROUTE}/:id`;
export const SETTINGS_DECLARATIONS_KEDO_BY_ID_ROUTE = `${SETTINGS_DECLARATIONS_KEDO_ROUTE}/:id`;
export const SETTINGS_DECLARATIONS_SOCIAL_BY_ID_ROUTE = `${SETTINGS_DECLARATIONS_SOCIAL_ROUTE}/:id`;

export const getSettingsDeclarationsEdoEditRoute = (id: string) =>
  `${SETTINGS_DECLARATIONS_EDO_ROUTE}/${id}`;
export const getSettingsDeclarationsKedoEditRoute = (id: string) =>
  `${SETTINGS_DECLARATIONS_KEDO_ROUTE}/${id}`;
export const getSettingsDeclarationsSocialEditRoute = (id: string) =>
  `${SETTINGS_DECLARATIONS_SOCIAL_ROUTE}/${id}`;

export const SETTINGS_1C_INSTANCES_ROUTE = `${SETTINGS_ROUTE}/1c-instances`;

export const SETTINGS_1C_INSTANCES_CREATE_ROUTE = `${SETTINGS_1C_INSTANCES_ROUTE}/create`;
export const SETTINGS_1C_INSTANCES_DETAILS_ROUTE = `${SETTINGS_1C_INSTANCES_ROUTE}/:id`;

export const SETTINGS_TELEGRAM_CHANNELS_ROUTE = `${SETTINGS_ROUTE}/telegram-channels`;

export const SETTINGS_TELEGRAM_CHANNEL_ADD_ROUTE = `${SETTINGS_ROUTE}/telegram-channels/create`;

export const SETTINGS_TELEGRAM_CHANNEL_DETAILS_ROUTE = `${SETTINGS_ROUTE}/telegram-channels/:id`;

export const getTelegramChannelWithIdRoute = (id: string) =>
  `${SETTINGS_ROUTE}/telegram-channels/${id}`;

export const get1CInstanceWithIdRoute = (id: string) => `${SETTINGS_1C_INSTANCES_ROUTE}/${id}`;

export const SETTINGS_LNA_EMPLOYMENT_ROUTE = `${SETTINGS_ROUTE}/lna-employment`;

export const SETTINGS_LNA_EMPLOYMENT_INSTANCE_ROUTE = `${SETTINGS_LNA_EMPLOYMENT_ROUTE}/:instanceId`;

export const SETTINGS_LNA_EMPLOYMENT_DETAILS_ROUTE = `${SETTINGS_LNA_EMPLOYMENT_INSTANCE_ROUTE}/lna/:id`;

export const get1CInstanceLnaWithIdRoute = (instanceId: string) =>
  `${SETTINGS_LNA_EMPLOYMENT_ROUTE}/${instanceId}`;

export const get1CInstanceLnaDetailsWithIdRoute = (instanceId: string, id: string) =>
  `${get1CInstanceLnaWithIdRoute(instanceId)}/lna/${id}`;

export const SETTINGS_POSITION_DIRECTORY_ROUTE = `${SETTINGS_ROUTE}/top-management`;
export const SETTINGS_POSITION_DIRECTORY_DETAILS_ROUTE = `${SETTINGS_POSITION_DIRECTORY_ROUTE}/:employerId`;

export const getSettingsPositionDirectoryDetailsRoute = (employerId: string) =>
  `${SETTINGS_POSITION_DIRECTORY_ROUTE}/${employerId}`;
