import { Dispatch, SetStateAction } from 'react';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCollapse from 'containers/filters/filter-collapse';

import Actions from '../actions';
import useFilterItems from './useFilterItems.hook';

interface Props {
  selectedEvents: string[];
  setSelectedEvents: Dispatch<SetStateAction<string[]>>;
}

const Filters = (props: Props) => {
  const { selectedEvents, setSelectedEvents } = props;

  const filterItems = useFilterItems();

  const hasSelectedNews = selectedEvents.length > 0;

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search hidden={hasSelectedNews} />
      {hasSelectedNews && (
        <Actions selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} />
      )}
      <FiltersContainer showClearAll overlay={<FilterCollapse items={filterItems} />} />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
