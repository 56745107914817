import { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetPersons1cQuery } from 'services/1c/cApiService';
import { useLazyGetNotificationsQuery } from 'services/notification/notificationApiService';
import {
  GetNotificationsPropsType,
  GetNotificationsResponseType,
} from 'services/notification/notificationApiService.types';

import { Form, FormInstance, handleRequest } from 'gazprom-ui-lib';

import { NotificationType, NotificationTypeWithPerson } from 'types/notification.types';

import { FormResponseType } from './notificationNewsletterEditStats.utils';

interface UseGetDataProps {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProps) => {
  const { form } = props;

  const params = useParams<{ newsletterId: string }>();

  const [
    getNotificationTemplates,
    { data: notificationsData, isFetching: notificationDataIsFetching },
  ] = useLazyGetNotificationsQuery();

  const [getPersons, { data: personsData, isFetching: personsIsFetching }] =
    useLazyGetPersons1cQuery();

  const formValues = Form.useWatch([], form);

  const { debouncedValue: debouncedFormValues } = useDebounce(formValues);

  useEffect(() => {
    const { page, size, filters, property, order, search } = debouncedFormValues ?? {};

    if (params.newsletterId) {
      const defaultBody: GetNotificationsPropsType = {
        page,
        size,
        packageId: params.newsletterId,
        search,
      };

      if (property && order) {
        defaultBody.sort = [{ order, property }];
      }

      if (filters?.viewed) {
        defaultBody.viewed = filters.viewed;
      }

      if (filters?.callbackExists) {
        defaultBody.callbackExists = filters.callbackExists;
      }

      if (filters?.feedbackExists) {
        defaultBody.feedbackExists = filters.feedbackExists;
      }

      const onSuccess = (data?: GetNotificationsResponseType) => {
        if (data)
          getPersons({
            ids: data.items.map((i) => i.personId),
            page: 1,
            size: data.items.length,
          });
      };

      getNotificationTemplates(defaultBody).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  }, [debouncedFormValues, getNotificationTemplates, getPersons, params.newsletterId]);

  const combinedData = useMemo(() => {
    if (personsData?.persons && notificationsData) {
      return notificationsData.items.map((not: NotificationType) => {
        return { ...not, person: personsData.persons.find((p) => p.id === not.personId) ?? {} };
      });
    } else {
      return [];
    }
  }, [notificationsData, personsData]);

  return {
    data: combinedData as NotificationTypeWithPerson[],
    totalCount: notificationsData?.totalCount ?? 0,
    isFetching: notificationDataIsFetching || personsIsFetching,
  };
};

export default useGetData;
