import { t } from 'tools/i18n';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import { FilterCheckboxGroupOptions } from 'containers/filters/filter-checkbox-group/filterCheckboxGroup';
import FilterHeader from 'containers/filters/filter-header';

import { modifyTaskStatuses } from 'pages/documents/documents.utils';
import useDocumentsFilters from 'pages/documents/useDocumentsFilters';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

const useFilterItems = () => {
  const {
    taskStatuses,
    employersResult,
    employersSearchHandler,
    categoriesResult,
    categoriesSearchHandler,
  } = useDocumentsFilters({
    categoryType: KEDO_TYPE_ENUM.BPMN,
  });

  const preparedFilters = taskStatuses?.reduce(
    modifyTaskStatuses,
    [] as FilterCheckboxGroupOptions[],
  );

  return {
    preparedFilters,
    filterItems: [
      {
        key: 0,
        label: <FilterHeader valueKey="employerIds">{t('common_organization')}</FilterHeader>,
        children: (
          <FilterCheckboxGroup
            valueKey="employerIds"
            optionFilterProp="value"
            options={employersResult?.employersOptions ?? []}
            showSearch
            searchProps={{
              placeholder: t('common_organization_search'),
              onChange: employersSearchHandler,
            }}
          />
        ),
      },
      {
        key: 1,
        label: <FilterHeader valueKey="categoryIds">{t('common_category')}</FilterHeader>,
        children: (
          <FilterCheckboxGroup
            valueKey="categoryIds"
            optionFilterProp="value"
            options={categoriesResult?.data ?? []}
            showSearch
            searchProps={{
              placeholder: t('common_categories_search'),
              onChange: categoriesSearchHandler,
            }}
          />
        ),
      },
    ],
  };
};

export default useFilterItems;
