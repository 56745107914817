import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateArticleMutationType,
  CreateArticlePropsType,
  DeleteArticlePropsType,
  DeleteArticleResponseType,
  DeleteArticlesPropsType,
  DeleteArticlesResponseType,
  EditArticlePropsType,
  EditArticleResponseType,
  GetArticleByCodePropsType,
  GetArticleByCodeResponseType,
  GetArticlesPropsType,
  GetArticlesResponseType,
  PinArticlePropsType,
  PinArticleResponseType,
} from './articleApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createArticleUrl } from './utils';

const articleApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query<GetArticlesResponseType, GetArticlesPropsType>({
      query: (params) => ({
        url: createArticleUrl(prepareQueryParams(params)),
      }),
      providesTags: [SERVICE_TAGS.GET_ARTICLES],
    }),
    getArticleByCode: builder.query<GetArticleByCodeResponseType, GetArticleByCodePropsType>({
      query: (code) => ({
        url: createArticleUrl(`/${code}`),
      }),
      providesTags: [SERVICE_TAGS.GET_ARTICLES],
    }),
    createArticle: builder.mutation<CreateArticleMutationType, CreateArticlePropsType>({
      query: (body) => ({
        url: createArticleUrl(''),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_ARTICLES],
    }),
    editArticle: builder.mutation<EditArticleResponseType, EditArticlePropsType>({
      query: ({ code, ...body }) => ({
        url: createArticleUrl(`/${code}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_ARTICLES],
    }),
    deleteArticle: builder.mutation<DeleteArticleResponseType, DeleteArticlePropsType>({
      query: (code) => ({
        url: createArticleUrl(`/${code}`),
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_ARTICLES],
    }),
    deleteArticles: builder.mutation<DeleteArticlesResponseType, DeleteArticlesPropsType>({
      query: (body) => ({
        url: createArticleUrl(``),
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_ARTICLES],
    }),
    pinArticle: builder.mutation<PinArticleResponseType, PinArticlePropsType>({
      query: ({ id, ...body }) => ({
        url: createArticleUrl(`/${id}/pin`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_ARTICLES],
    }),
  }),
});

export const {
  useDeleteArticlesMutation,
  useGetArticlesQuery,
  useLazyGetArticlesQuery,
  useEditArticleMutation,
  useDeleteArticleMutation,
  usePinArticleMutation,
  useLazyGetArticleByCodeQuery,
  useGetArticleByCodeQuery,
  useCreateArticleMutation,
} = articleApiService;
