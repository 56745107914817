import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { CEmployerType, UpdateEmployer1cByIdPropsType } from 'services/1c/cApiService.types';

import { Checkbox, ColumnsType, Typography } from 'gazprom-ui-lib';

interface UseColumnsProps {
  updateEmployer1cById: (params: UpdateEmployer1cByIdPropsType) => void;
}

const useColumns = (props: UseColumnsProps): ColumnsType<CEmployerType> => {
  const { updateEmployer1cById } = props;

  return useMemo(
    () => [
      {
        title: t('common_name'),
        dataIndex: 'name',
        render: (name: string) => (
          <Typography.Text size="14" weight="500">
            {name}
          </Typography.Text>
        ),
      },
      {
        title: t('1c_branch_organization'),
        dataIndex: 'isBranch',
        align: 'center',
        render: (isBranch: boolean) => <Checkbox checked={isBranch} disabled />,
      },
      {
        title: t('common_sign_enabled'),
        dataIndex: 'signEnabled',
        align: 'center',
        render: (signEnabled: boolean, row: CEmployerType) => (
          <Checkbox
            checked={signEnabled}
            onChange={(e) => {
              updateEmployer1cById({
                id: row.id,
                signEnabled: e.target.checked,
              });
            }}
          />
        ),
      },
    ],
    [updateEmployer1cById],
  );
};

export default useColumns;
