import { FC } from 'react';

import cn from 'classnames';

import { TextProps, Typography } from 'gazprom-ui-lib';

import s from './TableText.module.scss';

interface TableTextProps extends TextProps {
  className?: string;
}

const TableText: FC<TableTextProps> = (props) => {
  const { children, className, ...rest } = props;

  return (
    <Typography.Text {...rest} className={cn(s.text, className)}>
      {children}
    </Typography.Text>
  );
};

export default TableText;
