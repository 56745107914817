import { useState } from 'react';

import { t } from 'tools/i18n';
import { handleTableChange } from 'utils/table.utils';

import { Button, Icon, Input, InputProps, Table } from 'gazprom-ui-lib';

import s from './Participants.module.scss';
import { DEFAULT_REQUEST_BODY, RequestBodyType } from './participants.utils';
import useColumns from './useColumns.hook';
import useDownloadPdf from './useDownloadPdf.hook';
import useRequest from './useRequest.hook';

const Participants = () => {
  const [requestBody, setRequestBody] = useState<RequestBodyType>(DEFAULT_REQUEST_BODY);

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({ ...prevState, search: e.target.value }));
  };

  const { eventParticipants, eventParticipantsIsFetching } = useRequest({ requestBody });
  const { isLoading, handleDownload } = useDownloadPdf();
  const columns = useColumns();

  return (
    <>
      <div className={s.searchWrapper}>
        <Input
          allowClear
          value={requestBody.search}
          onChange={handleSearchChange}
          size="small"
          prefix={<Icon name="search" />}
          placeholder={t('common_search')}
        />
        <Button leftIcon="download" loading={isLoading} onClick={handleDownload} ghost size="small">
          {t('events_download_recipients_list')}
        </Button>
      </div>
      <Table
        rowKey="id"
        showSorterTooltip={false}
        columns={columns}
        dataSource={eventParticipants?.items}
        onChange={handleTableChange(setRequestBody)}
        pagination={{
          defaultCurrent: requestBody.page,
          defaultPageSize: requestBody.size,
          total: eventParticipants?.totalCount,
        }}
        loading={eventParticipantsIsFetching}
      />
    </>
  );
};

export default Participants;
