import { FaqCategoryType, FaqType } from 'types/faq.types';
import { SORT_ENUM } from 'types/sort.types';

export type RequestBodyType = {
  page: number;
  size: number;
  search: string;
  order: SORT_ENUM;
  property?: string;
  filters: null | {
    status?: boolean[];
  };
};

export const DEFAULT_REQUEST_BODY: RequestBodyType = {
  page: 1,
  size: 500,
  search: '',
  order: SORT_ENUM.DESC,
  property: 'updatedAt',
  filters: null,
};

export type UnionType = FaqType | FaqCategoryType;
export type FaqTreeDataType = {
  type: 'faq' | 'category';
  updatedAt: string;
  isPublish: boolean;
  title: string;
  children?: FaqTreeDataType[];
  parentId: string | null;
  key: string;
  organization?: { name: string; id: string };
};
