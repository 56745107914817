import EmployeeGroups from 'pages/employee-groups';
import EmployeeGroupsCreate from 'pages/employee-groups/employee-groups-create';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import {
  EMPLOYEE_GROUPS_BY_ID_ROUTE,
  EMPLOYEE_GROUPS_CREATE_ROUTE,
  EMPLOYEE_GROUPS_ROUTE,
} from './list';

export const employeeGroupsRoutes: RouteConfig[] = [
  {
    path: EMPLOYEE_GROUPS_ROUTE,
    element: <EmployeeGroups />,
    roles: [
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
      GLOBAL_ROLES.MODERATOR_NOTICE,
      GLOBAL_ROLES.MODERATOR_NEWS,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
    ],
  },
  {
    path: EMPLOYEE_GROUPS_BY_ID_ROUTE,
    element: <EmployeeGroupsCreate />,
    roles: [
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
      GLOBAL_ROLES.MODERATOR_NOTICE,
      GLOBAL_ROLES.MODERATOR_NEWS,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
    ],
  },
  {
    path: EMPLOYEE_GROUPS_CREATE_ROUTE,
    element: <EmployeeGroupsCreate />,
    roles: [
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_ANKETA,
      GLOBAL_ROLES.MODERATOR_NOTICE,
      GLOBAL_ROLES.MODERATOR_NEWS,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
    ],
  },
];
