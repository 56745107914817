import { t } from 'tools/i18n';

import { GetEmployersResponse } from 'services/employer/employerApiService.types';
import { GetRolesResponseType } from 'services/roles/rolesApiService.types';

import { FilterCheckboxGroupOptions } from 'containers/filters/filter-checkbox-group/filterCheckboxGroup';

import { USER_STATUS_ENUM } from 'pages/access-control/access-control-users/accessControlUsers.utils';

export const selectFromResultEmployers = ({
  data,
}: {
  data?: GetEmployersResponse;
}): { data: FilterCheckboxGroupOptions[] } => ({
  data: data?.content.map(({ name, id }) => ({ value: id, label: name })) ?? [],
});
export const selectFromResultRoles = ({
  data,
}: {
  data?: GetRolesResponseType;
}): { data: FilterCheckboxGroupOptions[] } => ({
  data:
    data?.map(({ code, name }) => ({
      value: code,
      label: name,
    })) ?? [],
});

export const STATUS_OPTIONS = [
  { label: t('common_all'), value: USER_STATUS_ENUM.ALL },
  { label: t('common_registered'), value: USER_STATUS_ENUM.REGISTERED },
  { label: t('common_not_registered'), value: USER_STATUS_ENUM.NOT_REGISTERED },
];
