import { useEffect } from 'react';

import { useLazyGetArticlesQuery } from 'services/article/articleApiService';
import {
  GetArticlesPropsType,
  GetArticlesResponseType,
} from 'services/article/articleApiService.types';

import { ARTICLE_TYPE_ENUM } from 'types/article.types';

import { RequestBody } from './newsTables.utils';

interface UseGetNewsProps {
  filters: Pick<GetArticlesPropsType, 'categoryIds'> | null;
  debouncedRequestBody: RequestBody;
}

const useGetNews = (
  props: UseGetNewsProps,
): {
  isLoading: boolean;
  isFetching: boolean;
  unpinnedNews?: GetArticlesResponseType;
} => {
  const { filters, debouncedRequestBody } = props;

  const [getArticles, { data: unpinnedNews, isLoading, isFetching }] = useLazyGetArticlesQuery();

  useEffect(() => {
    const { property, order, title, page, size } = debouncedRequestBody;

    const preparedBody: GetArticlesPropsType = {
      ...filters,
      type: [ARTICLE_TYPE_ENUM.NEWS],
      pinned: false,
      page,
      size,
    };

    if (property && order) {
      preparedBody.sort = [
        {
          property: `ARTICLE.${debouncedRequestBody.property}`,
          order: debouncedRequestBody.order,
        },
      ];
    }
    if (title) {
      preparedBody.title = title;
    }
    getArticles(preparedBody);
  }, [debouncedRequestBody, filters, getArticles]);

  return {
    isLoading,
    isFetching,
    unpinnedNews,
  };
};

export default useGetNews;
