import { useEffect } from 'react';

import { FormInstance } from 'gazprom-ui-lib';

import { NOTIFICATION_TEMPLATE_FORM_ENUM } from 'containers/notification-template-form/notificationTemplateForm.utils';

import { NotificationTemplateType } from 'types/notification.types';

interface UseFormSetProps {
  currentTemplate?: NotificationTemplateType;
  form?: FormInstance;
}

const useFormSet = (props: UseFormSetProps) => {
  const { form, currentTemplate } = props;

  useEffect(() => {
    const isSetAvailable = form && currentTemplate && !form.isFieldsTouched();

    if (isSetAvailable) {
      form.setFieldsValue({
        [NOTIFICATION_TEMPLATE_FORM_ENUM.NAME]: currentTemplate?.name,
        [NOTIFICATION_TEMPLATE_FORM_ENUM.TITLE]: currentTemplate?.title,
        [NOTIFICATION_TEMPLATE_FORM_ENUM.TEXT]: currentTemplate?.body,
        [NOTIFICATION_TEMPLATE_FORM_ENUM.RECEIVERS]: currentTemplate?.receivers[0]?.receiverIds,
        [NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_PUSH]: !!currentTemplate?.sendPush,
        [NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_APP]: !!currentTemplate?.sendApp,
        [NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_EMAIL]: !!currentTemplate?.sendEmail,
        [NOTIFICATION_TEMPLATE_FORM_ENUM.ALLOW_FEEDBACK]: !!currentTemplate?.allowFeedback,
        [NOTIFICATION_TEMPLATE_FORM_ENUM.REQUIRES_FEEDBACK]: !!currentTemplate?.requiresFeedback,
        [NOTIFICATION_TEMPLATE_FORM_ENUM.ALLOW_CALLBACK]: !!currentTemplate?.allowCallback,
      });
    }
  }, [form, currentTemplate]);
};

export default useFormSet;
