export const ACCESS_CONTROL_ROUTE = `/access-control`;
export const ACCESS_CONTROL_USERS_ROUTE = `${ACCESS_CONTROL_ROUTE}/users`;
export const ACCESS_CONTROL_USERS_BY_ID_ROUTE = `${ACCESS_CONTROL_ROUTE}/users/:id`;
export const ACCESS_CONTROL_USER_ROLES_ROUTE = `${ACCESS_CONTROL_ROUTE}/user-roles`;
export const ACCESS_CONTROL_USER_ROLES_DETAILS_ROUTE = `${ACCESS_CONTROL_USER_ROLES_ROUTE}/:code`;
export const getUserRoleWithCodeRoute = (code: string) => {
  return `${ACCESS_CONTROL_USER_ROLES_ROUTE}/${code}`;
};
export const getUsersByIdRoute = (id: string) => {
  return `${ACCESS_CONTROL_USERS_ROUTE}/${id}`;
};
