import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetCInstancesQuery } from 'services/1c-instances/cInstancesService';
import { GetCInstancesPropsType } from 'services/1c-instances/cInstancesService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './settings1cInstances.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: Props) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const [getCInstances, { data: cInstances, isFetching, isLoading, isUninitialized }] =
    useLazyGetCInstancesQuery();

  useEffect(() => {
    if (debouncedRequestBody) {
      const { search } = debouncedRequestBody;

      const preparedBody: GetCInstancesPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
      };

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        preparedBody.sort = [
          { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
        ];
      }

      if (search) {
        preparedBody.filterByNameParam = search;
      }

      getCInstances(preparedBody);
    }
  }, [getCInstances, debouncedRequestBody]);

  return {
    cInstances,
    isFetching: isDebouncing || isFetching,
    isLoading: isUninitialized || isLoading,
  };
};

export default useGetData;
