import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import ThankYouTable from './containers/table';
import { DEFAULT_FORM_STATE, FormResponseType } from './thank-you.utils';
import useGetData from './useGetData.hook';

const ThankYou = () => {
  const [form] = Form.useForm<FormResponseType>();

  const { data, isFetching, isLoading } = useGetData({ form });

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title total={data?.totalCount}>{t('thank_you_title')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <ThankYouTable loading={isFetching} isInitialLoading={isLoading} dataSource={data} />
      </FiltersV2>
    </WithOverflow>
  );
};

export default ThankYou;
