import { Dispatch, FC, SetStateAction, memo } from 'react';

import { t } from 'tools/i18n';

import { Button, ButtonProps, ModalDefault, ModalDefaultIconProps } from 'gazprom-ui-lib';

interface DocumentsDeclarationsModalProps {
  modal: string | null;
  setModal: Dispatch<SetStateAction<string | null>>;
  loading: boolean;
  handleClick: () => void;
}

const DocumentsDeclarationsModal: FC<DocumentsDeclarationsModalProps> = (props) => {
  const { modal, setModal, loading, handleClick } = props;

  let config: {
    icon: {
      name: ModalDefaultIconProps['name'];
      type: ModalDefaultIconProps['type'];
    };
    buttonProps: ButtonProps;
    title: string;
    description: string;
  } = {
    icon: {
      name: 'ok',
      type: 'success',
    },
    buttonProps: {
      children: t('common_ok'),
      onClick: () => setModal(null),
    },
    title: t('documents_declarations_modal_publish_title'),
    description: t('documents_declarations_modal_publish_subtitle'),
  };

  if (modal === 'error') {
    config = {
      icon: {
        name: 'error',
        type: 'warning',
      },
      buttonProps: {
        children: t('common_ok'),
        onClick: () => setModal(null),
      },
      title: t('documents_declarations_modal_error_title'),
      description: t('documents_declarations_modal_error_subtitle'),
    };
  }

  if (modal === 'unpublish') {
    config = {
      icon: {
        name: 'error',
        type: 'danger',
      },
      buttonProps: {
        children: t('common_confirm'),
        leftIcon: 'ok',
        onClick: handleClick,
      },
      title: t('documents_declarations_modal_unpublish_title'),
      description: t('documents_declarations_modal_unpublish_subtitle'),
    };
  }

  const { icon, title, description, buttonProps } = config;

  return (
    <ModalDefault open={!!modal} onCancel={() => setModal(null)} width={320} closable>
      <ModalDefault.Icon {...icon} />
      <ModalDefault.Title>{title}</ModalDefault.Title>
      <ModalDefault.Description>{description}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button size="large" loading={loading} fullWidth {...buttonProps} />
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default memo(DocumentsDeclarationsModal);
