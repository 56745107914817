import { t } from 'tools/i18n';

import { Button, ColumnsType } from 'gazprom-ui-lib';

interface UseColumnsProps {
  handleDelete: (positionName: string) => void;
}

const useColumns = (props: UseColumnsProps): ColumnsType<{ positionName: string }> => {
  const { handleDelete } = props;

  const onClick = (positionName: string) => () => {
    handleDelete(positionName);
  };

  const deleteButtonRenderer = (positionName: string) => (
    <Button size="small" danger leftIcon="deleteFilled" type="link" onClick={onClick(positionName)}>
      {t('common_exclude')}
    </Button>
  );

  return [
    {
      title: t('top_management_added_positions'),
      dataIndex: 'positionName',
    },
    {
      dataIndex: 'positionName',
      render: deleteButtonRenderer,
      width: 120,
    },
  ];
};

export default useColumns;
