import { t } from 'tools/i18n';

import { Button, Flex } from 'gazprom-ui-lib';

import { EVENT_FORM_NAME } from 'containers/event-form/eventForm.utils';

import { EVENT_EDIT, EVENT_TARGET_GROUP } from 'pages/events/events-details/useGetTabs.hook';

import { EventType } from 'types/event.types';

import s from './EventDetails.module.scss';

interface UseActionsProps {
  updateEventIsFetching: boolean;
  data?: EventType;
  handleModalOpenClose: () => void;
  handleSave: () => void;
  activeTab: string;
}

const useActions = (props: UseActionsProps) => {
  const { updateEventIsFetching, data, handleModalOpenClose, handleSave, activeTab } = props;

  const saveButton = (
    <Button
      rightIcon="ok"
      fullWidth
      loading={updateEventIsFetching}
      htmlType="button"
      form={EVENT_FORM_NAME}
      onClick={handleSave}>
      {t('common_save')}
    </Button>
  );

  let headerButton = <></>;
  let additionalActions = <></>;

  if ([EVENT_EDIT, EVENT_TARGET_GROUP].includes(activeTab)) {
    headerButton = (
      <Button
        leftIcon="ok"
        fullWidth
        loading={updateEventIsFetching}
        htmlType="button"
        form={EVENT_FORM_NAME}
        onClick={handleSave}>
        {t('common_save')}
      </Button>
    );
  }

  if (data) {
    additionalActions = data?.article?.isPublish ? (
      <Button
        key="event__unpublish__button"
        loading={updateEventIsFetching}
        htmlType="button"
        onClick={handleModalOpenClose}
        danger
        leftIcon="unpublish"
        fullWidth>
        {t('common_unpublish')}
      </Button>
    ) : (
      <Button
        key="event__publish__button"
        loading={updateEventIsFetching}
        htmlType="button"
        onClick={handleModalOpenClose}
        leftIcon="publish"
        fullWidth>
        {t('common_save_and_publish')}
      </Button>
    );
  }

  const actions = (
    <Flex justify="space-between" align="center" gap="12" className={s.actions}>
      {saveButton}
      {additionalActions}
      <Button type="secondary" fullWidth htmlType="reset" form={EVENT_FORM_NAME}>
        {t('common_close')}
      </Button>
    </Flex>
  );

  return {
    headerButton,
    actions,
  };
};

export default useActions;
