import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetTelegramChannelsQuery } from 'services/telegram-channel/telegramChannelApiService';
import { GetTelegramChannelPropsType } from 'services/telegram-channel/telegramChannelApiService.types';

import { RequestBody } from './settingsTelegram.utils';

interface UseRequestProps {
  requestBody: RequestBody;
}

const useRequest = (props: UseRequestProps) => {
  const { requestBody } = props;

  const [getTelegramChannels, { data, isFetching }] = useLazyGetTelegramChannelsQuery();

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  useEffect(() => {
    const preparedBody: GetTelegramChannelPropsType = {
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
    };

    if (debouncedRequestBody.search) {
      preparedBody.text = debouncedRequestBody.search;
    }

    if (debouncedRequestBody.property && debouncedRequestBody.order) {
      preparedBody.sort = [
        {
          property: debouncedRequestBody.property,
          order: debouncedRequestBody.order,
        },
      ];
    }

    getTelegramChannels(preparedBody);
  }, [debouncedRequestBody, getTelegramChannels]);

  return {
    data,
    isFetching,
  };
};

export default useRequest;
