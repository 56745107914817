import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { SuggestionDecisionType, SuggestionType } from 'types/suggestion.types';

const useColumns = (): ColumnsType<SuggestionDecisionType> => {
  const employeeRenderer = (suggestion: SuggestionType) => {
    const { firstName, lastName, patronymic } = suggestion?.employee?.person ?? {};

    return (
      <Typography.Text size="12" weight="500" type="secondary">
        {firstName} {lastName} {patronymic}
      </Typography.Text>
    );
  };

  const suggestionNameRenderer = (suggestion: SuggestionType) => {
    const { name } = suggestion ?? {};

    return (
      <Typography.Text size="12" weight="500">
        {name}
      </Typography.Text>
    );
  };

  const organizationRenderer = (employee: SuggestionType['employee']) => {
    const { name } = employee?.employer ?? {};

    return (
      <Typography.Text size="12" weight="500" type="secondary">
        {name}
      </Typography.Text>
    );
  };

  const statusRenderer = (status: SuggestionDecisionType['status']) => {
    return <TableStatus status={status.state.toUpperCase() as STATUSES}>{status.name}</TableStatus>;
  };

  const dateRenderer = (date: SuggestionDecisionType['createdAt']) => {
    return <TableDate date={date} />;
  };

  return [
    {
      title: t('common_number'),
      dataIndex: 'suggestion',
    },
    {
      title: t('common_employee'),
      dataIndex: 'suggestion',
      render: employeeRenderer,
    },
    {
      title: t('common_employee_organization'),
      dataIndex: 'employee',
      render: organizationRenderer,
    },
    {
      title: t('suggestion_name'),
      dataIndex: 'suggestion',
      render: suggestionNameRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      render: statusRenderer,
    },
    {
      align: 'center',
      title: t('common_created'),
      dataIndex: 'createdAt',
      render: dateRenderer,
      sorter: true,
      defaultSortOrder: 'descend',
    },
  ];
};

export default useColumns;
