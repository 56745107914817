import { t } from 'tools/i18n';

import { Form, Input, Typography } from 'gazprom-ui-lib';

import { HANDBOOK_BASE_FORM_ENUM } from '../handbookBaseForm.utils';

const HandbookBaseFormCoordinates = () => {
  const coordinatesValidator = (_: unknown, coordinates: string) => {
    if (!coordinates) {
      return Promise.reject(t('validation_field_not_empty'));
    }

    const [x, y] = coordinates.split(',');
    const isValid = !!coordinates && !isNaN(Number(x)) && !isNaN(Number(y));

    if (isValid) {
      return Promise.resolve();
    }

    return Promise.reject(t('errors_invalid_coordinates'));
  };

  return (
    <>
      <Typography.Text strong size="16">
        {t('guide_handbook_coordinates')}
      </Typography.Text>
      <Form.Item
        name={HANDBOOK_BASE_FORM_ENUM.COORDINATES}
        rules={[() => ({ validator: coordinatesValidator })]}>
        <Input placeholder="00.000000, 00.000000" />
      </Form.Item>
    </>
  );
};

export default HandbookBaseFormCoordinates;
