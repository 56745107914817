import { Form } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import FormBody from '../formBoody';
import useFormSaveHandler from '../useFormSaveHandler.hook';
import useSurveyFormData from '../useSurveyFormData.hook';
import s from './SurveyFormOnce.module.scss';
import AssignModal from './container/assign-modal';
import Buttons from './container/buttons';
import RecallModal from './container/recall-modal';
import useFormAssignHandler from './useFormAssignHandler.hook';

interface Props {
  refetchHandler?: () => void;
}

const SurveyFormOnce = (props: Props) => {
  const { refetchHandler } = props;
  const [form] = Form.useForm();

  const { isLoading, isSurveyActive, isFinished, isReadyToAssign } = useSurveyFormData();
  const {
    isLoading: isAssignLoading,
    modalOpen,
    cancelModalHandler,
    appointButtonHandler,
    acceptAppointButtonHandler,
    recallButtonHandler,
    acceptRecallButtonHandler,
  } = useFormAssignHandler({ form, refetchHandler });
  const { onFormFinishHandler, isLoading: isSaveLoading } = useFormSaveHandler();

  return (
    <WithLoader isLoading={isLoading}>
      <Form form={form} className={s.form} onFinish={onFormFinishHandler} disabled={isSurveyActive}>
        <FormBody />

        <Buttons
          isAssignLoading={isAssignLoading}
          isSaveLoading={isSaveLoading}
          isFinished={isFinished}
          isReadyToAssign={isReadyToAssign}
          recallButtonHandler={recallButtonHandler}
          appointButtonHandler={appointButtonHandler}
        />
      </Form>

      <AssignModal
        modalOpen={modalOpen}
        onCancel={cancelModalHandler}
        onActionClick={acceptAppointButtonHandler}
      />

      <RecallModal
        modalOpen={modalOpen}
        onCancel={cancelModalHandler}
        onActionClick={acceptRecallButtonHandler}
      />
    </WithLoader>
  );
};

export default SurveyFormOnce;
