import { t } from 'tools/i18n';

import CommissionDetails from './containers/commission-details';
import CommissionSuggestions from './containers/commission-suggestions';

export const SUGGESTIONS_COMMISSIONS_DETAILS_TAB = 'SUGGESTIONS_COMMISSIONS_DETAILS_TAB';
export const SUGGESTIONS_COMMISSIONS_OFFERS_TAB = 'SUGGESTIONS_COMMISSIONS_OFFERS_TAB';

export const TABS = [
  {
    key: SUGGESTIONS_COMMISSIONS_DETAILS_TAB,
    children: <CommissionDetails />,
    label: t('common_settings'),
  },
  {
    key: SUGGESTIONS_COMMISSIONS_OFFERS_TAB,
    children: <CommissionSuggestions />,
    label: t('suggestions_commission_view_offers'),
    disabled: true,
  },
];
