import { t } from 'tools/i18n';

import { ButtonProps, ModalDefaultIconProps } from 'gazprom-ui-lib';

import { LNA_MODAL_ENUM } from './settingsLnaEmploymentDetails.utils';

const useModalConfig = ({
  type,
  handleDelete,
  handleExclude,
  handleAssign,
}: {
  type?: LNA_MODAL_ENUM;
  handleDelete: () => void;
  handleExclude: () => void;
  handleAssign: () => void;
}) => {
  const modals: {
    [key: string]: {
      title: string;
      description: string;
      icon: ModalDefaultIconProps;
      button: ButtonProps;
    };
  } = {
    EXCLUDE: {
      title: t('1c_lna_exclude_title'),
      description: t('1c_lna_exclude_description'),
      icon: {
        type: 'primary',
        name: 'unpublish',
      },
      button: {
        type: 'primary',
        leftIcon: 'ok',
        children: t('common_accept'),
        onClick: handleExclude,
      },
    },
    ASSIGN: {
      title: t('1c_lna_assign_title'),
      description: t('1c_lna_assign_description'),
      icon: {
        type: 'primary',
        name: 'publish',
      },
      button: {
        type: 'primary',
        leftIcon: 'ok',
        children: t('common_accept'),
        onClick: handleAssign,
      },
    },
    DELETE: {
      title: t('common_accept_remove'),
      description: t('1c_lna_delete_description'),
      icon: {
        type: 'danger',
        name: 'bin',
      },
      button: {
        ghost: true,
        danger: true,
        leftIcon: 'bin',
        children: t('common_delete'),
        onClick: handleDelete,
      },
    },
  };

  return type ? modals[type] : {};
};

export default useModalConfig;
