import { FC } from 'react';

import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Form, TextArea, Typography } from 'gazprom-ui-lib';

import { NOTIFICATION_NEWSLETTER_FORM_ENUM } from '../notificationNewsletterForm.utils';

interface NotificationNewsletterFormTitleProps {
  disabled?: boolean;
}

const NotificationNewsletterFormTitle: FC<NotificationNewsletterFormTitleProps> = (props) => {
  const { disabled } = props;

  return (
    <div>
      <Typography.Text strong size="16">
        {t('common_message_title')}
      </Typography.Text>
      <Form.Item
        normalize={normalizeTextField}
        name={NOTIFICATION_NEWSLETTER_FORM_ENUM.TITLE}
        rules={[formRules.required, { max: 80 }]}>
        <TextArea
          disabled={disabled}
          placeholder={t('common_enter_text')}
          maxLength={80}
          showCount
        />
      </Form.Item>
    </div>
  );
};

export default NotificationNewsletterFormTitle;
