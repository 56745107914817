import { FC } from 'react';

import { t } from 'tools/i18n';

import { useDeleteFaqCategoryMutation, useDeleteFaqMutation } from 'services/faq/faqApiService';

import { Button, ModalDefault, handleRequest } from 'gazprom-ui-lib';

import { FaqTreeDataType } from 'pages/faq/faq.utils';

interface DeleteModalProps {
  data: null | FaqTreeDataType;
  onClose: () => void;
}

const DeleteModal: FC<DeleteModalProps> = (props) => {
  const { data, onClose } = props;

  const [deleteCategory, { isLoading: deleteCategoryIsLoading }] = useDeleteFaqCategoryMutation();
  const [deleteFaq, { isLoading: deleteFaqIsLoading }] = useDeleteFaqMutation();

  const isFaq = data?.type === 'faq';

  const handleDelete = () => {
    if (data) {
      const mutation = isFaq ? deleteFaq : deleteCategory;

      mutation(data.key).then(handleRequest({ onSuccess: onClose }));
    }
  };

  const isLoading = deleteCategoryIsLoading || deleteFaqIsLoading;

  return (
    <ModalDefault open={!!data} onCancel={onClose} width={320} closable>
      <ModalDefault.Icon name="bin" type="danger" />
      <ModalDefault.Title>{t('common_accept_remove')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t(isFaq ? 'faq_remove_description' : 'faq_category_remove_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button danger ghost fullWidth leftIcon="bin" loading={isLoading} onClick={handleDelete}>
          {t('common_delete')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default DeleteModal;
