import { FC, MouseEvent, useContext } from 'react';

import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import { FilterContext, getFilterCount } from '../filters.utils';
import s from './FilterReset.module.scss';

interface FilterResetProps {
  valueKey: string;
}

const FilterReset: FC<FilterResetProps> = (props) => {
  const { valueKey } = props;

  const { filters, clearFilter } = useContext(FilterContext);
  const selectedCount = getFilterCount(filters?.[valueKey] ?? null);

  const handleClear = (e: MouseEvent) => {
    e.stopPropagation();
    if (clearFilter) {
      clearFilter(valueKey);
    }
  };

  if (!selectedCount) {
    return <></>;
  }

  return (
    <Button onClick={handleClear} size="small" type="link" leftIcon="delete" className={s.wrapper}>
      {t('common_reset_selected', { count: selectedCount })}
    </Button>
  );
};

export default FilterReset;
