import { FC } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { t } from 'tools/i18n';

import {
  Checkbox,
  DatePicker,
  DatePickerProps,
  Form,
  TimePicker,
  Typography,
} from 'gazprom-ui-lib';

import { NEWS_FORM_ENUM } from 'containers/news-form/newsForm.utils';

import { NOTIFICATION_NEWSLETTER_FORM_ENUM } from '../notificationNewsletterForm.utils';
import s from './NotificationNewsletterFormPublish.module.scss';

interface NotificationNewsletterFormPublishProps {
  disabled?: boolean;
}

const NotificationNewsletterFormPublish: FC<NotificationNewsletterFormPublishProps> = (props) => {
  const { disabled } = props;

  const form = Form.useFormInstance();
  const isDelayAvailable = Form.useWatch(NOTIFICATION_NEWSLETTER_FORM_ENUM.DELAY);
  const normalizeDelay = (isChecked: boolean) => {
    if (!isChecked) {
      form.resetFields([
        NOTIFICATION_NEWSLETTER_FORM_ENUM.PUBLISH_FROM_DATE,
        NEWS_FORM_ENUM.PUBLISH_FROM_TIME,
      ]);
    }
    return isChecked;
  };

  const disabledDate: DatePickerProps['disabledDate'] = (current) =>
    dayjs(current as Dayjs).isBefore(dayjs().startOf('d'));

  return (
    <div>
      <Typography.Text strong size="16">
        {t('notification_newsletter_date_and_time')}
      </Typography.Text>
      <div className={s.wrapper}>
        <Form.Item name={NOTIFICATION_NEWSLETTER_FORM_ENUM.PUBLISH_FROM_DATE}>
          <DatePicker disabled={!isDelayAvailable || disabled} disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item name={NEWS_FORM_ENUM.PUBLISH_FROM_TIME}>
          <TimePicker format="HH:mm" placeholder="00:00" disabled={!isDelayAvailable || disabled} />
        </Form.Item>
      </div>
      <Form.Item
        name={NOTIFICATION_NEWSLETTER_FORM_ENUM.DELAY}
        valuePropName="checked"
        normalize={normalizeDelay}>
        <Checkbox disabled={disabled}>
          <Typography.Text size="14">{t('notification_newsletter_delay')}</Typography.Text>
        </Checkbox>
      </Form.Item>
    </div>
  );
};

export default NotificationNewsletterFormPublish;
