import { mapSocialPrograms } from 'pages/social-program/socialProgram.utils';

import { CreatorEditorType } from 'types/creatorEditor.types';
import { CurrentKedoStatusType } from 'types/documents.types';
import { EmployeeType } from 'types/employee.types';

export type SocialProgramType = {
  categoryId: string;
  code: string;
  createdAt: string;
  creator?: CreatorEditorType;
  deadlineSendDocs: string;
  editor: CreatorEditorType;
  id: string;
  isPublish: boolean;
  nameProgram: string;
  organizationId: string;
  paymentTerm: string;
  kedoStatementId: string;
  payoutAmounts: {
    createdAt: string;
    creator: string;
    editor: CreatorEditorType;
    id: string;
    namePayoutAmount: string;
    updatedAt?: string;
  }[];
  socialProgramDocuments: {
    createdAt?: string;
    creator?: string;
    editor?: string;
    id: string;
    nameDocument: string;
    updatedAt?: string;
  }[];
  socialProgramEmployeeRequirements: {
    createdAt?: string;
    creator?: string;
    editor?: string;
    id: string;
    nameEmployeeRequirements: string;
    updatedAt?: string;
  }[];
};

export type Employer = {
  id: string;
  name: string;
  isBranch: boolean;
  parent: {
    id: string | null;
    name: string | null;
  };
  createDate: string;
  updateDate: string;
};

export type MappedSocialPrograms = ReturnType<typeof mapSocialPrograms>;

export type EmployeeRequirements = {
  id: string;
  nameEmployeeRequirements: string;
};

export type SocialProgramDocuments = {
  id: string;
  nameDocument: string;
};

export type SocialProgramPayoutAmount = {
  id: string;
  createdAt: string;
  updatedAt: string;
  creator: string;
  editor: string;
  namePayoutAmount: string;
};

export enum SOCIAL_PROGRAM_STATUS_ENUM {
  PUBLISHED = 'PUBLISHED',
  NOT_PUBLISHED = 'NOT_PUBLISHED',
}

export enum SOCIAL_PROGRAM_REQUEST_ENUM {
  CREATED = 'CREATED',
  DOCS_CHECKING = 'DOCS_CHECKING',
  DOCS_FAILED = 'DOCS_FAILED',
  NEED_CORRECTION = 'NEED_CORRECTION',
  DOCS_ACCEPTED = 'DOCS_ACCEPTED',
  IN_REVIEW = 'IN_REVIEW',
  REVIEWED = 'REVIEWED',
  REJECTED = 'REJECTED',
  DELIVERING = 'DELIVERING',
  APPROVED = 'APPROVED',
  PROVIDED = 'PROVIDED',
}

export type SocialProgramRequestType = {
  attrValues: string;
  body: string;
  category: { id: string; name: string };
  createdAt: string;
  currentKedoStatus: CurrentKedoStatusType;
  currentStatus1C: string;
  filledAttributes: {
    attributeId: string;
    attributeName: string;
    num: number;
    userText: string;
  }[];
  employee: EmployeeType;
  executor: EmployeeType;
  files: [];
  hasCommission: boolean;
  history: {
    comment: string;
    createDate: string;
    state: 'error' | 'disabled' | 'pending' | 'done';
    title: string;
  }[];
  id: string;
  personId: string;
  result: null;
  role1cCode: string;
  socAmount: null;
  socCommissionDate: null;
  socFactDate: null;
  socPlanDate: null;
  statement: { id: string; name: string };
  task1cId: string;
  type: 'SOCIAL';
  updatedAt: string;
};
