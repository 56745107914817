import { t } from 'tools/i18n';

import { Flex, Icon, Progress, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import SurveyFormEvent from 'pages/surveys/containers/survey-form-event';
import SurveyFormOnce from 'pages/surveys/containers/survey-form-once';
import useMatchOnceRoute from 'pages/surveys/containers/useMatchOnceRoute';

import { SURVEY_STATUSES_ENUM } from 'types/surveys.types';

import useSurveysDetailsValue from '../../useSurveysDetailsValue';
import s from './SettingsTab.module.scss';

const SUCCESS_COUNT = 100;

const SettingsTab = () => {
  const isOnceSurveysRoute = useMatchOnceRoute();
  const { status, surveyStatusIsLoading, refetchHandler } = useSurveysDetailsValue();

  const { linkedCount, totalCount, status: currentStatus } = status || {};
  const isStatusInfo = (totalCount ?? 0) > 0 && (linkedCount ?? 0) > 0;
  const percentage =
    (isStatusInfo && ((linkedCount ?? 0) / (totalCount ?? 0)) * SUCCESS_COUNT) || 100;
  const showProgress =
    currentStatus !== SURVEY_STATUSES_ENUM.READY && currentStatus !== SURVEY_STATUSES_ENUM.CANCELED;

  const currentForm = isOnceSurveysRoute ? (
    <SurveyFormOnce refetchHandler={refetchHandler} />
  ) : (
    <SurveyFormEvent />
  );

  return (
    <WithLoader isLoading={surveyStatusIsLoading}>
      <Flex gap="32" vertical className={s.container}>
        {isStatusInfo && showProgress && (
          <Flex gap="8" vertical>
            <Typography.Title level={5}>{t('survey_status_title')}</Typography.Title>
            <Progress
              percent={Number(percentage?.toFixed())}
              strokeColor={percentage === SUCCESS_COUNT ? 'var(--color-success)' : ''}
            />
            <Flex gap="4">
              <Icon name="checklist" className={s.icon} />
              <Typography.Text size="14" weight="400" type="secondary">
                {t('survey_appoint_status', { current: linkedCount ?? 0, total: totalCount ?? 0 })}
              </Typography.Text>
            </Flex>
          </Flex>
        )}
        {currentForm}
      </Flex>
    </WithLoader>
  );
};

export default SettingsTab;
