import { FC, useEffect, useState } from 'react';

import { formRules } from 'utils/formRules';

import { useGetKedoCategoriesQuery } from 'services/kedo/kedoApiService';

import { Button, Form, Select, Typography } from 'gazprom-ui-lib';

import { ModalNewCategory } from 'pages/social-program/social-program-modals';

import { KedoCategoryType } from 'types/documents.types';
import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import s from './CategorySelect.module.scss';
import RequirementsSelectIcon from './categorySelect.utils';

interface CategorySelectProps {
  title: string;
  name: string;
  placeholder: string;
  initialValue?: string;
  buttonText: string;
}

const CategorySelect: FC<CategorySelectProps> = (props) => {
  const { title, name, placeholder, initialValue, buttonText } = props;

  const form = Form.useFormInstance();

  const [isModalActive, setIsModalActive] = useState<boolean>(false);

  const { options } = useGetKedoCategoriesQuery(
    { type: KEDO_TYPE_ENUM.SOCIAL, isPublish: true },
    {
      selectFromResult: ({ data }) => ({ options: mapSocialProgramCategories(data) ?? [] }),
    },
  );

  useEffect(() => {
    initialValue && form.setFieldValue(name, initialValue);
  }, [form, initialValue, name]);

  const onFinishHandler = (id: string) => {
    form.setFieldValue(name, id);
  };

  return (
    <>
      <div className={s.item}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Form.Item name={name} rules={[formRules.required]}>
          <Select
            placeholder={placeholder}
            options={options}
            showSearch
            filterOption
            optionFilterProp="name"
          />
        </Form.Item>
        <Button
          type="link"
          leftIcon="plus"
          className={s.button}
          onClick={() => setIsModalActive(true)}>
          {buttonText}
        </Button>
      </div>
      <ModalNewCategory
        open={isModalActive}
        onClose={() => setIsModalActive(false)}
        onFinish={onFinishHandler}
      />
    </>
  );
};

export const mapSocialProgramCategories = (data: KedoCategoryType[] | undefined) => {
  return data?.map((element) => ({
    value: element.id,
    label: <RequirementsSelectIcon icon={element.icon} name={element.name} />,
    name: element.name,
  }));
};

export default CategorySelect;
