import { Dispatch, SetStateAction, useMemo } from 'react';

import { t } from 'tools/i18n';

import { Button, Flex, TableProps, Typography } from 'gazprom-ui-lib';

import { GuideCategoryType } from 'types/guide.types';

import { ModalTypes } from './guideCategories.utils';

interface UseColumnsProps {
  setSelectedCategory: Dispatch<SetStateAction<null | GuideCategoryType>>;
  setModalOpenType: Dispatch<SetStateAction<ModalTypes>>;
}

const useColumns = (props: UseColumnsProps): TableProps<GuideCategoryType>['columns'] => {
  const { setSelectedCategory, setModalOpenType } = props;

  return useMemo(
    () => [
      {
        title: t('common_category'),
        dataIndex: 'name',
        sorter: true,
        width: 180,
        render: (name: string) => {
          return <Typography.Title level={5}>{name}</Typography.Title>;
        },
        defaultSortOrder: 'descend',
      },
      {
        width: 240,
        render: (row: GuideCategoryType) => {
          return (
            <Flex align="center" justify="flex-end">
              <Button
                leftIcon="edit"
                type="link"
                size="small"
                onClick={() => {
                  setSelectedCategory(row);
                  setModalOpenType('edit');
                }}>
                {t('common_edit_short')}
              </Button>
              <Button
                leftIcon="binFilled"
                type="text"
                danger
                size="small"
                onClick={() => {
                  setSelectedCategory(row);
                  setModalOpenType('delete');
                }}>
                {t('common_delete')}
              </Button>
            </Flex>
          );
        },
      },
    ],
    [setModalOpenType, setSelectedCategory],
  );
};
export default useColumns;
