import News from 'pages/news';
import NewsAdd from 'pages/news/news-add';
import NewsDetails from 'pages/news/news-details';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import { NEWS_ADD_ROUTE, NEWS_DETAILS_ROUTE, NEWS_ROUTE } from './list';

export const newsRoutes: RouteConfig[] = [
  {
    path: NEWS_ROUTE,
    element: <News />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_NEWS,
    ],
  },
  {
    path: NEWS_ADD_ROUTE,
    element: <NewsAdd />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_NEWS,
    ],
  },
  {
    path: NEWS_DETAILS_ROUTE,
    element: <NewsDetails />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_NEWS,
    ],
  },
];
