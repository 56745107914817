import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetEventsQuery } from 'services/meet/meetApiService';
import { GetEventsPropsType } from 'services/meet/meetApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from 'pages/events/events.utils';

interface UseRequestProps {
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: UseRequestProps) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const [getEvents, { isFetching, data, isLoading, isUninitialized }] = useLazyGetEventsQuery();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody && debouncedRequestBody.size) {
      const { filters } = debouncedRequestBody;

      const preparedBody: GetEventsPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
      };

      if (filters?.categoryIds?.length) {
        preparedBody.categoryIds = filters.categoryIds;
      }
      if (debouncedRequestBody.search) {
        preparedBody.title = debouncedRequestBody.search;
      }

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        preparedBody.sort = [
          {
            property: `ARTICLE.${debouncedRequestBody.property}`,
            order: debouncedRequestBody.order,
          },
        ];
      }
      getEvents(preparedBody);
    }
  }, [debouncedRequestBody, getEvents]);

  return {
    isLoading: isUninitialized || isLoading,
    isFetching: isFetching || isDebouncing,
    data,
  };
};

export default useRequest;
