import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateSurveyRequestType,
  CreateSurveyResponseType,
  GetEmployerRequestType,
  GetEmployerResponseType,
  GetEmployerTransformedResponseType,
  GetFreeStatsByIdPropsType,
  GetFreeStatsByIdResponseType,
  GetRespondentsPropsType,
  GetRespondentsResponseType,
  GetSurveyByIdRequestType,
  GetSurveyByIdResponseType,
  GetSurveyStatsByIdPropsType,
  GetSurveyStatsByIdResponseType,
  GetSurveyStatusByIdPropsType,
  GetSurveyStatusByIdResponseType,
  GetSurveysEmployeeGroupsRequestType,
  GetSurveysEmployeeGroupsResponseType,
  GetSurveysListResponseType,
  GetSurveysPropsType,
  GetSurveysResponseType,
  MappedFreeStatsResponseType,
  UpdateSurveyRequestType,
} from './surveysApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';
import { SurveyType } from 'types/surveys.types';

import { mapEmployeeGroups, mapEmployer, mapFreetable, mapSurvey, mapSurveysList } from './utils';

const surveysApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.query<GetSurveysResponseType, GetSurveysPropsType>({
      query: (params) => ({
        url: `anketa${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_SURVEYS],
    }),
    getSurveySettingsById: builder.query<ReturnType<typeof mapSurvey>, string>({
      query: (id) => ({
        url: `anketa/${id}`,
      }),
      transformResponse: (response: SurveyType) => {
        return mapSurvey(response);
      },
      providesTags: [SERVICE_TAGS.GET_SURVEYS_SETTINGS_BY_ID],
    }),
    getSurveyStatusById: builder.query<
      GetSurveyStatusByIdResponseType,
      GetSurveyStatusByIdPropsType
    >({
      query: ({ id }) => ({
        url: `anketa/${id}/assign/state`,
      }),
      providesTags: [SERVICE_TAGS.GET_SURVEY_STATUS],
    }),
    createSurvey: builder.mutation<CreateSurveyResponseType, CreateSurveyRequestType>({
      query: (body) => ({
        url: `anketa`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SURVEYS],
    }),
    updateSurvey: builder.mutation<CreateSurveyResponseType, UpdateSurveyRequestType>({
      query: ({ id, body }) => ({
        url: `anketa/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SURVEYS, SERVICE_TAGS.GET_SURVEYS_SETTINGS_BY_ID],
    }),
    assignSurvey: builder.mutation<void, string>({
      query: (id) => ({
        url: `anketa/${id}/assign`,
        method: 'POST',
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_SURVEYS,
        SERVICE_TAGS.GET_SURVEY_STATUS,
        SERVICE_TAGS.GET_SURVEYS_SETTINGS_BY_ID,
      ],
    }),
    cancelSurvey: builder.mutation<void, string>({
      query: (id) => ({
        url: `anketa/${id}/cancel`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SURVEYS, SERVICE_TAGS.GET_SURVEY_STATUS],
    }),
    getSurveysList: builder.query<ReturnType<typeof mapSurveysList>, void>({
      query: () => ({
        url: `anketolog/survey/manage/list`,
        method: 'POST',
      }),
      transformResponse: (response: GetSurveysListResponseType[]) => {
        return mapSurveysList(response);
      },
    }),
    getSurveysEmployeeGroups: builder.query<
      ReturnType<typeof mapEmployeeGroups>,
      GetSurveysEmployeeGroupsRequestType
    >({
      query: (query) => ({
        url: `employee-group${prepareQueryParams(query)}`,
      }),
      transformResponse: (response: GetSurveysEmployeeGroupsResponseType) => {
        return mapEmployeeGroups(response.content);
      },
    }),
    getEmployerSurveys: builder.query<GetEmployerTransformedResponseType, GetEmployerRequestType>({
      query: () => ({
        url: `employer`,
      }),
      transformResponse: (response: GetEmployerResponseType) => {
        return mapEmployer(response.content);
      },
    }),
    getSurveyById: builder.query<GetSurveyByIdResponseType, GetSurveyByIdRequestType>({
      query: (body) => ({
        url: `anketolog/survey/manage/info`,
        method: 'POST',
        body,
      }),
    }),
    getSurveyStatsById: builder.query<GetSurveyStatsByIdResponseType, GetSurveyStatsByIdPropsType>({
      query: ({ id, revisionId, qoaQuery, ...query }) => ({
        url: `anketa/${id}/revision/${revisionId}/stats${prepareQueryParams(query)}${
          qoaQuery ?? ''
        }`,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data.questions) {
          dispatch(
            initialApiService.util.invalidateTags([{ type: SERVICE_TAGS.GET_SURVEY_FREE_STATS }]),
          );
        }
      },
    }),
    getFreeStatsById: builder.query<MappedFreeStatsResponseType, GetFreeStatsByIdPropsType>({
      query: ({ id, revisionId, questionId, qoaQuery, ...body }) => ({
        url: `anketa/${id}/revision/${revisionId}/stats/question/${questionId}${prepareQueryParams(
          body,
        )}${qoaQuery ?? ''}`,
      }),
      providesTags: [SERVICE_TAGS.GET_SURVEY_FREE_STATS],
      transformResponse: (response: GetFreeStatsByIdResponseType) => {
        return mapFreetable(response);
      },
    }),
    getRespondents: builder.query<GetRespondentsResponseType, GetRespondentsPropsType>({
      query: ({ anketaId, ...params }) => ({
        url: `respondents/anketa/${anketaId}${prepareQueryParams(params)}`,
      }),
    }),
  }),
});

export const {
  useLazyGetSurveysQuery,
  useGetSurveySettingsByIdQuery,
  useGetSurveysListQuery,
  useGetSurveysEmployeeGroupsQuery,
  useCreateSurveyMutation,
  useGetSurveyByIdQuery,
  useLazyGetSurveyStatsByIdQuery,
  useAssignSurveyMutation,
  useGetEmployerSurveysQuery,
  useLazyGetFreeStatsByIdQuery,
  useUpdateSurveyMutation,
  useLazyGetRespondentsQuery,
  useGetSurveyStatusByIdQuery,
  useCancelSurveyMutation,
  useLazyGetSurveyStatusByIdQuery,
} = surveysApiService;
