import { Component } from 'react';

import cn from 'classnames';
import Quill from 'quill';
// @ts-ignore
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { t } from 'tools/i18n';
import { MEDIA_SECONDARY_PREFIX, createMediaDownloadUrl } from 'utils/createMediaUrl.utils';

import ImageModal from 'containers/image-modal';

import s from './EditorClass.module.scss';
import EditorToolbar from './editor-toolbar';
import { isEmptyHtml } from './editorClass.utils';

interface EditorProps {
  html?: string;
  onChange: (html: string) => void;
  disabled?: boolean;
}

class Editor extends Component<EditorProps, { imageModalIsOpened: boolean }> {
  constructor(props: EditorProps) {
    super(props);
    this.quillRef = null;
    this.reactQuillRef = null;
    this.state = {
      imageModalIsOpened: false,
    };
  }

  quillRef: Quill | null;
  reactQuillRef: ReactQuill | null;

  setEditorData(data: string) {
    const delta = this.quillRef?.clipboard.convert(
      this.transformOutputText(
        data.replaceAll(
          MEDIA_SECONDARY_PREFIX,
          `${process.env.https://api.dev.tockl.ru/}${MEDIA_SECONDARY_PREFIX}`,
        ),
      ),
    );
    const valuesAreDifferent: boolean =
      this.transformOutputText(this.reactQuillRef?.value?.toString()!) !==
      (delta as unknown as string);

    if (valuesAreDifferent && delta) {
      this.quillRef?.setContents(delta, 'silent');
    }
  }

  getEditorData() {
    return this.transformOutputText(this.reactQuillRef?.value?.toString()!);
  }

  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  transformOutputText = (text: string): string => {
    return text.replaceAll(/(<img[^>]*)>/g, '$1/>');
  };

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef?.getEditor === 'function') {
      this.quillRef = this.reactQuillRef.getEditor();
    }
  };

  insertImg = (url: string) => {
    const range = this.quillRef?.getSelection();
    const position = range ? range.index : 0;

    this.quillRef?.insertEmbed(position, 'image', createMediaDownloadUrl(url));
    this.handleImageOpenClose();
  };

  handleImageOpenClose = () => {
    this.setState((state) => ({ imageModalIsOpened: !state.imageModalIsOpened }));
  };

  handleOnChange = (html: string) => {
    const trimmedText = html.replaceAll(' ', '');
    const isEmptyText = isEmptyHtml(trimmedText);

    if (isEmptyText) {
      this.props.onChange('');
    } else {
      this.props.onChange(
        this.transformOutputText(html).replaceAll(process.env.https://api.dev.tockl.ru/, ''),
      );
    }
  };

  render() {
    const modules = {
      toolbar: {
        container: '#toolbar',
        handlers: {
          insertImg: this.handleImageOpenClose,
          undo: undoChange,
          redo: redoChange,
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
    };

    return (
      <>
        <div className={s.wrapper}>
          <EditorToolbar disabled={this.props.disabled} />
          <ReactQuill
            readOnly={this.props.disabled}
            onChange={this.handleOnChange}
            placeholder={t('common_enter_text')}
            className={cn(s.quill, { [s.disabled]: this.props.disabled })}
            modules={modules}
            ref={(el: ReactQuill | null) => {
              if (el) {
                this.reactQuillRef = el;
              }
            }}
            theme="snow"
          />
        </div>
        <ImageModal
          isOpen={this.state.imageModalIsOpened}
          onClose={this.handleImageOpenClose}
          onSubmit={this.insertImg}
        />
      </>
    );
  }
}

function undoChange(this: { quill: { history: { undo: () => void } } }) {
  this.quill.history.undo();
}
function redoChange(this: { quill: { history: { redo: () => void } } }) {
  this.quill.history.redo();
}
export default Editor;
