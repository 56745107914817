import { GetEventsPropsType } from 'services/meet/meetApiService.types';

import { ARTICLE_TYPE_ENUM } from 'types/article.types';
import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';

export type RequestBodyType = {
  page: number;
  size: number;
};
export type FiltersType = Pick<GetEventsPropsType, 'categoryIds'> | null;

export type FormResponseType = PaginationPropsType & {
  filters?: FiltersType;
  property: string;
  order: SORT_ENUM;
  type: ARTICLE_TYPE_ENUM[];
  search: string;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  property: 'updatedAt',
  order: SORT_ENUM.DESC,
  type: [ARTICLE_TYPE_ENUM.NEWS],
  search: '',
};
