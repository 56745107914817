import { kedoRoles } from 'roles/documents.roles';
import { RouteConfig } from 'routes/routes';

import DocumentsBusinessTrips from 'pages/documents/documents-business-trips';
import DocumentsBusinessTripsDetails from 'pages/documents/documents-business-trips/documents-business-trips-details';
import DocumentsBypassSheet from 'pages/documents/documents-bypass-sheet';
import DocumentsBypassSheetDetails from 'pages/documents/documents-bypass-sheet/documents-bypass-sheet-details';
import DocumentsInquiries from 'pages/documents/documents-inquiries';
import DocumentsInquiriesDetails from 'pages/documents/documents-inquiries/document-inquiries-details';
import DocumentsKedoReviewTasks from 'pages/documents/documents-kedo-review-tasks';
import DocumentsKedoReviewTaskCreate from 'pages/documents/documents-kedo-review-tasks/documents-kedo-review-task-create';
import DocumentsKedoReviewTaskDetails from 'pages/documents/documents-kedo-review-tasks/documents-kedo-review-task-details';
import DocumentsRecruit from 'pages/documents/documents-recruit';
import DocumentsRecruitDetails from 'pages/documents/documents-recruit/documents-recruit-details';
import DocumentsSigns from 'pages/documents/documents-signs';
import DocumentsSignsDetails from 'pages/documents/documents-signs/documents-signs-details';
import DocumentsStatements from 'pages/documents/documents-statements';
import DocumentsTopManagementForms from 'pages/documents/documents-top-management-forms';
import DocumentsTopManagementFormsDetails from 'pages/documents/documents-top-management-forms/top-management-forms-details';
import DocumentsView from 'pages/documents/documents-view';

import { GLOBAL_ROLES } from 'types/roles.types';

import {
  DOCUMENTS_BUSINESS_TRIPS_BY_ID_ROUTE,
  DOCUMENTS_BUSINESS_TRIPS_ROUTE,
  DOCUMENTS_BYPASS_SHEET_BY_ID_ROUTE,
  DOCUMENTS_BYPASS_SHEET_ROUTE,
  DOCUMENTS_INQUIRIES_BY_ID_ROUTE,
  DOCUMENTS_INQUIRIES_ROUTE,
  DOCUMENTS_KEDO_REVIEW_TASKS_BY_ID_ROUTE,
  DOCUMENTS_KEDO_REVIEW_TASKS_CREATE_ROUTE,
  DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE,
  DOCUMENTS_RECRUIT_BY_ID_ROUTE,
  DOCUMENTS_RECRUIT_ROUTE,
  DOCUMENTS_SINGS_BY_ID_ROUTE,
  DOCUMENTS_SINGS_ROUTE,
  DOCUMENTS_STATEMENTS_BY_ID_ROUTE,
  DOCUMENTS_STATEMENTS_ROUTE,
  DOCUMENTS_TOP_MANAGEMENT_FORMS_DETAILS_ROUTE,
  DOCUMENTS_TOP_MANAGEMENT_FORMS_ROUTE,
} from './list';

export const documentsRoutes: RouteConfig[] = [
  {
    path: DOCUMENTS_STATEMENTS_ROUTE,
    element: <DocumentsStatements />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_STATEMENTS_BY_ID_ROUTE,
    element: <DocumentsView />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE,
    element: <DocumentsKedoReviewTasks />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_KEDO_REVIEW_TASKS_CREATE_ROUTE,
    element: <DocumentsKedoReviewTaskCreate />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_KEDO_REVIEW_TASKS_BY_ID_ROUTE,
    element: <DocumentsKedoReviewTaskDetails />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_SINGS_ROUTE,
    element: <DocumentsSigns />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_SINGS_BY_ID_ROUTE,
    element: <DocumentsSignsDetails />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_INQUIRIES_ROUTE,
    element: <DocumentsInquiries />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIPS_ROUTE,
    element: <DocumentsBusinessTrips />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIPS_BY_ID_ROUTE,
    element: <DocumentsBusinessTripsDetails />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_INQUIRIES_BY_ID_ROUTE,
    element: <DocumentsInquiriesDetails />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_RECRUIT_ROUTE,
    element: <DocumentsRecruit />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_RECRUIT_BY_ID_ROUTE,
    element: <DocumentsRecruitDetails />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_BYPASS_SHEET_ROUTE,
    element: <DocumentsBypassSheet />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_BYPASS_SHEET_BY_ID_ROUTE,
    element: <DocumentsBypassSheetDetails />,
    ...kedoRoles,
  },
  {
    path: DOCUMENTS_TOP_MANAGEMENT_FORMS_ROUTE,
    element: <DocumentsTopManagementForms />,
    roles: [GLOBAL_ROLES.MODERATOR_TOPS_ANKETA],
  },
  {
    path: DOCUMENTS_TOP_MANAGEMENT_FORMS_DETAILS_ROUTE,
    element: <DocumentsTopManagementFormsDetails />,
    roles: [GLOBAL_ROLES.MODERATOR_TOPS_ANKETA],
  },
];
