import { FC, memo, useEffect, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { useDeleteGuideCategoriesMutation } from 'services/settlement/settlementApiService';

import {
  Button,
  ButtonProps,
  Icon,
  Modal,
  TIconsLiteral,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import { GuideCategoryType } from 'types/guide.types';

import s from './GuideCategoriesDeleteModal.module.scss';

interface GuideCategoriesAddEditModalProps {
  open: boolean;
  handleClose: () => void;
  selectedCategory: null | GuideCategoryType;
}

const GuideCategoriesDeleteModal: FC<GuideCategoriesAddEditModalProps> = (props) => {
  const { open, handleClose, selectedCategory } = props;

  const [isDeleteSucceeded, setIsDeleteSucceeded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [deleteCategory, { isLoading }] = useDeleteGuideCategoriesMutation();

  useEffect(() => {
    if (open) {
      setIsDeleteSucceeded(false);
      setIsError(false);
    }
  }, [open]);

  let config: {
    icon: TIconsLiteral;
    buttonProps: ButtonProps;
    title: string;
    subtitle: string;
    iconWrapperClassName: string;
  } = {
    iconWrapperClassName: s.deleteIcon,
    icon: 'bin',
    title: t('common_accept_remove'),
    subtitle: t('guide_category_will_be_removed'),
    buttonProps: {
      onClick: () =>
        deleteCategory([selectedCategory?.id ?? '']).then(
          handleRequest({
            onSuccess: () => setIsDeleteSucceeded(true),
            onError: () => setIsError(true),
          }),
        ),
      children: t('common_delete'),
      leftIcon: 'bin',
      loading: isLoading,
      danger: true,
      ghost: true,
    },
  };

  if (isError) {
    config = {
      iconWrapperClassName: s.errorIcon,
      icon: 'info',
      title: t('common_error'),
      subtitle: t('guide_category_cant_be_removed'),
      buttonProps: {
        onClick: handleClose,
        children: t('common_ok'),
      },
    };
  }

  if (isDeleteSucceeded) {
    config = {
      iconWrapperClassName: s.successIcon,
      icon: 'ok',
      title: t('guide_category_removed'),
      subtitle: t(''),
      buttonProps: {
        onClick: handleClose,
        children: t('common_ok'),
      },
    };
  }

  useEffect(() => {
    if (!open) {
      setIsDeleteSucceeded(false);
    }
  }, [open]);

  return (
    <Modal open={open} onCancel={handleClose} width={320} closable>
      <div className={s.wrapper}>
        <div className={cn(s.iconWrapper, config.iconWrapperClassName)}>
          <Icon name={config.icon} size={40} color="var(--color-neutral-1)" />
        </div>
        <div className={s.text}>
          <Typography.Title level={3}>{config.title}</Typography.Title>
          <Typography.Text size="16" weight="400" type="secondary">
            {config.subtitle}
          </Typography.Text>
        </div>
        <Button loading={isLoading} fullWidth {...config.buttonProps} />
      </div>
    </Modal>
  );
};

export default memo(GuideCategoriesDeleteModal);
