import { useCallback } from 'react';

import dayjs from 'dayjs';
import { useMatch, useNavigate } from 'react-router-dom';
import {
  NOTIFICATION_MAILING_MANAGEMENT_ROUTE,
  NOTIFICATION_SYSTEM_NEWSLETTER_EDIT_ROUTE,
  NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE,
} from 'routes/notification/list';
import { t } from 'tools/i18n';

import { Button, Tabs } from 'gazprom-ui-lib';

import LastChange from 'containers/last-change';
import NotificationNewsletterForm from 'containers/notification-newsletter-form';
import { NOTIFICATION_NEWSLETTER_FORM_NAME } from 'containers/notification-newsletter-form/notificationNewsletterForm.utils';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { NOTIFICATION_NEWSLETTER_TAB } from 'pages/notification/notification.utils';

import useCreateNewsletter from '../useCreateNewsletter.hook';
import s from './NotificationNewsletterEdit.module.scss';
import Actions from './containers/actions';
import Header from './containers/header';
import NotificationNewsletterEditStats from './notification-newsletter-edit-stats';
import useGetNewsletter from './useGetNewsletter.hook';
import useSubmit from './useSubmit.hook';

const NotificationNewsletterEdit = () => {
  const navigate = useNavigate();

  const isSystemNotification = !!useMatch(NOTIFICATION_SYSTEM_NEWSLETTER_EDIT_ROUTE);

  const handleGoBack = useCallback(() => {
    const navigateLink = isSystemNotification
      ? NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE
      : NOTIFICATION_MAILING_MANAGEMENT_ROUTE;

    navigate(navigateLink, {
      state: {
        activeTab: NOTIFICATION_NEWSLETTER_TAB,
      },
    });
  }, [isSystemNotification, navigate]);

  const { isLoading, currentNewsletter } = useGetNewsletter();
  const { handleSubmit: editNewsletter, updateNewsletterIsFetching } = useSubmit({ handleGoBack });
  const { handleSubmit: createNewsletter, createNewsletterIsFetching } = useCreateNewsletter({
    currentNewsletter,
  });

  const isFetching = createNewsletterIsFetching || updateNewsletterIsFetching;

  const isEdit =
    currentNewsletter?.dateToSend && dayjs(currentNewsletter.dateToSend).isAfter(dayjs());

  const saveButton = (
    <Button
      leftIcon="refresh"
      htmlType="submit"
      onClick={createNewsletter}
      form={NOTIFICATION_NEWSLETTER_FORM_NAME}>
      {t('notification_newsletter_refresh')}
    </Button>
  );

  const actions = (
    <Actions
      saveButton={saveButton}
      isFetching={isFetching}
      currentNewsletter={currentNewsletter}
      handleGoBack={handleGoBack}
    />
  );

  const tabs = [
    {
      key: 'statistic',
      children: (
        <>
          <NotificationNewsletterEditStats currentNewsletter={currentNewsletter} />
          {actions}
        </>
      ),
      label: t('common_statistic'),
    },
    {
      key: 'view_newsletter',
      children: (
        <>
          <div className={s.formWrapper}>
            <NotificationNewsletterForm
              disabled={true}
              handleReset={handleGoBack}
              handleSubmit={isEdit ? editNewsletter : createNewsletter}
              currentNewsletter={currentNewsletter}
            />
          </div>
          {actions}
        </>
      ),
      label: t('notification_view_newsletter'),
    },
  ];

  return (
    <WithOverflow className={s.wrapper}>
      <Header saveButton={saveButton} isSystemNotification={isSystemNotification} />
      <WithLoader isLoading={isLoading}>
        <div className={s.header}>
          <LastChange
            date={currentNewsletter?.createdAt}
            editorId={currentNewsletter?.sender?.personId}
            editorName={currentNewsletter?.sender?.senderName}
          />
        </div>
        <Tabs items={tabs} type="card" />
      </WithLoader>
    </WithOverflow>
  );
};

export default NotificationNewsletterEdit;
