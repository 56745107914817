import { FC } from 'react';

import { t } from 'tools/i18n';

import ContentHeader from 'containers/content-header';

const Header: FC = () => {
  return (
    <ContentHeader>
      <ContentHeader.Title>{t('top_management_positions')}</ContentHeader.Title>
    </ContentHeader>
  );
};

export default Header;
