import { FC, useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { EMPLOYEE_GROUPS_ROUTE } from 'routes/employee-groups/list';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useLazyGetEmployeeGroupQuery } from 'services/employee-group/employeeGroupApiService';

import { Button, Form, Select, Typography } from 'gazprom-ui-lib';

import { NOTIFICATION_TEMPLATE_FORM_ENUM } from '../notificationTemplateForm.utils';
import s from './NotificationTemplateFormReceivers.module.scss';

interface NotificationNewsletterFormReceiversProps {
  disabled?: boolean;
}

const NotificationTemplateFormReceivers: FC<NotificationNewsletterFormReceiversProps> = (props) => {
  const navigate = useNavigate();

  const { disabled } = props;

  const [getEmployeeGroup, { data: employeesGroup }] = useLazyGetEmployeeGroupQuery();

  useEffect(() => {
    getEmployeeGroup({ page: 1, size: 1000 });
  }, [getEmployeeGroup]);

  const options = useMemo(() => {
    return employeesGroup?.content?.map((group) => ({
      label: group.title,
      value: group.id,
    }));
  }, [employeesGroup?.content]);

  const handleNavigateToEmployeeGroups = () => {
    navigate(EMPLOYEE_GROUPS_ROUTE);
  };

  return (
    <div>
      <Typography.Title level={4}>{t('notification_receiver')}</Typography.Title>
      <Typography.Text strong size="16" className={s.label}>
        {t('notification_receivers_add')}
      </Typography.Text>
      <Form.Item name={NOTIFICATION_TEMPLATE_FORM_ENUM.RECEIVERS} rules={[formRules.required]}>
        <Select
          placeholder={t('common_enter_text')}
          options={options}
          mode="multiple"
          maxTagCount="responsive"
          showSearch
          filterOption
          optionFilterProp="label"
          disabled={disabled}
        />
      </Form.Item>
      <Button
        htmlType="button"
        type="link"
        leftIcon="groupsFilled"
        className={s.receiversLink}
        onClick={handleNavigateToEmployeeGroups}>
        {t('surveys_groups_management')}
      </Button>
    </div>
  );
};

export default NotificationTemplateFormReceivers;
