import { Outlet } from 'react-router-dom';

import { Card, Layout } from 'gazprom-ui-lib';

import Header from '../header';
import Sidebar from '../sidebar';
import s from './Layout.module.scss';

const MainLayout = () => {
  return (
    <Layout className={s.wrapper}>
      <Header />
      <Layout className={s.contentWithSidebar} id="app-content">
        <Sidebar />
        <Layout className={s.content}>
          <Card withPadding={false}>
            <Outlet />
          </Card>
        </Layout>
      </Layout>
      <Layout.Footer className={s.footer} id="app-footer" />
    </Layout>
  );
};

export default MainLayout;
