import { t } from 'tools/i18n';

import DocumentsDeclarationsAdditional from '../documents-declarations-additional';
import DocumentsDeclarationsParameters from '../documents-declarations-parameters';
import Common from './containers/common';

export const ADDITIONAL_TAB = 'ADDITIONAL_TAB';
export const PARAMETERS_TAB = 'PARAMETERS_TAB';
export const COMMON_TAB = 'COMMON_TAB';

export const INITIAL_TAB = {
  key: COMMON_TAB,
  label: t('common_common_description'),
  children: <Common />,
};

export const TABS = [
  INITIAL_TAB,
  {
    key: PARAMETERS_TAB,
    label: t('documents_declarations_parameters_tab'),
    children: <DocumentsDeclarationsParameters />,
  },
  {
    key: ADDITIONAL_TAB,
    label: t('common_additional_fields'),
    children: <DocumentsDeclarationsAdditional />,
  },
];
