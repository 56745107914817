import { Icon, Typography } from 'gazprom-ui-lib';

import s from './AdditionalInfo.module.scss';

const textParams = { size: '14', weight: '400', color: 'text45' } as const;

interface Props {
  id: number | string;
  revisionId: number | string;
}

const AdditionalInfo = (props: Props) => {
  const { id, revisionId } = props;
  return (
    <div className={s.container}>
      <div className={s.item}>
        <Icon name="solution" />
        <Typography.Text {...textParams}>ID Анкеты: {id}</Typography.Text>
      </div>
      <div className={s.item}>
        <Icon name="tags" />
        <Typography.Text {...textParams}>ID Ревизии: {revisionId}</Typography.Text>
      </div>
    </div>
  );
};

export default AdditionalInfo;
