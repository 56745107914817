import { useEffect, useMemo, useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { initRoutes } from 'routes/routes';
import { t } from 'tools/i18n';
import { SCREEN_SIZE_ENUM, useScreenSize } from 'utils/useScreenSize.hook';

import { Sidebar as LibSidebar, SidebarRouteType } from 'gazprom-ui-lib';

import { renderRoutes } from 'pages/pages';

import s from './Sidebar.module.scss';
import { sidebarButtons } from './sidebar.utils';

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isAuthenticated, user } = useAuth();
  const { screenSize, isInitialised } = useScreenSize();

  const isNormalView =
    screenSize === SCREEN_SIZE_ENUM.NORMAL_SCREEN || screenSize === SCREEN_SIZE_ENUM.SMALL_SCREEN;

  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);

  useEffect(() => {
    if (isInitialised) {
      setIsSideBarOpen(isNormalView);
    }
  }, [isNormalView, isInitialised]);

  const routes = useMemo(
    () =>
      initRoutes(isAuthenticated, user?.profile?.roles as string[] | undefined).map(renderRoutes),
    [isAuthenticated, user],
  );

  const availableRoutes = useMemo(() => {
    const checkSidebarItems = (
      previousValue: SidebarRouteType[],
      currentValue: SidebarRouteType,
    ) => {
      const hasInnerRoutes = !!currentValue.items?.length;
      const parentRouteIsAvailable = routes.find(
        (routeComponent) => routeComponent.props.path === currentValue.path,
      );

      if (hasInnerRoutes) {
        const availableInnerRoutes = currentValue.items?.reduce(
          checkSidebarItems,
          [] as SidebarRouteType[],
        );
        if (availableInnerRoutes?.length) {
          previousValue.push({ ...currentValue, items: availableInnerRoutes });
        }
      } else if (parentRouteIsAvailable) {
        previousValue.push(currentValue);
      }

      return previousValue;
    };

    return sidebarButtons.reduce(checkSidebarItems, [] as SidebarRouteType[]);
  }, [routes]);

  const getIsRouteActive = (path: string) => pathname.startsWith(path);
  const handleRouteClick = (path: string) => navigate(path);

  return (
    <LibSidebar
      isSideBarOpen={isSideBarOpen}
      setIsSideBarOpen={setIsSideBarOpen}
      classNameCollapse={s.classNameForMobileCollapsed}
      availableRoutes={availableRoutes}
      getIsInnerRouteActive={getIsRouteActive}
      getIsRouteActive={getIsRouteActive}
      title={t('common_available_sections')}
      handleRouteClick={handleRouteClick}
    />
  );
};

export default Sidebar;
