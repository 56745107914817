import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import {
  useAddPositionMutation,
  useLazyGetAvailablePositionsQuery,
} from 'services/renew-term/renewApiService';

import { Flex, Icon, Option, Select } from 'gazprom-ui-lib';

import { RenewTermPositionType } from 'types/topManagement.types';

import s from './PositionSelect.module.scss';

interface PositionSelectProps {
  employerId?: string;
}

const DEFAULT_REQUEST_BODY = { page: 1, size: 500 };

// TODO - rework this component after release
const PositionSelect: FC<PositionSelectProps> = (props) => {
  const { employerId } = props;

  const [search, setSearch] = useState<string>('');
  const { debouncedValue: debouncedSearch } = useDebounce(search);

  const [getAvailablePositions, { data: positionOptions, isFetching }] =
    useLazyGetAvailablePositionsQuery();

  useEffect(() => {
    if (employerId) {
      getAvailablePositions({ ...DEFAULT_REQUEST_BODY, employerId, name: debouncedSearch });
    }
  }, [debouncedSearch, employerId, getAvailablePositions]);

  const [addPosition, { isLoading }] = useAddPositionMutation();

  const renderPositionOption = (option: RenewTermPositionType) => {
    return (
      <Option value={option.positionName} key={option.positionName}>
        <Flex justify="space-between" align="center" className={s.option}>
          {option.positionName}
          <Icon name="plus" />
        </Flex>
      </Option>
    );
  };

  const handleSelect = (positionName: string) => {
    if (employerId && positionName) {
      addPosition([{ employerId, positionName }]);
    }
  };

  const loading = isLoading || isFetching;

  return (
    <Select
      value={null}
      className={s.select}
      placeholder={t('top_management_add_position_from_list')}
      loading={loading}
      allowClear
      showSearch
      searchValue={search}
      onSearch={setSearch}
      onSelect={handleSelect}>
      {positionOptions?.items?.map(renderPositionOption)}
    </Select>
  );
};

export default PositionSelect;
