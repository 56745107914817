import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { SOCIAL_PROGRAM_ADD_ROUTE } from 'routes/social-program/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

interface HeaderProps {
  total: number | undefined;
}
const Header: FC<HeaderProps> = (props) => {
  const { total } = props;

  const navigate = useNavigate();

  const handleAdd = () => {
    navigate(SOCIAL_PROGRAM_ADD_ROUTE);
  };

  return (
    <ContentHeader>
      <ContentHeader.Title total={total}>{t('social_program_list')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="plus" onClick={handleAdd}>
          {t('social_program_add')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
