import { FC, useEffect } from 'react';

import { t } from 'tools/i18n';
import { normalizeTextField } from 'utils/formRules';

import { Button, Form, Input, TextArea, Typography } from 'gazprom-ui-lib';

import { HandbookInstructionType } from 'types/guide.types';

import s from './HandbookInstructionForm.module.scss';
import ImageFile from './containers/image-file';
import {
  HANDBOOK_INSTRUCTION_ENUM,
  HandbookInstructionResponseType,
  createPhoneOption,
} from './handbookInstructionForm.utils';

interface HandbookInstructionFormProps {
  name: string;
  onSubmit: (formResponse: HandbookInstructionResponseType) => void;
  instruction?: HandbookInstructionType;
}

const HandbookInstructionForm: FC<HandbookInstructionFormProps> = (props) => {
  const { name, onSubmit, instruction } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (instruction) {
      form.setFieldsValue({
        [HANDBOOK_INSTRUCTION_ENUM.TITLE]: instruction.name,
        [HANDBOOK_INSTRUCTION_ENUM.OPERATION_MODE]: instruction.schedule,
        [HANDBOOK_INSTRUCTION_ENUM.LOCATION]: instruction.location,
        [HANDBOOK_INSTRUCTION_ENUM.SERVICE]: instruction.department,
        [HANDBOOK_INSTRUCTION_ENUM.PHONE]: instruction.contacts?.length
          ? instruction.contacts.map(createPhoneOption)
          : [
              {
                [HANDBOOK_INSTRUCTION_ENUM.PHONE_OUTER]: '',
                [HANDBOOK_INSTRUCTION_ENUM.PHONE_INNER]: '',
              },
            ],
        [HANDBOOK_INSTRUCTION_ENUM.EMAIL]: instruction.contacts?.[0]?.email,
        [HANDBOOK_INSTRUCTION_ENUM.FILE]: instruction.file?.link
          ? {
              link: instruction.file?.link,
              name: instruction.file?.name,
            }
          : null,
      });
    }
  }, [form, instruction]);

  return (
    <Form form={form} name={name} onFinish={onSubmit} className={s.wrapper}>
      <div>
        <Typography.Text strong size="16">
          {t('common_title')}
        </Typography.Text>
        <Form.Item normalize={normalizeTextField} name={HANDBOOK_INSTRUCTION_ENUM.TITLE}>
          <Input placeholder={t('common_enter_text')} />
        </Form.Item>
      </div>
      <div>
        <Typography.Text strong size="16">
          {t('common_operation_mode')}
        </Typography.Text>
        <Form.Item normalize={normalizeTextField} name={HANDBOOK_INSTRUCTION_ENUM.OPERATION_MODE}>
          <TextArea placeholder={t('common_enter_text')} maxLength={240} showCount />
        </Form.Item>
      </div>
      <div>
        <Typography.Text strong size="16">
          {t('common_location')}
        </Typography.Text>
        <Form.Item normalize={normalizeTextField} name={HANDBOOK_INSTRUCTION_ENUM.LOCATION}>
          <Input placeholder={t('common_enter_text')} />
        </Form.Item>
      </div>
      <div>
        <Typography.Text strong size="16">
          {t('common_responsible_service')}
        </Typography.Text>
        <Form.Item normalize={normalizeTextField} name={HANDBOOK_INSTRUCTION_ENUM.SERVICE}>
          <Input placeholder={t('common_enter_text')} />
        </Form.Item>
      </div>
      <ImageFile />
      <div>
        <Typography.Text strong size="16">
          {t('common_phone')}
        </Typography.Text>
        <Form.List name={HANDBOOK_INSTRUCTION_ENUM.PHONE}>
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} className={s.row}>
                  <Form.Item
                    {...restField}
                    name={[name, HANDBOOK_INSTRUCTION_ENUM.PHONE_OUTER]}
                    normalize={(value) => value.trimStart()}>
                    <Input
                      size="small"
                      type="phoneFilled"
                      placeholder={t('common_enter_the_number')}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={t('Внутр. номер')}
                    name={[name, HANDBOOK_INSTRUCTION_ENUM.PHONE_INNER]}
                    normalize={(value) => value.trim()}>
                    <Input size="small" placeholder="0000" />
                  </Form.Item>
                  <Button onClick={() => remove(name)} type="text" leftIcon="bin" danger>
                    {t('common_remove')}
                  </Button>
                </div>
              ))}
              <Button onClick={add} className={s.addPhone} ghost leftIcon="plus" size="small">
                {t('common_add_phone')}
              </Button>
            </div>
          )}
        </Form.List>
      </div>
      <div>
        <Typography.Text strong size="16">
          {t('common_email')}
          <Typography.Text size="12" type="secondary" className={s.optional}>
            {t('common_optional')}
          </Typography.Text>
        </Typography.Text>
        <Form.Item normalize={normalizeTextField} name={HANDBOOK_INSTRUCTION_ENUM.EMAIL}>
          <Input placeholder={t('common_enter_address')} />
        </Form.Item>
      </div>
      <Button htmlType="submit" className={s.submitButton}>
        {t('common_save')}
      </Button>
    </Form>
  );
};

export default HandbookInstructionForm;
