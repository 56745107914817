import { FC, ReactElement, memo } from 'react';

import { useAuth } from 'react-oidc-context';
import { Route, Routes } from 'react-router-dom';
import { RouteConfig, initRoutes } from 'routes/routes';

import MainLayout from 'containers/layouts';

import useLastPathHook from '../utils/useLastPath.hook';

export const renderRoutes = ({ path, element, children, id = '' }: RouteConfig): ReactElement => {
  const routes = children?.map(renderRoutes);
  return (
    <Route path={path} element={element} key={`route__${path}__${id}`}>
      {routes}
    </Route>
  );
};

const Pages: FC = () => {
  const { isAuthenticated, user } = useAuth();

  const routes = initRoutes(isAuthenticated, user?.profile?.roles as string[] | undefined).map(
    renderRoutes,
  );

  useLastPathHook(isAuthenticated);

  if (isAuthenticated) {
    return (
      <Routes>
        <Route element={<MainLayout />}>{routes}</Route>
      </Routes>
    );
  }

  return <Routes>{routes}</Routes>;
};

export default memo(Pages);
