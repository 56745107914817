import { t } from 'tools/i18n';

import { useGetBusinessTripStatusesQuery } from 'services/1c/work-tour/workTourApiService';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { KedoStatusType } from 'types/kedo.types';

import { FormFiltersType } from '../../documentsBusinessTrips.utils';

const Filters = () => {
  const { data: statuses } = useGetBusinessTripStatusesQuery();

  const statusIntoOption = (status: KedoStatusType) => ({
    label: status.name,
    value: status.code,
  });

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <FiltersV2.RangePicker />
      <FiltersContainer<FormFiltersType>
        overlay={
          <FilterCustomWrapper>
            <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
            <FilterCheckboxGroup
              usePadding={false}
              valueKey="status"
              options={(statuses ?? [])?.map(statusIntoOption)}
            />
          </FilterCustomWrapper>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
