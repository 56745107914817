import { FC, useState } from 'react';

import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';

import { Form } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import UploadImitation from 'components/upload-imitation';
import ImageModal from 'containers/image-modal';

import { EVENT_FORM_ENUM, FORM_LABELS } from '../../../eventForm.utils';
import s from './Cover.module.scss';

const Cover: FC = () => {
  const form = Form.useFormInstance();

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const cover: string = Form.useWatch(EVENT_FORM_ENUM.COVER);

  const handleModalOpenClose = () => {
    setShowUploadModal((prevState) => !prevState);
  };
  const onImgUpload = (url: string) => {
    form.setFieldValue(EVENT_FORM_ENUM.COVER, url);
    handleModalOpenClose();
  };

  const resetImage = () => {
    form.resetFields([EVENT_FORM_ENUM.COVER]);
  };

  return (
    <>
      <Form.Item name={EVENT_FORM_ENUM.COVER} label={FORM_LABELS[EVENT_FORM_ENUM.COVER]}>
        {cover ? (
          <ImgPreview type="image" url={createMediaDownloadUrl(cover)} resetImage={resetImage} />
        ) : (
          <UploadImitation handleClick={handleModalOpenClose} className={s.upload} />
        )}
      </Form.Item>
      <ImageModal isOpen={showUploadModal} onSubmit={onImgUpload} onClose={handleModalOpenClose} />
    </>
  );
};

export default Cover;
