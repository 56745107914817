import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetNotificationsPropsType,
  GetNotificationsResponseType,
} from './notificationApiService.types';

import { notificationUrl } from './utils';

const notificationApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<GetNotificationsResponseType, GetNotificationsPropsType>({
      query: (params) => ({
        url: notificationUrl(`${prepareQueryParams(params)}`),
      }),
    }),
  }),
});

export const { useLazyGetNotificationsQuery } = notificationApiService;
