import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetSynclogByIdQuery } from 'services/synclog/synclogService';
import { GetSynclogByIdPropsType } from 'services/synclog/synclogService.types';

import { RequestBody } from './settings1cInstanceDetails.utils';

interface UseSynclogRequestHookProps {
  requestBody: RequestBody;
  cInstanceId?: string;
}

const useSynclogRequestHook = (props: UseSynclogRequestHookProps) => {
  const { requestBody, cInstanceId } = props;

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  const [getSyncLog, { data: synclogData, isFetching }] = useLazyGetSynclogByIdQuery();

  useEffect(() => {
    if (cInstanceId) {
      const defaultBody: GetSynclogByIdPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        instance1cIdFilterParam: cInstanceId,
      };

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        defaultBody.sort = [
          { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
        ];
      }

      getSyncLog(defaultBody);
    }
  }, [debouncedRequestBody, getSyncLog, cInstanceId]);

  return { synclogData, isFetching };
};

export default useSynclogRequestHook;
