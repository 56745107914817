import dayjs from 'dayjs';
import { DOCUMENTS_BUSINESS_TRIPS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import ContentHeader from 'containers/content-header';

import { BusinessTripType } from 'types/businessTrip.types';

interface Props {
  businessTrip?: BusinessTripType;
}

const Header = (props: Props) => {
  const { businessTrip } = props;

  const description = businessTrip && (
    <>
      {`${t('common_last_change')}: `}
      <Flex gap="4" align="center">
        <Icon name="calendar" />
        <Typography.Text type="secondary">
          {dayjs.utc(businessTrip.updatedAt).local().format('DD MMMM YYYY, HH:mm')}
        </Typography.Text>
      </Flex>
    </>
  );

  return (
    <ContentHeader align="flex-start" showBorder>
      <ContentHeader.Title description={description} goBackLink={DOCUMENTS_BUSINESS_TRIPS_ROUTE}>
        {t('documents_business_trip_view')}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        {businessTrip && (
          <KedoStatusTag size="30" currentKedoStatus={businessTrip?.currentKedoStatus} />
        )}
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
