import { useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useGetFaqCategoriesQuery } from 'services/faq/faqApiService';

import { Button, Flex, Form, Select } from 'gazprom-ui-lib';

import FaqCategoryModal from 'containers/faq-category-modal';

import { FaqCategoryType } from 'types/faq.types';

import { FORM_ENUM } from '../../faqQuestionForm.utils';
import s from './Category.module.scss';
import { selectFromResult } from './category.utils';

const Category = () => {
  const form = Form.useFormInstance();

  const [showModal, setShowModal] = useState<boolean>(false);

  const { data: options, isFetching: faqCategoriesIsFetching } = useGetFaqCategoriesQuery(
    {
      page: 1,
      size: 500,
    },
    {
      selectFromResult,
    },
  );

  const handleModalOpenClose = () => {
    setShowModal((prevState) => !prevState);
  };

  const handleCategoryCreate = (category?: FaqCategoryType) => {
    form.setFieldValue(FORM_ENUM.PARENT, category?.id);
  };

  return (
    <Flex vertical align="flex-start" className={s.wrapper}>
      <Form.Item name={FORM_ENUM.PARENT} label={t('common_category')} rules={[formRules.required]}>
        <Select
          placeholder={t('common_choose_category_from_list_or_add')}
          loading={faqCategoriesIsFetching}
          options={options}
        />
      </Form.Item>
      <Button type="link" leftIcon="plus" onClick={handleModalOpenClose}>
        {t('common_add_category')}
      </Button>
      <FaqCategoryModal
        onClose={handleModalOpenClose}
        open={showModal}
        onFinish={handleCategoryCreate}
      />
    </Flex>
  );
};

export default Category;
