import { ArticleType } from 'types/article.types';

export enum EVENT_GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  BOTH = 'BOTH',
}

export type EventGroupTreeType = {
  children: null | EventGroupTreeType[];
  hasChildren: boolean;
  id: string;
  name: string;
  type: string;
};

export type EventType = {
  article: ArticleType;
  meeting: {
    id: string;
    articleId: string;
    meetTypeRepeat: boolean;
    repeatEvery: 'EVERY_MONTH' | 'EVERY_YEAR' | 'EVERY_WEEK';
    meetDate: string;
    maxEmployees: number;
    city: string;
    location: string;
    requiresDocs: boolean;
    registrationActive: boolean;
    countMembers: number;
    isNotify: boolean;
    gender: EVENT_GENDER;
    bodyNotice: string;
    titleNotice: string;
    group: {
      checked: string[];
      partialChecked: string[];
      treeData: EventGroupTreeType[];
    };
  };
  isParticipant: boolean;
  uploadedDocs: {
    meetId: string;
    files: {
      num: number;
      link: string;
      name: string;
      type: string;
      size: number;
      version: number;
    }[];
    resolution: 'PENDING' | 'APPROVED' | 'DECLINED';
    resolutionComment: string;
  }[];
};
