import { FC, useContext } from 'react';

import cn from 'classnames';

import { Radio, RadioGroupProps } from 'gazprom-ui-lib';

import { FilterContext } from 'containers/filters/filters.utils';

import s from './FilterRadioGroup.module.scss';

export type FilterRadioGroupOptions = { label: string; value: string | number | boolean };

export interface FilterRadioGroupProps {
  options: FilterRadioGroupOptions[];
  valueKey: string;
  usePadding?: boolean;
}

const FilterRadioGroup: FC<FilterRadioGroupProps> = (props) => {
  const { options, valueKey, usePadding = true } = props;

  const { filters, setFilters } = useContext(FilterContext);

  const defaultValue = (filters?.[valueKey] ?? null) as RadioGroupProps['defaultValue'];
  const typedValue = filters?.[valueKey];

  const handleChange: RadioGroupProps['onChange'] = (e) => {
    if (setFilters) {
      setFilters((prevState) => ({ ...prevState, [valueKey]: e.target.value }));
    }
  };

  return (
    <div className={cn(s.wrapper, { [s.padding]: usePadding })}>
      <Radio.Group
        direction="vertical"
        options={options}
        onChange={handleChange}
        defaultValue={defaultValue}
        value={typedValue}
      />
    </div>
  );
};

export default FilterRadioGroup;
