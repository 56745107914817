import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import {
  useCreateArticleCategoryMutation,
  useGetNewsCategoriesQuery,
} from 'services/category/categoryApiService';
import { GetNewsCategoriesResponseType } from 'services/category/categoryApiService.types';

import {
  Button,
  Checkbox,
  Flex,
  Form,
  Icon,
  Input,
  Modal,
  Select,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import OptionalLabel from 'components/optional-label';

import { NewsCategoryType } from 'types/news.types';
import { TelegramChannelType } from 'types/telegramChannel.types';

import { NEWS_FORM_ENUM } from '../news-form/newsForm.utils';
import s from './TelegramChannelForm.module.scss';
import {
  TELEGRAM_CHANNEL_FORM_ENUM,
  TELEGRAM_CHANNEL_FORM_NAME,
  TelegramChannelFormResponse,
} from './telegramChannelForm.utils';

const MAX_CATEGORIES_PER_PAGE = 500;

interface TelegramChannelFormProps {
  onSubmit: (form: TelegramChannelFormResponse) => void;
  telegramChannel?: TelegramChannelType;
}

const TelegramChannelForm: FC<TelegramChannelFormProps> = (props) => {
  const { onSubmit, telegramChannel } = props;

  const [form] = Form.useForm();

  const [createArticleCategory, { isLoading: createArticleIsLoading }] =
    useCreateArticleCategoryMutation();
  const { categoriesOptions, isFetching } = useGetNewsCategoriesQuery(
    { page: 1, size: MAX_CATEGORIES_PER_PAGE, visible: true, name: '' },
    {
      selectFromResult,
    },
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleModalOpenClose = () => setIsModalOpen((prevState) => !prevState);

  const handleCheckValidation = () =>
    form.validateFields([NEWS_FORM_ENUM.NEW_CATEGORY_NAME]).then(handleCategoryCreate);

  const handleCategoryCreate = () => {
    const alreadySelectedCategories =
      form.getFieldValue(TELEGRAM_CHANNEL_FORM_ENUM.CATEGORIES) ?? [];

    const newCategoryName = form.getFieldValue(NEWS_FORM_ENUM.NEW_CATEGORY_NAME);

    const onSuccess = (data?: NewsCategoryType) => {
      if (data) {
        form.setFieldValue(
          TELEGRAM_CHANNEL_FORM_ENUM.CATEGORIES,
          alreadySelectedCategories.concat(data.id),
        );
        handleModalOpenClose();
        form.resetFields([NEWS_FORM_ENUM.NEW_CATEGORY_NAME]);
      }
    };

    const onError = () => {
      form.setFields([
        {
          name: NEWS_FORM_ENUM.NEW_CATEGORY_NAME,
          errors: [t('errors_category_name_exist')],
        },
      ]);
    };

    createArticleCategory({ name: newCategoryName, visible: true, type: 'news' }).then(
      handleRequest({
        onSuccess,
        onError,
      }),
    );
  };

  useEffect(() => {
    if (telegramChannel) {
      form.setFieldsValue({
        [TELEGRAM_CHANNEL_FORM_ENUM.TELEGRAM_TITLE]: telegramChannel.telegramTitle,
        [TELEGRAM_CHANNEL_FORM_ENUM.LINK]: telegramChannel.link,
        [TELEGRAM_CHANNEL_FORM_ENUM.CATEGORIES]: telegramChannel.categories.length
          ? telegramChannel?.categories.map(({ id }) => id)
          : [],
        [TELEGRAM_CHANNEL_FORM_ENUM.ACTIVE]: telegramChannel.active,
      });
    }
  }, [form, telegramChannel]);

  return (
    <Form className={s.wrapper} form={form} name={TELEGRAM_CHANNEL_FORM_NAME} onFinish={onSubmit}>
      <div>
        <Flex>
          <Typography.Text strong size="16">
            {t('common_channel_name')}
          </Typography.Text>
        </Flex>
        <Form.Item
          normalize={normalizeTextField}
          name={TELEGRAM_CHANNEL_FORM_ENUM.TELEGRAM_TITLE}
          rules={[formRules.required, { max: 80 }]}>
          <Input placeholder={t('common_enter_text')} maxLength={80} />
        </Form.Item>
      </div>
      <div>
        <Flex>
          <Typography.Text strong size="16">
            {t('common_channel_link')}
          </Typography.Text>
        </Flex>
        <Form.Item
          normalize={normalizeTextField}
          name={TELEGRAM_CHANNEL_FORM_ENUM.LINK}
          rules={[formRules.required, { max: 80 }]}>
          <Input placeholder={t('common_enter_text')} maxLength={80} />
        </Form.Item>
      </div>
      <div>
        <Typography.Text strong size="16">
          {t('news_of_category')}
        </Typography.Text>
        <Form.Item name={TELEGRAM_CHANNEL_FORM_ENUM.CATEGORIES} rules={[formRules.required]}>
          <Select
            mode="multiple"
            placeholder={t('news_select_category')}
            loading={isFetching}
            options={categoriesOptions}
            filterOption={(search, option) => {
              return JSON.stringify(option)?.toLowerCase().includes(search?.toLowerCase());
            }}
          />
        </Form.Item>
        <button type="button" className={s.modalOpenButton} onClick={handleModalOpenClose}>
          <Icon name="plus" />
          <Typography.Text size="14" weight="500">
            {t('common_add_category')}
          </Typography.Text>
        </button>
      </div>
      <div>
        <OptionalLabel>
          <Typography.Text strong size="16">
            {t('common_news_automatic_import')}
          </Typography.Text>
        </OptionalLabel>
        <Form.Item name={TELEGRAM_CHANNEL_FORM_ENUM.ACTIVE} valuePropName="checked">
          <Checkbox>
            <Typography.Text size="14">{t('telegram_import_on')}</Typography.Text>
          </Checkbox>
        </Form.Item>
      </div>

      <Modal open={isModalOpen} onCancel={handleModalOpenClose} closable>
        <Typography.Title level={4}>{t('common_add_category')}</Typography.Title>
        <Form.Item
          normalize={(e: string) => e.trimStart()}
          name={NEWS_FORM_ENUM.NEW_CATEGORY_NAME}
          className={s.categoryInput}
          rules={[formRules.required]}>
          <Input placeholder={t('common_enter_category_name')} />
        </Form.Item>
        <div className={s.actions}>
          <Button htmlType="button" type="secondary" onClick={handleModalOpenClose}>
            {t('common_close')}
          </Button>
          <Button
            htmlType="button"
            onClick={handleCheckValidation}
            loading={createArticleIsLoading}>
            {t('common_save')}
          </Button>
        </div>
      </Modal>
    </Form>
  );
};

const selectFromResult = ({
  data,
  ...props
}: {
  data?: GetNewsCategoriesResponseType;
  isFetching: boolean;
}) => ({
  categoriesOptions: data?.items.map(({ id, name }) => ({ value: id, label: name, key: id })) ?? [],
  ...props,
});

export default TelegramChannelForm;
