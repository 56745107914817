import { ReactComponent as GspAnniversary } from 'assets/icons/gsp-anniversary.svg';
import { ReactComponent as LogoGsp5 } from 'assets/logo/logo-gsp-5.svg';
import { ReactComponent as LogoSquare } from 'assets/logo/logo-short-name-square-blue.svg';

import s from './LoginLoader.module.scss';

const LoginLoader = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.mainBackground}>
        <LogoGsp5 />
        <GspAnniversary />
      </div>
      <div className={s.secondaryBackground}>
        <LogoSquare />
      </div>
    </div>
  );
};

export default LoginLoader;
