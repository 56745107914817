import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiServiceV2 from 'services/initialApiServiceV2';

import {
  CreateGroupRequest,
  EmployeeFormData,
  GetEmployeeGroupByIdRequest,
  GetEmployeeGroupByIdResponse,
  GetEmployeeGroupTreeRequest,
  GetEmployeeGroupTreeResponse,
  GetEmployeeGroupsMembersByGroupsIdsPropsType,
  GetEmployeeGroupsMembersByGroupsIdsResponseType,
  GetEmployeeGroupsPropsType,
  GetEmployeeGroupsResponseType,
  GetEmployerRootResponse,
  GetGroupCountDraftPropsType,
  GetGroupCountDraftResponseType,
  GetGroupCountPropsType,
  GetGroupCountResponseType,
  PreviewGroupByIdProps,
  PreviewGroupByIdResponse,
  PreviewGroupResponseType,
  RemoveGroupsPropsType,
  UpdateGroupByIdRequest,
} from './employeeGroupsNewApiService.types';
import { SERVICE_API_V2_TAGS } from 'constants/serviceTags';

import {
  getEmployeeGroupByIdTransform,
  getEmployeeGroupTreeTransform,
  getEmployerRootTransform,
} from './employeeGroupsNewApiService.utils';

const employeeGroupsApiService = initialApiServiceV2.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeGroups: builder.query<GetEmployeeGroupsResponseType, GetEmployeeGroupsPropsType>({
      query: (params) => ({
        url: `employee-group${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_API_V2_TAGS.GET_EMPLOYEE_GROUP],
    }),
    getEmployerRoot: builder.query<GetEmployerRootResponse, void>({
      query: () => ({
        url: 'employer-root',
      }),
      transformResponse: getEmployerRootTransform,
    }),
    getEmployeeGroupTree: builder.query<GetEmployeeGroupTreeResponse, GetEmployeeGroupTreeRequest>({
      query: (params) => ({
        url: `employee-group-tree${prepareQueryParams(params)}`,
      }),
      transformResponse: getEmployeeGroupTreeTransform,
    }),
    getEmployeeGroupById: builder.query<GetEmployeeGroupByIdResponse, GetEmployeeGroupByIdRequest>({
      query: (id) => ({
        url: `employee-group/${id}`,
      }),
      providesTags: [SERVICE_API_V2_TAGS.GET_EMPLOYEE_GROUP_BY_ID],
      transformResponse: getEmployeeGroupByIdTransform,
    }),
    getEmployeeGroupsMembersByGroupsIds: builder.query<
      GetEmployeeGroupsMembersByGroupsIdsResponseType,
      GetEmployeeGroupsMembersByGroupsIdsPropsType
    >({
      query: (params) => ({
        url: `group-members-multiple${prepareQueryParams(params)}`,
      }),
    }),
    createGroup: builder.mutation<GetEmployeeGroupByIdResponse, EmployeeFormData>({
      query: (body) => ({
        url: `employee-group`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_API_V2_TAGS.GET_EMPLOYEE_GROUP],
    }),
    updateGroup: builder.mutation<GetEmployeeGroupByIdResponse, UpdateGroupByIdRequest>({
      query: ({ id, body }) => ({
        url: `employee-group/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_API_V2_TAGS.GET_EMPLOYEE_GROUP_BY_ID],
    }),
    previewDraftGroup: builder.query<PreviewGroupResponseType, CreateGroupRequest>({
      query: ({ body, ...params }) => ({
        url: `group-members-preview${prepareQueryParams(params)}`,
        method: 'POST',
        body,
      }),
      providesTags: [SERVICE_API_V2_TAGS.GET_EMPLOYEE_GROUP_BY_ID],
    }),
    previewGroupById: builder.query<PreviewGroupByIdResponse, PreviewGroupByIdProps>({
      query: ({ id, ...params }) => ({
        url: `group-members/${id}${prepareQueryParams(params)}`,
      }),
    }),
    removeGroups: builder.mutation<void, RemoveGroupsPropsType>({
      query: (body) => ({
        url: `employee-group`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [SERVICE_API_V2_TAGS.GET_EMPLOYEE_GROUP],
    }),
    getGroupCountDraft: builder.mutation<
      GetGroupCountDraftResponseType,
      GetGroupCountDraftPropsType
    >({
      query: (body) => ({
        url: `group-members-preview-count`,
        method: 'POST',
        body,
      }),
    }),
    getGroupCount: builder.query<GetGroupCountResponseType, GetGroupCountPropsType>({
      query: ({ groupId }) => ({
        url: `group-members-count/${groupId}`,
      }),
    }),
  }),
});

export const {
  useGetEmployerRootQuery,
  useLazyGetEmployeeGroupTreeQuery,
  useLazyGetEmployeeGroupsQuery,
  useLazyGetEmployeeGroupsMembersByGroupsIdsQuery,
  useGetEmployeeGroupByIdQuery,
  useCreateGroupMutation,
  useLazyPreviewDraftGroupQuery,
  useUpdateGroupMutation,
  useRemoveGroupsMutation,
  useLazyPreviewGroupByIdQuery,
  useGetGroupCountDraftMutation,
  useLazyGetGroupCountQuery,
} = employeeGroupsApiService;
