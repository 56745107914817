import { t } from 'tools/i18n';

import { ColumnsType, Flex, Typography } from 'gazprom-ui-lib';

import { ExpertCommissionType } from 'types/expertCommission.types';

const useColumns = (): ColumnsType<ExpertCommissionType> => {
  const nameRenderer = (name: string) => {
    return (
      <Typography.Text size="14" weight="500">
        {name}
      </Typography.Text>
    );
  };

  const employerRenderer = (employer: ExpertCommissionType['employer']) => {
    const { name } = employer ?? {};

    return (
      <Typography.Text size="12" weight="500" type="secondary">
        {name}
      </Typography.Text>
    );
  };

  const headRenderer = (head: ExpertCommissionType['head']) => {
    const { position, person } = head ?? {};

    const { firstName, lastName, patronymic } = person ?? {};

    return (
      <Flex vertical align="flex-start" justify="center">
        <Typography.Text weight="500">{`${lastName} ${firstName[0]}. ${patronymic[0]}.`}</Typography.Text>
        <Typography.Text size="12" type="secondary">
          {position?.name}
        </Typography.Text>
      </Flex>
    );
  };

  return [
    {
      title: t('suggestions_commission_name'),
      dataIndex: 'name',
      render: nameRenderer,
      width: '30%',
    },
    {
      title: t('common_organization'),
      dataIndex: 'employer',
      render: employerRenderer,
      width: '30%',
    },
    {
      title: t('suggestions_commission_head'),
      dataIndex: 'head',
      render: headRenderer,
    },
  ];
};

export default useColumns;
