import { t } from 'tools/i18n';

import { TableProps, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import KedoStatusTag from 'components/kedo-status-tag';
import TableDate from 'components/table/table-date';

import { BusinessTripApprovalItemType } from 'types/businessTrip.types';

const useColumns = (): TableProps<BusinessTripApprovalItemType>['columns'] => {
  const employeeRenderer = (approvalItem: BusinessTripApprovalItemType) => {
    const { division, fio } = approvalItem;

    const splitFio = fio.split(' ');
    const [lastName, firstName, patronymic] = splitFio;

    return (
      <Bio firstName={firstName} lastName={lastName} patronymic={patronymic} staffName={division} />
    );
  };

  const positionRenderer = (position: BusinessTripApprovalItemType['position']) => (
    <Typography.Text type="secondary" size="12">
      {position}
    </Typography.Text>
  );

  const dateRequestRenderer = (dateRequest: BusinessTripApprovalItemType['dateRequest']) => (
    <TableDate date={dateRequest} />
  );

  const dateApprovalRenderer = (dateApproval: BusinessTripApprovalItemType['dateSolution']) => (
    <TableDate date={dateApproval} />
  );

  const approvalStatusRenderer = (
    approvalStatus: BusinessTripApprovalItemType['approvalStatus'],
  ) => {
    return <KedoStatusTag size="24" currentKedoStatus={approvalStatus} />;
  };

  const approvalCommentRenderer = (comment: BusinessTripApprovalItemType['comment']) => (
    <Typography.Text type="secondary" size="12">
      {comment}
    </Typography.Text>
  );

  return [
    {
      title: t('common_employee'),
      key: 'employee',
      render: employeeRenderer,
    },
    {
      title: t('common_staffName'),
      dataIndex: 'position',
      key: 'position',
      render: positionRenderer,
    },
    {
      title: t('documents_business_trip_request_date'),
      dataIndex: 'dateRequest',
      key: 'dateRequest',
      align: 'center',
      render: dateRequestRenderer,
    },
    {
      title: t('documents_business_trip_approval_date'),
      dataIndex: 'dateSolution',
      key: 'dateSolution',
      align: 'center',
      render: dateApprovalRenderer,
    },
    {
      title: t('common_status'),
      key: 'status',
      dataIndex: 'approvalStatus',
      render: approvalStatusRenderer,
    },
    {
      title: t('documents_business_trip_approval_comment'),
      dataIndex: 'comment',
      key: 'comment',
      render: approvalCommentRenderer,
    },
  ];
};

export default useColumns;
