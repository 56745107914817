import { FC, PropsWithChildren, useContext } from 'react';

import { t } from 'tools/i18n';

import { Button, Flex } from 'gazprom-ui-lib';

import { FilterContext } from '../filters.utils';
import s from './FilterApplyContent.module.scss';

interface FilterApplyContentProps extends PropsWithChildren {
  handleApply: () => void;
  handleFormClear: () => void;
  buttonLabel?: string;
  filtersCount?: number;
  showClearAll?: boolean;
}

const FilterApplyContent: FC<FilterApplyContentProps> = (props) => {
  const { handleApply, children, buttonLabel, handleFormClear, filtersCount, showClearAll } = props;

  const { filters } = useContext(FilterContext);

  const defaultButtonLabel = t(
    Object.keys(filters || {}).length > 1 ? 'common_apply_filters' : 'common_apply_filter',
  );

  const showClearButton = Boolean(showClearAll && filtersCount);

  return (
    <>
      {children}
      <Flex fullWidth vertical className={s.wrapper} gap="4">
        <Button fullWidth onClick={handleApply} leftIcon="filter">
          {buttonLabel ?? defaultButtonLabel}
        </Button>
        {showClearButton && (
          <Button
            fullWidth
            onClick={handleFormClear}
            size="small"
            type="link"
            leftIcon="delete"
            className={s.delete}>
            {t('common_reset_all_filters', { count: filtersCount })}
          </Button>
        )}
      </Flex>
    </>
  );
};

export default FilterApplyContent;
