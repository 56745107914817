import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = {
  page: number;
  size: number;
  property?: string;
  order: SORT_ENUM;
  search: string;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  search: '',
  page: 1,
  order: SORT_ENUM.DESC,
  property: 'createdAt',
  size: 10,
};
