import { useState } from 'react';

import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import CreateModal from '../create-modal';

const Header = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenCloseModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ContentHeader>
        <ContentHeader.Title>{t('notification_mailing_management')}</ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="send" ghost onClick={handleOpenCloseModal}>
            {t('notification_create_newsletter')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <CreateModal isOpen={isOpen} handleClose={handleOpenCloseModal} />
    </>
  );
};

export default Header;
