import { Key, useEffect, useState } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { FaqTreeDataType, RequestBodyType } from 'pages/faq/faq.utils';

interface Props {
  data?: FaqTreeDataType[];
  requestBody: RequestBodyType;
}

const useSearch = (props: Props) => {
  const { data, requestBody } = props;
  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);

  const handleExpand = (expanded: boolean, record: FaqTreeDataType) => {
    if (expanded) {
      setExpandedRowKeys((prevKeys) => [...prevKeys, record.key]);
    } else {
      setExpandedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record.key));
    }
  };

  useEffect(() => {
    if (data && debouncedRequestBody.search) {
      const matches = findMatches(data, debouncedRequestBody.search);
      setExpandedRowKeys(matches);
    }
  }, [data, debouncedRequestBody.search]);

  return {
    expandedRowKeys,
    handleExpand,
  };
};

const findMatches = (tree: FaqTreeDataType[], searchTerm: string) => {
  const results: Key[] = [];

  const searchTree = (node: FaqTreeDataType, path: Key[]) => {
    const newPath = path.concat(node.key);

    if (node.title.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())) {
      results.push(...newPath);
    }

    if (node.children) {
      node.children.forEach((child) => searchTree(child, newPath));
    }
  };

  tree.forEach((node) => searchTree(node, []));

  return results;
};

export default useSearch;
