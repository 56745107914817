import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Form, TextArea, Typography } from 'gazprom-ui-lib';

import { HANDBOOK_BASE_FORM_ENUM } from '../handbookBaseForm.utils';

const HandbookBaseFormName = () => {
  return (
    <>
      <Typography.Text strong size="16">
        {t('guide_handbook_name')}
      </Typography.Text>
      <Form.Item
        normalize={normalizeTextField}
        name={HANDBOOK_BASE_FORM_ENUM.NAME}
        rules={[formRules.required, { max: 240 }]}>
        <TextArea placeholder={t('common_enter_text')} maxLength={240} showCount />
      </Form.Item>
    </>
  );
};

export default HandbookBaseFormName;
