import { t } from 'tools/i18n';

import { GetEmployerTransformedResponseType } from 'services/surveys/surveysApiService.types';
import { mapEmployeeGroups } from 'services/surveys/utils';

import { FormInstance } from 'gazprom-ui-lib';

import { RECEIVERS_ENUM } from 'types/surveys.types';

type GetReceivers = {
  receiverType: RECEIVERS_ENUM;
  receivers: {
    id: string;
    name: string;
  }[];
};

type ItemsType = GetEmployerTransformedResponseType;

export type SelectData = { name: string | null; condition: string | null };

export const INITIAL_SELECT_DATA = {
  name: null,
  condition: null,
};

export const DEFAULT_PAGE_DATA = { page: 1, size: 500 };

export const validateFields = async (form: FormInstance) => {
  const result = await form
    .validateFields(['organization', 'receivers'])
    .then((res) => {
      if (!!res.organization.length || !!res.receivers.length) {
        form.setFields([
          { name: 'receivers', errors: [] },
          { name: 'organization', errors: [] },
        ]);

        return true;
      }

      throw new Error();
    })
    .catch(() => {
      form.setFields([
        { name: 'receivers', errors: [t('validation_field_not_empty')] },
        { name: 'organization', errors: [t('validation_field_not_empty')] },
      ]);

      return false;
    });

  return result;
};

const mapReceivers = (ids: string[], allItems: ItemsType): GetReceivers['receivers'] => {
  return ids.reduce((acc, id) => {
    const result = allItems.find((item) => item.value === id);

    if (result) {
      acc.push({
        id: result.value,
        name: result.label,
      });
    }

    return acc;
  }, [] as GetReceivers['receivers']);
};

export const getReceivers = (
  receiversIds: string[],
  allReceivers: ReturnType<typeof mapEmployeeGroups>,
  organizationIds: string[] | undefined,
  allOrganizations: GetEmployerTransformedResponseType,
): GetReceivers[] => {
  return [
    {
      receiverType: RECEIVERS_ENUM.EMPLOYEE_GROUP,
      receivers: receiversIds ? mapReceivers(receiversIds, allReceivers) : [],
    },
    {
      receiverType: RECEIVERS_ENUM.EMPLOYER,
      receivers: organizationIds ? mapReceivers(organizationIds, allOrganizations) : [],
    },
  ];
};
