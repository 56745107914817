import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetHandbookByIdQuery } from 'services/settlement/settlementApiService';

import { Button, Typography } from 'gazprom-ui-lib';

import s from './HandbookBaseFormQrButton.module.scss';

const HandbookBaseFormQrButton = () => {
  const { handbookId } = useParams();
  const { data } = useGetHandbookByIdQuery(handbookId!);

  if (!handbookId) {
    return null;
  }

  return (
    <div className={s.container}>
      <Typography.Text strong size="16">
        {t('guide_qr_code')}
      </Typography.Text>
      <Button
        onClick={() =>
          window.open(`/settlement-qr.html?id=${handbookId}&name=${data?.name}`, '_blank')
        }>
        {t('guide_print_qr')}
      </Button>
    </div>
  );
};

export default HandbookBaseFormQrButton;
