import { FC, useMemo, useState } from 'react';

import { t } from 'tools/i18n';

import { Form, Typography } from 'gazprom-ui-lib';

import ImageModalUpload from 'containers/image-modal/image-modal-upload';
import ImageModalUrl from 'containers/image-modal/image-modal-url';
import { FORM_IMAGE_ENUM, IMPORT, TABS, URL } from 'containers/image-modal/imageModal.utils';

interface EditorImageModalProps {
  uploadDescription?: string;
  availableTypes?: string[];
  uploadTitle?: string;
  maxLimitSizeMB?: number;
  kedoReviewTaskId?: string | null;
  handleCreateTaskId?: () => void;
}

const FileItem: FC<EditorImageModalProps> = (props) => {
  const {
    uploadDescription,
    uploadTitle,
    availableTypes,
    maxLimitSizeMB,
    kedoReviewTaskId,
    handleCreateTaskId,
  } = props;

  const [activeTab] = useState<string>(DEFAULT_ACTIVE_TAB);

  const form = Form.useFormInstance();

  const content = useMemo(() => {
    switch (activeTab) {
      case URL:
        return <ImageModalUrl form={form} />;
      case IMPORT:
        return (
          <ImageModalUpload
            handleCreateTaskId={handleCreateTaskId}
            kedoReviewTaskId={kedoReviewTaskId}
            maxLimitSizeMB={maxLimitSizeMB}
            form={form}
            title={uploadTitle}
            description={uploadDescription}
            availableTypes={availableTypes}
          />
        );
    }
  }, [
    activeTab,
    availableTypes,
    form,
    handleCreateTaskId,
    kedoReviewTaskId,
    maxLimitSizeMB,
    uploadDescription,
    uploadTitle,
  ]);

  return (
    <>
      <Typography.Text size="14" weight="500" type="primary">
        {t('common_document_file')}
      </Typography.Text>
      <Form.Item name={FORM_IMAGE_ENUM.SHOW_PREVIEW} noStyle />
      <Form.Item name={FORM_IMAGE_ENUM.FULL_INFO} noStyle />
      {content}
    </>
  );
};

const DEFAULT_ACTIVE_TAB = TABS[0]?.value;

export default FileItem;
