import { ARTICLE_TYPE_ENUM } from 'types/article.types';
import { SORT_ENUM } from 'types/sort.types';

export const MAX_CATEGORIES_PER_PAGE = 500;

export type RequestBody = {
  page: number;
  size: number;
  property: string;
  order: SORT_ENUM;
  type: ARTICLE_TYPE_ENUM[];
  title: string;
};

export const DEFAULT_REQUEST_BODY: RequestBody = {
  page: 1,
  size: 10,
  property: 'updatedAt',
  order: SORT_ENUM.DESC,
  type: [ARTICLE_TYPE_ENUM.NEWS],
  title: '',
};

export const GET_PINNED_NEWS_ARGS = {
  page: 1,
  size: 3,
  pinned: true,
  sort: [{ order: SORT_ENUM.ASC, property: 'pinNum' }],
};
