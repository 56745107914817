import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import { SURVEYS_ONCE_MODAL_ENUM } from '../../surveyFormOnce.utils';

interface Props {
  modalOpen: SURVEYS_ONCE_MODAL_ENUM;
  onCancel: () => void;
  onActionClick: () => void;
}

const AssignModal = (props: Props) => {
  const { modalOpen, onCancel, onActionClick } = props;

  return (
    <ModalDefault
      open={modalOpen === SURVEYS_ONCE_MODAL_ENUM.ASSIGN}
      onCancel={onCancel}
      width={320}
      closable>
      <ModalDefault.Icon type="danger" name="error" />
      <ModalDefault.Title>{t('surveys_start_survey_title')}</ModalDefault.Title>
      <ModalDefault.Description>{t('surveys_start_survey_description')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button fullWidth type="primary" onClick={onActionClick}>
          {t('common_accept')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default AssignModal;
