import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiService from 'services/1c/cApiService';

import {
  GetRecruitByIdPropsType,
  GetRecruitByIdResponseType,
  GetRecruitsPropsType,
  GetRecruitsResponseType,
} from './recruitApiService.types';

import { createRecruitUrl } from './utils';

const recruitApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getRecruits: builder.query<GetRecruitsResponseType, GetRecruitsPropsType>({
      query: (params) => ({
        url: createRecruitUrl(`${prepareQueryParams(params)}`),
      }),
    }),
    getRecruitById: builder.query<GetRecruitByIdResponseType, GetRecruitByIdPropsType>({
      query: (id) => ({
        url: createRecruitUrl(`/${id}`),
      }),
    }),
  }),
});

export const { useGetRecruitsQuery, useGetRecruitByIdQuery } = recruitApiService;
