import { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { GUIDE_HANDBOOK_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { useLazyGetHandbookInstructionsQuery } from 'services/settlement/settlementApiService';

import { Button, Collapse, Typography } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';
import HandbookInstructionForm from 'containers/handbook-instruction-form';

import { HandbookInstructionType } from 'types/guide.types';

import s from './GuideHandbookDetailsInstructions.module.scss';
import GuideHandbookDetailsInstructionsCategoriesModal from './guide-handbook-details-instructions-categories-modal';
import useActionsHook from './useActions.hook';

const GuideHandbookDetailsInstructions = () => {
  const navigate = useNavigate();
  const params = useParams<{ handbookId: string }>();

  const [isAddCategoryModal, setIsAddCategoryModal] = useState<boolean>(false);

  const [getHandbookInstructions, { data: handbookInstructions }] =
    useLazyGetHandbookInstructionsQuery();

  useEffect(() => {
    if (params.handbookId) {
      getHandbookInstructions(params.handbookId);
    }
  }, [getHandbookInstructions, params.handbookId]);

  const {
    isFetching,
    deleteConfig,
    setDeleteConfig,
    handleCategoryUpdate,
    handleAddCategory,
    handleAddStep,
    handleDelete,
  } = useActionsHook({
    handbookInstructions,
  });

  const handleGoBack = () => {
    navigate(GUIDE_HANDBOOK_ROUTE);
  };

  const handleOpenCloseModal = () => {
    setIsAddCategoryModal((prevState) => !prevState);
  };
  const renderInstructionStep =
    (categoryId: string) => (step: HandbookInstructionType, index: number) => {
      const key = `inner__step__${step.id}`;

      return {
        key,
        label: (
          <div className={s.collapseTitle}>
            <Typography.Title level={5}>{`${t('common_step')} ${index + 1}`}</Typography.Title>
            <Button
              type="text"
              danger
              leftIcon="binFilled"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteConfig({
                  instructions: [step],
                  subtitle: t('guide_handbook_remove_step'),
                });
              }}>
              {t('common_delete')}
            </Button>
          </div>
        ),
        children: (
          <div className={s.formWrapper}>
            <HandbookInstructionForm
              instruction={step}
              name={key}
              onSubmit={(formResponse) => handleCategoryUpdate(formResponse, categoryId, step)}
            />
          </div>
        ),
      };
    };

  const renderHandbookInstruction = (handbookInstruction: {
    category: { id: string; name: string; withEtaps: boolean };
    instructions: HandbookInstructionType[];
  }) => {
    const { category, instructions } = handbookInstruction;

    const canHaveSteps = category.withEtaps;

    const sortedInstructions = [...instructions]?.sort(
      (a: HandbookInstructionType, b: HandbookInstructionType) => b.num - a.num,
    );

    return {
      key: category.id,
      label: (
        <div className={s.collapseTitle}>
          <Typography.Title level={4}>{category.name}</Typography.Title>
          <Button
            loading={isFetching}
            type="text"
            danger
            leftIcon="binFilled"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteConfig({ instructions, subtitle: t('guide_handbook_remove') });
            }}>
            {t('common_delete')}
          </Button>
        </div>
      ),
      children: (
        <div className={cn({ [s.stepWithInnerSteps]: canHaveSteps })}>
          {canHaveSteps ? (
            <Collapse
              noLeftBorder={false}
              noPadding={false}
              className={cn({ [s.innerStep]: canHaveSteps })}
              items={[...sortedInstructions]
                .sort(sortInstructionsByOrder)
                .map(renderInstructionStep(category.id))}
            />
          ) : (
            <div className={s.formWrapper}>
              <HandbookInstructionForm
                instruction={instructions[0]}
                name={category.id}
                onSubmit={(formResponse) =>
                  handleCategoryUpdate(formResponse, category.id, instructions[0])
                }
              />
            </div>
          )}
          {canHaveSteps && (
            <div className={s.addCategoryWrapper}>
              <Button
                loading={isFetching}
                ghost
                leftIcon="plus"
                size="large"
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddStep(category.id);
                }}>
                {t('common_add_step')}
              </Button>
            </div>
          )}
        </div>
      ),
    };
  };

  const alreadySelectedHandbookCategories = useMemo(
    () => handbookInstructions?.map((handbook) => handbook.category.id),
    [handbookInstructions],
  );

  return (
    <>
      <div className={s.wrapper}>
        <Collapse items={handbookInstructions?.map(renderHandbookInstruction)} noPadding />
        <div className={s.addCategoryWrapper}>
          <Button
            ghost
            leftIcon="plus"
            size="large"
            onClick={handleOpenCloseModal}
            loading={isFetching}>
            {t('common_add_category')}
          </Button>
        </div>
        <div className={s.goBackWrapper}>
          <Button ghost leftIcon="arrowLeft" onClick={handleGoBack}>
            {t('common_go_back')}
          </Button>
        </div>
      </div>
      <GuideHandbookDetailsInstructionsCategoriesModal
        alreadySelectedHandbookCategories={alreadySelectedHandbookCategories}
        open={isAddCategoryModal}
        handleClose={handleOpenCloseModal}
        handleSubmit={handleAddCategory}
      />
      <RemoveConfirm
        type="danger"
        buttonIcon="bin"
        title={t('common_accept_remove')}
        subtitle={deleteConfig?.subtitle}
        isLoading={isFetching}
        isOpen={!!deleteConfig}
        onCancel={() => setDeleteConfig(null)}
        onConfirm={handleDelete}
        buttonText={t('common_remove')}
      />
    </>
  );
};

const sortInstructionsByOrder = (a: HandbookInstructionType, b: HandbookInstructionType) =>
  a.num - b.num;

export default GuideHandbookDetailsInstructions;
