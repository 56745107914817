import { t } from 'tools/i18n';

import { Checkbox, Form } from 'gazprom-ui-lib';

import { HANDBOOK_BASE_FORM_ENUM } from '../handbookBaseForm.utils';

const HandbookBaseFormPublish = () => {
  return (
    <Form.Item name={HANDBOOK_BASE_FORM_ENUM.PUBLISH} valuePropName="checked" hidden>
      <Checkbox>{t('common_publish')}</Checkbox>
    </Form.Item>
  );
};

export default HandbookBaseFormPublish;
