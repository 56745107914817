import { t } from 'tools/i18n';

import NotificationNewsletter from './notification-newsletter';
import NotificationTemplate from './notification-template';

export const NOTIFICATION_NEWSLETTER_TAB = 'NOTIFICATION_NEWSLETTER_TAB';
export const NOTIFICATION_TEMPLATES_TAB = 'NOTIFICATION_TEMPLATES_TAB';

export const TABS = [
  {
    key: NOTIFICATION_NEWSLETTER_TAB,
    children: <NotificationNewsletter />,
    label: t('common_newsletters'),
  },
  {
    key: NOTIFICATION_TEMPLATES_TAB,
    children: <NotificationTemplate />,
    label: t('common_templates'),
  },
];
