import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = {
  page: number;
  size: number;
  property: string;
  order: SORT_ENUM;
  search: string;
};
export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  property: 'name',
  order: SORT_ENUM.DESC,
  search: '',
};
export type ModalTypes = '' | 'delete' | 'add' | 'edit';
