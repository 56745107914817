import { useState } from 'react';

import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ChangeParticipantModal from '../change-participant-modal';
import s from './ChangeParticipant.module.scss';

interface Props {
  employerId?: string;
  employeeId?: string;
}

const ChangeParticipant = (props: Props) => {
  const { employerId, employeeId } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleModalOpen = () => setIsModalOpen((prev) => !prev);

  return (
    <>
      <Button leftIcon="refresh" onClick={handleModalOpen} className={s.changeButton}>
        {t('common_change')}
      </Button>
      <ChangeParticipantModal
        isOpen={isModalOpen}
        close={handleModalOpen}
        employerId={employerId}
        employeeId={employeeId}
      />
    </>
  );
};

export default ChangeParticipant;
