import { useEffect, useState } from 'react';

import { t } from 'tools/i18n';

import {
  useClearAuthorizationSessionMutation,
  useLazyGetAuthorizationInfoQuery,
} from 'services/authorization/authorizationApiService';

import { Flex, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import s from './SettingsTelegramAuthorization.module.scss';
import AuthorizationConfirmForm from './containers/authorization-confirm-form';
import AuthorizationInfo from './containers/authorization-info';
import AuthorizationRequestForm from './containers/authorization-request-form';

const SettingsTelegramAuthorization = () => {
  const [getAuthorizationInfo, { data: authorizationData, isLoading }] =
    useLazyGetAuthorizationInfoQuery();

  const [clearAuthorizationSession] = useClearAuthorizationSessionMutation();

  useEffect(() => {
    getAuthorizationInfo();
  }, [getAuthorizationInfo]);

  useEffect(() => {
    if (authorizationData && authorizationData?.telegramError) {
      clearAuthorizationSession();
    }
  }, [clearAuthorizationSession, authorizationData]);

  const [isPhoneConfirmed, setIsPhoneConfirmed] = useState<boolean>(false);
  const [authID, setAuthId] = useState<string>('');

  const handleChangeNumber = () => {
    setIsPhoneConfirmed(false);
    clearAuthorizationSession();
  };

  const showAuthorizationRequest = !authorizationData?.isAuth && !isPhoneConfirmed;
  const showAuthorizationConfirm = !authorizationData?.isAuth && isPhoneConfirmed;

  return (
    <div className={s.wrapper}>
      <WithLoader isLoading={isLoading}>
        {!authorizationData?.isAuth && (
          <Typography.Title level={4}>{t('telegram_authorization_settings')}</Typography.Title>
        )}
        <Flex gap="24" vertical>
          {authorizationData?.isAuth && (
            <AuthorizationInfo
              setIsPhoneConfirmed={setIsPhoneConfirmed}
              linkName={authorizationData?.link}
            />
          )}
          {showAuthorizationRequest && (
            <AuthorizationRequestForm
              setAuthId={setAuthId}
              setIsPhoneConfirmed={setIsPhoneConfirmed}
            />
          )}
          {showAuthorizationConfirm && (
            <AuthorizationConfirmForm authID={authID} handleChangeNumber={handleChangeNumber} />
          )}
        </Flex>
      </WithLoader>
    </div>
  );
};

export default SettingsTelegramAuthorization;
