import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetKedoReviewTaskAssignmentsQuery } from 'services/kedo-review-tasks/kedoReviewTasksApiService';
import { GetKedoReviewTaskAssignmentsPropsType } from 'services/kedo-review-tasks/kedoReviewTasksApiService.types';

import { RequestBodyType } from './documentsKedoReviewAssignments.utils';

interface UseRequestHookProps {
  requestBody: RequestBodyType;
  kedoReviewTaskAssignmentsId?: string;
  filters?: Pick<GetKedoReviewTaskAssignmentsPropsType, 'status'> | null;
}

const useRequestHook = (props: UseRequestHookProps) => {
  const { requestBody, kedoReviewTaskAssignmentsId, filters } = props;

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  const [
    getKedoReviewTaskAssignments,
    {
      data: kedoReviewTaskAssignments,
      isLoading: isKedoReviewTaskAssignmentsLoading,
      isFetching: isKedoReviewTaskAssignmentsFetching,
    },
  ] = useLazyGetKedoReviewTaskAssignmentsQuery();

  useEffect(() => {
    if (kedoReviewTaskAssignmentsId) {
      const initialRequestBody: GetKedoReviewTaskAssignmentsPropsType = {
        kedoReviewTaskId: kedoReviewTaskAssignmentsId,
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        ...filters,
      };

      if (debouncedRequestBody.search) {
        initialRequestBody.executorText = debouncedRequestBody.search;
      }

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        initialRequestBody.sort = [
          { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
        ];
      }

      getKedoReviewTaskAssignments(initialRequestBody);
    }
  }, [getKedoReviewTaskAssignments, kedoReviewTaskAssignmentsId, debouncedRequestBody, filters]);

  return {
    kedoReviewTaskAssignments,
    isKedoReviewTaskAssignmentsLoading,
    isKedoReviewTaskAssignmentsFetching,
  };
};

export default useRequestHook;
