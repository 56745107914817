import { FC } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { getTimezone } from 'utils/date.utils';
import { formatComplexStatusesToFlat } from 'utils/filter.utils';

import { useDownloadKedoTaskReportMutation } from 'services/1c/report/reportApiService';
import { DownloadKedoTaskReportPropsType } from 'services/1c/report/reportApiService.types';

import { Button, Flex, Form } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { FormResponseType } from '../../documentsStatements.utils';
import useFilterItems from './useFilterItems.hook';

const Filters: FC = () => {
  const form = Form.useFormInstance<FormResponseType>();

  const [downloadKedoTaskReport, { isLoading }] = useDownloadKedoTaskReportMutation();

  const handleDownload = () => {
    const { filters, search, range } = form.getFieldsValue();

    const modifiedStatuses = formatComplexStatusesToFlat(filters?.statuses);

    const requestBody: DownloadKedoTaskReportPropsType = {
      filename: `Список заявлений ${dayjs().format()}`,
      timeZone: getTimezone(),
      kedoTasksFilterParam: {
        type: 'BPMN',
        search,
        statuses: modifiedStatuses,
        categoryIds: filters?.categoryIds,
        employerIds: filters?.employerIds,
      },
    };

    if (range) {
      requestBody.kedoTasksFilterParam.from = dayjs.utc(range[0]).format();
      requestBody.kedoTasksFilterParam.to = dayjs.utc(range[1]).format();
    }

    downloadKedoTaskReport(requestBody);
  };

  const { preparedFilters, filterItems } = useFilterItems();

  return (
    <FiltersV2.TableFilters>
      <Flex align="center">
        <FiltersV2.Search />
        <Button
          htmlType="button"
          size="small"
          type="link"
          leftIcon="download"
          onClick={handleDownload}
          loading={isLoading}>
          {t('common_download_report')}
        </Button>
      </Flex>
      <FiltersV2.RangePicker />
      <FiltersContainer
        showClearAll
        overlay={
          <>
            <FilterCollapse items={filterItems} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="statuses">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="statuses"
                options={preparedFilters}
              />
            </FilterCustomWrapper>
          </>
        }
      />
    </FiltersV2.TableFilters>
  );
};
export default Filters;
