import { t } from 'tools/i18n';

import { useGetTaskStatuses1cQuery } from 'services/1c/cApiService';

import InternalFilters from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import { FilterCheckboxGroupOptions } from 'containers/filters/filter-checkbox-group/filterCheckboxGroup';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { modifyTaskStatuses } from 'pages/documents/documents.utils';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

const Filters = () => {
  const { data: taskStatuses = [] } = useGetTaskStatuses1cQuery({
    types: [KEDO_TYPE_ENUM.REVIEW],
  });

  const preparedFilters = taskStatuses?.reduce(
    modifyTaskStatuses,
    [] as FilterCheckboxGroupOptions[],
  );

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <InternalFilters
        overlay={
          <FilterCustomWrapper>
            <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
            <FilterCheckboxGroup usePadding={false} valueKey="status" options={preparedFilters} />
          </FilterCustomWrapper>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
