export const WORDS_WITH_EV_ENDING = [''];

export const pluralEnding = (amount: number, initialWord: string): string => {
  if (amount % 10 === 1 && amount % 100 !== 11) {
    return '';
  }

  if ([2, 3, 4].includes(amount % 10) && ![12, 13, 14].includes(amount % 100)) {
    return 'а';
  }

  if (WORDS_WITH_EV_ENDING.includes(initialWord)) {
    return 'ев';
  }

  return 'ов';
};
