import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

interface Props {
  handleOpenAddModal: () => void;
}

const Header = (props: Props) => {
  const { handleOpenAddModal } = props;

  return (
    <ContentHeader>
      <ContentHeader.Title>{t('guide_categories_vzg')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="plus" onClick={handleOpenAddModal}>
          {t('common_add_category')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
