import { FC, memo } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useSocialRequestCommission1cMutation } from 'services/1c/cApiService';

import { Button, DatePicker, Form, Icon, Modal, Typography, handleRequest } from 'gazprom-ui-lib';

import s from '../../Actions.module.scss';

interface CommissionModalProps {
  open: boolean;
  onCancel: () => void;
}
const CommissionModal: FC<CommissionModalProps> = (props) => {
  const { open, onCancel } = props;
  const params = useParams<{ id: string }>();

  const [sendToCommission, { isLoading }] = useSocialRequestCommission1cMutation();

  const [form] = Form.useForm();

  const onSuccess = () => {
    if (form) {
      onCancel();
      form.resetFields();
    }
  };

  const handleCorrection = (formResponse: { socCommissionDate: string }) => {
    if (params.id) {
      const sendToCommissionProps = {
        taskId: params.id,
        socCommissionDate: formResponse.socCommissionDate,
      };

      sendToCommission(sendToCommissionProps).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const submitDisabled: boolean = !Form.useWatch('socCommissionDate', form);

  return (
    <Modal open={open} onCancel={onCancel} closable>
      <Form form={form} className={s.modalWrapper} onFinish={handleCorrection} onReset={onCancel}>
        <div className={cn(s.iconWrapper, s.primary)}>
          <Icon name="resumeFilled" />
        </div>
        <Typography.Title level={3}>
          {t('social_program_return_to_commission_title')}
        </Typography.Title>
        <Typography.Title level={5} className={s.description} strong={false} type="secondary">
          {t('social_program_return_to_commission_description')}
        </Typography.Title>
        <Form.Item name="socCommissionDate" rules={[formRules.required]}>
          <DatePicker size="middle" className={s.fullWidth} />
        </Form.Item>
        <div className={s.actions}>
          <Button
            leftIcon="ok"
            fullWidth
            loading={isLoading}
            htmlType="submit"
            disabled={submitDisabled}>
            {t('common_confirm')}
          </Button>
          <Button ghost fullWidth onClick={onCancel} htmlType="reset">
            {t('common_close')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default memo(CommissionModal);
