import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateKedoCategoriesPropsType,
  CreateKedoCategoriesResponseType,
  GetKedoAttributeValidationPropsType,
  GetKedoAttributeValidationResponseType,
  GetKedoCategoriesPropsType,
  GetKedoCategoriesResponseType,
} from './kedoApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createKedoUrl } from './utils';

const kedoApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getKedoCategories: builder.query<GetKedoCategoriesResponseType, GetKedoCategoriesPropsType>({
      query: (params) => ({
        url: createKedoUrl(`/category${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_KEDO_CATEGORIES],
    }),
    createKedoCategory: builder.mutation<
      CreateKedoCategoriesResponseType,
      CreateKedoCategoriesPropsType
    >({
      query: (body) => ({
        url: createKedoUrl(`/category`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_KEDO_CATEGORIES],
    }),
    getAttributeValidation: builder.query<
      GetKedoAttributeValidationResponseType,
      GetKedoAttributeValidationPropsType
    >({
      query: (params) => ({
        url: createKedoUrl(`/attribute-validation${prepareQueryParams(params)}`),
      }),
    }),
  }),
});

export const {
  useCreateKedoCategoryMutation,
  useGetKedoCategoriesQuery,
  useGetAttributeValidationQuery,
} = kedoApiService;
