import { NotificationTemplateType } from 'types/notification.types';

export enum NOTIFICATION_TEMPLATE_FORM_ENUM {
  TEMPLATE = 'TEMPLATE',
}

export type NotificationTemplateFormResponse = {
  [NOTIFICATION_TEMPLATE_FORM_ENUM.TEMPLATE]: string;
};

export const DEFAULT_REQUEST_BODY = { page: 1, size: 500 };

export const selectFromResult = ({
  data,
  ...other
}: {
  data?: { items: NotificationTemplateType[] };
}) => ({
  data: data?.items?.map(({ name, id }) => ({ value: id, label: name })) ?? [],
  ...other,
});
