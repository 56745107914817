import { FC } from 'react';

import cn from 'classnames';

import { Icon } from 'gazprom-ui-lib';

import s from './TableIsPublish.module.scss';

interface TableIsPublishProps {
  isPublish: boolean;
}

const TableIsPublish: FC<TableIsPublishProps> = (props) => {
  const { isPublish } = props;

  return (
    <div className={cn(s.container, isPublish ? s.colored : '')}>
      <Icon name="ok" size={14} />
    </div>
  );
};

export default TableIsPublish;
