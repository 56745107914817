import { FC, memo } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useSocialRequestApprove1cMutation } from 'services/1c/cApiService';

import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import s from '../../Actions.module.scss';

interface ApproveModalProps {
  open: boolean;
  onCancel: () => void;
}

const ApproveModal: FC<ApproveModalProps> = (props) => {
  const { open, onCancel } = props;
  const params = useParams<{ id: string }>();

  const [approve, { isLoading }] = useSocialRequestApprove1cMutation();

  const [form] = Form.useForm();

  const handleClear = () => {
    onCancel();
    form.resetFields();
  };

  const handleCorrection = (formResponse: { socPlanDate: string; socAmount: string | number }) => {
    if (params.id) {
      const approveProps = {
        taskId: params.id,
        socPlanDate: formResponse.socPlanDate,
        socAmount: Number(formResponse.socAmount),
      };

      approve(approveProps).then(
        handleRequest({
          onSuccess: handleClear,
        }),
      );
    }
  };

  const handleNormalize = (e: string | number) => {
    const numberE = Number(e);

    if (numberE < 0) {
      return 0;
    }

    return numberE;
  };

  const amount = Number(Form.useWatch('socAmount', form));
  const date = Form.useWatch('socPlanDate', form);
  const submitDisabled: boolean = !amount || !date;

  return (
    <Modal open={open} onCancel={onCancel} closable>
      <Form form={form} className={s.modalWrapper} onFinish={handleCorrection} onReset={onCancel}>
        <div className={cn(s.iconWrapper, s.success)}>
          <Icon name="ok" />
        </div>
        <Typography.Title level={3}>{t('social_program_return_to_approve_title')}</Typography.Title>
        <Typography.Title level={5} className={s.description} strong={false} type="secondary">
          {t('social_program_return_to_approve_description')}
        </Typography.Title>
        <div className={s.row}>
          <Form.Item name="socPlanDate" rules={[formRules.required]}>
            <DatePicker size="middle" className={s.fullWidth} />
          </Form.Item>
          <Form.Item name="socAmount" rules={[formRules.required]} normalize={handleNormalize}>
            <Input
              type="number"
              size="middle"
              addonAfter={t('common_rub')}
              placeholder={t('common_payment_amount')}
            />
          </Form.Item>
        </div>
        <div className={s.actions}>
          <Button
            leftIcon="ok"
            fullWidth
            loading={isLoading}
            htmlType="submit"
            disabled={submitDisabled}>
            {t('common_confirm')}
          </Button>
          <Button ghost fullWidth onClick={onCancel} htmlType="reset">
            {t('common_close')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default memo(ApproveModal);
