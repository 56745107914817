import { Employer, SOCIAL_PROGRAM_STATUS_ENUM, SocialProgramType } from 'types/socialProgram.types';
import { SORT_ENUM } from 'types/sort.types';

export const mapSocialPrograms = (socialPrograms: SocialProgramType[], employers: Employer[]) => {
  return socialPrograms.map((socialProgram) => ({
    ...socialProgram,
    organization: {
      id: socialProgram.organizationId,
      name: employers.find((employer) => employer.id === socialProgram.organizationId)?.name,
    },
  }));
};

export const PAGE_SETTING_ALL_ITEMS = {
  page: 1,
  size: 500, // хард лимит на бэке
};

export type FormFiltersType = {
  status?: SOCIAL_PROGRAM_STATUS_ENUM[];
  categories: string[];
  organizations: string[];
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  search: '',
  order: SORT_ENUM.DESC,
  property: 'updatedAt',
};

export type FormResponseType = {
  page: number;
  size: number;
  search: string;
  order: SORT_ENUM;
  property?: string;
  filters?: FormFiltersType;
};
