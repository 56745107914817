import { FC } from 'react';

import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

interface HeaderProps {
  total?: number;
}

const Header: FC<HeaderProps> = (props) => {
  const { total } = props;

  return (
    <ContentHeader>
      <ContentHeader.Title>
        <Flex gap="4" align="center">
          {t('social_program_requests_list')}
          <Typography.Text size="12" weight="500" type="primary">
            {total}
          </Typography.Text>
        </Flex>
      </ContentHeader.Title>
    </ContentHeader>
  );
};

export default Header;
