import { useState } from 'react';

import { t } from 'tools/i18n';

import {
  useApproveDecisionsByCommissionMutation,
  useRejectDecisionsByCommissionsMutation,
  useRevisionDecisionsByCommissionMutation,
} from 'services/suggestion/suggestionApiService';

import { Button, Flex, Form } from 'gazprom-ui-lib';

import ApproveDecisionModal from '../approve-decision-modal';
import RejectDecisionModal from '../reject-decision-modal';
import s from './Actions.module.scss';
import {
  APPROVE_REJECT_DECISION_FORM_ENUM,
  ApproveRejectDecisionFormResponseType,
} from './action.utils';

interface Props {
  decisionId?: string;
  suggestionId?: string;
}

export type ModalType = '' | 'approve' | 'reject';

const Actions = (props: Props) => {
  const { suggestionId, decisionId } = props;

  const [approveDecision, { isLoading: isApproveLoading }] =
    useApproveDecisionsByCommissionMutation();
  const [rejectDecision, { isLoading: isRejectLoading }] =
    useRejectDecisionsByCommissionsMutation();
  const [sendToRevisionDecision, { isLoading: isRevisionLoading }] =
    useRevisionDecisionsByCommissionMutation();

  const [form] = Form.useForm<ApproveRejectDecisionFormResponseType>();

  const decisionComment = Form.useWatch(APPROVE_REJECT_DECISION_FORM_ENUM.COMMENT, form);
  const decisionPerformer = Form.useWatch(APPROVE_REJECT_DECISION_FORM_ENUM.PERFORMER, form);

  const [modalType, setModalType] = useState<ModalType>('');

  const handleCloseModal = () => {
    setModalType('');
  };

  const handleOpenApproveModal = () => {
    setModalType('approve');
  };

  const handleOpenRejectModal = () => {
    setModalType('reject');
  };

  const handleApproveDecision = () => {
    if (decisionPerformer && decisionId) {
      approveDecision({ id: decisionId, performerId: decisionPerformer }).then(handleCloseModal);
    }
  };

  const handleRejectDecision = () => {
    if (decisionId) {
      rejectDecision({ id: decisionId, comment: decisionComment }).then(handleCloseModal);
    }
  };

  const handleSendToRevisionDecision = () => {
    if (decisionId) {
      sendToRevisionDecision({ id: decisionId, comment: decisionComment }).then(handleCloseModal);
    }
  };

  return (
    <>
      <Flex gap="16" className={s.actionsWrapper}>
        <Button leftIcon="ok" fullWidth onClick={handleOpenApproveModal}>
          {t('thank_you_approve')}
        </Button>
        <Button danger fullWidth type="secondary" onClick={handleOpenRejectModal}>
          {t('thank_you_reject')}
        </Button>
      </Flex>

      <ApproveDecisionModal
        form={form}
        isOpen={modalType === 'approve'}
        onCancel={handleCloseModal}
        handleApprove={handleApproveDecision}
        suggestionId={suggestionId}
        isLoading={isApproveLoading}
      />
      <RejectDecisionModal
        form={form}
        isOpen={modalType === 'reject'}
        onCancel={handleCloseModal}
        handleReject={handleRejectDecision}
        handleRevision={handleSendToRevisionDecision}
        isLoading={isRejectLoading || isRevisionLoading}
      />
    </>
  );
};

export default Actions;
