import { useNavigate } from 'react-router-dom';
import { GUIDE_HANDBOOK_CREATE_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

const GuideHandbookHeader = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(GUIDE_HANDBOOK_CREATE_ROUTE);
  };

  return (
    <ContentHeader>
      <ContentHeader.Title>{t('guide_handbook')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="plus" onClick={handleNavigate}>
          {t('guide_add_handbook')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default GuideHandbookHeader;
