import { t } from 'tools/i18n';

import { SUGGESTION_NEED_EXPENSES_ENUM } from 'types/suggestion.types';

export const getNeedExpensesTitle = (needExpenses?: SUGGESTION_NEED_EXPENSES_ENUM): string => {
  switch (needExpenses) {
    case SUGGESTION_NEED_EXPENSES_ENUM.NEED_MONEY: {
      return t('common_yes');
    }
    case SUGGESTION_NEED_EXPENSES_ENUM.NO_NEED_MONEY: {
      return t('common_no');
    }
    case SUGGESTION_NEED_EXPENSES_ENUM.UNKNOWN_NEED_MONEY:
    default: {
      return t('common_unknown');
    }
  }
};
