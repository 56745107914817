import { FC } from 'react';

import cn from 'classnames';

import { Button, ButtonProps, Flex } from 'gazprom-ui-lib';

import s from './TableSelectedActions.module.scss';

interface TableSelectedActionsProps {
  buttons: ButtonProps[];
  className?: string;
}

const TableSelectedActions: FC<TableSelectedActionsProps> = (props) => {
  const { buttons, className } = props;

  return (
    <Flex gap="32" className={cn(s.container, className)}>
      {buttons.map((props) => {
        return (
          <Button
            key={props.children?.toString()}
            type="link"
            size="small"
            htmlType="button"
            {...props}
          />
        );
      })}
    </Flex>
  );
};

export default TableSelectedActions;
