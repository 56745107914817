import { t } from 'tools/i18n';

export const DECLARATIONS_OPTIONS_TYPE = {
  SOCIAL: 'social',
  EDO: 'edo',
} as const;

export enum DECLARATIONS_MODAL_FORM_ENUM {
  TYPE = 'type',
}

export type DeclarationsFieldType = {
  [DECLARATIONS_MODAL_FORM_ENUM.TYPE]:
    | typeof DECLARATIONS_OPTIONS_TYPE.SOCIAL
    | typeof DECLARATIONS_OPTIONS_TYPE.EDO;
};

export const selectOptions = [
  {
    value: DECLARATIONS_OPTIONS_TYPE.SOCIAL,
    label: t('documents_declarations_for_social_programs'),
  },
  {
    value: DECLARATIONS_OPTIONS_TYPE.EDO,
    label: t('documents_declarations_for_edo'),
  },
];
