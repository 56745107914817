import { useState } from 'react';

import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import DocumentsDeclarationsHeaderModal from './documents-declarations-header-modal';

const DocumentsDeclarationsHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <ContentHeader>
        <ContentHeader.Title>{t('common_declaration_types')}</ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="plus" onClick={handleModal}>
            {t('documents_declarations_add_declarations')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <DocumentsDeclarationsHeaderModal open={isModalOpen} close={handleModal} />
    </>
  );
};

export default DocumentsDeclarationsHeader;
