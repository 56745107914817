import { FC } from 'react';

import { t } from 'tools/i18n';

import { Form, Input, Typography } from 'gazprom-ui-lib';

import { DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import s from './DocumentsDeclarationsFormMask.module.scss';

interface DocumentsDeclarationsFormMaskProps {
  id: string;
  defaultValue?: string;
}

const DocumentsDeclarationsFormMask: FC<DocumentsDeclarationsFormMaskProps> = (props) => {
  const { id, defaultValue } = props;
  return (
    <>
      <div className={s.titleContainer}>
        <Typography.Title level={5}>
          {t('documents_declarations_additional_input_mask')}
        </Typography.Title>
        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_optional')}
        </Typography.Text>
      </div>
      <Form.Item
        name={`${DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM.MASK}.${id}`}
        initialValue={defaultValue}>
        <Input placeholder={t('common_enter_text')} />
      </Form.Item>
    </>
  );
};

export default DocumentsDeclarationsFormMask;
