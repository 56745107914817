import { FC, PropsWithChildren } from 'react';

import { Flex, Typography } from 'gazprom-ui-lib';

import FilterReset from '../filter-reset';

interface FilterHeaderProps extends PropsWithChildren {
  valueKey: string;
}

const FilterHeader: FC<FilterHeaderProps> = (props) => {
  const { children, valueKey } = props;

  return (
    <Flex align="flex-start" justify="space-between">
      <Typography.Title level={5} key={children?.toString()}>
        {children}
      </Typography.Title>
      <FilterReset valueKey={valueKey} />
    </Flex>
  );
};

export default FilterHeader;
