import { ReactElement } from 'react';

import cn from 'classnames';

import { Icon } from 'gazprom-ui-lib';

import { SOCIAL_PROGRAM_REQUEST_ENUM, SocialProgramRequestType } from 'types/socialProgram.types';
import { SORT_ENUM } from 'types/sort.types';

import s from './SocialProgramRequests.module.scss';

export type FormFiltersType = {
  status?: SOCIAL_PROGRAM_REQUEST_ENUM[];
  categories: string[];
};

export type FormResponseType = {
  page: number;
  size: number;
  property: string;
  order: SORT_ENUM;
  search: string;
  filters?: FormFiltersType;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  property: 'KEDO_TASK.createdAt',
  order: SORT_ENUM.DESC,
  search: '',
};

export const getStatus = (status?: SocialProgramRequestType['currentKedoStatus']): ReactElement => {
  switch (status?.state) {
    case 'done': {
      return (
        <div className={cn(s.tag, s.success)}>
          <Icon name="wallet" />
          {status?.title}
        </div>
      );
    }
    case 'pending': {
      return (
        <div className={cn(s.tag, s.warning)}>
          <Icon name="time" />
          {status?.title}
        </div>
      );
    }
    case 'error': {
      return (
        <div className={cn(s.tag, s.error)}>
          <Icon name="deleteFilled" />
          {status?.title}
        </div>
      );
    }
    case 'continue': {
      return (
        <div className={cn(s.tag, s.continue)}>
          <Icon name="refresh" />
          {status?.title}
        </div>
      );
    }
    default: {
      return (
        <div className={cn(s.tag, s.gray)}>
          <Icon name="edit" />
          {status?.title}
        </div>
      );
    }
  }
};

export const getFiltersCount = (filters: Record<string, string[]>): number => {
  return Object.values(filters)
    .filter((filter) => !!filter)
    ?.reduce((acc, item) => [...acc, ...item], []).length;
};
