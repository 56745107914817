import { PaginationPropsType, PaginationResponseType } from 'types/pagination.types';
import { SortType } from 'types/sort.types';
import {
  EmployeeGroup,
  FreeTableData,
  NewSurveyType,
  QuestionStatsType,
  RespondentType,
  SURVEY_STATUSES_ENUM,
  SurveyType,
} from 'types/surveys.types';

import { mapFreetable } from './utils';

export enum CONDITION_SURVEY_ENUM {
  ONCE = 'ONCE',
  EVENT = 'EVENT',
}

export type GetSurveysPropsType = PaginationPropsType & {
  name?: string;
  condition: CONDITION_SURVEY_ENUM;
} & SortType;

export type GetSurveysResponseType = PaginationResponseType & {
  items: SurveyType[];
};

export type GetSurveysListResponseType = {
  id: number;
  revision_id: number;
  settings: {
    name: string;
  };
};

export type GetSurveysEmployeeGroupsResponseType = {
  content: EmployeeGroup[];
};

export type CreateSurveyRequestType = NewSurveyType;
export type CreateSurveyResponseType = {
  id: string;
  anketologId: string;
  revisionId: number;
};

export type UpdateSurveyRequestType = {
  id: string;
  body: NewSurveyType;
};

export type GetSurveyByIdRequestType = { survey_id: string };
export type GetSurveyByIdResponseType = {
  id: number;
  create_date: number;
  update_date: number;
  lock_date: null;
  archive_date: null;
  suspend_date: null;
  is_suspended: false;
  is_locked: false;
  is_archive: false;
  revision_id: 2265310;
  meta: {
    start_count: 0;
    answer_count: 0;
    answer_count_today: 0;
    disqualify_count: 0;
    disqualify_count_today: 0;
    page_count: 1;
    question_count: 18;
  };
  settings: {
    name: string;
    survey_is_time_limited: false;
    survey_time_limit: 0;
    archive_on_date: false;
    archive_date: 0;
    archive_on_answer_count: false;
    archive_answer_count: null;
  };
};

export type GetSurveyStatsByIdPropsType = {
  id: string;
  revisionId: string;
  positionId?: string;
  employerId?: string;
  divisionId?: string;
  from?: string;
  to?: string;
  options?: string;
  qoaQuery?: string;
};
export type GetSurveyStatsByIdResponseType = {
  questions: QuestionStatsType[];
};

export type GetFreeStatsByIdPropsType = {
  id: string;
  revisionId: string;
  questionId: string | number;
  text?: string;
  positionId?: string;
  employerId?: string;
  divisionId?: string;
  from?: string;
  to?: string;
  qoaQuery?: string | undefined;
} & PaginationPropsType;

export type GetFreeStatsByIdResponseType = {
  items: FreeTableData[];
} & PaginationResponseType;

export type MappedFreeStatsResponseType = ReturnType<typeof mapFreetable>;

export type GetEmployerTransformedResponseType = { value: string; label: string }[];
export type GetEmployerResponseType = {
  content: {
    id: string;
    name: string;
  }[];
} & PaginationResponseType;
export type GetEmployerRequestType = PaginationPropsType;

export type GetSurveysEmployeeGroupsRequestType = PaginationPropsType;

export type GetRespondentsPropsType = {
  anketaId?: string;
} & PaginationPropsType;

export type GetRespondentsResponseType = {
  items: RespondentType[];
} & PaginationResponseType;

export type GetSurveyStatusByIdResponseType = {
  status: SURVEY_STATUSES_ENUM | null;
  startedAt: string;
  timePassed: number;
  chronoUnit: string;
  linkedCount: number;
  totalCount: number;
};

export type GetSurveyStatusByIdPropsType = {
  id: string;
};
