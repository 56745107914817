import { FC } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { userManager } from 'utils/keycloak';

import { Button, Icon, Typography } from 'gazprom-ui-lib';

import DocumentView from 'containers/document-view';

import { SocialProgramRequestType } from 'types/socialProgram.types';

import s from './Content.module.scss';
import { FileType, renderSign } from './content.utils';

interface ContentProps {
  socialRequestData?: SocialProgramRequestType;
}

const Content: FC<ContentProps> = (props) => {
  const { socialRequestData } = props;
  const params = useParams<{ id: string }>();

  const handleDownload = async (file: FileType) => {
    const user = await userManager.getUser();
    const token = user?.access_token as string;
    return fetch(
      `${process.env.https://admin-gateway.dev.tockl.ru/api/v1/}kedo-file/task/${params.id}/download?fileKey=${file.fileKey}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((res) => res.blob())
      .then((res) => {
        const blobUrl = URL.createObjectURL(res);
        const a = document.createElement('a');

        a.href = blobUrl;
        a.download = file.fileName;
        a.click();

        URL.revokeObjectURL(blobUrl);
      });
  };

  const renderFile = (file: FileType) => {
    const { fileKey, fileName, signs } = file;

    const isSigned = !!signs?.length;

    return (
      <div key={fileKey} className={cn(s.download, { [s.signed]: isSigned })}>
        <div>
          <Icon name="imageFilled" className={s.icon} />
          <div className={s.fileDescription}>
            <Typography.Text weight="500" size="14" type={isSigned ? 'success' : 'primary'}>
              {fileName}
            </Typography.Text>
            {signs?.map(renderSign)}
          </div>
        </div>
        <div>
          {isSigned && <Icon name="okFilled" color="var(--color-success)" />}
          <button className={s.downloadButton} onClick={() => handleDownload(file)}>
            <Icon name="download" color="var(--color-primary-6)" />
          </button>
        </div>
      </div>
    );
  };

  const handleDownloadArchive = async () => {
    const user = await userManager.getUser();
    const token = user?.access_token as string;

    fetch(`${process.env.https://admin-gateway.dev.tockl.ru/api/v1/}kedo-file/archive?kedoTaskId=${params.id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const blobUrl = URL.createObjectURL(res);
        const a = document.createElement('a');

        a.href = blobUrl;
        a.download = `${socialRequestData?.id}.zip`;
        a.click();

        URL.revokeObjectURL(blobUrl);
      });
  };

  return (
    <div className={s.wrapper}>
      <div>
        <Typography.Title level={5}>{t('common_social_program')}</Typography.Title>
        <Typography.Text type="secondary" size="14">
          {socialRequestData?.category?.name}
        </Typography.Text>
        <Typography.Text size="14">{socialRequestData?.statement?.name}</Typography.Text>
      </div>
      <DocumentView.Employee employee={socialRequestData?.employee} />
      {socialRequestData?.filledAttributes?.map(renderAttributes)}
      {!!socialRequestData?.files?.length && (
        <div className={s.filesWrapper}>
          <Typography.Title level={5}>{t('common_attached_files')}</Typography.Title>
          {socialRequestData.files.map(renderFile)}
          <Button onClick={handleDownloadArchive} leftIcon="download" className={s.archiveButton}>
            {t('common_download_archive')}
          </Button>
        </div>
      )}
    </div>
  );
};
const renderAttributes = (attribute: {
  attributeId: string;
  attributeName: string;
  num: number;
  userText: string;
}) => {
  return (
    <div key={attribute.attributeId}>
      <Typography.Title level={5}>{attribute.attributeName}</Typography.Title>
      <Typography.Text type="secondary" size="14" className={s.pre}>
        {attribute.userText}
      </Typography.Text>
    </div>
  );
};

export default Content;
