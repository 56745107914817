import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'styles/index.scss';
import { userManager } from 'utils/keycloak';

import { ADDITIONAL_COLORS, ConfigProvider } from 'gazprom-ui-lib';

import WithErrorBoundary from 'containers/wrappers/with-error-boundary';

import AppPrepare from './app';
import {
  initDayJsPlugins,
  initLanguagesForThirdPartyLibs,
  initPinCodeAuthorization,
} from './index.utils';
import { store } from './store';
import { theme } from './utils/theme.utils';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const { antdLocale } = initLanguagesForThirdPartyLibs();
initDayJsPlugins();
initPinCodeAuthorization();

root.render(
  <ConfigProvider theme={theme} cssVariables={ADDITIONAL_COLORS} locale={antdLocale}>
    <AuthProvider userManager={userManager}>
      <Provider store={store}>
        <BrowserRouter>
          <WithErrorBoundary>
            <AppPrepare />
          </WithErrorBoundary>
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  </ConfigProvider>,
);
