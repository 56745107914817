import { t } from 'tools/i18n';

import { Icon } from 'gazprom-ui-lib';

export const URL = 'URL';
export const IMPORT = 'IMPORT';

export const TABS = [
  {
    label: (
      <>
        <Icon name="upload" />
        {t('common_from_disk')}
      </>
    ),
    value: IMPORT,
  },
  {
    label: (
      <>
        <Icon name="link" />
        {t('common_via_url')}
      </>
    ),
    value: URL,
  },
];

export enum FORM_IMAGE_ENUM {
  URL = 'URL',
  FULL_INFO = 'FULL_INFO',
  DRAGGER = 'DRAGGER',
  SHOW_PREVIEW = 'SHOW_PREVIEW',
}

export type FormImageResponse = {
  [FORM_IMAGE_ENUM.URL]: string;
  [FORM_IMAGE_ENUM.FULL_INFO]: { size: number; name: string; url: string };
};
