import { createApi } from '@reduxjs/toolkit/query/react';

import { SERVICE_TAGS_LIST } from 'constants/serviceTags';

import { BASE_QUERY_WITH_AUTH } from './utils';

export const API_NAME = 'initialApi';

const initialApiService = createApi({
  reducerPath: API_NAME,
  baseQuery: BASE_QUERY_WITH_AUTH,
  endpoints: () => ({}),
  tagTypes: SERVICE_TAGS_LIST,
  keepUnusedDataFor: 0,
});

export default initialApiService;
