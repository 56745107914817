import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { Tabs } from 'gazprom-ui-lib';

import WithOverflow from 'containers/wrappers/with-overflow';

import usePopoverHook from '../usePopover.hook';
import useTemplateTab from '../useTemplateTab.hook';
import s from './DocumentsDeclarationsSocial.module.scss';
import Header from './containers/header';
import { COMMON_TAB, INITIAL_TAB, TABS } from './documentsDeclarationsSocial.utils';

const DocumentsDeclarationsSocial = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(COMMON_TAB);
  const { popoverIsOpen, popoverHandler } = usePopoverHook({ activeTab });
  const templateTab = useTemplateTab({ popoverIsOpen, onClose: popoverHandler });

  const tabsWithTemplate = [...TABS, templateTab];

  return (
    <WithOverflow>
      <Header activeTab={activeTab} />
      <Tabs
        items={id ? tabsWithTemplate : [INITIAL_TAB]}
        type="card"
        onTabClick={setActiveTab}
        activeKey={activeTab}
        className={s.tabs}
      />
    </WithOverflow>
  );
};

export default DocumentsDeclarationsSocial;
