export const formatComplexStatusesToFlat = (statuses?: string[] | null) => {
  return (statuses || [])?.reduce((prev: string[], curr: string) => {
    const currentValue = curr.includes(',') ? curr.split(',') : curr;

    return prev.concat(currentValue);
  }, [] as string[]);
};
export const getFiltersCount = <R extends Object>(filters: R | null | undefined): number => {
  if (!filters) return 0;

  return Object.values(filters).reduce((acc, item) => {
    if (Array.isArray(item)) {
      acc += item.length;
    }

    return acc;
  }, 0);
};
