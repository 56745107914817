import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetEventParticipantsPropsType,
  GetEventParticipantsResponseType,
} from './pmeetApiService.types';

import { createPmeetUrl } from './utils';

const pmeetApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getEventParticipants: builder.query<
      GetEventParticipantsResponseType,
      GetEventParticipantsPropsType
    >({
      query: ({ id, ...params }) => ({
        url: createPmeetUrl(`/${id}/members${prepareQueryParams(params)}`),
      }),
    }),
  }),
});

export const { useLazyGetEventParticipantsQuery } = pmeetApiService;
