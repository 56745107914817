import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Badge, Button, ButtonProps, Form, Popover } from 'gazprom-ui-lib';

import useFiltersCount from 'containers/filters/useFiltersCount.hook';

import s from './Filters.module.scss';
import FilterApplyContent from './filter-apply-content';
import { FilterContext, FilterContextType, FiltersResponseType } from './filters.utils';

export interface FiltersProps<R> {
  overlay: ReactNode;
  overlayClassName?: string;
  filtersCount?: number | null;
  handleApply?: (value: R | null) => void;
  formFiltersName?: string;
  initialValues?: R | null;
  buttonProps?: ButtonProps;
  showClearAll?: boolean;
}

const Filters = <R extends FiltersResponseType>(props: FiltersProps<R>) => {
  const {
    formFiltersName = 'filters',
    overlayClassName,
    overlay,
    filtersCount: filtersCountFromProps,
    handleApply,
    initialValues = null,
    buttonProps,
    showClearAll = false,
  } = props;
  const refInitialSetAvailable = useRef<boolean>(true);

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [filters, setFilters] = useState<R | null>(initialValues);

  const form = Form.useFormInstance();

  const filtersCount = useFiltersCount({ filtersCountFromProps });

  useEffect(() => {
    if (initialValues && refInitialSetAvailable.current) {
      setFilters(initialValues);
      refInitialSetAvailable.current = false;
    }
  }, [initialValues]);

  const handleOpenChange = () => {
    setIsOpened((prevState) => !prevState);
  };

  const handleFormApply = () => {
    if (filters) {
      if (handleApply) {
        handleApply(filters);
      }
      // TODO Переделывать фильтры очень долго на новые рельсы, поэтому вот так
      if (form) {
        form.setFieldValue(formFiltersName, filters);
        form.setFieldValue('page', 1);
      }
      handleOpenChange();
    }
  };

  const handleFormClear = () => {
    if (filters) {
      if (handleApply) {
        handleApply(null);
      }
      // TODO Переделывать фильтры очень долго на новые рельсы, поэтому вот так
      if (form) {
        form.setFieldValue(formFiltersName, null);
        form.setFieldValue('page', 1);
      }
      setFilters(null);
      handleOpenChange();
    }
  };

  const memoizedContext = useMemo(
    () => ({
      clearFilter: (key: string, value?: R) =>
        setFilters((prevState) => {
          if (prevState) {
            return { ...prevState, [key]: value ?? null };
          }

          return prevState ?? null;
        }),
      filters,
      setFilters,
    }),
    [filters],
  ) as FilterContextType;

  return (
    <Popover
      forceRender
      open={isOpened}
      onOpenChange={handleOpenChange}
      arrow={false}
      overlayClassName={cn(s.overlayClassName, overlayClassName)}
      placement="bottomRight"
      content={
        <FilterContext.Provider value={memoizedContext}>
          <FilterApplyContent
            showClearAll={showClearAll}
            handleApply={handleFormApply}
            handleFormClear={handleFormClear}
            filtersCount={filtersCount}>
            {overlay}
          </FilterApplyContent>
        </FilterContext.Provider>
      }>
      <Button
        className={s.button}
        rightIcon={isOpened ? 'up' : 'down'}
        shape="default"
        ghost
        leftIcon="filter"
        {...buttonProps}>
        {t('common_filters')}
        <Badge count={filtersCount} />
      </Button>
      <Form.Item name={formFiltersName} noStyle />
    </Popover>
  );
};

export default Filters;
