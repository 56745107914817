import { t } from 'tools/i18n';

import { TableProps } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';
import { TablePersonProps } from 'components/table/table-person/tablePerson';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';
import TableText from 'components/table/table-text';

import { ModifiedFeedback } from 'pages/thank-you/thank-you.utils';

import { TStatusFeedback } from 'types/thankYou.types';

const useColumns = (): TableProps<ModifiedFeedback>['columns'] => {
  return [
    {
      title: t('thank_you_sender'),
      dataIndex: 'sender',
      sorter: true,
      render: (props: TablePersonProps) => {
        return <TablePerson {...props} position="" />;
      },
    },
    {
      title: t('thank_you_recipient'),
      dataIndex: 'recipient',
      sorter: true,
      render: (props: TablePersonProps) => {
        return <TablePerson {...props} position="" />;
      },
    },
    {
      title: t('thank_you_category'),
      dataIndex: 'category',
      sorter: true,
      render: ({ name }: { name: string }) => (
        <TableText size="12" weight="500">
          {name}
        </TableText>
      ),
    },
    {
      title: t('thank_you_thank_text'),
      dataIndex: 'thankText',
      sorter: true,
      render: (text: string) => <TableText size="10">{text}</TableText>,
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      sorter: true,
      render: (value: TStatusFeedback) => <TableStatus status={value as STATUSES} />,
    },
    {
      title: t('thank_you_data'),
      dataIndex: 'date',
      align: 'center',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (date: string) => <TableDate date={date} />,
      width: 150,
    },
  ];
};

export default useColumns;
