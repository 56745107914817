import { FC } from 'react';

import { t } from 'tools/i18n';

import { Checkbox, Form, Icon, Tooltip, Typography } from 'gazprom-ui-lib';

import { DeclarationType } from 'types/documents.types';

import s from './DocumentsDeclarationsFormCheck.module.scss';

interface DocumentsDeclarationsFormCheckProps {
  className?: string;
  id?: string;
  formName: string;
  label: string;
  title?: string;
  defaultValue?: DeclarationType['isPublish'] | DeclarationType['hasCommission'];
}

const DocumentsDeclarationsFormCheck: FC<DocumentsDeclarationsFormCheckProps> = (props) => {
  const { id, label, title, formName, defaultValue, className } = props;
  const fieldName = id ? `${formName}.${id}` : formName;

  return (
    <div className={className}>
      {title && (
        <div className={s.titleContainer}>
          <Typography.Title level={5}>{title}</Typography.Title>
          <Tooltip
            align={{ offset: [-6, 0] }}
            placement="bottomLeft"
            title={
              <Typography.Text size="12" weight="400" className={s.tooltipTitle}>
                {t('documents_declarations_social_programs_comission_approve_info')}
              </Typography.Text>
            }>
            <Typography.Text size="12" weight="400" type="secondary" className={s.tooltip}>
              <Icon name="info" />
              {t('common_optional')}
            </Typography.Text>
          </Tooltip>
        </div>
      )}
      <Form.Item name={fieldName} valuePropName="checked" initialValue={defaultValue ?? false}>
        <Checkbox>{label}</Checkbox>
      </Form.Item>
    </div>
  );
};

export default DocumentsDeclarationsFormCheck;
