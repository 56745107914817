import { useNavigate } from 'react-router-dom';
import { NEWS_ROUTE } from 'routes/news/list';
import { prepareDateAndTime } from 'utils/date.utils';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useEditArticleMutation } from 'services/article/articleApiService';

import { NEWS_FORM_ENUM, NewsFormResponse } from 'containers/news-form/newsForm.utils';

interface UseActionsProps {
  code?: string;
}

const useActions = (props: UseActionsProps) => {
  const { code } = props;

  const navigate = useNavigate();

  const [editArticle, { isLoading: editArticleIsFetching }] = useEditArticleMutation();

  const handleReset = () => navigate(NEWS_ROUTE);

  const handleEdit = useHandleRequestWithModal({
    onSuccessModalConfig: {
      onClose: handleReset,
      onConfirm: handleReset,
    },
  });

  const handleSubmit = (form: NewsFormResponse) => {
    if (code) {
      const publishFrom = prepareDateAndTime(
        form[NEWS_FORM_ENUM.PUBLISH_FROM_DATE],
        form[NEWS_FORM_ENUM.PUBLISH_FROM_TIME],
      );

      const editArticleProps = {
        title: form[NEWS_FORM_ENUM.TITLE],
        text: form[NEWS_FORM_ENUM.TEXT],
        categories: form[NEWS_FORM_ENUM.CATEGORIES],
        cover: { link: form[NEWS_FORM_ENUM.COVER] },
        isPublish: form[NEWS_FORM_ENUM.PUBLISH],
        publishFrom,
        code,
      };

      editArticle(editArticleProps).then(handleEdit);
    }
  };

  return { handleReset, handleSubmit, isFetching: editArticleIsFetching };
};

export default useActions;
