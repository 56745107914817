import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateNotificationTemplatePropsType,
  CreateNotificationTemplateResponseType,
  GetNotificationTemplateByIdPropsType,
  GetNotificationTemplateByIdResponseType,
  GetNotificationTemplatesPropsType,
  GetNotificationTemplatesResponseType,
  UpdateNotificationTemplatePropsType,
  UpdateNotificationTemplateResponseType,
} from './notificationTemplateApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { notificationTemplateUrl } from './utils';

const notificationTemplateApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationTemplates: builder.query<
      GetNotificationTemplatesResponseType,
      GetNotificationTemplatesPropsType
    >({
      query: (params) => ({
        url: notificationTemplateUrl(prepareQueryParams(params)),
      }),
      providesTags: [SERVICE_TAGS.GET_NOTIFICATION_TEMPLATES],
    }),
    getNotificationTemplateById: builder.query<
      GetNotificationTemplateByIdResponseType,
      GetNotificationTemplateByIdPropsType
    >({
      query: (id) => ({
        url: notificationTemplateUrl(`/${id}`),
      }),
      providesTags: [SERVICE_TAGS.GET_NOTIFICATION_TEMPLATE_BY_ID],
    }),
    updateNotificationTemplate: builder.mutation<
      UpdateNotificationTemplateResponseType,
      UpdateNotificationTemplatePropsType
    >({
      query: ({ id, ...body }) => ({
        url: notificationTemplateUrl(`/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_NOTIFICATION_TEMPLATE_BY_ID,
        SERVICE_TAGS.GET_NOTIFICATION_TEMPLATES,
      ],
    }),
    createNotificationTemplate: builder.mutation<
      CreateNotificationTemplateResponseType,
      CreateNotificationTemplatePropsType
    >({
      query: (body) => ({
        url: notificationTemplateUrl(''),
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_NOTIFICATION_TEMPLATE_BY_ID,
        SERVICE_TAGS.GET_NOTIFICATION_TEMPLATES,
      ],
    }),
  }),
});

export const {
  useGetNotificationTemplatesQuery,
  useLazyGetNotificationTemplatesQuery,
  useGetNotificationTemplateByIdQuery,
  useCreateNotificationTemplateMutation,
  useUpdateNotificationTemplateMutation,
} = notificationTemplateApiService;
