import { FC } from 'react';

import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { FormFiltersType } from '../../socialProgram.utils';
import { FILTER_STATUSES } from './filters.utils';
import useFilterItems from './useFilterItems.hook';

const Filters: FC = () => {
  const form = Form.useFormInstance();

  const filters = Form.useWatch('filters', form);

  const filterItems = useFilterItems();

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <FiltersContainer<FormFiltersType>
        showClearAll
        initialValues={filters}
        overlay={
          <>
            <FilterCollapse items={filterItems} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup usePadding={false} valueKey="status" options={FILTER_STATUSES} />
            </FilterCustomWrapper>
          </>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
