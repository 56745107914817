import { FC, useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { t } from 'tools/i18n';

import { Avatar, Dropdown, HandleItemClickType, Icon, Typography } from 'gazprom-ui-lib';

import s from './Header.module.scss';
import HeaderLogo from './header-logo';

const APP_CONTENT = 'app-content';
const APP_FOOTER = 'app-footer';
const Header: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const auth = useAuth();

  const onLogout = () => {
    auth.signoutSilent();
  };

  const version = process.env.REACT_APP_VERSION;

  const items = [
    {
      disabled: true,
      key: 'profile',
      label: (
        <div className={s.item}>
          <Icon name="accountCircle" />
          <Typography.Text size="14" weight="500" type="primary">
            {t('common_profile')}
          </Typography.Text>
        </div>
      ),
    },
    {
      key: 'logout',
      label: (
        <div className={s.item}>
          <Icon name="logout" />
          <Typography.Text size="14" weight="500" type="primary">
            {t('common_logout')}
          </Typography.Text>
        </div>
      ),
    },
    {
      disabled: true,
      key: 'dummy key',
      label: (
        <div className={s.item}>
          <Typography.Text size="12" type="secondary">{`${t(
            'common_app_version',
          )}: ${version}`}</Typography.Text>
        </div>
      ),
    },
  ];

  const onOpenChange = (flag: boolean) => {
    setIsOpen(flag);
    if (flag) {
      document.getElementById(APP_CONTENT)!.style.opacity = '0.3';
      document.getElementById(APP_FOOTER)!.style.opacity = '0.3';
    } else {
      document.getElementById(APP_CONTENT)!.style.opacity = '1';
      document.getElementById(APP_FOOTER)!.style.opacity = '1';
    }
  };

  const handleItemClick: HandleItemClickType = (item) => {
    if (item.key === 'logout') {
      return onLogout();
    }
  };

  return (
    <div className={s.wrapper}>
      <HeaderLogo />
      <Dropdown
        align={{ offset: [16, 6] }}
        items={items}
        handleItemClick={handleItemClick}
        onOpenChange={onOpenChange}
        overlayClassName={s.overlay}>
        <button className={s.dropdownButton}>
          <Avatar
            firstName={auth?.user?.profile?.given_name ?? ''}
            lastName={auth?.user?.profile?.family_name ?? ''}
            alt="аватар пользователя"
            size="40"
          />
          <Icon name={isOpen ? 'up' : 'down'} />
        </button>
      </Dropdown>
    </div>
  );
};

export default Header;
