import initialApiService from 'services/initialApiService';

import {
  GetRolesByCodePropsType,
  GetRolesByCodeResponseType,
  GetRolesPropsType,
  GetRolesResponseType,
  UpdateRolePropsType,
  UpdateRoleResponseType,
  UpdateUserRolePropsType,
  UpdateUserRoleResponseType,
} from './rolesApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createRolesUrl } from './utils';

const rolesApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<GetRolesResponseType, GetRolesPropsType>({
      query: () => ({
        url: createRolesUrl(''),
      }),
      providesTags: [SERVICE_TAGS.GET_ROLES],
    }),
    updateUserRole: builder.mutation<UpdateUserRoleResponseType, UpdateUserRolePropsType>({
      query: ({ personId, codes }) => ({
        url: createRolesUrl(`/assign/${personId}`),
        method: 'POST',
        body: {
          codes,
        },
      }),
      invalidatesTags: [SERVICE_TAGS.GET_PERSONS, SERVICE_TAGS.GET_PERSON_BY_ID],
    }),
    updateRole: builder.mutation<UpdateRoleResponseType, UpdateRolePropsType>({
      query: ({ code, ...body }) => ({
        url: createRolesUrl(`/${code}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_ROLES, SERVICE_TAGS.GET_ROLES_BY_CODE],
    }),
    getRolesByCode: builder.query<GetRolesByCodeResponseType, GetRolesByCodePropsType>({
      query: (code) => ({
        url: createRolesUrl(`/${code}`),
      }),
      providesTags: [SERVICE_TAGS.GET_ROLES_BY_CODE],
    }),
  }),
});

export const {
  useUpdateUserRoleMutation,
  useGetRolesQuery,
  useGetRolesByCodeQuery,
  useUpdateRoleMutation,
} = rolesApiService;
