import { useGetBPMNProcessesQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { transformData } from './documentsDeclarationsForm.utils';

const useBPMNProcesses = () => {
  const { data, isLoading } = useGetBPMNProcessesQuery();

  const { options, dataObject } = transformData(data ?? []);

  return {
    options,
    dataObject,
    isLoading,
  };
};

export default useBPMNProcesses;
