import dayjs, { Dayjs } from 'dayjs';

export const prepareDateAndTime = (date?: Dayjs, time?: Dayjs) => {
  let initDate = date ?? null;

  if (initDate) {
    if (time) {
      initDate = initDate.set('hours', time.get('hours')).set('minutes', time.get('minutes'));
    } else {
      initDate = initDate.startOf('date');
    }
  }

  if (initDate && dayjs(initDate).isValid()) {
    initDate = dayjs(initDate)?.utc();
  }

  return initDate;
};

export const getTimezone = (): string => {
  const offsetMinutes = dayjs().utcOffset();
  const offsetHours = Math.abs(offsetMinutes / 60);
  const sign = offsetMinutes > 0 ? '+' : '-';

  const formattedHours = String(offsetHours);

  return `UTC${sign}${formattedHours}`;
};
