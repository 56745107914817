import { t } from 'tools/i18n';

import { Checkbox, Form } from 'gazprom-ui-lib';

import { FORM_ENUM } from '../../faqQuestionForm.utils';

const Published = () => {
  return (
    <Form.Item name={FORM_ENUM.PUBLISH} valuePropName="checked">
      <Checkbox>{t('common_published')}</Checkbox>
    </Form.Item>
  );
};

export default Published;
