import { GetFaqCategoriesResponseType } from 'services/faq/faqApiService.types';

import { SelectProps } from 'gazprom-ui-lib';

import { FaqCategoryType } from 'types/faq.types';

export const selectFromResult = ({
  data,
  ...other
}: {
  data?: GetFaqCategoriesResponseType;
  isFetching: boolean;
}) => {
  return {
    data: createOptionsTree(data?.categories),
    ...other,
  };
};
const createOptionsTree = (dataset?: FaqCategoryType[]): SelectProps['options'] => {
  if (!dataset) {
    return [];
  }

  const hashTable = Object.create(null);
  const dataTree: SelectProps['options'] = [];

  const modifyUnionData = (category: FaqCategoryType) => {
    hashTable[category.id] = {
      label: category.nameCategory,
      value: category.id,
    };
  };

  const setTreeData = (category: FaqCategoryType) => {
    const parentId = category.parentCategory.id;

    if (parentId) {
      if (hashTable[parentId].options) {
        hashTable[parentId].options.push(hashTable[category.id]);
      } else {
        hashTable[parentId].options = [hashTable[category.id]];
      }
    } else {
      dataTree.push(hashTable[category.id]);
    }
  };

  dataset.forEach(modifyUnionData);
  dataset.forEach(setTreeData);

  return dataTree;
};
