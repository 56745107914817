import { EmployeeType } from 'types/employee.types';

export const prepareOptions = (performers?: EmployeeType[]) => {
  if (!performers) {
    return [];
  }

  return performers.map((performer) => {
    const { firstName, lastName, patronymic } = performer?.person ?? {};

    return {
      value: performer.id,
      label: `${firstName} ${lastName} ${patronymic} (${performer.position.name})`,
    };
  });
};
