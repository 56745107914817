import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Form, TextArea, Typography } from 'gazprom-ui-lib';

import { NOTIFICATION_TEMPLATE_FORM_ENUM } from '../notificationTemplateForm.utils';
import s from './NotificationTemplateFormName.module.scss';

const NotificationTemplateFormName = () => {
  return (
    <div>
      <Typography.Text strong size="16">
        {t('common_template_name')}
      </Typography.Text>
      <Form.Item
        normalize={normalizeTextField}
        name={NOTIFICATION_TEMPLATE_FORM_ENUM.NAME}
        rules={[formRules.required, { max: 80 }]}>
        <TextArea
          placeholder={t('common_enter_text')}
          maxLength={80}
          showCount
          className={s.title}
        />
      </Form.Item>
    </div>
  );
};

export default NotificationTemplateFormName;
