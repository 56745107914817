import { GetSocialTypesResponseType } from 'services/social-program/socialProgramApiService.types';

import {
  EmployeeRequirements,
  Employer,
  SocialProgramDocuments,
  SocialProgramPayoutAmount,
} from 'types/socialProgram.types';

export const socialProgramCategoryUrl = (url?: string): string =>
  `social-program-category${url ?? ''}`;

export const mapEmployeeRequirements = (values: EmployeeRequirements[]) => {
  return values.map((value) => ({ value: value.id, label: value.nameEmployeeRequirements }));
};

export const mapEmployers = (values: Employer[]) => {
  return values.map((value) => ({ label: value.name, value: value.id }));
};

export const mapDocuments = (values: SocialProgramDocuments[]) => {
  return values.map((value) => ({ value: value.id, label: value.nameDocument }));
};

export const mapPayoutAmount = (values: SocialProgramPayoutAmount[]) => {
  return values.map((value) => ({ value: value.id, label: value.namePayoutAmount }));
};

export const mapSocialTypes = (values: GetSocialTypesResponseType['items']) => {
  return values.map((value) => ({ value: value.id, label: value.name }));
};
