import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { EVENTS_ROUTE } from 'routes/events/list';

import { Form, Tabs } from 'gazprom-ui-lib';

import { EVENT_FORM_NAME } from 'containers/event-form/eventForm.utils';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './EventDetails.module.scss';
import Header from './containers/header';
import Modal from './containers/modal';
import ModalValidation from './containers/modal-validation';
import useActions from './useActions.hook';
import useGetAndSetInitialValues from './useGetAndSetInitialData';
import useGetTabs, { EVENT_EDIT } from './useGetTabs.hook';
import useHandleUpdate from './useHandleUpdate.hook';

const EventsDetails = () => {
  const navigate = useNavigate();

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>(EVENT_EDIT);

  const [form] = Form.useForm();

  const handleGoBack = () => {
    navigate(EVENTS_ROUTE);
  };

  const handleModalOpenClose = () => {
    setIsModalOpened((prevState) => !prevState);
  };

  const { data, isLoading, isFetching } = useGetAndSetInitialValues({ form });
  const { updateEventIsFetching, handleSave, publishUnpublishEvent } = useHandleUpdate({
    data,
    form,
    setErrorFields,
    setIsModalOpened,
  });

  const isPublished = !!data?.article?.isPublish;

  const { headerButton, actions } = useActions({
    updateEventIsFetching: isFetching || updateEventIsFetching,
    data,
    handleSave,
    activeTab,
    handleModalOpenClose,
  });

  const tabs = useGetTabs({
    isLoading,
    isPublished,
    article: data?.article.id,
    actions,
    treeIsLoading: updateEventIsFetching,
  });

  return (
    <>
      <WithOverflow className={s.wrapper}>
        <Header event={data} headerButton={headerButton} />
        <Form form={form} onReset={handleGoBack} id={EVENT_FORM_NAME}>
          <Tabs items={tabs} type="card" onTabClick={setActiveTab} activeKey={activeTab} />
        </Form>
      </WithOverflow>
      <Modal
        isPublished={isPublished}
        open={isModalOpened}
        handleSubmit={publishUnpublishEvent}
        isLoading={updateEventIsFetching}
        handleClose={handleModalOpenClose}
      />
      <ModalValidation handleClose={() => setErrorFields('')} fields={errorFields} />
    </>
  );
};

export default EventsDetails;
