import { FC, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { get1CInstanceLnaDetailsWithIdRoute } from 'routes/settings/list';

import { useLazyGetKedoReviewTasksQuery } from 'services/kedo-review-tasks/kedoReviewTasksApiService';

import { Table } from 'gazprom-ui-lib';

import { KedoReviewTaskType, REVIEW_TASK_TYPES_ENUM } from 'types/review-task.types';

import useColumnsHook from './useColumns.hook';

interface InactiveLnaTableProps {
  handleAssign: (id: string) => void;
  handleDelete: (id: string) => void;
}

const InactiveLnaTable: FC<InactiveLnaTableProps> = (props) => {
  const { handleAssign, handleDelete } = props;
  const params = useParams<{ instanceId: string }>();
  const columns = useColumnsHook({
    handleAssign,
    handleDelete,
  });
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const [getKedoReviewTasks, { data, isLoading }] = useLazyGetKedoReviewTasksQuery();

  useEffect(() => {
    getKedoReviewTasks({
      page,
      size: 10,
      status: [REVIEW_TASK_TYPES_ENUM.LNA_CANCELED, REVIEW_TASK_TYPES_ENUM.LNA_CREATED],
      type: 'LNA',
      instance1cId: params.instanceId!,
    });
  }, [page, getKedoReviewTasks, params.instanceId]);

  const onRow = ({ id }: KedoReviewTaskType) => ({
    onClick: () => {
      navigate(get1CInstanceLnaDetailsWithIdRoute(params.instanceId!, id));
    },
  });

  return (
    <Table
      dataSource={data?.items}
      columns={columns}
      onRow={onRow}
      pagination={{
        current: page,
        total: data?.totalCount,
      }}
      loading={isLoading}
      rowKey="id"
      onChange={(pagination) => setPage(pagination.current as number)}
    />
  );
};

export default InactiveLnaTable;
