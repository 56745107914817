import initialApiService from 'services/initialApiService';

import {
  CreateArticleCategoryPropsType,
  CreateArticleCategoryResponseType,
  GetNewsCategoriesPropsType,
  GetNewsCategoriesResponseType,
} from './categoryApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createCategoryUrl } from './utils';

const categoryApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getNewsCategories: builder.query<GetNewsCategoriesResponseType, GetNewsCategoriesPropsType>({
      query: (params) => ({
        url: createCategoryUrl(''),
        params,
      }),
      providesTags: [SERVICE_TAGS.GET_ARTICLES_CATEGORIES],
    }),
    createArticleCategory: builder.mutation<
      CreateArticleCategoryResponseType,
      CreateArticleCategoryPropsType
    >({
      query: (body) => ({
        url: createCategoryUrl(''),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_ARTICLES_CATEGORIES],
    }),
  }),
});

export const { useGetNewsCategoriesQuery, useCreateArticleCategoryMutation } = categoryApiService;
