import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableIsPublish from 'components/table/table-is-publish';
import TableText from 'components/table/table-text';

import { DeclarationsTypes } from 'types/documents.types';

import s from './DocumentsDeclarations.module.scss';

const useColumns = (): ColumnsType<DeclarationsTypes> => {
  return useMemo(
    () => [
      {
        title: t('common_name'),
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => {
          return (
            <TableText size="14" weight="500" className={s.nameColumn}>
              {text}
            </TableText>
          );
        },
      },
      {
        title: t('social_program_category'),
        dataIndex: 'categoryName',
        key: 'categoryName',
        render: (text: string) => {
          return (
            <Typography.Text size="12" weight="500" type="secondary">
              {text}
            </Typography.Text>
          );
        },
      },
      {
        title: t('common_published'),
        dataIndex: 'isPublish',
        key: 'isPublish',
        align: 'center',
        render: (value: boolean) => <TableIsPublish isPublish={value} />,
      },
      {
        title: t('employee_groups_column_created_date'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: (date: string) => <TableDate date={date} />,
        sorter: true,
        defaultSortOrder: 'descend',
      },
    ],
    [],
  );
};

export default useColumns;
