import { useMatch, useParams } from 'react-router-dom';
import { SUGGESTIONS_DECISIONS_DETAILS_ROUTE } from 'routes/suggestions/list';

import {
  useGetSuggestionByIdQuery,
  useGetSuggestionDecisionByIdQuery,
} from 'services/suggestion/suggestionApiService';

const useGetData = () => {
  const params = useParams<{ id: string }>();

  const isSuggestionDecision = !!useMatch(SUGGESTIONS_DECISIONS_DETAILS_ROUTE);

  const {
    data: suggestion,
    isLoading: isSuggestionLoading,
    isFetching: isSuggestionFetching,
  } = useGetSuggestionByIdQuery({ id: params.id! }, { skip: !params.id || isSuggestionDecision });

  const {
    data: decision,
    isLoading: isDecisionLoading,
    isFetching: isDecisionFetching,
  } = useGetSuggestionDecisionByIdQuery(
    { id: params.id! },
    { skip: !params.id || !isSuggestionDecision },
  );

  const isLoading = isSuggestionLoading || isDecisionLoading;
  const isFetching = isSuggestionFetching || isDecisionFetching;

  return {
    decision,
    suggestion,
    isLoading: isLoading || isFetching,
  };
};

export default useGetData;
