import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { EmployeeType } from 'types/employee.types';
import { RenewFormItemStatusType, RenewFormItemType } from 'types/topManagement.types';

const useColumns = (): ColumnsType<RenewFormItemType> => {
  const employeeRenderer = (employee: EmployeeType) => {
    const { firstName, lastName, patronymic } = employee.person;

    return (
      <Typography.Text size="14" weight="500">
        {firstName} {lastName} {patronymic}
      </Typography.Text>
    );
  };

  const statusRenderer = (status: RenewFormItemStatusType) => {
    return <TableStatus status={status as STATUSES} />;
  };

  const organizationRenderer = (employee: EmployeeType) => {
    const { name } = employee?.employer ?? {};

    return (
      <Typography.Text size="12" weight="500" type="secondary">
        {name}
      </Typography.Text>
    );
  };

  const departmentRenderer = (employee: EmployeeType) => {
    const { name } = employee.division;

    return (
      <Typography.Text size="12" weight="500" type="secondary">
        {name}
      </Typography.Text>
    );
  };

  return [
    {
      title: t('common_employee'),
      dataIndex: 'employee',
      render: employeeRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      render: statusRenderer,
      width: 300,
    },
    {
      title: t('social_program_organization'),
      dataIndex: 'employee',
      render: organizationRenderer,
      width: 300,
    },
    {
      title: t('common_department'),
      dataIndex: 'employee',
      render: departmentRenderer,
      width: 300,
    },
    {
      align: 'center',
      title: t('common_created'),
      dataIndex: 'createdAt',
      render: (date: string) => <TableDate date={date} />,
      sorter: true,
    },
    {
      align: 'center',
      title: t('common_updated'),
      dataIndex: 'updatedAt',
      render: (date: string) => <TableDate date={date} />,
      sorter: true,
      defaultSortOrder: 'descend',
    },
  ];
};

export default useColumns;
