export const HANDBOOK_BASE_FORM_NAME = 'HANDBOOK_BASE_FORM_NAME';

export enum HANDBOOK_BASE_FORM_ENUM {
  NAME = 'NAME',
  COORDINATES = 'COORDINATES',
  MAP = 'MAP',
  MEMO = 'MEMO',
  TG_CHANEL = 'TG_CHANEL',
  PUBLISH = 'PUBLISH',
}

export interface HandbookBaseFormResponse {
  [HANDBOOK_BASE_FORM_ENUM.NAME]: string;
  [HANDBOOK_BASE_FORM_ENUM.COORDINATES]: string;
  [HANDBOOK_BASE_FORM_ENUM.MAP]?: {
    link: string;
    name: string | null;
    size: number;
  };
  [HANDBOOK_BASE_FORM_ENUM.MEMO]?: {
    link: string;
    name: string | null;
    size: number;
  };
  [HANDBOOK_BASE_FORM_ENUM.TG_CHANEL]?: string;
  [HANDBOOK_BASE_FORM_ENUM.PUBLISH]?: boolean;
}
