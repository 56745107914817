import { FC, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import { BusinessTripApprovalItemType } from 'types/businessTrip.types';

import Table from '../table';
import s from './ApprovalCycle.module.scss';

interface ApprovalCycleProps {
  expandedByDefault?: boolean;
  cycleNumber: string;
  dataSource: BusinessTripApprovalItemType[];
}

const ApprovalCycle: FC<ApprovalCycleProps> = (props) => {
  const { cycleNumber, dataSource, expandedByDefault = false } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(expandedByDefault);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Flex vertical>
      <Flex gap="16" align="center" onClick={handleToggleExpand} className={s.header}>
        <Icon
          className={cn(!isExpanded && s.iconClosed)}
          name="down"
          color="var(--color-primary-6)"
        />
        <Typography.Title>{`${t('common_cycle')} ${cycleNumber}`}</Typography.Title>
      </Flex>
      {isExpanded && <Table dataSource={dataSource} />}
    </Flex>
  );
};

export default ApprovalCycle;
