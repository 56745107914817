import { FC, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import {
  useGetDeclarationTypeQuery,
  usePatchDeclarationTypesMutation,
} from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Button, Flex, handleRequest } from 'gazprom-ui-lib';

import DocumentsDeclarationsModal from '../documents-declarations-modal';
import s from './DocumentsDeclarationsButtons.module.scss';

interface DocumentsDeclarationsButtonsProps {
  isFetching: boolean;
}

const enum MODAL_TYPES {
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
  ERROR = 'error',
}

const DocumentsDeclarationsButtons: FC<DocumentsDeclarationsButtonsProps> = (props) => {
  const { isFetching } = props;

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [activeModal, setActiveModal] = useState<string | null>(null);

  const { data } = useGetDeclarationTypeQuery(id ?? skipToken);
  const [patchDeclarationTypes, { isLoading: isPatchLoading }] = usePatchDeclarationTypesMutation();

  const isPublish = data?.isPublish;
  const publishConfig = {
    onSuccess: () => setActiveModal(MODAL_TYPES.PUBLISH),
    onError: () => setActiveModal(MODAL_TYPES.ERROR),
  };
  const unpublishConfig = {
    onSuccess: () => setActiveModal(null),
  };

  const publishHandler = () => {
    if (isPublish) {
      setActiveModal(MODAL_TYPES.UNPUBLISH);
      return;
    }

    togglePublish();
  };

  const togglePublish = () => {
    patchDeclarationTypes({
      id: id!,
      body: {
        isPublish: !isPublish,
      },
    }).then((r) => {
      handleRequest(isPublish ? unpublishConfig : publishConfig)(r);
    });
  };

  return (
    <>
      <div className={data ? s.longContainer : s.container}>
        <Flex gap="8">
          <Button leftIcon="ok" htmlType="submit" fullWidth loading={isFetching}>
            {t('common_save')}
          </Button>
          {data && (
            <Button
              danger={isPublish}
              loading={isPatchLoading}
              leftIcon={isPublish ? 'unpublishFilled' : 'publishFilled'}
              fullWidth
              onClick={publishHandler}>
              {isPublish ? t('common_unpublish') : t('common_publish')}
            </Button>
          )}
          <Button type="secondary" fullWidth onClick={() => navigate(-1)}>
            {t('common_close')}
          </Button>
        </Flex>
      </div>
      <DocumentsDeclarationsModal
        modal={activeModal}
        setModal={setActiveModal}
        loading={isPatchLoading}
        handleClick={togglePublish}
      />
    </>
  );
};

export default DocumentsDeclarationsButtons;
