import { t } from 'i18next';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  open: boolean;
  onCancel: () => void;
  handleRemoveGroup: () => void;
  isLoading: boolean;
}

const RemoveModal = (props: Props) => {
  const { open, onCancel, handleRemoveGroup, isLoading } = props;

  return (
    <ModalDefault open={open} closable width={320} onCancel={onCancel}>
      <ModalDefault.Icon name="bin" type="danger" />
      <ModalDefault.Title>{t('common_accept_remove')}</ModalDefault.Title>
      <ModalDefault.Description>{t('employee_group_remove_description')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" loading={isLoading} onClick={handleRemoveGroup}>
          {t('common_remove')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default RemoveModal;
