import { Fragment } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { GetEmployersResponse } from 'services/employer/employerApiService.types';

import { Typography } from 'gazprom-ui-lib';

import { FilterCheckboxGroupOptions } from 'containers/filters/filter-checkbox-group/filterCheckboxGroup';

import { KedoStatusType } from 'types/kedo.types';

export const modifyTaskStatuses = (prev: FilterCheckboxGroupOptions[], curr: KedoStatusType) => {
  const alreadyExistingOptionIndex = prev.findIndex((option) => option.label === curr.name);

  if (alreadyExistingOptionIndex === -1) {
    prev.push({ label: curr.name, value: curr.code });
  } else {
    const existingOption = prev[alreadyExistingOptionIndex];

    existingOption.value = `${existingOption.value},${curr.code}`;
  }

  return prev;
};
export const renderSign = (sign: { date: string; key: string; owner: string }) => (
  <Fragment key={sign.key}>
    <Typography.Text size="12" type="secondary">
      {t('common_certificate_owner', { certificateOwner: sign.owner })}
    </Typography.Text>
    <Typography.Text size="12" type="secondary">
      {t('common_sign_date', { date: dayjs(sign.date).local().format('DD.MM.YYYY, HH:mm') })}
    </Typography.Text>
  </Fragment>
);

export const selectFromResult = ({ data }: { data?: GetEmployersResponse }) => ({
  employersOptions: data?.content.map(({ id, name }) => ({ value: id, label: name })) ?? [],
});

export const simpleSelectFromResult = ({ data }: { data?: { id: string; name: string }[] }) => ({
  data: data?.map(({ name, id }) => ({ value: id, label: name })) ?? [],
});

// as BE send objects with id duplicates, we need to filter response to achieve filters works properly
export const selectFromResultWithFilter = ({ data }: { data?: { id: string; name: string }[] }) => {
  const uniqueIds = new Set();

  return {
    data:
      data?.reduce((acc: { value: string; label: string }[], { id, name }) => {
        if (!uniqueIds.has(id)) {
          uniqueIds.add(id);
          acc.push({ value: id, label: name });
        }
        return acc;
      }, []) ?? [],
  };
};

export type RequestBody = {
  employerName?: string | null;
  categoryName?: string | null;
  positionName?: string | null;
  divisionName?: string | null;
  page?: number;
  size?: number;
};

export const INITIAL_REQUEST_BODY: RequestBody = {
  employerName: null,
  categoryName: null,
  positionName: null,
  divisionName: null,
  page: 1,
  size: 20,
};
