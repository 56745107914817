import { ChangeEvent, FC } from 'react';

import { Form, TextArea } from 'gazprom-ui-lib';

import TextCounter from '../text-counter';
import s from './ModalBody.module.scss';

interface ModalBodyProps {
  text: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const ModalBody: FC<ModalBodyProps> = (props) => {
  const { value, text, onChange } = props;
  return (
    <div className={s.container}>
      <TextCounter current={value.length} total={260} text={text} />
      <Form.Item name="text">
        <TextArea value={value} onChange={onChange} className={s.area} />
      </Form.Item>
    </div>
  );
};

export default ModalBody;
