import { t } from 'tools/i18n';

import { TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { getStatus } from 'pages/social-program/social-program-requests/socialProgramRequests.utils';

import { SocialProgramRequestType } from 'types/socialProgram.types';

const useColumns = (): TableProps<SocialProgramRequestType>['columns'] => {
  const employeeRenderer = (employee: SocialProgramRequestType['employee']) => {
    const { firstName, lastName } = employee.person;

    return (
      <TablePerson
        firstName={firstName}
        lastName={lastName}
        position={null}
        department={employee.division.name}
      />
    );
  };

  const statementRenderer = (statement: SocialProgramRequestType['statement']) => {
    return <Typography.Text weight="500">{statement.name}</Typography.Text>;
  };

  const statusRenderer = (currentKedoStatus: SocialProgramRequestType['currentKedoStatus']) => {
    return getStatus(currentKedoStatus);
  };

  const executorRenderer = (executor: SocialProgramRequestType['executor']) => {
    const firstName = executor.person.firstName || '';
    const lastName = executor.person.lastName || '';

    return <Typography.Text weight="500">{`${firstName} ${lastName}`}</Typography.Text>;
  };

  const createdRenderer = (date: string) => <TableDate date={date} />;

  return [
    {
      title: t('common_employee'),
      dataIndex: 'employee',
      width: 180,
      render: employeeRenderer,
    },
    {
      title: t('common_program'),
      dataIndex: 'statement',
      render: statementRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'currentKedoStatus',
      sorter: true,
      align: 'left',
      width: 240,
      render: statusRenderer,
    },
    {
      title: t('common_executor'),
      dataIndex: 'executor',
      width: 240,
      render: executorRenderer,
    },
    {
      title: t('common_created'),
      dataIndex: 'createdAt',
      align: 'center',
      width: 120,
      render: createdRenderer,
    },
  ];
};

export default useColumns;
