import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_KEDO_REVIEW_TASKS_CREATE_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

const Header: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(DOCUMENTS_KEDO_REVIEW_TASKS_CREATE_ROUTE);
  };

  return (
    <ContentHeader>
      <ContentHeader.Title>{t('kedo_review_tasks')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="plus" onClick={handleNavigate}>
          {t('kedo_review_tasks_create')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
