import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetPersonsQuery } from 'services/person/personApiService';
import { GetPersonsPropsType } from 'services/person/personApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType, USER_STATUS_ENUM } from './accessControlUsers.utils';

interface UseGetDataProp {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProp) => {
  const { form } = props;

  const [getPersons, { data, isLoading, isFetching, isUninitialized }] = useLazyGetPersonsQuery();

  const requestBody: FormResponseType = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody?.size) {
      const initialRequestBody: GetPersonsPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
      };

      if (debouncedRequestBody.search) {
        initialRequestBody.text = debouncedRequestBody.search;
      }

      if (debouncedRequestBody.filters?.roleIds) {
        initialRequestBody.roleIds = debouncedRequestBody.filters?.roleIds;
      }

      if (debouncedRequestBody.filters?.employerIds) {
        initialRequestBody.employerIds = debouncedRequestBody.filters?.employerIds;
      }

      const status = debouncedRequestBody.filters?.status;

      if (status) {
        if (status === USER_STATUS_ENUM.REGISTERED) {
          initialRequestBody.isRegistered = true;
        } else if (status === USER_STATUS_ENUM.NOT_REGISTERED) {
          initialRequestBody.isRegistered = false;
        }
      }

      getPersons(initialRequestBody);
    }
  }, [debouncedRequestBody, getPersons]);

  return {
    data,
    isLoading: isUninitialized || isLoading,
    isFetching: isDebouncing || isFetching,
  };
};

export default useGetData;
