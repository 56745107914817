import { FC, useEffect } from 'react';

import { Form } from 'gazprom-ui-lib';

import { FaqType } from 'types/faq.types';

import s from './FaqQuestionForm.module.scss';
import Category from './containers/category';
import Organization from './containers/organization';
import Published from './containers/published';
import Text from './containers/text';
import Title from './containers/title';
import { FAQ_QUESTION_FORM, FORM_ENUM, FormResponseType } from './faqQuestionForm.utils';

interface FaqQuestionFormProps {
  handleFinish: (formResponse: FormResponseType) => void;
  handleReset: () => void;
  faqQuestion?: FaqType;
}

const FaqQuestionForm: FC<FaqQuestionFormProps> = (props) => {
  const { handleFinish, handleReset, faqQuestion } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (faqQuestion) {
      form.setFields([
        { name: FORM_ENUM.TITLE, value: faqQuestion.title },
        { name: FORM_ENUM.TEXT, value: faqQuestion.answer },
        { name: FORM_ENUM.ORGANIZATION, value: faqQuestion.organization?.id },
        { name: FORM_ENUM.PARENT, value: faqQuestion.category.id },
        { name: FORM_ENUM.PUBLISH, value: faqQuestion.isPublish },
      ]);
    }
  }, [faqQuestion, form]);

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      onReset={handleReset}
      id={FAQ_QUESTION_FORM}
      className={s.wrapper}>
      <Title />
      <Text />
      <Organization />
      <Category />
      <Published />
    </Form>
  );
};

export default FaqQuestionForm;
