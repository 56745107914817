import { ChangeEvent, FC } from 'react';

import { t } from 'tools/i18n';

import { Flex, Icon, Input } from 'gazprom-ui-lib';

import s from './Filters.module.scss';

interface FiltersProps {
  text?: string;
  handleSearchChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

const Filters: FC<FiltersProps> = (props) => {
  const { text, handleSearchChange } = props;

  return (
    <Flex className={s.container}>
      <Input
        size="small"
        prefix={<Icon name="search" />}
        placeholder={t('common_search')}
        allowClear
        value={text}
        onChange={handleSearchChange}
      />
    </Flex>
  );
};

export default Filters;
