import { ReactNode } from 'react';

import { t } from 'tools/i18n';

import Comments from 'containers/comments';
import EventFormMain from 'containers/event-form/event-form-main';
import EventFormTarget from 'containers/event-form/event-form-target';
import WithLoader from 'containers/wrappers/with-loader';

import s from './EventDetails.module.scss';
import Docs from './containers/docs';
import Participants from './containers/participants';

interface UseGetTabsProps {
  actions: ReactNode;
  isLoading: boolean;
  treeIsLoading?: boolean;
  isPublished?: boolean;
  article?: string;
}

const useGetTabs = (props: UseGetTabsProps) => {
  const { treeIsLoading, actions, isLoading, isPublished, article } = props;

  return [
    {
      key: EVENT_EDIT,
      children: (
        <WithLoader isLoading={isLoading}>
          <EventFormMain className={s.mainForm} actions={actions} />
        </WithLoader>
      ),
      label: t('events_adding'),
      forceRender: true,
    },
    {
      key: EVENT_TARGET_GROUP,
      children: (
        <WithLoader isLoading={isLoading}>
          <EventFormTarget actions={actions} disabled={isPublished} treeIsLoading={treeIsLoading} />
        </WithLoader>
      ),
      label: t('common_target_group'),
      forceRender: true,
    },
    {
      key: EVENT_COMMENTS,
      children: <Comments articleId={article} />,
      label: t('common_comments'),
    },
    {
      key: EVENT_PARTICIPANTS,
      children: <Participants />,
      label: t('common_participants'),
    },
    {
      key: EVENT_DOCS,
      children: <Docs />,
      label: t('common_documents'),
      disabled: true,
    },
  ];
};

export const EVENT_EDIT = 'EVENT_EDIT';
export const EVENT_TARGET_GROUP = 'EVENT_TARGET_GROUP';
export const EVENT_COMMENTS = 'EVENT_COMMENTS';
export const EVENT_PARTICIPANTS = 'EVENT_PARTICIPANTS';
export const EVENT_DOCS = 'EVENT_DOCS';

export default useGetTabs;
