import { PieConfig } from '@ant-design/charts';

import { TextProps, Typography } from 'gazprom-ui-lib';

import { BACKEND_V1_BASE_URL } from 'constants/apiUrls';
import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';
import {
  HandlerParamsType,
  MappedFreeQuestionType,
  MappedQuestionStatType,
  OptionType,
  QoaParamType,
  QuestionStatsType,
  RequestBodyType,
} from 'types/surveys.types';

import s from './StatsTab.module.scss';

export enum QUESTION_TYPES {
  MATRIX = 'matrix',
  SELECT = 'select',
  FREE = 'free',
  SCALE = 'scale',
}

export const getReportUrl = (id: string) => `${BACKEND_V1_BASE_URL}reports/survey/${id}/answers`;

export const countStats = (options: OptionType[], questionId: number) => {
  const stats = {
    sumOfRatings: 0,
    sumOfCounts: 0,
    averageRating: '',
  };

  options.forEach(({ value, type }) => {
    stats.sumOfRatings += Number(type) * value;
    stats.sumOfCounts += value;
  });

  stats.averageRating =
    stats.sumOfCounts !== 0 ? (stats.sumOfRatings / stats.sumOfCounts).toFixed(1) : 'N/A';

  const mappedOptions = options.map(({ type, value, optionId }) => {
    const percentage = `${type} (${((value / stats.sumOfCounts) * 100).toFixed(0)}%)`;

    return { type: percentage, value, optionId, questionId };
  });

  return {
    averageRating: stats.averageRating,
    mappedOptions,
  };
};

export const DEFAULT_COLORS = [
  '#F87950',
  '#044A6F',
  '#A65F42',
  '#3AB5F9',
  '#DDB12C',
  '#8F330E',
  '#EED485',
  '#53B675',
  '#61CCF2',
  '#8F6F0E',
  '#006DB6',
  '#EE885F',
  '#003854',
  '#6CEAE4',
  '#DD5F2C',
  '#27AAE1',
  '#21587A',
  '#DDB12C',
  '#075D96',
  '#EEA385',
  '#17C1B9',
  '#85D2FF',
  '#A68D42',
  '#002D44',
  '#EECA5F',
];

export const extendColors = (colors: typeof DEFAULT_COLORS, count: number) => {
  const extendedColors = [];
  for (let i = 0; i < count; i++) {
    extendedColors.push(colors[i % colors.length]);
  }
  return extendedColors;
};

export const createConfig = (params: {
  data: Record<string, string | number>[];
  statisticTitle?: { top: string; bottom: string };
  legend?: PieConfig['legend'];
  onclickHandler?: (data: HandlerParamsType) => void;
  onHover?: (values: HandlerParamsType, isSelected: boolean) => void;
}) => {
  const colorsForData = extendColors(DEFAULT_COLORS, params.data.length);
  const configOptions: PieConfig = {
    tooltip: {
      position: 'top',
      domStyles: {
        'g2-tooltip': {
          'z-index': '1000',
          'background-color': 'black',
          'white-space': 'pre-line',
          'word-wrap': 'break-word',
          'min-width': '170px',
        },
      },
      customContent: (_title, items: Record<string, string>[]) => {
        if (items.length) {
          const { name, value } = items[0];
          const typographyTextProps = {
            size: '12' as TextProps['size'],
            className: s.tooltipText as TextProps['className'],
          };

          return (
            <div className={s.tooltip}>
              <div className={s.firstSection}>
                <Typography.Text {...typographyTextProps}>Вариант ответа: </Typography.Text>
                <Typography.Text {...typographyTextProps}>{name}</Typography.Text>
              </div>
              <div className={s.secondSection}>
                <Typography.Text {...typographyTextProps}>Ответов: </Typography.Text>
                <Typography.Text {...typographyTextProps}>{value}</Typography.Text>
              </div>
            </div>
          );
        }
      },
    },
    legend: false,
    appendPadding: 0,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    innerRadius: 0.7,
    data: params.data,
    color: colorsForData,
    autoFit: false,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [{ type: 'element-active' }],
    statistic: {
      // @ts-ignore-next-line
      title: params.statisticTitle
        ? {
            customHtml: () => (
              <div className={s.pieTextContainer}>
                <Typography.Text size="10" disabled>
                  {params.statisticTitle?.top}
                </Typography.Text>
                <Typography.Title level={3}>{params.statisticTitle?.bottom}</Typography.Title>
              </div>
            ),
          }
        : '',
      content: false,
    },
    padding: [5, 5, 5, 5],
    height: 130,
    width: 150,
    onReady: (chart) => {
      chart.on('element:click', (event: { data: { data: HandlerParamsType } }) => {
        const clickedData = event.data?.data;
        params.onclickHandler?.(clickedData);
      });
      chart.on('element:mouseenter', (event: { data: { data: HandlerParamsType } }) => {
        params.onHover?.(event.data.data, true);
      });
      chart.on('element:mouseleave', (event: { data: { data: HandlerParamsType } }) => {
        params.onHover?.(event.data.data, false);
      });
    },
  };

  return configOptions;
};

export const DEFAULT_REQUEST_BODY: RequestBodyType = {
  staff: null,
  company: null,
  division: null,
};

export const renderNumberedLegend = (
  { type, value, questionId, optionId }: HandlerParamsType,
  index: number,
  colors: string[],
) => {
  const [num, percent] = type.split(' ');

  return (
    <div key={index + type + value} className={s.legendItem} data-id={`${questionId}${optionId}`}>
      <div className={s.circle} style={{ backgroundColor: colors[index] }}>
        <Typography.Text size="10">{num}</Typography.Text>
      </div>
      <Typography.Text size="12" weight="500">
        {value}
      </Typography.Text>
      <Typography.Text size="10" type="secondary">
        {percent}
      </Typography.Text>
    </div>
  );
};

export const renderTextLegend = (
  { type, value, optionId }: HandlerParamsType,
  index: number,
  colors: string[],
) => {
  const regex = /^(.+)\s(\(\d+%\))$/;
  const result = type.match(regex);
  const color = colors[index];
  const text = result?.[1] ?? '';
  const percent = result?.[2] ?? '';

  return (
    <div key={index + type + value} className={s.legendItem} data-id={optionId}>
      <div className={s.circle} style={{ backgroundColor: color }} />
      <Typography.Text
        size="10"
        weight="500"
        style={{ color }}
        className={s.legendText}
        title={text}>
        {text}
      </Typography.Text>
      <Typography.Text size="12" weight="500">
        {value}
      </Typography.Text>
      <Typography.Text size="10" type="secondary">
        {percent}
      </Typography.Text>
    </div>
  );
};

export const getQueryQoaString = (array: QoaParamType[]) => {
  return array
    .map((item, index) => {
      const secondPart = item.oId
        ? `&qoa[${index}].oId=${item.oId}`
        : `&qoa[${index}].aT=${item.aT}`;

      return `&qoa[${index}].qId=${item.qId}${secondPart}`;
    })
    .join('');
};

export const paintSelectedLegend = (element: Element, isSelected: boolean) => {
  if (isSelected) {
    element?.classList.add(s.selected);
    return;
  }
  element?.classList.remove(s.selected);
};

export const filterEmptyFreeQuestions = (questions: MappedFreeQuestionType[]) =>
  questions.filter((question) => question.totalCount);

export const updateQuestionsWithStats = (
  questions: QuestionStatsType[],
  stats: MappedFreeQuestionType[],
): MappedQuestionStatType[] => {
  const statsMap = new Map(stats.map((item) => [item.freeQuestionId, item]));

  return questions.reduce((acc, current) => {
    if (current.type !== 'free') {
      acc.push(current);
    }

    if (current.type === 'free' && statsMap.has(current.freeQuestionId)) {
      const additionalRequestData = statsMap.get(current.freeQuestionId);

      if (!additionalRequestData?.totalCount) {
        return acc;
      }

      const free = {
        ...current,
        ...additionalRequestData,
      } as MappedFreeQuestionType;

      acc.push(free);
    }

    return acc;
  }, [] as MappedQuestionStatType[]);
};

export type RequestBody = PaginationPropsType & {
  company?: string | null;
  division?: string | null;
  staff?: string | null;
  search: string;
  property: string;
  order: SORT_ENUM;
};
