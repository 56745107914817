import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  NOTIFICATION_NEWSLETTER_CREATE_ROUTE,
  getNewsletterCreateWithTemplateRoute,
} from 'routes/notification/list';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetNotificationTemplatesQuery } from 'services/notification-template/notificationTemplateApiService';

import { Button, Flex, Form, Modal, Select, Typography } from 'gazprom-ui-lib';

import s from './CreateModal.module.scss';
import {
  DEFAULT_REQUEST_BODY,
  NOTIFICATION_TEMPLATE_FORM_ENUM,
  NotificationTemplateFormResponse,
  selectFromResult,
} from './createModal.utils';

interface CreateModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const CreateModal: FC<CreateModalProps> = (props) => {
  const navigate = useNavigate();

  const { isOpen, handleClose } = props;

  const [search, setSearch] = useState<string>('');

  const { debouncedValue: debouncedSearch } = useDebounce(search);

  const { data: templates } = useGetNotificationTemplatesQuery(
    { ...DEFAULT_REQUEST_BODY, search: debouncedSearch },
    {
      selectFromResult,
    },
  );

  const [form] = Form.useForm<NotificationTemplateFormResponse>();

  const handleCreateNew = () => {
    navigate(NOTIFICATION_NEWSLETTER_CREATE_ROUTE);
  };

  const onFinish = (formResponse: NotificationTemplateFormResponse) => {
    navigate(
      getNewsletterCreateWithTemplateRoute(formResponse[NOTIFICATION_TEMPLATE_FORM_ENUM.TEMPLATE]),
    );
  };

  return (
    <Modal open={isOpen} className={s.modal} onCancel={handleClose} closable>
      <Typography.Title level={4}>{t('notification_select_mailing_creation')}</Typography.Title>
      <Form form={form} onFinish={onFinish}>
        <Flex vertical gap="8" className={s.content}>
          <Typography.Text size="14" weight="500" type="primary">
            {t('notification_by_template')}
          </Typography.Text>

          <Form.Item name={NOTIFICATION_TEMPLATE_FORM_ENUM.TEMPLATE} rules={[formRules.required]}>
            <Select
              searchValue={search}
              onSearch={setSearch}
              allowClear
              showSearch
              placeholder={t('notification_choose_template_in_list')}
              className={s.select}
              options={templates}
            />
          </Form.Item>
        </Flex>

        <div className={s.buttonContainer}>
          <Button htmlType="submit" leftIcon="draft" type="primary" fullWidth>
            {t('notification_by_template')}
          </Button>
          <Button
            htmlType="button"
            type="secondary"
            leftIcon="plus"
            fullWidth
            onClick={handleCreateNew}>
            {t('common_create_new')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateModal;
