import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { GetHotContactsByEmployersResponse } from 'services/guide/guideApiService.types';

import { Collapse, ColumnsType, Typography } from 'gazprom-ui-lib';

import { GuideHotContactType } from 'types/guide.types';

import s from './GuideContacts.module.scss';
import GuideContactsRowChildren from './guide-contacts-row/guide-contacts-row-children';
import GuideContactsRowLabel from './guide-contacts-row/guide-contacts-row-label';

const textProps = { size: '12', weight: '500', color: 'text45' } as const;

const useColumns = (): ColumnsType<GetHotContactsByEmployersResponse> => {
  return useMemo(
    () => [
      {
        title: (
          <div className={s.tableHeaderContainer}>
            <Typography.Text {...textProps}>{t('guide_hot_contacts_organization')}</Typography.Text>
            <Typography.Text {...textProps}>{t('guide_hot_contacts_contact_info')}</Typography.Text>
          </div>
        ),
        dataIndex: 'employer',
        align: 'center',
        render: (
          { name, id }: { name: string; id: string },
          { hotContacts }: { hotContacts: GuideHotContactType[] },
        ) => {
          return (
            <Collapse
              noLeftBorder
              noPadding
              className={s.collapse}
              items={[
                {
                  key: '1',
                  label: <GuideContactsRowLabel name={name} id={id} />,
                  children: <GuideContactsRowChildren hotContacts={hotContacts} />,
                },
              ]}
            />
          );
        },
      },
    ],
    [],
  );
};

export default useColumns;
