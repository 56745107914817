import { FC, ReactNode, useState } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import { NOTIFICATION_NEWSLETTER_FORM_NAME } from 'containers/notification-newsletter-form/notificationNewsletterForm.utils';

import { NotificationNewsletterType } from 'types/notification.types';

import EditDeleteModal from '../edit-delete-modal';
import s from './Actions.module.scss';

interface ActionsProps {
  saveButton: ReactNode;
  isFetching: boolean;
  currentNewsletter?: NotificationNewsletterType;
  handleGoBack: () => void;
}

const Actions: FC<ActionsProps> = (props) => {
  const { saveButton, isFetching, handleGoBack, currentNewsletter } = props;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const isDeleteAvailable =
    currentNewsletter && dayjs(currentNewsletter.dateToSend).isAfter(dayjs());

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className={s.wrapper}>
        {saveButton}
        {isDeleteAvailable && (
          <Button
            loading={isFetching}
            danger
            fullWidth
            htmlType="button"
            leftIcon="binFilled"
            onClick={handleDeleteModalOpen}>
            {t('common_delete')}
          </Button>
        )}
        <Button
          onClick={handleGoBack}
          loading={isFetching}
          type="secondary"
          fullWidth
          htmlType="reset"
          form={NOTIFICATION_NEWSLETTER_FORM_NAME}>
          {t('common_close')}
        </Button>
      </div>
      <EditDeleteModal
        open={isDeleteModalOpen}
        handleClose={handleDeleteModalOpen}
        handleGoBack={handleGoBack}
      />
    </>
  );
};

export default Actions;
