import { Dispatch, SetStateAction } from 'react';

import { SorterResult } from 'antd/lib/table/interface';

import { FormInstance } from 'gazprom-ui-lib';

import { SORT_ENUM, SortOrderPropertyType } from 'types/sort.types';

const getSort = <R>(sorter: SorterResult<R> | SorterResult<R>[]) => {
  if (sorter && 'order' in sorter && sorter.order) {
    return sorter.order === 'ascend' ? SORT_ENUM.ASC : SORT_ENUM.DESC;
  }

  return undefined;
};

const getProperties = <R>(sorter: SorterResult<R> | SorterResult<R>[]) => {
  if ('field' in sorter) {
    return typeof sorter.field === 'string' ? sorter.field : undefined;
  }
};

export const handleTableChange =
  <S, R>(setRequestBody: Dispatch<SetStateAction<S>>, fallbackSort?: SortOrderPropertyType) =>
  (
    pagination: {
      pageSize?: number;
      current?: number;
    },
    _filter: unknown,
    sorter: SorterResult<R> | SorterResult<R>[],
  ) => {
    setRequestBody((prevState) => ({
      ...prevState,
      page: pagination.current ?? 1,
      size: pagination.pageSize ?? 10,
      order: getSort(sorter) ?? fallbackSort?.order,
      property: getProperties(sorter) ?? fallbackSort?.property,
    }));
  };

export const handleFormTableChange =
  <F, R>(form: FormInstance<F>, fallbackSort?: SortOrderPropertyType) =>
  (
    pagination: {
      pageSize?: number;
      current?: number;
    },
    _filter: unknown,
    sorter: SorterResult<R> | SorterResult<R>[],
  ) => {
    form.setFields([
      { name: 'page', value: pagination.current ?? 1 },
      { name: 'size', value: pagination.pageSize ?? 10 },
      { name: 'order', value: getSort(sorter) ?? fallbackSort?.order },
      { name: 'property', value: getProperties(sorter) ?? fallbackSort?.property },
    ]);
  };
