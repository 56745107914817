import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';

import {
  useGetEmployerSurveysQuery,
  useGetSurveySettingsByIdQuery,
  useGetSurveyStatusByIdQuery,
  useGetSurveysEmployeeGroupsQuery,
  useGetSurveysListQuery,
} from 'services/surveys/surveysApiService';

import { DEFAULT_PAGE_DATA, INITIAL_SELECT_DATA, SelectData } from 'pages/surveys/surveys.utils';

import { SURVEY_STATUSES_ENUM } from 'types/surveys.types';

const useSurveyFormData = () => {
  const { id } = useParams<{ id: string }>();

  const [selectData, setSelectData] = useState<SelectData>(INITIAL_SELECT_DATA);

  const { data: surveysList, isLoading: surveysListIsLoading } = useGetSurveysListQuery();
  const { data: employeeGroupsResult, isLoading: isEmployeeGroupsLoading } =
    useGetSurveysEmployeeGroupsQuery(DEFAULT_PAGE_DATA);
  const { data: organizationOptions, isLoading: isOrganizationOptionsLoading } =
    useGetEmployerSurveysQuery(DEFAULT_PAGE_DATA);
  const { data: surveySettings, isLoading: isLoadingSurveySettings } =
    useGetSurveySettingsByIdQuery(id! ?? skipToken);
  const { data: surveyStatus, isLoading: surveyStatusIsLoading } = useGetSurveyStatusByIdQuery(
    id ? { id } : skipToken,
  );

  const isReadyToAssign = surveyStatus?.status === SURVEY_STATUSES_ENUM.READY;
  const isFinished = surveyStatus?.status === SURVEY_STATUSES_ENUM.FINISHED;
  const isSurveyActive = Boolean(surveySettings?.active);
  const isLoading =
    surveysListIsLoading ||
    isLoadingSurveySettings ||
    isOrganizationOptionsLoading ||
    isEmployeeGroupsLoading ||
    surveyStatusIsLoading;

  useEffect(() => {
    if (surveySettings) {
      setSelectData({ name: surveySettings.info.name, condition: surveySettings?.condition });
    }
  }, [surveySettings]);

  return {
    selectData,
    surveysList,
    employeeGroupsResult,
    organizationOptions,
    isSurveyActive,
    isLoading,
    surveySettings,
    isReadyToAssign,
    isFinished,
  };
};

export default useSurveyFormData;
