import { FC, useCallback } from 'react';

import { formRules } from 'utils/formRules';

import { Form, FormInstance, Icon, Input } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';

import { FORM_IMAGE_ENUM } from '../imageModal.utils';
import s from './ImageModalUrl.module.scss';

interface EditorImageModalUrlProps {
  form: FormInstance;
}

const ImageModalUrl: FC<EditorImageModalUrlProps> = (props) => {
  const { form } = props;

  const url = Form.useWatch(FORM_IMAGE_ENUM.URL, form);
  const showPreview = Form.useWatch(FORM_IMAGE_ENUM.SHOW_PREVIEW, form);

  const onInputSubmit = (e: { stopPropagation: () => void; preventDefault: () => void }) => {
    e.preventDefault();
    e.stopPropagation();
    if (formRules.url.pattern.test(url)) {
      form.setFieldValue(FORM_IMAGE_ENUM.SHOW_PREVIEW, true);
    }
  };
  const resetImage = useCallback(() => {
    form.setFieldValue(FORM_IMAGE_ENUM.SHOW_PREVIEW, false);
  }, [form]);

  return (
    <div className={s.wrapper}>
      <Form.Item name={FORM_IMAGE_ENUM.URL} required rules={[formRules.required, formRules.url]}>
        <Input
          onPressEnter={onInputSubmit}
          addonAfter={
            <button className={s.iconRight} type="button" onClick={onInputSubmit}>
              <Icon name="arrowRight" />
            </button>
          }
        />
      </Form.Item>
      {showPreview && <ImgPreview type="image" url={url} resetImage={resetImage} />}
    </div>
  );
};

export default ImageModalUrl;
