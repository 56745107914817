import { useState } from 'react';

import { EVENTS_ROUTE } from 'routes/events/list';
import { t } from 'tools/i18n';

import { Button, Flex, Form, Tabs, TabsProps } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import EventFormMain from 'containers/event-form/event-form-main';
import EventFormTarget from 'containers/event-form/event-form-target';
import {
  EVENT_FORM_ENUM,
  EVENT_FORM_NAME,
  EVENT_MAIN_FILED_LIST,
} from 'containers/event-form/eventForm.utils';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './EventsAdd.module.scss';
import { ADD_EVENT, TARGET_GROUP } from './eventsAdd.utils';
import useSubmit from './useSubmit.hook';

const EventsAdd = () => {
  const [activeTab, setActiveTab] = useState<string>(ADD_EVENT);
  const [targetDisabled, setTargetDisabled] = useState<boolean>(true);

  const [form] = Form.useForm();

  const { createEventIsFetching, handleSubmit, handleReset } = useSubmit();

  const handleEventCreate = () => {
    form.validateFields(EVENT_MAIN_FILED_LIST).then(() => {
      setTargetDisabled(false);
      setActiveTab(TARGET_GROUP);

      // workaround to clear default error on tab change
      setTimeout(() => form.setFields([{ name: EVENT_FORM_ENUM.TARGET_GROUP, errors: [] }]), 0);
    });
  };

  const headerAction = targetDisabled ? (
    <Button
      rightIcon="arrowRight"
      htmlType="button"
      fullWidth
      type="primary"
      onClick={handleEventCreate}>
      {t('common_go_to_settings')}
    </Button>
  ) : (
    <Button rightIcon="ok" loading={createEventIsFetching} htmlType="submit" form={EVENT_FORM_NAME}>
      {t('common_save')}
    </Button>
  );

  const addActions = (
    <Flex justify="space-between" gap="12">
      {targetDisabled ? (
        <Button
          rightIcon="arrowRight"
          fullWidth
          type="primary"
          htmlType="button"
          onClick={handleEventCreate}>
          {t('common_go_to_settings')}
        </Button>
      ) : (
        <Button
          fullWidth
          rightIcon="ok"
          loading={createEventIsFetching}
          htmlType="submit"
          form={EVENT_FORM_NAME}>
          {t('common_save')}
        </Button>
      )}
      <Button type="secondary" fullWidth htmlType="reset" form={EVENT_FORM_NAME}>
        {t('common_close')}
      </Button>
    </Flex>
  );

  const targetActions = (
    <Flex justify="space-between" align="center" gap="12" className={s.actions}>
      <Button rightIcon="ok" fullWidth loading={createEventIsFetching} htmlType="submit">
        {t('common_save')}
      </Button>
      <Button type="secondary" fullWidth htmlType="reset" form={EVENT_FORM_NAME}>
        {t('common_close')}
      </Button>
    </Flex>
  );

  const tabs: TabsProps['items'] = [
    {
      key: ADD_EVENT,
      children: (
        <div className={s.padding}>
          <EventFormMain actions={addActions} />
        </div>
      ),
      label: t('events_adding'),
      forceRender: true,
    },
    {
      key: TARGET_GROUP,
      children: <EventFormTarget actions={targetActions} />,
      label: t('common_target_group'),
      disabled: targetDisabled,
      forceRender: true,
    },
  ];

  return (
    <WithOverflow>
      <ContentHeader align="flex-start">
        <ContentHeader.Title goBackLink={EVENTS_ROUTE}>{t('events_add')}</ContentHeader.Title>
        <ContentHeader.Actions>{headerAction}</ContentHeader.Actions>
      </ContentHeader>
      <Form
        form={form}
        className={s.tabs}
        name={EVENT_FORM_NAME}
        onReset={handleReset}
        onFinish={handleSubmit}>
        <Tabs items={tabs} type="card" onChange={setActiveTab} activeKey={activeTab} />
      </Form>
    </WithOverflow>
  );
};

export default EventsAdd;
