import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { updateNode } from 'utils/treeNode.utils';

import { DataNode } from 'gazprom-ui-lib';

interface EmployeeGroupsSlice {
  employerTree: DataNode[];
  checkedNodes: string[];
  partialChecked: string[];
  employees: string[];
  isChanged: boolean;
}

const initialState: EmployeeGroupsSlice = {
  employerTree: [],
  checkedNodes: [],
  partialChecked: [],
  employees: [],
  isChanged: false,
};

export const employeeGroupsSlice = createSlice({
  name: 'employeeGroupsSlice',
  initialState,
  reducers: {
    setDefaultData(state, action) {
      const newState = {
        ...state,
        employerTree: action.payload.tree,
        employees: action.payload.employees,
        checkedNodes: action.payload.checkedNodes,
        partialChecked: action.payload.partialChecked,
      };
      return newState;
    },
    setEmployerTree(state, action) {
      state.employerTree = action.payload;
    },
    updateEmployerTree(
      state,
      { payload: { key, children } }: PayloadAction<{ key: React.Key; children: DataNode[] }>,
    ) {
      state.employerTree = updateNode(state.employerTree, key, children);
    },
    setCheckedNodes(
      state,
      action: PayloadAction<{ checkedNodes: string[]; partialChecked: string[] }>,
    ) {
      state.checkedNodes = action.payload.checkedNodes;
      state.partialChecked = action.payload.partialChecked;
      state.isChanged = true;
    },
    setEmployees(state, action: PayloadAction<string[]>) {
      state.employees = action.payload;
      state.isChanged = true;
    },
    setIsChanged(state, action) {
      state.isChanged = action.payload;
    },
    setInitialState() {
      return initialState;
    },
  },
});

export const {
  setEmployerTree,
  updateEmployerTree,
  setCheckedNodes,
  setEmployees,
  setDefaultData,
  setInitialState,
  setIsChanged,
} = employeeGroupsSlice.actions;

export default employeeGroupsSlice.reducer;
