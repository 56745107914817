import Notification from 'pages/notification';
import NotificationNewsletterCreate from 'pages/notification/notification-newsletter/notification-newsletter-create';
import NotificationNewsletterEdit from 'pages/notification/notification-newsletter/notification-newsletter-edit';
import NotificationSystem from 'pages/notification/notification-system';
import NotificationTemplateEdit from 'pages/notification/notification-template/notification-template-edit';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import {
  NOTIFICATION_MAILING_MANAGEMENT_ROUTE,
  NOTIFICATION_NEWSLETTER_CREATE_ROUTE,
  NOTIFICATION_NEWSLETTER_CREATE_WITH_TEMPLATE_ROUTE,
  NOTIFICATION_NEWSLETTER_EDIT_ROUTE,
  NOTIFICATION_SYSTEM_NEWSLETTER_EDIT_ROUTE,
  NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE,
  NOTIFICATION_TEMPLATE_EDIT_ROUTE,
} from './list';

export const notificationRoutes: RouteConfig[] = [
  {
    path: NOTIFICATION_MAILING_MANAGEMENT_ROUTE,
    element: <Notification />,
    roles: [
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR_NOTICE,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.MODERATOR,
    ],
  },
  {
    path: NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE,
    element: <NotificationSystem />,
    roles: [
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR_NOTICE,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.MODERATOR,
    ],
  },
  {
    path: NOTIFICATION_NEWSLETTER_CREATE_ROUTE,
    element: <NotificationNewsletterCreate />,
    roles: [
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR_NOTICE,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.MODERATOR,
    ],
  },
  {
    path: NOTIFICATION_NEWSLETTER_CREATE_WITH_TEMPLATE_ROUTE,
    element: <NotificationNewsletterCreate />,
    roles: [
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR_NOTICE,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.MODERATOR,
    ],
  },
  {
    path: NOTIFICATION_TEMPLATE_EDIT_ROUTE,
    element: <NotificationTemplateEdit />,
    roles: [
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR_NOTICE,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.MODERATOR,
    ],
  },
  {
    path: NOTIFICATION_NEWSLETTER_EDIT_ROUTE,
    element: <NotificationNewsletterEdit />,
    roles: [
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR_NOTICE,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.MODERATOR,
    ],
  },
  {
    path: NOTIFICATION_SYSTEM_NEWSLETTER_EDIT_ROUTE,
    element: <NotificationNewsletterEdit />,
    roles: [
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR_NOTICE,
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.MODERATOR,
    ],
  },
];
