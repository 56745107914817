import { prepareQueryParams } from 'utils/prepareQueryParams';

import {
  CreateExpertCommissionPropsType,
  CreateExpertCommissionResponseType,
  GetExpertCommissionByIdPropsType,
  GetExpertCommissionByIdResponseType,
  GetExpertCommissionsPropsType,
  GetExpertCommissionsResponseType,
  UpdateExpertCommissionPropsType,
  UpdateExpertCommissionResponseType,
} from 'services/expert-commissions/expertCommissionsApiService.types';
import { createExpertCommissionsUrl } from 'services/expert-commissions/utils';
import initialApiService from 'services/initialApiService';

const expertCommissionsApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getExpertCommissions: builder.query<
      GetExpertCommissionsResponseType,
      GetExpertCommissionsPropsType
    >({
      query: (params) => ({
        url: createExpertCommissionsUrl(prepareQueryParams(params)),
      }),
    }),
    getExpertCommissionById: builder.query<
      GetExpertCommissionByIdResponseType,
      GetExpertCommissionByIdPropsType
    >({
      query: ({ id, ...params }) => ({
        url: createExpertCommissionsUrl(`/${id}${prepareQueryParams(params)}`),
      }),
    }),
    createExpertCommission: builder.mutation<
      CreateExpertCommissionResponseType,
      CreateExpertCommissionPropsType
    >({
      query: (body) => ({
        url: createExpertCommissionsUrl(''),
        method: 'POST',
        body,
      }),
    }),
    updateExpertCommission: builder.mutation<
      UpdateExpertCommissionResponseType,
      UpdateExpertCommissionPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createExpertCommissionsUrl(`/${id}`),
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetExpertCommissionsQuery,
  useCreateExpertCommissionMutation,
  useGetExpertCommissionByIdQuery,
  useUpdateExpertCommissionMutation,
} = expertCommissionsApiService;
