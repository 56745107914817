import { FC, ReactNode, useContext, useState } from 'react';

import cn from 'classnames';

import {
  Checkbox,
  CheckboxGroupProps,
  CheckboxValueType,
  Icon,
  Input,
  InputProps,
} from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import { FilterContext } from 'containers/filters/filters.utils';

import s from './FilterCheckboxGroup.module.scss';

export type FilterCheckboxGroupOptions = { label: string; value: string | number | boolean };

export interface FilterCheckboxGroupProps {
  options: FilterCheckboxGroupOptions[];
  valueKey: string;
  usePadding?: boolean;
  optionFilterProp?: string;
  showSearch?: boolean;
  searchProps?: InputProps;
  noData?: ReactNode;
}

const FilterCheckboxGroup: FC<FilterCheckboxGroupProps> = (props) => {
  const {
    options,
    valueKey,
    optionFilterProp,
    usePadding = true,
    showSearch = false,
    searchProps,
    noData = (
      <Empty
        title="common_no_records_found"
        description="common_change_the_search_query"
        showIcon={false}
      />
    ),
  } = props;

  const { filters, setFilters } = useContext(FilterContext);

  const [search, setSearch] = useState<string>('');

  const defaultValue = (filters?.[valueKey] ?? null) as CheckboxGroupProps['defaultValue'];
  const typedValue = filters?.[valueKey] as undefined | CheckboxValueType[];

  const filterOptions = (option: FilterCheckboxGroupOptions) => {
    const { value, label } = option;

    if (optionFilterProp === 'label') {
      return label.toLowerCase().includes(search.toLowerCase());
    }

    if (typeof value === 'number' && !isNaN(value)) {
      return value.toString().includes(search);
    }

    if (typeof value === 'string') {
      return value.toLowerCase().includes(search.toLowerCase());
    }

    return false;
  };

  const filteredOptions = showSearch ? options?.filter(filterOptions) : options;

  const handleChange: CheckboxGroupProps['onChange'] = (value) => {
    if (setFilters) {
      setFilters((prevState) => ({ ...prevState, [valueKey]: value }));
    }
  };

  const handleSearch: InputProps['onChange'] = (e) => setSearch(e.target.value);

  return (
    <div className={cn(s.wrapper, { [s.padding]: usePadding })}>
      {showSearch && (
        <Input
          prefix={<Icon name="search" />}
          onChange={handleSearch}
          allowClear
          {...searchProps}
        />
      )}
      {showSearch && filteredOptions.length === 0 && noData}
      <Checkbox.Group
        column
        options={filteredOptions}
        onChange={handleChange}
        defaultValue={defaultValue}
        value={typedValue}
      />
    </div>
  );
};

export default FilterCheckboxGroup;
