import { useMemo } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Button, Flex, TableProps, Typography } from 'gazprom-ui-lib';

import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { FileType } from 'types/file.types';
import { KedoReviewTaskType } from 'types/review-task.types';

interface Props {
  handleAssign: (id: string) => void;
  handleDelete: (id: string) => void;
}

const useColumnsHook = (props: Props): TableProps<KedoReviewTaskType>['columns'] => {
  const { handleAssign } = props;
  return useMemo(
    () => [
      {
        title: t('common_document_name'),
        dataIndex: 'docName',
        render: (docName: string) => (
          <Typography.Text weight="500" size="14">
            {docName}
          </Typography.Text>
        ),
      },
      {
        title: t('common_file'),
        dataIndex: 'files',
        render: (files: FileType[]) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {files.map((file) => file.fileName).join(', ')}
          </Typography.Text>
        ),
      },
      {
        title: t('common_status'),
        dataIndex: 'status',
        render: (status: { state: string; name: string }) => (
          <TableStatus status={status.state.toUpperCase() as STATUSES}>{status.name}</TableStatus>
        ),
      },
      {
        title: t('common_active_date'),
        dataIndex: 'sentDate',
        width: 150,
        render: (sentDate: string) =>
          sentDate && (
            <Typography.Text weight="500" size="14">
              {dayjs(sentDate).format('DD.MM.YYYY')}
            </Typography.Text>
          ),
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, row: KedoReviewTaskType) => (
          <Flex align="center">
            <Button
              leftIcon="publish"
              type="link"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleAssign(row.id);
              }}>
              {t('common_appoint')}
            </Button>
            {/*<Button*/}
            {/*  leftIcon="bin"*/}
            {/*  type="link"*/}
            {/*  danger*/}
            {/*  className={s.action}*/}
            {/*  onClick={(e) => {*/}
            {/*    e.stopPropagation();*/}
            {/*    handleDelete(row.id);*/}
            {/*  }}>*/}
            {/*  {t('common_delete')}*/}
            {/*</Button>*/}
          </Flex>
        ),
      },
    ],
    [handleAssign],
  );
};

export default useColumnsHook;
