import { FC } from 'react';

import { t } from 'tools/i18n';

import { useGetEmployeeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import { DeclarationType } from 'types/documents.types';

import s from './DocumentsDeclarationsFormDefaultExecutor.module.scss';

interface DocumentsDeclarationsFormDefaultExecutorProps {
  title: string;
  formName: string;
  defaultValue?: DeclarationType['defaultExecutor'];
}

const DocumentsDeclarationsFormDefaultExecutor: FC<
  DocumentsDeclarationsFormDefaultExecutorProps
> = (props) => {
  const { formName, title, defaultValue } = props;
  const { data: employeeData, isLoading } = useGetEmployeeQuery({
    page: 1,
    size: 1000,
    role: 'moderator_social',
  });

  const options = employeeData?.employees?.map((employee) => ({
    label: `${employee.person.firstName} ${employee.person.lastName?.slice(0, 1)}. ${
      employee.staff.name
    }`,
    value: employee.id,
  }));

  return (
    <>
      <div className={s.titleContainer}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_optional')}
        </Typography.Text>
      </div>
      <Form.Item name={formName} initialValue={defaultValue}>
        <Select
          placeholder={t('documents_declarations_social_programs_executor_placeholder')}
          loading={isLoading}
          options={options}
          showSearch
          filterOption
          optionFilterProp="label"
        />
      </Form.Item>
    </>
  );
};

export default DocumentsDeclarationsFormDefaultExecutor;
