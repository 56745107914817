import { useNavigate } from 'react-router-dom';
import { FAQ_ROUTE } from 'routes/faq/list';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useCreateFaqMutation } from 'services/faq/faqApiService';

import { Flex } from 'gazprom-ui-lib';

import FaqQuestionForm from 'containers/faq-question-form';
import { FORM_ENUM, FormResponseType } from 'containers/faq-question-form/faqQuestionForm.utils';
import WithOverflow from 'containers/wrappers/with-overflow';

import Actions from 'pages/faq/faq-add/containers/actions';

import s from './FaqAdd.module.scss';
import Header from './containers/header';

const FaqAdd = () => {
  const navigate = useNavigate();

  const [createFaq, { isLoading }] = useCreateFaqMutation();

  const handleReset = () => {
    navigate(FAQ_ROUTE);
  };

  const handleCreate = useHandleRequestWithModal({
    onSuccessModalConfig: {
      onClose: handleReset,
      onConfirm: handleReset,
    },
  });

  const handleFinish = (formResponse: FormResponseType) => {
    createFaq({
      title: formResponse[FORM_ENUM.TITLE],
      answer: formResponse[FORM_ENUM.TEXT],
      isPublish: !!formResponse[FORM_ENUM.PUBLISH],
      organizationId: formResponse[FORM_ENUM.ORGANIZATION],
      idCategory: formResponse[FORM_ENUM.PARENT],
    }).then(handleCreate);
  };

  return (
    <WithOverflow>
      <Header isLoading={isLoading} />
      <Flex vertical className={s.contentWrapper}>
        <FaqQuestionForm handleFinish={handleFinish} handleReset={handleReset} />
        <Actions isLoading={isLoading} />
      </Flex>
    </WithOverflow>
  );
};

export default FaqAdd;
