export const DOCUMENTS_ROUTE = '/documents';

export const DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE = `${DOCUMENTS_ROUTE}/kedo-review-tasks`;
export const DOCUMENTS_KEDO_REVIEW_TASKS_CREATE_ROUTE = `${DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE}/create`;
export const DOCUMENTS_KEDO_REVIEW_TASKS_BY_ID_ROUTE = `${DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE}/:id`;

export const getKedoReviewTaskWithIdRoute = (id: string) =>
  `${DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE}/${id}`;

export const DOCUMENTS_STATEMENTS_ROUTE = `${DOCUMENTS_ROUTE}/statements`;
export const DOCUMENTS_STATEMENTS_BY_ID_ROUTE = `${DOCUMENTS_STATEMENTS_ROUTE}/:id`;

export const DOCUMENTS_SINGS_ROUTE = `${DOCUMENTS_ROUTE}/signs`;
export const DOCUMENTS_SINGS_BY_ID_ROUTE = `${DOCUMENTS_SINGS_ROUTE}/:id/:executorId`;

export const DOCUMENTS_INQUIRIES_ROUTE = `${DOCUMENTS_ROUTE}/inquiries`;
export const DOCUMENTS_INQUIRIES_BY_ID_ROUTE = `${DOCUMENTS_INQUIRIES_ROUTE}/:id`;

export const DOCUMENTS_RECRUIT_ROUTE = `${DOCUMENTS_ROUTE}/recruit`;
export const DOCUMENTS_RECRUIT_BY_ID_ROUTE = `${DOCUMENTS_RECRUIT_ROUTE}/:id`;
export const getDocumentsRecruitWithIdRoute = (id: string) => `${DOCUMENTS_RECRUIT_ROUTE}/${id}`;

export const DOCUMENTS_BUSINESS_TRIPS_ROUTE = `${DOCUMENTS_ROUTE}/business-trips`;
export const DOCUMENTS_BUSINESS_TRIPS_BY_ID_ROUTE = `${DOCUMENTS_BUSINESS_TRIPS_ROUTE}/:id`;
export const getDocumentsBusinessTripsWithIdRoute = (id: string) =>
  `${DOCUMENTS_BUSINESS_TRIPS_ROUTE}/${id}`;

export const DOCUMENTS_TOP_MANAGEMENT_FORMS_ROUTE = `${DOCUMENTS_ROUTE}/top-management`;
export const DOCUMENTS_TOP_MANAGEMENT_FORMS_DETAILS_ROUTE = `${DOCUMENTS_TOP_MANAGEMENT_FORMS_ROUTE}/:id`;

export const getDocumentsTopManagementFormsDetailsRoute = (id: string) =>
  `${DOCUMENTS_TOP_MANAGEMENT_FORMS_ROUTE}/${id}`;

export const DOCUMENTS_BYPASS_SHEET_ROUTE = `${DOCUMENTS_ROUTE}/bypass-sheet`;
export const DOCUMENTS_BYPASS_SHEET_BY_ID_ROUTE = `${DOCUMENTS_BYPASS_SHEET_ROUTE}/:id`;
export const getDocumentsBypassSheetWithIdRoute = (id: string) =>
  `${DOCUMENTS_BYPASS_SHEET_ROUTE}/${id}`;
