import { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { useEditArticleMutation } from 'services/article/articleApiService';

import { Button, Icon, Tooltip, Typography } from 'gazprom-ui-lib';

import { ArticleType } from 'types/article.types';

import { MAX_PINNED_NEWS } from '../newsTable.utils';
import s from './newsTablePin.module.scss';

interface NewsTablePinProps {
  article: ArticleType;
  canPinned?: boolean;
}

const NewsTablePin: FC<NewsTablePinProps> = (props) => {
  const { article, canPinned = true } = props;

  const { pinned, code } = article;
  const isCoverExists = !!article?.cover?.link;

  const [pinUnpinMutation, { isLoading }] = useEditArticleMutation();

  let tooltipTitle = t('news_pinned_limit', { limit: MAX_PINNED_NEWS });

  if (pinned) {
    tooltipTitle = t('news_remove_from_pinned');
  } else if (!isCoverExists) {
    tooltipTitle = t('news_only_news_with_cover_pin');
  } else if (canPinned) {
    tooltipTitle = t('news_pin_news');
  }

  const isPinAvailable = canPinned && isCoverExists;

  const handleTogglePin = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();

    if (isPinAvailable) {
      pinUnpinMutation({ code, pinned: !pinned });
    }
  };

  return (
    <Tooltip
      align={{ offset: [-8, 0] }}
      placement="bottomRight"
      title={
        <Typography.Text size="10" className={s.tooltipTitle}>
          {tooltipTitle}
        </Typography.Text>
      }>
      <Button
        onClick={handleTogglePin}
        disabled={isLoading}
        type="text"
        shape="round"
        className={s.newsTablePin}>
        <Icon
          className={cn({ [s.canPinned]: isPinAvailable, [s.pinned]: pinned })}
          name="pinFilled"
        />
      </Button>
    </Tooltip>
  );
};

export default NewsTablePin;
