import { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Divider, Flex, Form, FormInstance, Typography } from 'gazprom-ui-lib';

import { NotificationNewsletterType, NotificationTemplateType } from 'types/notification.types';

import s from './NotificationNewsletterForm.module.scss';
import TemplateModal from './containers/template-modal';
import NotificationNewsletterFormApp from './notification-newsletter-form-app';
import NotificationNewsletterFormEmail from './notification-newsletter-form-email';
import NotificationNewsletterFormName from './notification-newsletter-form-name';
import NotificationNewsletterFormPublish from './notification-newsletter-form-publish';
import NotificationNewsletterFormReceivers from './notification-newsletter-form-receivers';
import NotificationNewsletterFormText from './notification-newsletter-form-text';
import NotificationNewsletterFormTitle from './notification-newsletter-form-title';
import {
  NOTIFICATION_NEWSLETTER_FORM_ENUM,
  NOTIFICATION_NEWSLETTER_FORM_NAME,
  NotificationNewsletterFormResponse,
} from './notificationNewsletterForm.utils';
import useFormSet from './useFormSet.hook';

interface NotificationNewsletterFormProps {
  formFromProps?: FormInstance;
  newsletterTemplate?: NotificationTemplateType;
  templateModalOpen?: boolean;
  handleCloseTemplateModal?: () => void;
  isTemplateCreating?: boolean;
  className?: string;
  handleSubmit: (form: NotificationNewsletterFormResponse) => void;
  handleSaveAsTemplate?: (form: NotificationNewsletterFormResponse) => void;
  handleReset: () => void;
  currentNewsletter?: NotificationNewsletterType;
  disabled?: boolean;
}

const NotificationNewsletterForm: FC<NotificationNewsletterFormProps> = (props) => {
  const {
    formFromProps,
    newsletterTemplate,
    templateModalOpen,
    handleCloseTemplateModal,
    handleSaveAsTemplate,
    isTemplateCreating,
    handleSubmit,
    handleReset,
    className,
    currentNewsletter,
    disabled,
  } = props;

  const [form] = Form.useForm();

  const formToUse = formFromProps ?? form;

  useFormSet({ form: formToUse, currentNewsletter, newsletterTemplate });

  const preSubmit = (formResponse: NotificationNewsletterFormResponse) => {
    const templateName = formResponse[NOTIFICATION_NEWSLETTER_FORM_ENUM.TEMPLATE_NAME];
    const shouldSaveAsTemplate = !!templateName?.length && handleSaveAsTemplate;

    if (shouldSaveAsTemplate) {
      handleSaveAsTemplate(formResponse);
    } else if (
      !formResponse[NOTIFICATION_NEWSLETTER_FORM_ENUM.NAME] &&
      !formResponse[NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION] &&
      !formResponse[NOTIFICATION_NEWSLETTER_FORM_ENUM.EMAIL]
    ) {
      formToUse.setFields([
        {
          name: NOTIFICATION_NEWSLETTER_FORM_ENUM.NAME,
          errors: [t('validation_field_not_empty')],
        },
        {
          name: NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION,
          errors: [t('notification_newsletter_validation')],
        },
        {
          name: NOTIFICATION_NEWSLETTER_FORM_ENUM.EMAIL,
          errors: [t('notification_newsletter_validation')],
        },
      ]);
    } else {
      handleSubmit(formResponse);
    }
  };

  return (
    <Form
      className={cn(s.wrapper, className)}
      form={formToUse}
      onFinish={preSubmit}
      onReset={handleReset}
      id={NOTIFICATION_NEWSLETTER_FORM_NAME}>
      <NotificationNewsletterFormName disabled={disabled} />
      <Divider />
      <NotificationNewsletterFormReceivers disabled={disabled} />
      <Divider />
      <Flex vertical gap="32">
        <Typography.Title level={4}>{t('notification_newsletters_content')}</Typography.Title>
        <NotificationNewsletterFormTitle disabled={disabled} />
        <NotificationNewsletterFormText disabled={disabled} />
        <Flex vertical gap="4">
          <NotificationNewsletterFormApp disabled={disabled} />
          <NotificationNewsletterFormEmail disabled={disabled} />
        </Flex>
      </Flex>
      <NotificationNewsletterFormPublish disabled={disabled} />
      <TemplateModal
        form={formToUse}
        isOpen={templateModalOpen}
        handleClose={handleCloseTemplateModal}
        isTemplateCreating={isTemplateCreating}
      />
    </Form>
  );
};

export default NotificationNewsletterForm;
