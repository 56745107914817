import { t } from 'tools/i18n';

export const C_INSTANCE_FORM_NAME = 'C_INSTANCE_FORM_NAME';

export enum C_INSTANCE_FORM_ENUM {
  NAME = 'NAME',
  URL = 'URL',
  LOGIN = 'LOGIN',
  PASSWORD = 'PASSWORD',
  ACTIVE = 'ACTIVE',
  STRUCTURE_TYPE = 'STRUCTURE_TYPE',
}

export interface CInstanceFormResponse {
  [C_INSTANCE_FORM_ENUM.NAME]: string;
  [C_INSTANCE_FORM_ENUM.URL]: string;
  [C_INSTANCE_FORM_ENUM.LOGIN]: string;
  [C_INSTANCE_FORM_ENUM.PASSWORD]: string;
  [C_INSTANCE_FORM_ENUM.ACTIVE]: boolean;
  [C_INSTANCE_FORM_ENUM.STRUCTURE_TYPE]: string;
}

export const STRUCTURE_TYPE_OPTIONS = [
  {
    label: t('1c_structure_type_v1'),
    value: 'V1',
  },
  {
    label: t('1c_structure_type_v2'),
    value: 'V2',
  },
];

export const DEFAULT_PASSWORD_VALUE = '********';
