import { Typography } from 'gazprom-ui-lib';

import s from './TitleWithTags.module.scss';

interface Props {
  text: string;
  primaryTag: string;
  secondaryTag: string;
}

const TitleWithTags = (props: Props) => {
  const { text, primaryTag, secondaryTag } = props;

  return (
    <div className={s.container}>
      <Typography.Text size="14" weight="500" className={s.title} title={text}>
        {text}
      </Typography.Text>
      <div className={s.tagsContainer}>
        <Typography.Text size="10" weight="500" className={s.primaryTag}>
          {primaryTag}
        </Typography.Text>
        <Typography.Text size="10" weight="500" className={s.secondaryTag}>
          {secondaryTag}
        </Typography.Text>
      </div>
    </div>
  );
};

export default TitleWithTags;
