import { FC, useState } from 'react';

import { t } from 'tools/i18n';

import { useCreateKedoCategoryMutation } from 'services/kedo/kedoApiService';

import {
  Form,
  Icon,
  Input,
  Modal,
  Radio,
  TIconsLiteral,
  Typography,
  handleRequest,
  socialPolicyIcons,
} from 'gazprom-ui-lib';

import ModalButtons from '../components/modal-buttons';
import ModalHeader from '../components/modal-header';
import TextCounter from '../components/text-counter';

import { KedoCategoryType } from 'types/documents.types';
import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import s from './ModalNewCategory.module.scss';

interface ModalNewCategoryProps {
  open: boolean;
  onClose: () => void;
  onFinish: (id: string) => void;
}

const ModalNewCategory: FC<ModalNewCategoryProps> = (props) => {
  const { onClose, onFinish, open } = props;
  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [createCategory, { isLoading }] = useCreateKedoCategoryMutation();

  const onSuccess = (data?: KedoCategoryType) => {
    if (data) {
      onFinish(data.id);
      onClose();
      form?.resetFields();
    }
  };

  const submitHandler = (value: { name: string; icon: string }) => {
    const body = {
      type: KEDO_TYPE_ENUM.SOCIAL,
      isPublish: true,
      ...value,
    };
    createCategory(body).then(
      handleRequest({
        onSuccess,
      }),
    );
  };

  const changeHandler = (_: Record<string, string>, values: Record<string, string>) => {
    const isAllFieldsHasValue = Object.values(values).every((value) => Boolean(value));
    setIsButtonDisabled(!isAllFieldsHasValue);
  };

  return (
    <Modal open={open} onCancel={onClose}>
      <ModalHeader text={t('common_add_new_category')} onClose={onClose} />
      <Form form={form} onFinish={submitHandler} onValuesChange={changeHandler}>
        <div className={s.search}>
          <TextCounter
            current={inputValue.length}
            total={100}
            text={t('social_program_modal_category_name')}
          />
          <Form.Item name="name">
            <Input
              placeholder={t('common_enter_text')}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              maxLength={100}
            />
          </Form.Item>
          <Typography.Text size="14" weight="500" type="primary">
            {t('social_program_modal_icon')}
          </Typography.Text>
          <Form.Item name="icon">
            <Radio.Group className={s.grid}>
              {Object.keys(socialPolicyIcons).map((icon) => {
                return (
                  <Radio.Button value={icon} key={icon}>
                    <Icon name={icon as TIconsLiteral} size={20} />
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </div>
        <ModalButtons disabled={isButtonDisabled} onClose={onClose} loading={isLoading} />
      </Form>
    </Modal>
  );
};

export default ModalNewCategory;
