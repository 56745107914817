import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { TableProps } from 'gazprom-ui-lib';

import TableText from 'components/table/table-text';

import { EmployeeType } from 'types/employee.types';

const useColumns = (): TableProps<EmployeeType>['columns'] => {
  return useMemo(
    () => [
      {
        title: t('common_employee'),
        dataIndex: '',
        key: 'person',
        width: '25%',
        render: (person: EmployeeType) => {
          const { lastName, firstName, patronymic } = person?.person || {};

          return (
            <>
              <TableText size="12" type="secondary">
                {person?.employer?.name}
              </TableText>
              <TableText size="12" weight="500">
                {`${lastName} ${firstName} ${patronymic}`}
              </TableText>
            </>
          );
        },
      },
      {
        title: t('common_staffName'),
        dataIndex: 'position',
        key: 'position',
        width: '25%',
        render: (position: EmployeeType['position']) => {
          return (
            <TableText size="12" weight="500" type="secondary">
              {position?.name}
            </TableText>
          );
        },
      },
      {
        title: t('common_department'),
        dataIndex: 'division',
        key: 'division',
        width: '25%',
        render: (division: EmployeeType['division']) => {
          return (
            <TableText size="12" type="secondary">
              {division?.name}
            </TableText>
          );
        },
      },
      {
        title: t('common_organization'),
        dataIndex: 'employer',
        key: 'employer',
        width: '25%',
        render: (employer: EmployeeType['employer']) => {
          return (
            <TableText size="12" weight="500" type="secondary">
              {employer?.name}
            </TableText>
          );
        },
      },
    ],
    [],
  );
};

export default useColumns;
