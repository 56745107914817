import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateFaqCategoryPropsType,
  CreateFaqCategoryResponseType,
  CreateFaqPropsType,
  CreateFaqResponseType,
  DeleteFaqCategoryPropsType,
  DeleteFaqCategoryResponseType,
  DeleteFaqPropsType,
  DeleteFaqResponseType,
  GetFaqByIdPropsType,
  GetFaqByIdResponseType,
  GetFaqCategoriesPropsType,
  GetFaqCategoriesResponseType,
  GetFaqsPropsType,
  GetFaqsResponseType,
  UpdateFaqCategoryPropsType,
  UpdateFaqCategoryResponseType,
  UpdateFaqPropsType,
  UpdateFaqResponseType,
} from './faqApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';
import { FaqType } from 'types/faq.types';

import { createFaqUrl } from './utils';

// TODO убрать после того, как бэк уберёт employeeId из обязательных параметров
const findFaqById = (id: string) => (faq: FaqType) => String(faq.id) === String(id);
const getFaqByIdTransform = (resp: GetFaqsResponseType, _: unknown, id: string) => {
  return resp.faqLists.find(findFaqById(id));
};
const faqApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getFaqs: builder.query<GetFaqsResponseType, GetFaqsPropsType>({
      query: (params) => ({
        url: createFaqUrl(`-list${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_FAQS],
    }),
    getFaqById: builder.query<GetFaqByIdResponseType, GetFaqByIdPropsType>({
      query: () => ({
        url: createFaqUrl(`-list?page=1&size=500`),
      }),
      transformResponse: getFaqByIdTransform,
      providesTags: [SERVICE_TAGS.GET_FAQ_BY_ID],
      // TODO вернуть после того, как бэк уберёт employeeId из обязательных параметров
      // query: (id) => ({
      //   url: createFaqUrl(`-list/${id}`),
      // }),
      // providesTags: (_a, _b, id) => [{ type: SERVICE_TAGS.GET_FAQ_BY_ID, id }],
    }),
    createFaq: builder.mutation<CreateFaqResponseType, CreateFaqPropsType>({
      query: (body) => ({
        url: createFaqUrl(`-list`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_FAQS],
    }),
    updateFaq: builder.mutation<UpdateFaqResponseType, UpdateFaqPropsType>({
      query: ({ id, ...body }) => ({
        url: createFaqUrl(`-list/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_FAQS, SERVICE_TAGS.GET_FAQ_BY_ID],
      //   (_a, _b, id) => [{ type: SERVICE_TAGS.GET_FAQ_BY_ID, id }]
    }),
    deleteFaq: builder.mutation<DeleteFaqResponseType, DeleteFaqPropsType>({
      query: (id) => ({
        url: createFaqUrl(`-list/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_FAQS],
    }),
    getFaqCategories: builder.query<GetFaqCategoriesResponseType, GetFaqCategoriesPropsType>({
      query: (params) => ({
        url: createFaqUrl(`-category${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_FAQ_CATEGORIES],
    }),
    createFaqCategory: builder.mutation<CreateFaqCategoryResponseType, CreateFaqCategoryPropsType>({
      query: (body) => ({
        url: createFaqUrl(`-category`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_FAQ_CATEGORIES],
    }),
    updateFaqCategory: builder.mutation<UpdateFaqCategoryResponseType, UpdateFaqCategoryPropsType>({
      query: ({ id, ...body }) => ({
        url: createFaqUrl(`-category/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_FAQ_CATEGORIES],
    }),
    deleteFaqCategory: builder.mutation<DeleteFaqCategoryResponseType, DeleteFaqCategoryPropsType>({
      query: (id) => ({
        url: createFaqUrl(`-category/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_FAQ_CATEGORIES],
    }),
  }),
});

export const {
  useDeleteFaqMutation,
  useCreateFaqMutation,
  useUpdateFaqMutation,
  useGetFaqsQuery,
  useGetFaqCategoriesQuery,
  useGetFaqByIdQuery,
  useDeleteFaqCategoryMutation,
  useCreateFaqCategoryMutation,
  useUpdateFaqCategoryMutation,
} = faqApiService;
