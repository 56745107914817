import { FC } from 'react';

import { t } from 'tools/i18n';
import { getFiltersCount } from 'utils/filter.utils';

import { Icon, Input, InputProps } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';
import { FiltersResponseType } from 'containers/filters/filters.utils';

import { RequestBodyType } from '../../faq.utils';
import s from './Filters.module.scss';

interface FiltersProps {
  search: string;
  handleSearchChange?: InputProps['onChange'];
  filters: RequestBodyType['filters'];
  setFilters: (values: FiltersResponseType) => void;
}

const Filters: FC<FiltersProps> = (props) => {
  const { search, handleSearchChange, filters, setFilters } = props;

  const handleApply = (values: FiltersResponseType) => {
    setFilters(values);
  };

  return (
    <div className={s.wrapper}>
      <Input
        allowClear
        value={search}
        onChange={handleSearchChange}
        className={s.input}
        placeholder={t('common_search')}
        size="small"
        prefix={<Icon name="search" />}
      />
      <FiltersContainer
        initialValues={filters as FiltersResponseType | null}
        handleApply={handleApply}
        overlay={
          <>
            {/*<FilterCollapse items={items} />*/}
            <FilterCustomWrapper>
              <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="status"
                options={[
                  { label: t('common_published'), value: true },
                  { label: t('common_not_published'), value: false },
                ]}
              />
            </FilterCustomWrapper>
          </>
        }
        filtersCount={getFiltersCount(filters)}
      />
    </div>
  );
};

export default Filters;
