import { t } from 'tools/i18n';

import { SOCIAL_PROGRAM_STATUS_ENUM } from 'types/socialProgram.types';

export const FILTER_STATUSES = [
  {
    label: t('common_published_plural'),
    value: SOCIAL_PROGRAM_STATUS_ENUM.PUBLISHED,
  },
  {
    label: t('common_not_published'),
    value: SOCIAL_PROGRAM_STATUS_ENUM.NOT_PUBLISHED,
  },
];
