import { ReactElement } from 'react';

import { SOCIAL_PROGRAM_REQUEST_ENUM } from 'types/socialProgram.types';

export const REVISION = 'REVISION';
export const REJECT = 'REJECT';
export const COMMISSION = 'COMMISSION';
export const APPROVE = 'APPROVE';
export const EXECUTE = 'EXECUTE';

export const REJECT_AVAILABLE_TYPES = [
  SOCIAL_PROGRAM_REQUEST_ENUM.DOCS_CHECKING,
  SOCIAL_PROGRAM_REQUEST_ENUM.IN_REVIEW,
];
export const renderAction = (node: ReactElement) => node;
