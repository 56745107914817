import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GUIDE_HANDBOOK_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { useLazyGetHandbookByIdQuery } from 'services/settlement/settlementApiService';

import { Button, Tabs } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import { HANDBOOK_BASE_FORM_NAME } from 'containers/handbook-base-form/handbookBaseForm.utils';
import LastChange from 'containers/last-change';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './GuideHandbookDetails.module.scss';
import { HANDBOOK_BASE_INFO_TAB } from './guideHandbookDetails.utils';
import useTabItemsHook from './useTabItems.hook';

const GuideHandbookDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ handbookId: string }>();

  const [activeTab, setActiveTab] = useState<string>(HANDBOOK_BASE_INFO_TAB);

  const [getHandbook, { data: handbook, isFetching: handbookIsFetching, isLoading }] =
    useLazyGetHandbookByIdQuery();

  useEffect(() => {
    if (params.handbookId) {
      getHandbook(params.handbookId);
    }
  }, [getHandbook, params.handbookId]);

  const handleGoBack = () => navigate(GUIDE_HANDBOOK_ROUTE);

  const handleTabChange = (e: string) => {
    setActiveTab(e);
    navigate(location.pathname, { replace: true });
  };

  const tabs = useTabItemsHook({ handbook, handleGoBack });

  const editor = handbook?.editor;

  return (
    <WithOverflow className={s.wrapper}>
      <ContentHeader align="flex-start">
        <ContentHeader.Title
          goBackLink={GUIDE_HANDBOOK_ROUTE}
          description={
            <LastChange
              date={handbook?.updatedAt}
              editorId={editor?.id}
              editorName={`${editor?.firstName} ${editor?.lastName}`}
            />
          }>
          {handbook?.name}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          {activeTab === HANDBOOK_BASE_INFO_TAB && (
            <Button
              leftIcon="ok"
              htmlType="submit"
              loading={handbookIsFetching}
              form={HANDBOOK_BASE_FORM_NAME}>
              {t('common_save')}
            </Button>
          )}
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={isLoading}>
        <Tabs items={tabs} type="card" onChange={handleTabChange} activeKey={activeTab} />
      </WithLoader>
    </WithOverflow>
  );
};

export default GuideHandbookDetails;
