import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { CommentType } from 'types/comment.types';

import s from './сomments.module.scss';

const useColumns = (): ColumnsType<CommentType> => [
  {
    title: t('common_sender'),
    dataIndex: 'creator',
    render: ({ person, employer }: CommentType['creator']) => {
      const { firstName, lastName } = person;
      const { name } = employer;

      return <TablePerson firstName={firstName} lastName={lastName} department={name} />;
    },
  },
  {
    title: t('common_staffName'),
    dataIndex: 'creator',
    render: ({ position }: CommentType['creator']) => (
      <Typography.Text size="12" weight="500" type="secondary">
        {position.name}
      </Typography.Text>
    ),
  },
  {
    title: t('common_comment'),
    dataIndex: 'comment',
    render: (comment: CommentType['comment']) => (
      <Typography.Text size="12" weight="400" className={s.comment}>
        {comment}
      </Typography.Text>
    ),
  },
  {
    title: t('thank_you_data'),
    dataIndex: 'createdAt',
    render: (date: CommentType['createdAt']) => <TableDate date={date} />,
    align: 'center',
  },
];

export default useColumns;
