export const TELEGRAM_CHANNEL_FORM_NAME = 'TELEGRAM_CHANNEL_FORM_NAME';

export enum TELEGRAM_CHANNEL_FORM_ENUM {
  TELEGRAM_TITLE = 'TELEGRAM_TITLE',
  LINK = 'LINK',
  CATEGORIES = 'CATEGORIES',
  ACTIVE = 'ACTIVE',
}

export interface TelegramChannelFormResponse {
  [TELEGRAM_CHANNEL_FORM_ENUM.TELEGRAM_TITLE]: string;
  [TELEGRAM_CHANNEL_FORM_ENUM.LINK]: string;
  [TELEGRAM_CHANNEL_FORM_ENUM.CATEGORIES]: string[];
  [TELEGRAM_CHANNEL_FORM_ENUM.ACTIVE]: boolean;
}
