import { useContext } from 'react';

import { SaveModalConfigType, SaveModalContext } from 'app.utils';

import { HandleRequestProps } from 'gazprom-ui-lib';
import { handleRequest } from 'gazprom-ui-lib';

interface UseHandleRequestWithModalProps<D, E, R> {
  onSuccess?: HandleRequestProps<D, E, R>['onSuccess'];
  onError?: HandleRequestProps<D, E, R>['onError'];
  onErrorModalConfig?: SaveModalConfigType;
  onSuccessModalConfig?: SaveModalConfigType;
  callOnSuccessWithoutData?: boolean;
}

const useHandleRequestWithModal = <D, E, R>(props: UseHandleRequestWithModalProps<D, E, R>) => {
  const { onSuccess, onError, onSuccessModalConfig, onErrorModalConfig, callOnSuccessWithoutData } =
    props;

  const setModalConfig = useContext(SaveModalContext);

  const handleSuccess = async (data?: D) => {
    if (onSuccess) {
      if (data) {
        await onSuccess(data);
      } else if (callOnSuccessWithoutData) {
        await onSuccess();
      }
    }

    if (onSuccessModalConfig && setModalConfig) {
      setModalConfig(onSuccessModalConfig);
    }
  };

  const handleError = async (data?: E) => {
    if (onError && data) {
      await onError(data);
    }

    if (onErrorModalConfig && setModalConfig) {
      setModalConfig(onErrorModalConfig);
    }
  };

  return handleRequest({
    onSuccess: handleSuccess,
    onError: handleError,
  });
};

export default useHandleRequestWithModal;
