import { FC } from 'react';

import { t } from 'tools/i18n';

import { Form, Input, Typography } from 'gazprom-ui-lib';

import s from './DocumentsDeclarationsFormTextPlain.module.scss';

interface DocumentsDeclarationsFormTextPlainProps {
  name: string;
  title: string;
  defaultValue?: string;
}

const DocumentsDeclarationsFormTextPlain: FC<DocumentsDeclarationsFormTextPlainProps> = (props) => {
  const { name, title, defaultValue } = props;

  return (
    <div className={s.container}>
      <Typography.Title level={5}>{title}</Typography.Title>
      <Form.Item name={name} initialValue={defaultValue}>
        <Input placeholder={t('common_enter_text')} />
      </Form.Item>
    </div>
  );
};

export default DocumentsDeclarationsFormTextPlain;
