import { SocialProgramType } from 'types/socialProgram.types';

export const mapSocialProgramById = (value: SocialProgramType) => {
  return {
    categoryId: value.categoryId,
    nameProgram: value.nameProgram,
    deadlineSendDocs: value.deadlineSendDocs,
    paymentTerm: value.paymentTerm,
    organizationId: value.organizationId,
    isPublish: value.isPublish,
    socialProgramDocumentIds: value.socialProgramDocuments.map((doc) => doc.id),
    socialEmployeeRequirementIds: value.socialProgramEmployeeRequirements.map((req) => req.id),
    payoutAmountIds: value.payoutAmounts.map((el) => el.id),
    kedoStatementId: value.kedoStatementId,
  };
};

export const SOCIAL_PROGRAM_FORM_NAME = 'SOCIAL_PROGRAM_FORM_NAME';
