import { Dayjs } from 'dayjs';

import { GetStatementsRequestType } from 'services/1c/cApiService.types';

import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = PaginationPropsType & {
  property: string;
  order: SORT_ENUM;
  filters?: Pick<GetStatementsRequestType, 'categoryIds' | 'statuses' | 'employerIds'>;
  range?: [Dayjs, Dayjs];
};
export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  property: 'updatedAt',
  order: SORT_ENUM.DESC,
};
