import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetHotContactByIdRequest,
  GetHotContactByIdResponse,
  GetHotContactsByEmployersResponse,
  PostHotContactByIdRequest,
  UpdateHotContactByIdRequest,
  UpdateHotContactByIdResponse,
} from './guideApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

const guideApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getHotContactsByEmployers: builder.query<GetHotContactsByEmployersResponse[], void>({
      query: () => ({
        url: `hot-contact/by-employers`,
      }),
      providesTags: [SERVICE_TAGS.GET_GUIDE_HOT_CONTACTS],
    }),
    getHotContactById: builder.query<GetHotContactByIdResponse, GetHotContactByIdRequest>({
      query: (params) => ({
        url: `hot-contact${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_GUIDE_HOT_CONTACTS_BY_ID],
    }),
    postHotContactById: builder.mutation<void, PostHotContactByIdRequest>({
      query: (body) => ({
        url: `hot-contact`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_GUIDE_HOT_CONTACTS_BY_ID,
        SERVICE_TAGS.GET_GUIDE_HOT_CONTACTS,
      ],
    }),
    updateHotContactById: builder.mutation<
      UpdateHotContactByIdResponse,
      UpdateHotContactByIdRequest
    >({
      query: ({ id, ...rest }) => ({
        url: `hot-contact/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_GUIDE_HOT_CONTACTS_BY_ID,
        SERVICE_TAGS.GET_GUIDE_HOT_CONTACTS,
      ],
    }),
    deleteHotContactById: builder.mutation<void, [string]>({
      query: (body) => ({
        url: `hot-contact`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_GUIDE_HOT_CONTACTS_BY_ID,
        SERVICE_TAGS.GET_GUIDE_HOT_CONTACTS,
      ],
    }),
  }),
});

export const {
  useGetHotContactsByEmployersQuery,
  useGetHotContactByIdQuery,
  usePostHotContactByIdMutation,
  useDeleteHotContactByIdMutation,
  useUpdateHotContactByIdMutation,
} = guideApiService;
