import RangePicker from './containers/range-picker';
import Search from './containers/search';
import Table from './containers/table';
import TableFilters from './containers/table-filters';
import InnerComponent from './filtersV2';

type CompoundedComponents = typeof InnerComponent & {
  Search: typeof Search;
  Table: typeof Table;
  TableFilters: typeof TableFilters;
  RangePicker: typeof RangePicker;
};

const FiltersV2 = InnerComponent as CompoundedComponents;
FiltersV2.Search = Search;
FiltersV2.Table = Table;
FiltersV2.TableFilters = TableFilters;
FiltersV2.RangePicker = RangePicker;

export default FiltersV2;
