import { MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';
import { NOTIFICATION_MAILING_MANAGEMENT_ROUTE } from 'routes/notification/list';
import { t } from 'tools/i18n';
import { prepareDateAndTime } from 'utils/date.utils';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useCreateNotificationTemplateMutation } from 'services/notification-template/notificationTemplateApiService';
import { CreateNotificationTemplatePropsType } from 'services/notification-template/notificationTemplateApiService.types';
import { useCreateNotificationNewsletterMutation } from 'services/notificationV2/notificationV2ApiService';
import { CreateNotificationNewsletterPropsType } from 'services/notificationV2/notificationV2ApiService.types';

import {
  NOTIFICATION_NEWSLETTER_FORM_ENUM,
  NotificationNewsletterFormResponse,
} from 'containers/notification-newsletter-form/notificationNewsletterForm.utils';

import {
  NEWSLETTER_RECEIVER_ENUM,
  NOTIFICATION_ENUM,
  NotificationNewsletterType,
} from 'types/notification.types';

interface UseCreateNewsletterProps {
  currentNewsletter?: NotificationNewsletterType;
}

const useCreateNewsletter = (props: UseCreateNewsletterProps) => {
  const { currentNewsletter } = props;
  const navigate = useNavigate();
  const [createNotificationNewsletter, { isLoading }] = useCreateNotificationNewsletterMutation();
  const [createNotificationTemplate, { isLoading: isTemplateLoading }] =
    useCreateNotificationTemplateMutation();
  const onSuccess = () => navigate(NOTIFICATION_MAILING_MANAGEMENT_ROUTE);

  const handleCreate = useHandleRequestWithModal({
    onSuccess,
    onSuccessModalConfig: {
      title: {
        children: t('common_created_successfully'),
      },
      description: {
        children: t('notification_template_saved_successfully'),
      },
    },
  });

  const recreateNewsletter = (event: MouseEvent, currentNewsletter: NotificationNewsletterType) => {
    event.stopPropagation();
    event.preventDefault();

    return createNotificationNewsletter(currentNewsletter).then(handleCreate);
  };
  const createNewsletter = (form: NotificationNewsletterFormResponse) => {
    const requestBody = prepareRequestBody(form);

    createNotificationNewsletter(requestBody).then(handleCreate);
  };

  const handleSubmit = (formOrEvent?: NotificationNewsletterFormResponse | MouseEvent) => {
    if (currentNewsletter && formOrEvent && 'stopPropagation' in formOrEvent) {
      recreateNewsletter(formOrEvent, currentNewsletter);
    } else if (formOrEvent && NOTIFICATION_NEWSLETTER_FORM_ENUM.NAME in formOrEvent) {
      createNewsletter(formOrEvent);
    }
  };

  const handleSaveAsTemplate = (form: NotificationNewsletterFormResponse) => {
    const requestBody = prepareTemplateRequestBody(form);

    createNotificationTemplate(requestBody).then(handleCreate);
  };

  return {
    handleSubmit,
    createNewsletterIsFetching: isLoading,
    handleSaveAsTemplate,
    createTemplateIsLoading: isTemplateLoading,
  };
};

const prepareRequestBody = (form: NotificationNewsletterFormResponse) => {
  const initialRequestBody: CreateNotificationNewsletterPropsType = {
    dateToSend: prepareDateAndTime(
      form[NOTIFICATION_NEWSLETTER_FORM_ENUM.PUBLISH_FROM_DATE],
      form[NOTIFICATION_NEWSLETTER_FORM_ENUM.PUBLISH_FROM_TIME],
    ),
    name: form[NOTIFICATION_NEWSLETTER_FORM_ENUM.NAME],
    body: form[NOTIFICATION_NEWSLETTER_FORM_ENUM.TEXT] ?? '',
    title: form[NOTIFICATION_NEWSLETTER_FORM_ENUM.TITLE] ?? '',
    receivers: [
      {
        receiverType: NEWSLETTER_RECEIVER_ENUM.EMPLOYEE_GROUP,
        receiverIds: form[NOTIFICATION_NEWSLETTER_FORM_ENUM.RECEIVERS],
      },
    ],
    requiresFeedback: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_IS_REQUIRED],
    allowFeedback: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_ON],
    allowCallback: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.CALLBACK_ON],
    sendApp: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION],
    sendPush: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION],
    sendEmail: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.EMAIL],
  };

  if (form[NOTIFICATION_NEWSLETTER_FORM_ENUM.NOTIFICATION_TEMPLATE]) {
    initialRequestBody.transports?.push({
      sendType: NOTIFICATION_ENUM.APP,
      templateId: form[NOTIFICATION_NEWSLETTER_FORM_ENUM.NOTIFICATION_TEMPLATE],
    });
  }

  return initialRequestBody;
};

const prepareTemplateRequestBody = (form: NotificationNewsletterFormResponse) => {
  const initialRequestBody: CreateNotificationTemplatePropsType = {
    name: form[NOTIFICATION_NEWSLETTER_FORM_ENUM.TEMPLATE_NAME],
    title: form[NOTIFICATION_NEWSLETTER_FORM_ENUM.TITLE] ?? '',
    body: form[NOTIFICATION_NEWSLETTER_FORM_ENUM.TEXT] ?? '',
    receivers: [
      {
        receiverType: NEWSLETTER_RECEIVER_ENUM.EMPLOYEE_GROUP,
        receiverIds: form[NOTIFICATION_NEWSLETTER_FORM_ENUM.RECEIVERS],
      },
    ],
    requiresFeedback: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_IS_REQUIRED],
    allowFeedback: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_ON],
    allowCallback: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.CALLBACK_ON],
    sendApp: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION],
    sendPush: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION],
    sendEmail: !!form[NOTIFICATION_NEWSLETTER_FORM_ENUM.EMAIL],
  };

  return initialRequestBody;
};

export default useCreateNewsletter;
