import { t } from 'tools/i18n';

export const TELEGRAM_AUTHORIZATION_PHONE_FORM_NAME = 'TELEGRAM_AUTHORIZATION_PHONE_FORM_NAME';
export const TELEGRAM_AUTHORIZATION_CODE_FORM_NAME = 'TELEGRAM_AUTHORIZATION_FORM_NAME';

export enum TELEGRAM_AUTHORIZATION_FORM_ENUM {
  PHONE_NUMBER = 'Номер телефона',
  CODE = 'Код',
}

export interface TelegramAuthorizationPhoneFormResponse {
  [TELEGRAM_AUTHORIZATION_FORM_ENUM.PHONE_NUMBER]: string;
}

export interface TelegramAuthorizationCodeFormResponse {
  [TELEGRAM_AUTHORIZATION_FORM_ENUM.CODE]: string;
}

export const getAuthId = (response: string) => {
  const splitResponse = response.split(' ');

  return splitResponse[splitResponse.length - 1];
};

export const AuthorizationErrorTitles = {
  PHONE_CODE_INVALID: t('telegram_authorization_wrong_code'),
  NOT_A_PHONE_NUMBER: t('telegram_authorization_wrong_number'),
  DEFAULT: t('telegram_authorization_wrong_code'),
};

export type AuthorizationErrorCode = keyof typeof AuthorizationErrorTitles;

export const getAuthorizationErrorTitle = (errorCode: AuthorizationErrorCode) =>
  AuthorizationErrorTitles[errorCode] ?? AuthorizationErrorTitles['DEFAULT'];
