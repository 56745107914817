import { FC } from 'react';

import { t } from 'tools/i18n';

import { Flex, Icon, Input, InputProps } from 'gazprom-ui-lib';

import InternalFilters from 'containers/filters';
import FilterCollapse from 'containers/filters/filter-collapse';
import { FiltersResponseType } from 'containers/filters/filters.utils';

import s from './Filters.module.scss';
import useFilterItemsHook from './useFilterItems.hook';

interface FiltersProps {
  search?: string;
  handleSearchChange?: InputProps['onChange'];
  filters: FiltersResponseType;
  setFilters: (values: FiltersResponseType) => void;
}

const Filters: FC<FiltersProps> = (props) => {
  const { search, handleSearchChange, setFilters } = props;

  const items = useFilterItemsHook();

  return (
    <Flex align="center" justify="space-between" className={s.container}>
      <Input
        allowClear
        value={search}
        onChange={handleSearchChange}
        className={s.input}
        placeholder={t('common_search')}
        prefix={<Icon name="search" />}
      />
      <InternalFilters handleApply={setFilters} overlay={<FilterCollapse items={items} />} />
    </Flex>
  );
};

export default Filters;
