import { FC } from 'react';

import { useMatch, useNavigate } from 'react-router-dom';
import {
  SUGGESTIONS_DECISIONS_DETAILS_ROUTE,
  SUGGESTIONS_DECISIONS_ROUTE,
  SUGGESTIONS_LIST_ROUTE,
} from 'routes/suggestions/list';
import { t } from 'tools/i18n';

import { Button, Divider, Flex, StepsProps, Typography } from 'gazprom-ui-lib';

import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './SuggestionDetails.module.scss';
import Actions from './containers/actions';
import FilesField from './containers/files-field';
import Header from './containers/header';
import TextField from './containers/text-field';
import { getNeedExpensesTitle } from './suggestionDetails.utils';
import useGetData from './useGetData.hook';

const DECISION_WAITING_STATUS = 'WAITING';
const DECISION_COMMISSION_REVIEW_TYPE = 'COMMISSION_1L_REVIEW';

const SuggestionDetails: FC = () => {
  const navigate = useNavigate();

  const isSuggestionDecision = !!useMatch(SUGGESTIONS_DECISIONS_DETAILS_ROUTE);

  const { decision: decisionData, suggestion: suggestionData, isLoading } = useGetData();

  const suggestionDataToUse = isSuggestionDecision ? decisionData?.suggestion : suggestionData;

  const {
    id: suggestionId,
    employee,
    category,
    name,
    problem,
    suggestion,
    expectation,
    needExpenses,
    personalParticipation,
    curStatus,
    statusHistory,
    createdAt,
    releaseReportFile,
    problemFiles,
    suggestionFiles,
  } = suggestionDataToUse ?? {};

  const {
    id: decisionId,
    status,
    createdAt: decisionCreatedAt,
    type: decisionType,
  } = decisionData ?? {};

  const handleGoBack = () => {
    navigate(isSuggestionDecision ? SUGGESTIONS_DECISIONS_ROUTE : SUGGESTIONS_LIST_ROUTE);
  };

  const participationTitle = personalParticipation ? t('common_yes') : t('common_no');
  const isSuggestionReleased = !!releaseReportFile;

  const currentStatus = isSuggestionDecision ? status : curStatus;
  const createdDate = isSuggestionDecision ? decisionCreatedAt : createdAt;

  const isActionAvailable =
    isSuggestionDecision &&
    currentStatus?.code === DECISION_WAITING_STATUS &&
    decisionType === DECISION_COMMISSION_REVIEW_TYPE;

  return (
    <WithOverflow>
      <Header status={currentStatus} createdDate={createdDate} />
      <WithLoader isLoading={isLoading}>
        <DocumentView>
          <DocumentView.Content>
            <Flex vertical gap="24" fullWidth>
              <DocumentView.Employee employee={employee} />
              <TextField title={t('suggestion_category')} value={category?.name} />
              <TextField title={t('suggestion_name')} value={name} />
              <TextField title={t('suggestion_problem_description')} value={problem} />
              <FilesField title={t('suggestion_photo_video_problem')} files={problemFiles} />
              <TextField title={t('suggestion_idea')} value={suggestion} />
              <FilesField title={t('suggestion_photo_video_idea')} files={suggestionFiles} />
              <TextField title={t('suggestion_expected_result')} value={expectation} />
              <TextField
                title={t('suggestion_need_expenses')}
                value={getNeedExpensesTitle(needExpenses)}
              />
              <TextField title={t('suggestion_participation')} value={participationTitle} />
              {isSuggestionReleased && (
                <>
                  <Divider />
                  <Typography.Title level={4}>
                    {t('suggestions_realization_title')}
                  </Typography.Title>
                  <DocumentView.Employee employee={employee} title={t('common_executor')} />
                  <TextField title={t('suggestion_release_comment')} value="-" />
                  <TextField title={t('suggestion_release_report')} value="-" />
                  <FilesField
                    title={t('suggestion_release_report')}
                    files={releaseReportFile}
                    withArchive
                  />
                </>
              )}
            </Flex>
            <DocumentView.History historyItems={statusHistory as StepsProps['items']} />
          </DocumentView.Content>
          {!isActionAvailable && (
            <Button type="secondary" className={s.closeButton} onClick={handleGoBack}>
              {t('common_close')}
            </Button>
          )}

          {isActionAvailable && <Actions decisionId={decisionId} suggestionId={suggestionId} />}
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default SuggestionDetails;
