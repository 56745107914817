import { t } from 'tools/i18n';

import Employees from './containers/employees';
import Preview from './containers/preview';
import Structure from './containers/structure';

export const getResultNode = (node: string) => {
  const [id, type] = node.split('.');
  return { id, type };
};
export const TABS = [
  {
    key: '1',
    label: t('employee_groups_organizational_structure'),
    children: <Structure />,
  },
  {
    key: '2',
    label: t('employee_groups_add_employees'),
    children: <Employees />,
  },
  {
    key: '3',
    label: t('employee_groups_preview_group'),
    children: <Preview />,
  },
];

export enum EMPLOYEE_GROUPS_FORM_ENUM {
  NAME = 'name',
}

export type EmployeeGroupsFormResponse = {
  [EMPLOYEE_GROUPS_FORM_ENUM.NAME]: string;
};
