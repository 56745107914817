import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { DEFAULT_FORM_STATE, FormResponseType } from './accessControlUsers.utils';
import Filters from './containers/filters';
import Table from './containers/table';
import useGetData from './useGetData.hook';

const AccessControlUsers = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state, pathname } = location || {};

  const [form] = Form.useForm<FormResponseType>();

  const { isFetching, data, isLoading } = useGetData({ form });

  useEffect(() => {
    const stateFromNavigationExists = state && (state.requestBody || state.role);
    if (stateFromNavigationExists) {
      let bodyToSet: FormResponseType = {
        filters: {},
        ...DEFAULT_FORM_STATE,
      };
      if (state.role) {
        bodyToSet.filters!.roleIds = [state.role];
      }
      if (state.requestBody) {
        bodyToSet = { ...bodyToSet, ...state.requestBody };
      }

      form.setFieldsValue(bodyToSet);
      navigate(pathname, { replace: true });
    }
  }, [form, navigate, pathname, state]);

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title total={data?.totalCount ?? ''}>
          {t('common_users_list')}
        </ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <Table dataSource={data} loading={isFetching} isInitialLoading={isLoading} />
      </FiltersV2>
    </WithOverflow>
  );
};

export default AccessControlUsers;
