import { t } from 'tools/i18n';

import { ColumnsType } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableText from 'components/table/table-text';

import { EmployeeGroupType } from 'types/employeeGroups.types';

const useColumns: () => ColumnsType<EmployeeGroupType> = () => [
  {
    title: t('employee_groups_column_group_name'),
    dataIndex: 'title',
    render: (title: string) => (
      <TableText size="16" weight="500">
        {title}
      </TableText>
    ),
    sorter: true,
  },
  {
    title: t('employee_groups_column_created_date'),
    dataIndex: 'createdAt',
    align: 'center',
    width: 200,
    render: (date: string) => <TableDate date={date} />,
    sorter: true,
  },
];

export default useColumns;
