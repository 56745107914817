import { prepareQueryParams } from 'utils/prepareQueryParams';

import {
  CompleteAuthorizationPropsType,
  CompleteAuthorizationResponseType,
  GetAuthorizationInfoPropsType,
  GetAuthorizationInfoResponseType,
  LogoutPropsType,
  LogoutResponseType,
  RequestAuthorizationPropsType,
  RequestAuthorizationResponseType,
} from 'services/authorization/authorizationApiService.types';
import { createAuthorizationUrl } from 'services/authorization/utils';
import initialApiService from 'services/initialApiService';

import { SERVICE_TAGS } from 'constants/serviceTags';

const authorizationApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    requestAuthorization: builder.query<
      RequestAuthorizationResponseType,
      RequestAuthorizationPropsType
    >({
      query: (params) => ({
        url: createAuthorizationUrl(`/request${prepareQueryParams(params)}`),
      }),
    }),
    completeAuthorization: builder.mutation<
      CompleteAuthorizationResponseType,
      CompleteAuthorizationPropsType
    >({
      query: (params) => ({
        url: createAuthorizationUrl(`/request/code${prepareQueryParams(params)}`),
        method: 'POST',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_TELEGRAM_AUTHORIZATION_INFO],
    }),
    getAuthorizationInfo: builder.query<
      GetAuthorizationInfoResponseType,
      GetAuthorizationInfoPropsType
    >({
      query: () => ({
        url: createAuthorizationUrl('/info'),
        method: 'GET',
      }),
      providesTags: [SERVICE_TAGS.GET_TELEGRAM_AUTHORIZATION_INFO],
    }),
    logout: builder.mutation<LogoutResponseType, LogoutPropsType>({
      query: () => ({
        url: createAuthorizationUrl('/logout'),
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_TELEGRAM_AUTHORIZATION_INFO],
    }),
    clearAuthorizationSession: builder.mutation<void, void>({
      query: () => ({
        url: createAuthorizationUrl('/clear'),
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_TELEGRAM_AUTHORIZATION_INFO],
    }),
  }),
});

export const {
  useLazyRequestAuthorizationQuery,
  useCompleteAuthorizationMutation,
  useLazyGetAuthorizationInfoQuery,
  useLogoutMutation,
  useClearAuthorizationSessionMutation,
} = authorizationApiService;
