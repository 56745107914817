import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { SETTINGS_1C_INSTANCES_CREATE_ROUTE } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

const Header: FC = () => {
  const navigate = useNavigate();

  const handleAdd = () => {
    navigate(SETTINGS_1C_INSTANCES_CREATE_ROUTE);
  };

  return (
    <ContentHeader>
      <ContentHeader.Title>{t('common_employers_list')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="plus" onClick={handleAdd}>
          {t('1c_create_instance')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
