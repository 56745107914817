import { SORT_ENUM } from 'types/sort.types';

export type RequestBodyType = {
  page: number;
  size: number;
  search: string;
  property?: string;
  order?: SORT_ENUM;
};

export const DEFAULT_REQUEST_BODY: RequestBodyType = {
  page: 1,
  size: 10,
  search: '',
};
