import { FC, useCallback, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE } from 'routes/documents/list';
import { get1CInstanceLnaWithIdRoute } from 'routes/settings/list';
import { t } from 'tools/i18n';
import { pluralEnding } from 'utils/declension.utils';
import { getShortenName } from 'utils/getShortenName.utils';

import { useGetKedoReviewTaskByIdQuery } from 'services/kedo-review-tasks/kedoReviewTasksApiService';

import { Button, Icon, Tabs, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import CreationInfo from 'containers/creation-info';

import { CurrentKedoStatusType } from 'types/documents.types';
import { REVIEW_TASK_TYPES_ENUM } from 'types/review-task.types';

import { getKedoReviewTaskDetailsTitle } from '../documentsKedoReviewTasks.utils';
import s from './DocumentsKedoReviewTaskDetails.module.scss';
import {
  KEDO_REVIEW_TASK_ASSIGNMENTS_TAB,
  KEDO_REVIEW_TASK_BASE_INFO_TAB,
} from './documentsKedoReviewTaskDetails.utils';
import useTabItemsHook from './useTabItems.hook';

const DocumentsKedoReviewTaskDetails: FC = () => {
  const params = useParams<{ id: string; instanceId?: string }>();
  const navigate = useNavigate();

  const { data: kedoReviewTask } = useGetKedoReviewTaskByIdQuery(params?.id!, {
    skip: !params?.id,
  });

  const [activeTab, setActiveTab] = useState<string>(KEDO_REVIEW_TASK_BASE_INFO_TAB);
  const handleGoBack = useCallback(() => {
    if (params.instanceId) {
      navigate(get1CInstanceLnaWithIdRoute(params.instanceId));
    } else {
      navigate(DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE);
    }
  }, [params.instanceId, navigate]);

  const handleTabChange = (e: string) => {
    setActiveTab(e);
  };

  const { code = '', state = '', description = '', name = '' } = kedoReviewTask?.status ?? {};

  const title = getKedoReviewTaskDetailsTitle(code);

  const { firstName, lastName, patronymic } = kedoReviewTask?.creator?.person || {};

  const [assignmentsCount, setAssignmentsCount] = useState(0);

  const tabs = useTabItemsHook({
    kedoReviewTask,
    handleGoBack,
    status: code as REVIEW_TASK_TYPES_ENUM,
    setAssignmentsCount,
  });

  const assigneesHeaderInfo = (
    <div className={s.headerListInfo}>
      <Icon name="groups" />
      <Typography.Text size="14" className={s.headerListInfoTitle}>
        {t('common_employees_count', {
          current: assignmentsCount,
          ending: pluralEnding(assignmentsCount, t('common_employee').toLowerCase()),
        })}
      </Typography.Text>
    </div>
  );

  const showAssigneesInfo = activeTab === KEDO_REVIEW_TASK_ASSIGNMENTS_TAB && assignmentsCount > 0;
  const currentKedoStatusImitation: CurrentKedoStatusType = {
    comment: null,
    createdAt: null,
    description,
    state,
    status: code,
    title: name,
    titleAlt: null,
  };

  return (
    <div className={s.wrapper}>
      <div className={s.actionsHeader}>
        <div>
          <Button htmlType="button" type="link" leftIcon="arrowLeft" onClick={handleGoBack}>
            {t('common_go_back')}
          </Button>
          <Typography.Title level={4}>{title}</Typography.Title>
          <div className={s.headerInfo}>
            {showAssigneesInfo && assigneesHeaderInfo}
            <CreationInfo
              createdDate={kedoReviewTask?.createDate}
              createdBy={getShortenName(lastName, firstName, patronymic)}
            />
          </div>
        </div>
        {kedoReviewTask && (
          <KedoStatusTag currentKedoStatus={currentKedoStatusImitation} size="30" />
        )}
      </div>
      <Tabs items={tabs} type="card" onChange={handleTabChange} activeKey={activeTab} />
    </div>
  );
};

export default DocumentsKedoReviewTaskDetails;
