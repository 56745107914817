import { useEffect, useMemo, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button, Form, Icon, Popover, Typography } from 'gazprom-ui-lib';

import HandbookBaseForm from 'containers/handbook-base-form';
import { HANDBOOK_BASE_FORM_NAME } from 'containers/handbook-base-form/handbookBaseForm.utils';

import { HandbookType } from 'types/guide.types';

import s from './GuideHandbookDetails.module.scss';
import ModalUnpublish from './containers/modal-unpublish';
import GuideHandbookDetailsInstructions from './guide-handbook-details-instructions';
import { HANDBOOK_BASE_INFO_TAB, HANDBOOK_INSTRUCTIONS_TAB } from './guideHandbookDetails.utils';
import useActions from './useActions.hook';

interface UseTabItemsHook {
  handbook?: HandbookType;
  handleGoBack: () => void;
}

const useTabItemsHook = (props: UseTabItemsHook) => {
  const { handbook, handleGoBack } = props;

  const location = useLocation();

  const isShowAvailable = useRef<boolean>(true);

  const [popoverIsOpen, setPopoverIsOpen] = useState<boolean>(false);
  const [openedModalType, setOpenedModalType] = useState<'' | 'unpublish'>('');

  const [form] = Form.useForm();

  const handleOpenUnpublishModal = () => {
    setOpenedModalType('unpublish');
  };

  const handleModalClose = () => {
    setOpenedModalType('');
  };

  const { handleBaseFormSubmit, handleSaveAndPublish, handleUnpublish, updateHandbookIsLoading } =
    useActions({
      form,
      handbook,
      handleModalClose,
    });

  const popoverContent = useMemo(() => {
    return (
      <div className={s.popoverContent}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setPopoverIsOpen(false);
          }}>
          <Icon name="delete" />
        </button>
        <Typography.Text size="14" weight="500">
          {t('common_saved_successfully')}
        </Typography.Text>
        <Typography.Text size="12" type="secondary">
          {t('guide_handbook_can_fill_instructions')}
        </Typography.Text>
      </div>
    );
  }, []);

  useEffect(() => {
    if (isShowAvailable.current) {
      if (location?.state?.newHandbook) {
        setPopoverIsOpen(true);
      }
      isShowAvailable.current = false;
    }
  }, [location?.state?.newHandbook]);

  return [
    {
      key: HANDBOOK_BASE_INFO_TAB,
      children: (
        <>
          <div className={s.content}>
            <HandbookBaseForm
              form={form}
              onSubmit={handleBaseFormSubmit()}
              handbook={handbook}
              className={s.form}
            />
            <div className={s.actions}>
              <Button
                fullWidth
                type="primary"
                leftIcon="ok"
                htmlType="submit"
                loading={updateHandbookIsLoading}
                form={HANDBOOK_BASE_FORM_NAME}>
                {t('common_save')}
              </Button>
              {handbook?.isPublish ? (
                <Button
                  danger
                  fullWidth
                  type="primary"
                  htmlType="button"
                  leftIcon="publish"
                  loading={updateHandbookIsLoading}
                  onClick={handleOpenUnpublishModal}>
                  {t('common_unpublish')}
                </Button>
              ) : (
                <Button
                  fullWidth
                  type="primary"
                  htmlType="button"
                  leftIcon="publish"
                  loading={updateHandbookIsLoading}
                  onClick={handleSaveAndPublish}>
                  {t('common_save_and_publish')}
                </Button>
              )}
              <Button fullWidth type="secondary" onClick={handleGoBack}>
                {t('common_close')}
              </Button>
            </div>
          </div>
          <ModalUnpublish
            open={openedModalType === 'unpublish'}
            handleClose={handleModalClose}
            handleSubmit={handbook?.isPublish ? handleUnpublish : handleSaveAndPublish}
            isLoading={updateHandbookIsLoading}
          />
        </>
      ),
      label: t('guide_handbook_info'),
    },
    {
      key: HANDBOOK_INSTRUCTIONS_TAB,
      children: <GuideHandbookDetailsInstructions />,
      label: (
        <Popover
          overlayClassName={s.popover}
          open={popoverIsOpen}
          content={popoverContent}
          arrow={false}
          placement="rightTop"
          align={{ offset: [0, 20] }}>
          {t('guide_handbook_memo')}
        </Popover>
      ),
    },
  ];
};

export default useTabItemsHook;
