import { useEffect, useRef } from 'react';

import useLocalStorage from './useLocalStorage.hook';

export const LAST_ACTION_TIMESTAMP = 'lastActionTimestamp';
const USER_ACTION_TRACKING_DELAY = 1000 * 3;

const useLastUserActionHook = () => {
  const [, setLastUserActionTimestamp] = useLocalStorage(LAST_ACTION_TIMESTAMP, Date.now());

  const lastClickTimeRef = useRef<number>(0);

  useEffect(() => {
    const updateLastActionTime = () => {
      const now = Date.now();
      if (now - lastClickTimeRef.current >= USER_ACTION_TRACKING_DELAY) {
        setLastUserActionTimestamp(now);
        lastClickTimeRef.current = now;
      }
    };

    window.addEventListener('mousemove', updateLastActionTime);
    window.addEventListener('keypress', updateLastActionTime);
    window.addEventListener('click', updateLastActionTime);

    return () => {
      window.removeEventListener('mousemove', updateLastActionTime);
      window.removeEventListener('keypress', updateLastActionTime);
      window.removeEventListener('click', updateLastActionTime);
    };
  }, [setLastUserActionTimestamp]);
};

export default useLastUserActionHook;
