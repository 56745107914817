import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetEmployeeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Form } from 'gazprom-ui-lib';

import MultiTagsSelect from 'components/form/multi-tags-select';

import { EXPERT_COMMISSION_FORM_ENUM } from '../../expertCommissionForm.utils';

const Composition = () => {
  const { id } = useParams<{ id: string }>();

  const form = Form.useFormInstance();
  const organizationId = Form.useWatch(EXPERT_COMMISSION_FORM_ENUM.ORGANIZATION);

  const { data: employeeData } = useGetEmployeeQuery({
    page: 1,
    size: 500,
    employerId: organizationId,
  });

  useEffect(() => {
    if (!id && organizationId) {
      form.setFieldValue(EXPERT_COMMISSION_FORM_ENUM.MEMBERS, null);
    }
  }, [form, id, organizationId]);

  const options = employeeData?.employees?.map((employee) => ({
    label: `${employee.person.firstName} ${employee.person.lastName?.slice(0, 1)}. ${
      employee.staff.name
    }`,
    value: employee.id,
  }));

  return (
    <MultiTagsSelect
      name={EXPERT_COMMISSION_FORM_ENUM.MEMBERS}
      title={t('suggestions_commission_composition')}
      placeholder={t('suggestions_commission_composition_placeholder')}
      options={options ?? []}
      isRequired={true}
      disabled={!organizationId}
    />
  );
};

export default Composition;
