import { Dispatch, FC, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import DocumentsDeclarationsFormCheck from 'containers/documents-declarations-form/documents-declarations-form-check';
import DocumentsDeclarationsFormFieldName from 'containers/documents-declarations-form/documents-declarations-form-field-name';
import DocumentsDeclarationsFormMask from 'containers/documents-declarations-form/documents-declarations-form-mask';
import DocumentsDeclarationsFormRules from 'containers/documents-declarations-form/documents-declarations-form-rules';
import DocumentsDeclarationsFormText from 'containers/documents-declarations-form/documents-declarations-form-text';
import DocumentsDeclarationsFormType from 'containers/documents-declarations-form/documents-declarations-form-type';
import { DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';

import { AttributeType, DocumentsDeclarationsAdditionalSectionType } from 'types/documents.types';
import { KEDO_ATTRIBUTE_VALIDATION_TYPES } from 'types/kedo.types';

import s from './DocumentsDeclarationsAdditionalSection.module.scss';

interface DocumentsDeclarationsAdditionalSectionProps {
  id: string;
  setSections: Dispatch<SetStateAction<DocumentsDeclarationsAdditionalSectionType[]>>;
  removeHandler: () => void;
  sectionData?: AttributeType;
  num: null | number;
}

const DocumentsDeclarationsAdditionalSection: FC<DocumentsDeclarationsAdditionalSectionProps> = (
  props,
) => {
  const { id, num, sectionData, setSections, removeHandler } = props;
  const initialValueId = id.includes('-') ? id : undefined;

  const formType = Form.useWatch(`${DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM.TYPE}.${id}`);
  const shouldUseValidation = Object.values(KEDO_ATTRIBUTE_VALIDATION_TYPES).includes(formType);

  return (
    <div className={s.container}>
      <Form.Item
        name={`${DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM.ID}.${id}`}
        initialValue={initialValueId}
        hidden
      />
      <Form.Item
        name={`${DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM.NUM}.${id}`}
        initialValue={num}
        hidden
      />
      <DocumentsDeclarationsFormFieldName
        id={id}
        setSections={setSections}
        removeHandler={removeHandler}
        defaultValue={sectionData?.name}
      />
      <DocumentsDeclarationsFormType id={id} defaultValue={sectionData?.type} />
      <DocumentsDeclarationsFormMask id={id} defaultValue={sectionData?.inputMask} />
      <DocumentsDeclarationsFormText id={id} defaultValue={sectionData?.tooltip} />
      <DocumentsDeclarationsFormCheck
        id={id}
        formName={DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM.REQUIRED}
        label={t('common_field_required')}
        defaultValue={sectionData?.isMandatory}
      />
      {shouldUseValidation && (
        <DocumentsDeclarationsFormRules id={id} defaultValue={sectionData?.validation?.code} />
      )}
    </div>
  );
};

export default DocumentsDeclarationsAdditionalSection;
