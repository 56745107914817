import { Flex, Typography } from 'gazprom-ui-lib';

interface Props {
  title: string;
  value?: string;
}

const TextField = (props: Props) => {
  const { title, value } = props;

  return (
    <Flex vertical gap="4">
      <Typography.Title level={5}>{title}</Typography.Title>
      <Typography.Text size="14">{value}</Typography.Text>
    </Flex>
  );
};

export default TextField;
