import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import { GetPositionsPropsType, GetPositionsResponseType } from './positionApiService.types';

import { createPositionUrl } from './utils';

const positionApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getPositions: builder.query<GetPositionsResponseType, GetPositionsPropsType>({
      query: (params) => ({
        url: createPositionUrl(prepareQueryParams(params)),
      }),
    }),
  }),
});

export const { useLazyGetPositionsQuery } = positionApiService;
