import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Button, Flex, Form, FormInstance, ModalDefault, TextArea } from 'gazprom-ui-lib';

import { APPROVE_REJECT_DECISION_FORM_ENUM } from '../actions/action.utils';
import s from './RejectDecisionModal.module.scss';

interface Props {
  form: FormInstance;
  isOpen: boolean;
  onCancel: () => void;
  handleReject: () => void;
  handleRevision: () => void;
  isLoading?: boolean;
}

const RejectDecisionModal = (props: Props) => {
  const { form, isOpen, handleReject, handleRevision, onCancel, isLoading } = props;

  return (
    <ModalDefault open={isOpen} onCancel={onCancel} closable>
      <ModalDefault.Icon name="delete" type="danger" />
      <ModalDefault.Title>{t('suggestions_decision_reject_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('suggestions_decision_reject_description')}
      </ModalDefault.Description>

      <Form form={form} className={s.form}>
        <Form.Item
          label={t('common_comment')}
          name={APPROVE_REJECT_DECISION_FORM_ENUM.COMMENT}
          rules={[formRules.required]}>
          <TextArea showCount placeholder={t('common_enter_text')} maxLength={240} />
        </Form.Item>

        <ModalDefault.Actions>
          <Flex gap="16">
            <Button fullWidth leftIcon="return" onClick={handleRevision} loading={isLoading}>
              {t('common_send_to_revision')}
            </Button>
            <Button danger ghost fullWidth onClick={handleReject} loading={isLoading}>
              {t('thank_you_reject')}
            </Button>
          </Flex>
        </ModalDefault.Actions>
      </Form>
    </ModalDefault>
  );
};

export default RejectDecisionModal;
