import { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  NOTIFICATION_MAILING_MANAGEMENT_ROUTE,
  NOTIFICATION_NEWSLETTER_CREATE_WITH_TEMPLATE_ROUTE,
} from 'routes/notification/list';
import { t } from 'tools/i18n';

import { useGetNotificationTemplateByIdQuery } from 'services/notification-template/notificationTemplateApiService';

import { Button, Flex, Form, Icon, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import NotificationNewsletterForm from 'containers/notification-newsletter-form';
import {
  NOTIFICATION_NEWSLETTER_FORM_ENUM,
  NOTIFICATION_NEWSLETTER_FORM_NAME,
} from 'containers/notification-newsletter-form/notificationNewsletterForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import { NOTIFICATION_NEWSLETTER_TAB } from 'pages/notification/notification.utils';

import useCreateNewsletter from '../useCreateNewsletter.hook';
import s from './NotificationNewsletterCreate.module.scss';

const NotificationNewsletterCreate = () => {
  const params = useParams<{ templateId?: string }>();
  const navigate = useNavigate();

  const withTemplate = !!useMatch(NOTIFICATION_NEWSLETTER_CREATE_WITH_TEMPLATE_ROUTE);
  const [form] = Form.useForm();

  const notificationTitle = Form.useWatch(NOTIFICATION_NEWSLETTER_FORM_ENUM.TITLE, form);
  const notificationText = Form.useWatch(NOTIFICATION_NEWSLETTER_FORM_ENUM.TEXT, form);
  const notificationReceivers = Form.useWatch(NOTIFICATION_NEWSLETTER_FORM_ENUM.RECEIVERS, form);

  const canSaveAsTemplate =
    notificationTitle && notificationText && !!notificationReceivers?.length;

  const templateId = withTemplate && params?.templateId;
  const { data: template, isLoading } = useGetNotificationTemplateByIdQuery(
    templateId ? templateId : skipToken,
  );

  const [templateModalOpen, setTemplateModalOpen] = useState<boolean>(false);

  const handleGoBack = () => {
    navigate(NOTIFICATION_MAILING_MANAGEMENT_ROUTE, {
      state: {
        activeTab: NOTIFICATION_NEWSLETTER_TAB,
      },
    });
  };
  const handleReset = () => {
    handleGoBack();
  };

  const {
    handleSubmit,
    createNewsletterIsFetching,
    handleSaveAsTemplate,
    createTemplateIsLoading,
  } = useCreateNewsletter({});

  const handleOpenCloseTemplateModal = () => {
    setTemplateModalOpen(!templateModalOpen);
  };

  return (
    <>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title
          description={
            <Flex align="center" gap="8">
              <Icon name="info" color="var(--color-primary-6)" />
              <Typography.Text type="secondary" size="14">
                {t('notification_form_create_notification_newsletter_description')}
              </Typography.Text>
            </Flex>
          }
          goBackLink={NOTIFICATION_MAILING_MANAGEMENT_ROUTE}
          goBackState={{
            activeTab: NOTIFICATION_NEWSLETTER_TAB,
          }}>
          {t('notification_create_newsletter')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="send" htmlType="submit" form={NOTIFICATION_NEWSLETTER_FORM_NAME}>
            {t('notification_create_newsletter')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={isLoading}>
        <div className={s.formWrapper}>
          <NotificationNewsletterForm
            handleSaveAsTemplate={handleSaveAsTemplate}
            formFromProps={form}
            newsletterTemplate={template}
            templateModalOpen={templateModalOpen}
            handleCloseTemplateModal={handleOpenCloseTemplateModal}
            isTemplateCreating={createTemplateIsLoading}
            handleReset={handleReset}
            handleSubmit={handleSubmit}
          />
          <div className={s.actionsFooter}>
            <Button
              loading={createNewsletterIsFetching}
              leftIcon="send"
              fullWidth
              type="primary"
              htmlType="submit"
              form={NOTIFICATION_NEWSLETTER_FORM_NAME}>
              {t('notification_create_newsletter')}
            </Button>
            <Button
              disabled={!canSaveAsTemplate}
              loading={createTemplateIsLoading}
              leftIcon="draft"
              fullWidth
              type="primary"
              onClick={handleOpenCloseTemplateModal}
              form={NOTIFICATION_NEWSLETTER_FORM_NAME}>
              {t('notification_save_as_template')}
            </Button>
            <Button
              type="secondary"
              fullWidth
              htmlType="reset"
              onClick={handleReset}
              form={NOTIFICATION_NEWSLETTER_FORM_NAME}>
              {t('common_close')}
            </Button>
          </div>
        </div>
      </WithLoader>
    </>
  );
};

export default NotificationNewsletterCreate;
