import { useParams } from 'react-router-dom';

import { useGetNotificationTemplateByIdQuery } from 'services/notification-template/notificationTemplateApiService';

const useGetTemplate = () => {
  const params = useParams<{ templateId: string }>();

  const { data: template, isFetching } = useGetNotificationTemplateByIdQuery(params.templateId!, {
    skip: !params?.templateId,
  });

  return {
    template,
    isFetching,
  };
};

export default useGetTemplate;
