export const NOTIFICATION_TEMPLATE_FORM_NAME = 'NOTIFICATION_TEMPLATE_FORM_NAME';

export enum NOTIFICATION_TEMPLATE_FORM_ENUM {
  NAME = 'NAME',
  TITLE = 'TITLE',
  TEXT = 'TEXT',
  RECEIVERS = 'RECEIVERS',
  SEND_APP = 'SEND_APP',
  SEND_PUSH = 'SEND_PUSH',
  SEND_EMAIL = 'SEND_EMAIL',
  ALLOW_CALLBACK = 'ALLOW_CALLBACK',
  ALLOW_FEEDBACK = 'ALLOW_FEEDBACK',
  REQUIRES_FEEDBACK = 'REQUIRES_FEEDBACK',
}

export interface NotificationTemplateFormResponse {
  [NOTIFICATION_TEMPLATE_FORM_ENUM.NAME]: string;
  [NOTIFICATION_TEMPLATE_FORM_ENUM.TITLE]: string;
  [NOTIFICATION_TEMPLATE_FORM_ENUM.TEXT]: string;
  [NOTIFICATION_TEMPLATE_FORM_ENUM.RECEIVERS]: string[];
  [NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_APP]?: boolean;
  [NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_PUSH]?: boolean;
  [NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_EMAIL]?: boolean;
  [NOTIFICATION_TEMPLATE_FORM_ENUM.ALLOW_CALLBACK]?: boolean;
  [NOTIFICATION_TEMPLATE_FORM_ENUM.ALLOW_FEEDBACK]?: boolean;
  [NOTIFICATION_TEMPLATE_FORM_ENUM.REQUIRES_FEEDBACK]?: boolean;
}
