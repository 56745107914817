import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetEmployeeGroupPropsType,
  GetEmployeeGroupResponseType,
} from './employeeGroupApiService.types';

import { createEmployeeUrl } from './utils';

const employeeGroupApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeGroup: builder.query<GetEmployeeGroupResponseType, GetEmployeeGroupPropsType>({
      query: (params) => ({
        url: createEmployeeUrl(`${prepareQueryParams(params)}`),
      }),
    }),
  }),
});

export const { useLazyGetEmployeeGroupQuery } = employeeGroupApiService;
