import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { Icon, TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { ExecutionType } from 'types/bypassSheet.types';

const useColumnsHook = (): TableProps<ExecutionType>['columns'] => {
  return useMemo(
    () => [
      {
        title: t('bypass_sheet_duty_title'),
        dataIndex: 'dutyTitle',
        render: (dutyTitle: string) => (
          <Typography.Text weight="600" size="14">
            {dutyTitle}
          </Typography.Text>
        ),
      },
      {
        title: t('bypass_sheet_fio'),
        dataIndex: 'dutyEmployee',
        render: (dutyEmployee: ExecutionType['dutyEmployee']) => (
          <Typography.Text weight="600" size="14">
            {dutyEmployee.person.lastName} {dutyEmployee.person.firstName}{' '}
            {dutyEmployee.person.patronymic}
          </Typography.Text>
        ),
      },
      {
        title: t('common_status'),
        dataIndex: 'status',
        render: (status: ExecutionType['status']) => (
          <TableStatus status={(status.state === 'done' ? 'DONE' : 'PENDING') as STATUSES}>
            {status.name}
          </TableStatus>
        ),
      },
      {
        title: t('bypass_sheet_comment'),
        dataIndex: 'comment',
        align: 'center',
        render: (_: unknown, row: ExecutionType) =>
          row.hasComment && <Icon name="comment" color="var(--color-primary-6)" />,
      },
      {
        title: t('bypass_sheet_date_signed'),
        dataIndex: 'doneDate',
        align: 'center',
        render: (doneDate: string) => <TableDate date={doneDate} />,
      },
      {
        title: t('common_message'),
        dataIndex: 'message',
        align: 'center',
        render: (_: unknown, row: ExecutionType) =>
          row.hasMessage && <Icon name="comment" color="var(--color-primary-6)" />,
      },
    ],
    [],
  );
};

export default useColumnsHook;
