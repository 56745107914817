import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

import { LOCALES } from 'gazprom-ui-lib';

const FALLBACK_LANGUAGE = 'ru';

export const initLanguagesForThirdPartyLibs = () => {
  const INIT_LOCALE = FALLBACK_LANGUAGE;

  import(`dayjs/locale/${INIT_LOCALE}.js`)
    .then(() => {
      dayjs.locale(INIT_LOCALE);
    })
    .catch(() => {
      import(`dayjs/locale/${FALLBACK_LANGUAGE}.js`).then(() => {
        dayjs.locale(FALLBACK_LANGUAGE);
      });
    });

  return {
    antdLocale: LOCALES[INIT_LOCALE],
  };
};

export const initDayJsPlugins = () => {
  dayjs.extend(isLeapYear);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(utc);
  dayjs.extend(isToday);
  dayjs.extend(duration);
};

export const initPinCodeAuthorization = () => {
  const isAuthorizedWithPinCode = window.location.search.includes('?state');

  if (isAuthorizedWithPinCode) {
    localStorage.setItem('lastActionTimestamp', Date.now().toString());
  }
};
