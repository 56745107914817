import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import NotificationNewsletterTable from 'containers/notification-newsletter-table';

import { DEFAULT_FORM_STATE, FormResponseType } from './notificationNewsletter.util';

const NotificationNewsletter = () => {
  const [form] = Form.useForm<FormResponseType>();

  return (
    <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
      <FiltersV2.TableFilters>
        <FiltersV2.Search />
      </FiltersV2.TableFilters>
      <NotificationNewsletterTable />
    </FiltersV2>
  );
};

export default NotificationNewsletter;
