import { useEffect } from 'react';

import dayjs from 'dayjs';

import { FormInstance } from 'gazprom-ui-lib';

import { NOTIFICATION_NEWSLETTER_FORM_ENUM } from 'containers/notification-newsletter-form/notificationNewsletterForm.utils';

import { NotificationNewsletterType, NotificationTemplateType } from 'types/notification.types';

interface UseFormSetProps {
  form?: FormInstance;
  currentNewsletter?: NotificationNewsletterType;
  newsletterTemplate?: NotificationTemplateType;
}

const setDefaultWithTemplate = (
  form: FormInstance,
  newsletterTemplate: NotificationTemplateType,
) => {
  form.setFieldsValue({
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.NAME]: newsletterTemplate.name,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.TITLE]: newsletterTemplate.title,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.TEXT]: newsletterTemplate.body,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.RECEIVERS]: newsletterTemplate.receivers[0]?.receiverIds,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION]: !!newsletterTemplate?.sendApp,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION_ADDED]: !!newsletterTemplate?.sendApp,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.EMAIL]: !!newsletterTemplate?.sendEmail,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.CALLBACK_ON]: newsletterTemplate.allowCallback,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_ON]: newsletterTemplate.allowFeedback,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_IS_REQUIRED]: newsletterTemplate.requiresFeedback,
  });
};

const setDefaultWithNewsletter = (
  form: FormInstance,
  currentNewsletter: NotificationNewsletterType,
) => {
  form.setFieldsValue({
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.NAME]: currentNewsletter.name,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.RECEIVERS]: currentNewsletter.receivers[0]?.receiverIds,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.TITLE]: currentNewsletter.title,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.TEXT]: currentNewsletter.body,
  });

  if (currentNewsletter.dateToSend) {
    form.setFieldsValue({
      [NOTIFICATION_NEWSLETTER_FORM_ENUM.DELAY]: true,
      [NOTIFICATION_NEWSLETTER_FORM_ENUM.PUBLISH_FROM_DATE]: dayjs(currentNewsletter.dateToSend),
      [NOTIFICATION_NEWSLETTER_FORM_ENUM.PUBLISH_FROM_TIME]: dayjs(currentNewsletter.dateToSend),
    });
  }

  form.setFieldsValue({
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION]: currentNewsletter?.sendApp,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.APP_NOTIFICATION_ADDED]: currentNewsletter?.sendApp,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.EMAIL]: currentNewsletter?.sendEmail,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.CALLBACK_ON]: currentNewsletter.allowCallback,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_ON]: currentNewsletter.allowFeedback,
    [NOTIFICATION_NEWSLETTER_FORM_ENUM.FEEDBACK_IS_REQUIRED]: currentNewsletter.requiresFeedback,
  });
};

const useFormSet = (props: UseFormSetProps) => {
  const { form, currentNewsletter, newsletterTemplate } = props;

  useEffect(() => {
    const defaultValueCanBeSet = form && !form.isFieldsTouched();

    if (defaultValueCanBeSet) {
      if (newsletterTemplate) {
        setDefaultWithTemplate(form, newsletterTemplate);
      }

      if (currentNewsletter) {
        setDefaultWithNewsletter(form, currentNewsletter);
      }
    }
  }, [currentNewsletter, form, newsletterTemplate]);
};

export default useFormSet;
