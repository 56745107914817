import { FC } from 'react';

import { t } from 'tools/i18n';

import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import { NOTIFICATION_TEMPLATE_FORM_ENUM } from '../notificationTemplateForm.utils';

const NotificationTemplateFormEmail: FC = () => {
  return (
    <Form.Item name={NOTIFICATION_TEMPLATE_FORM_ENUM.SEND_EMAIL} valuePropName="checked">
      <Checkbox>
        <Typography.Text size="14">{t('common_by_email')}</Typography.Text>
      </Checkbox>
    </Form.Item>
  );
};

export default NotificationTemplateFormEmail;
