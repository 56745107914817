import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useGetSuggestionDecisionPerformersQuery } from 'services/suggestion/suggestionApiService';

import { Button, Flex, Form, FormInstance, ModalDefault, Select } from 'gazprom-ui-lib';

import { APPROVE_REJECT_DECISION_FORM_ENUM } from '../actions/action.utils';
import s from './ApproveDecisionModal.module.scss';
import { prepareOptions } from './approveDecisionModal.utils';

interface Props {
  form: FormInstance;
  isOpen: boolean;
  onCancel: () => void;
  handleApprove: () => void;
  suggestionId?: string;
  isLoading?: boolean;
}

const ApproveDecisionModal = (props: Props) => {
  const { form, isOpen, handleApprove, onCancel, suggestionId, isLoading } = props;

  const { data: performers, isLoading: isPerformersLoading } =
    useGetSuggestionDecisionPerformersQuery(
      {
        id: suggestionId!,
        page: 1,
        size: 500,
      },
      { skip: !suggestionId },
    );

  return (
    <ModalDefault open={isOpen} onCancel={onCancel} closable>
      <ModalDefault.Icon name="ok" type="primary" />
      <ModalDefault.Title>{t('suggestions_decision_approve_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('suggestions_decision_approve_description')}
      </ModalDefault.Description>

      <Form form={form} className={s.form}>
        <Form.Item name={APPROVE_REJECT_DECISION_FORM_ENUM.PERFORMER} rules={[formRules.required]}>
          <Select
            className={s.select}
            loading={isPerformersLoading}
            showSearch
            placeholder={t('common_select_second')}
            options={prepareOptions(performers?.items)}
          />
        </Form.Item>

        <ModalDefault.Actions>
          <Flex gap="16">
            <Button fullWidth leftIcon="return" onClick={handleApprove} loading={isLoading}>
              {t('suggestions_decision_take_for_release')}
            </Button>
            <Button danger ghost fullWidth onClick={onCancel}>
              {t('common_close')}
            </Button>
          </Flex>
        </ModalDefault.Actions>
      </Form>
    </ModalDefault>
  );
};

export default ApproveDecisionModal;
