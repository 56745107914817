import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { SUGGESTIONS_COMMISSION_ROUTE } from 'routes/suggestions/list';
import { t } from 'tools/i18n';

import { useGetExpertCommissionByIdQuery } from 'services/expert-commissions/expertCommissionsApiService';

import { Button, Flex, Tabs } from 'gazprom-ui-lib';

import { EXPERT_COMMISSION_FORM_NAME } from 'containers/expert-commission-form/expertCommissionForm.utils';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './SuggestionsCommissionsDetails.module.scss';
import Header from './containers/header';
import { SUGGESTIONS_COMMISSIONS_DETAILS_TAB, TABS } from './suggestionsCommissionsDetails.utils';

const SuggestionsCommissionsDetails = () => {
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();

  const [activeTab, setActiveTab] = useState<string>(SUGGESTIONS_COMMISSIONS_DETAILS_TAB);

  const handleTabChange = (e: string) => {
    setActiveTab(e);
  };

  const handleClose = () => {
    navigate(SUGGESTIONS_COMMISSION_ROUTE);
  };

  const { data: expertCommission, isLoading } = useGetExpertCommissionByIdQuery(
    { id: params?.id! },
    {
      skip: !params?.id,
    },
  );

  const { name, updatedAt, createdAt, editor } = expertCommission ?? {};

  const isDetailsTab = activeTab === SUGGESTIONS_COMMISSIONS_DETAILS_TAB;

  return (
    <WithOverflow>
      <Header name={name} updatedAt={updatedAt ?? createdAt} editorId={editor} />
      <WithLoader isLoading={isLoading}>
        <Flex vertical gap="16" className={s.wrapper}>
          <Tabs items={TABS} type="card" onChange={handleTabChange} activeKey={activeTab} />

          <Flex className={s.actions} align="center" gap="16">
            {isDetailsTab && (
              <Button form={EXPERT_COMMISSION_FORM_NAME} htmlType="submit" fullWidth leftIcon="ok">
                {t('common_save')}
              </Button>
            )}
            <Button onClick={handleClose} type="secondary" fullWidth>
              {t('common_close')}
            </Button>
          </Flex>
        </Flex>
      </WithLoader>
    </WithOverflow>
  );
};

export default SuggestionsCommissionsDetails;
