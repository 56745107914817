import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { handleTableChange } from 'utils/table.utils';

import { GetKedoReviewTaskAssignmentsPropsType } from 'services/kedo-review-tasks/kedoReviewTasksApiService.types';

import { InputProps, Table } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { REVIEW_TASK_TYPES_ENUM } from 'types/review-task.types';

import s from './DocumentsKedoReviewTaskAssignments.module.scss';
import Filters from './containers/filters';
import { DEFAULT_REQUEST_BODY, RequestBodyType } from './documentsKedoReviewAssignments.utils';
import useColumns from './useColumnsHook';
import useRequestHook from './useRequest.hook';

interface DocumentsKedoReviewTaskAssignmentsProps {
  taskStatus?: REVIEW_TASK_TYPES_ENUM;
  needEmployeeSign?: boolean;
  setAssignmentsCount: Dispatch<SetStateAction<number>>;
}

const DocumentsKedoReviewTaskAssignments: FC<DocumentsKedoReviewTaskAssignmentsProps> = (props) => {
  const params = useParams<{ id: string }>();

  const { taskStatus, needEmployeeSign, setAssignmentsCount } = props;

  const [requestBody, setRequestBody] = useState<RequestBodyType>(DEFAULT_REQUEST_BODY);
  const [filters, setFilters] = useState<Pick<
    GetKedoReviewTaskAssignmentsPropsType,
    'status'
  > | null>(null);

  const onFiltersSubmit = (values: Record<string, string[]> | null) => {
    setFilters(values);
    setRequestBody((prevState) => ({ ...prevState, page: 1 }));
  };

  const {
    kedoReviewTaskAssignments,
    isKedoReviewTaskAssignmentsLoading,
    isKedoReviewTaskAssignmentsFetching,
  } = useRequestHook({ requestBody, kedoReviewTaskAssignmentsId: params?.id, filters });

  useEffect(() => {
    if (kedoReviewTaskAssignments) {
      setAssignmentsCount(kedoReviewTaskAssignments.totalCount);
    }
  }, [kedoReviewTaskAssignments, setAssignmentsCount]);

  const columns = useColumns({ needEmployeeSign });

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  return (
    <WithLoader isLoading={isKedoReviewTaskAssignmentsLoading}>
      <Filters
        filters={filters}
        setFilters={onFiltersSubmit}
        taskStatus={taskStatus}
        search={requestBody.search}
        handleSearchChange={handleSearchChange}
      />
      <Table
        loading={isKedoReviewTaskAssignmentsFetching}
        onChange={handleTableChange(setRequestBody)}
        className={s.table}
        dataSource={kedoReviewTaskAssignments?.items}
        pagination={{
          defaultCurrent: requestBody.page,
          defaultPageSize: requestBody.size,
          total: kedoReviewTaskAssignments?.totalCount,
        }}
        columns={columns}
      />
    </WithLoader>
  );
};

export default DocumentsKedoReviewTaskAssignments;
