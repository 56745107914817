import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetDeclarationTypeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import DocumentsDeclarationsFormCheck from 'containers/documents-declarations-form/documents-declarations-form-check';
import DocumentsDeclarationsFormTextPlain from 'containers/documents-declarations-form/documents-declarations-form-text-plain';
import { DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import DocumentsDeclarationsFormBpmnProcess from 'containers/documents-declarations-form/documnets-declarations-form-bpnm-process/documentsDeclarationsFormBpmnProcess';
import useBPMNProcesses from 'containers/documents-declarations-form/useBPMNProcess.hook';
import WithLoader from 'containers/wrappers/with-loader';

import { DeclarationParamType, PROCESS_PARAM_TYPE, ProcessParamType } from 'types/documents.types';
import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import DocumentsDeclarationsButtons from '../documents-declarations-buttons';
import s from './DocumentsDeclarationsParameters.module.scss';
import {
  CANCEL_ORDERS_PROCESS,
  DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM,
  DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME,
} from './documentsDeclarationsParameters.utils';
import useParametersFormSubmitHandler from './useParametersFormSubmitHandler.hook';

const DocumentsDeclarationsParameters = () => {
  const { id } = useParams<{ id: string }>();
  const { dataObject, isLoading: bpmnIsLoading } = useBPMNProcesses();
  const { data: declarationData, isLoading: declarationDataIsLoading } = useGetDeclarationTypeQuery(
    id ?? skipToken,
  );

  const [form] = Form.useForm();
  const bpmnProcess = Form.useWatch(DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS, form);
  const processParameters = dataObject[bpmnProcess]?.params;
  const cancelProcessParameters = dataObject[CANCEL_ORDERS_PROCESS]?.params ?? [];

  const showIsCancellableParameter = declarationData?.type === KEDO_TYPE_ENUM.BPMN;
  const showHasDigitalForm = declarationData?.type === KEDO_TYPE_ENUM.KEDO;
  const showBpmnProcess = declarationData?.type !== KEDO_TYPE_ENUM.KEDO;

  const isCancellable = Form.useWatch(
    DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.IS_CANCELABLE,
    form,
  );

  const hasDigitalForm = Form.useWatch(
    DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.HAS_DIGITAL_FORM,
    form,
  );

  const { isLoading, handleFormSubmit } = useParametersFormSubmitHandler({
    isCancellable,
    hasDigitalForm,
    declarationData,
  });

  const renderComponent = (params: ProcessParamType) => {
    const { type, code, name } = params;
    const fieldName = `${code}.${type}`;
    const findCode = (el: DeclarationParamType) => el.code === code;
    const currentParam = declarationData?.params.find(findCode);
    const defaultValue = currentParam?.value;

    if (type === PROCESS_PARAM_TYPE.STRING) {
      return (
        <DocumentsDeclarationsFormTextPlain
          name={fieldName}
          title={name}
          key={`${type}.${code}.${name}`}
          defaultValue={defaultValue as string}
        />
      );
    }

    return (
      <DocumentsDeclarationsFormCheck
        label={name}
        formName={fieldName}
        className={s.check}
        key={`${type}.${code}.${name}`}
        defaultValue={defaultValue === 'true'}
      />
    );
  };

  return (
    <WithLoader isLoading={bpmnIsLoading || declarationDataIsLoading}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        id={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_NAME}>
        <div className={s.container}>
          {showBpmnProcess && (
            <DocumentsDeclarationsFormBpmnProcess defaultValue={declarationData?.bpmnProcess} />
          )}
          {processParameters && (
            <>
              <Typography.Title level={4} className={s.title}>
                {t('documents_declarations_parameters_title')}
              </Typography.Title>
              {processParameters.map(renderComponent)}
            </>
          )}
          {showIsCancellableParameter && (
            <Form.Item
              initialValue={declarationData?.isCancellable}
              name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.IS_CANCELABLE}
              valuePropName="checked">
              <Checkbox>{t('documents_declarations_parameters_cancel_title')}</Checkbox>
            </Form.Item>
          )}
          {isCancellable && cancelProcessParameters?.map(renderComponent)}
          {showHasDigitalForm && (
            <Form.Item
              initialValue={declarationData?.hasDigitalForm}
              name={DOCUMENTS_DECLARATIONS_EDO_PARAMETERS_FORM_ENUM.HAS_DIGITAL_FORM}
              valuePropName="checked">
              <Checkbox>{t('documents_declarations_parameters_digital_title')}</Checkbox>
            </Form.Item>
          )}
        </div>
        <DocumentsDeclarationsButtons isFetching={isLoading} />
      </Form>
    </WithLoader>
  );
};

export default DocumentsDeclarationsParameters;
