import Events from 'pages/events';
import EventsAdd from 'pages/events/events-add';
import EventsDetails from 'pages/events/events-details';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import { EVENTS_ROUTE, EVENT_ADD_EVENT_ROUTE, EVENT_DETAILS_ROUTE } from './list';

export const eventsRoutes: RouteConfig[] = [
  {
    path: EVENTS_ROUTE,
    element: <Events />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_NEWS,
      GLOBAL_ROLES.MODERATOR_NEWS_ORG,
    ],
  },
  {
    path: EVENT_ADD_EVENT_ROUTE,
    element: <EventsAdd />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_NEWS,
      GLOBAL_ROLES.MODERATOR_NEWS_ORG,
    ],
  },
  {
    path: EVENT_DETAILS_ROUTE,
    element: <EventsDetails />,
    roles: [
      GLOBAL_ROLES.ADMIN,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.MODERATOR_NEWS,
      GLOBAL_ROLES.MODERATOR_NEWS_ORG,
    ],
  },
];
