import { FC, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE,
  getKedoReviewTaskWithIdRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useCreateKedoReviewTaskMutation } from 'services/kedo-review-tasks/kedoReviewTasksApiService';
import { CreateKedoReviewTaskPropsType } from 'services/kedo-review-tasks/kedoReviewTasksApiService.types';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import KedoReviewTaskForm from 'containers/kedo-review-task-form';
import {
  KEDO_REVIEW_TASK_FORM_ENUM,
  KEDO_REVIEW_TASK_FORM_NAME,
  KedoReviewTaskFormResponse,
} from 'containers/kedo-review-task-form/kedoReviewTaskForm.utils';
import WithOverflow from 'containers/wrappers/with-overflow';

import { KedoReviewTaskType } from 'types/review-task.types';

import { prepareKedoReviewFile } from '../documents-kedo-review-task-details/documentsKedoReviewTaskDetails.utils';
import s from './DocumentsKedoReviewTaskCreate.module.scss';

const DocumentsKedoReviewTaskCreate: FC = () => {
  const navigate = useNavigate();

  const kedoReviewTaskIdRef = useRef<string>(crypto.randomUUID());

  const [createKedoReviewTask, { isLoading }] = useCreateKedoReviewTaskMutation();

  const handleGoBack = () => navigate(DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE);

  const handleNavigateDetails = (data?: KedoReviewTaskType) => {
    if (data) {
      navigate(getKedoReviewTaskWithIdRoute(data.id));
    }
  };

  const handleCreate = useHandleRequestWithModal({
    onSuccess: handleNavigateDetails,
    onSuccessModalConfig: {
      title: {
        children: t('common_saved_successfully'),
      },
    },
    onErrorModalConfig: {
      title: {
        children: t('common_error'),
      },
      icon: {
        name: 'error',
        type: 'warning',
      },
    },
  });

  const handleSubmit = (form: KedoReviewTaskFormResponse) => {
    const preparedFile = prepareKedoReviewFile(form[KEDO_REVIEW_TASK_FORM_ENUM.FILE]);

    const requestBody: CreateKedoReviewTaskPropsType = {
      id: kedoReviewTaskIdRef.current,
      name: form[KEDO_REVIEW_TASK_FORM_ENUM.NAME],
      docName: form[KEDO_REVIEW_TASK_FORM_ENUM.DOC_NAME],
      files: [preparedFile],
      employeeGroupIds: form[KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEE_GROUPS],
      needEmployeeSign: form[KEDO_REVIEW_TASK_FORM_ENUM.SIGN_REQUIRED],
    };

    createKedoReviewTask(requestBody).then(handleCreate);
  };

  return (
    <WithOverflow>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title goBackLink={DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE}>
          {t('kedo_review_tasks_create_title')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button
            leftIcon="ok"
            htmlType="submit"
            loading={isLoading}
            form={KEDO_REVIEW_TASK_FORM_NAME}>
            {t('common_save')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <div className={s.content}>
        <KedoReviewTaskForm
          kedoReviewTaskId={kedoReviewTaskIdRef.current}
          onSubmit={handleSubmit}
        />
        <div className={s.actions}>
          <Button
            fullWidth
            type="primary"
            leftIcon="ok"
            htmlType="submit"
            loading={isLoading}
            form={KEDO_REVIEW_TASK_FORM_NAME}>
            {t('common_save')}
          </Button>
          <Button fullWidth type="secondary" onClick={handleGoBack}>
            {t('common_close')}
          </Button>
        </div>
      </div>
    </WithOverflow>
  );
};

export default DocumentsKedoReviewTaskCreate;
