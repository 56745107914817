import { FC, useState } from 'react';

import cn from 'classnames';

import { Tabs as TabsLib, TabsProps as TabsPropsLib } from 'gazprom-ui-lib';

import s from './Tabs.module.scss';
import { TabsTypes } from './tabs.utils';

interface TabsProps extends TabsPropsLib {}

const Tabs: FC<TabsProps> = (props) => {
  const { items, className } = props;

  const [activeTab, setActiveTab] = useState<TabsTypes>('attached');

  return (
    <TabsLib
      items={items}
      type="card"
      onTabClick={(key) => setActiveTab(key as TabsTypes)}
      activeKey={activeTab}
      className={cn(s.wrapper, className)}
    />
  );
};

export default Tabs;
