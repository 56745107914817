import { t } from 'tools/i18n';

import { Icon, TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';

import { BypassSheetType } from 'types/bypassSheet.types';

const useColumns = (): TableProps<BypassSheetType>['columns'] => {
  const employeeRenderer = (employee: BypassSheetType['employee']) => {
    const { person } = employee;

    return (
      <Typography.Text size="14" weight="500">
        {person.lastName} {person.firstName} {person.patronymic}
      </Typography.Text>
    );
  };

  const positionNameRenderer = (_: unknown, row: BypassSheetType) => {
    return (
      <Typography.Text size="14" weight="500">
        {row.employee.position.name}
      </Typography.Text>
    );
  };

  const dismissDateRenderer = (date: string) => {
    return <TableDate date={date} hideTime />;
  };

  const statusRenderer = (status: string) => {
    return (
      <Icon
        name="okFilled"
        color={`var(${status === 'DONE' ? '--color-success' : '--color-placeholder-25'})`}
      />
    );
  };

  return [
    {
      title: t('bypass_sheet_resigning_employee'),
      dataIndex: 'employee',
      align: 'left',
      render: employeeRenderer,
    },
    {
      title: t('common_staffName'),
      dataIndex: 'positionName',
      align: 'left',
      width: 300,
      render: positionNameRenderer,
    },
    {
      title: t('bypass_sheet_dismissal_date'),
      dataIndex: 'dismissDate',
      align: 'center',
      sorter: true,
      width: 180,
      defaultSortOrder: 'descend',
      render: dismissDateRenderer,
    },
    {
      title: t('common_signing'),
      dataIndex: 'status',
      align: 'center',
      width: 180,
      render: statusRenderer,
    },
  ];
};

export default useColumns;
