import { Icon, Tag } from 'gazprom-ui-lib';

import s from './IsPublish.module.scss';
import { activeConfig, neutralConfig } from './isPublish.utils';

interface Props {
  isActive: boolean;
}

const IsPublish = (props: Props) => {
  const { isActive } = props;
  const config = isActive ? activeConfig : neutralConfig;
  const { icon, type } = config;

  return (
    <Tag type={type} className={s.container}>
      <Icon name={icon} size={16} />
    </Tag>
  );
};

export default IsPublish;
