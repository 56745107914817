import { Dispatch, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { ButtonProps } from 'gazprom-ui-lib';

import { MODALS } from './thankYouTable.utils';

interface Props {
  setIsModalOpen: Dispatch<SetStateAction<MODALS>>;
  handleDownloadClick: () => void;
  removeSelection: () => void;
}

const useActions = (props: Props): ButtonProps[] => {
  const { setIsModalOpen, removeSelection, handleDownloadClick } = props;

  return [
    {
      children: t('news_deselect'),
      leftIcon: 'delete',
      onClick: removeSelection,
    },
    {
      children: t('thank_you_download_report'),
      leftIcon: 'calendar',
      onClick: handleDownloadClick,
    },
    {
      children: t('common_remove'),
      danger: true,
      leftIcon: 'radar',
      onClick: () => setIsModalOpen(MODALS.REMOVE),
    },
  ];
};

export default useActions;
