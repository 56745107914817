import ThankYou from 'pages/thank-you';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import { THANK_YOU_ROUTE } from './list';

export const thankYouRoutes: RouteConfig[] = [
  {
    path: THANK_YOU_ROUTE,
    element: <ThankYou />,
    roles: [GLOBAL_ROLES.MODERATOR, GLOBAL_ROLES.ADMIN_MOBILE, GLOBAL_ROLES.ADMIN],
  },
];
