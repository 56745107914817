import { t } from 'tools/i18n';

import { TableProps } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { CurrentKedoStatusType } from 'types/documents.types';
import { RecruitType } from 'types/recruit.types';

const useColumns = (): TableProps<RecruitType>['columns'] => {
  const personRenderer = (person: RecruitType['person']) => {
    const { firstName, lastName } = person;

    return <TablePerson firstName={firstName} lastName={lastName} position={null} />;
  };

  const statusRenderer = (status: CurrentKedoStatusType) => {
    return <TableStatus status={status.code as STATUSES}>{status.title}</TableStatus>;
  };

  const createdUpdatedRenderer = (date: 'string') => {
    return <TableDate date={date} />;
  };

  return [
    {
      title: t('common_employee'),
      dataIndex: 'person',
      align: 'left',
      render: personRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      align: 'left',
      width: 300,
      render: statusRenderer,
    },
    {
      title: t('common_created'),
      dataIndex: 'createdAt',
      align: 'center',
      sorter: true,
      width: 180,
      defaultSortOrder: 'descend',
      render: createdUpdatedRenderer,
    },
    {
      title: t('common_issued'),
      dataIndex: 'finishDate',
      align: 'center',
      sorter: true,
      width: 180,
      render: createdUpdatedRenderer,
    },
  ];
};

export default useColumns;
