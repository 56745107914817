import { createSlice } from '@reduxjs/toolkit';

import { QoaParamType } from 'types/surveys.types';

export const SLICE_NAME = 'surveySlice';
export type SurveysSliceState = {
  qoa: QoaParamType[];
};

const initialState: SurveysSliceState = {
  qoa: [],
};

export const surveysSlice = createSlice({
  name: 'surveySlice',
  initialState,
  reducers: {
    setQoa(state, action: { payload: QoaParamType }) {
      const newState = [...state.qoa, action.payload];
      state.qoa = newState;
    },
    removeQoaFromFree(state, action: { payload: number }) {
      const newState = state.qoa.filter((qoa) => qoa.qId !== action.payload);
      state.qoa = newState;
    },
    resetQoa(state) {
      state.qoa = initialState.qoa;
    },
  },
});

export const { setQoa, removeQoaFromFree, resetQoa } = surveysSlice.actions;

export default surveysSlice.reducer;
