import { useState } from 'react';

import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetEmployersListQuery } from 'services/employer/employerApiService';
import { useGetRolesQuery } from 'services/roles/rolesApiService';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterHeader from 'containers/filters/filter-header';

import { MAX_EMPLOYERS_PER_PAGE } from '../../accessControlUsers.utils';
import { selectFromResultEmployers, selectFromResultRoles } from './filters.utils';

const useGetCollapseItems = () => {
  const [employer, setEmployer] = useState<string>();

  const { debouncedValue: debouncedEmployer } = useDebounce(employer);

  const { data: roleOptions = [] } = useGetRolesQuery(undefined, {
    selectFromResult: selectFromResultRoles,
  });
  const { data: employersOptions } = useGetEmployersListQuery(
    { page: 1, size: MAX_EMPLOYERS_PER_PAGE, name: debouncedEmployer },
    { selectFromResult: selectFromResultEmployers },
  );

  return [
    {
      key: 'roleIds',
      label: <FilterHeader valueKey="roleIds">{t('common_roles')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          showSearch
          optionFilterProp="label"
          valueKey="roleIds"
          options={roleOptions}
        />
      ),
    },
    {
      key: 'employerIds',
      label: <FilterHeader valueKey="employerIds">{t('common_employers')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          showSearch
          optionFilterProp="label"
          valueKey="employerIds"
          searchProps={{
            placeholder: t('common_employers'),
            value: employer,
            onChange: (e) => setEmployer(e.target.value || undefined),
          }}
          options={employersOptions}
        />
      ),
    },
  ];
};

export default useGetCollapseItems;
