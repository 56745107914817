import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  open: boolean;
  onCancel: () => void;
  onRemove: () => void;
}

const ModalRemove = (props: Props) => {
  const { open, onCancel, onRemove } = props;

  return (
    <ModalDefault open={open} closable width={320} onCancel={onCancel}>
      <ModalDefault.Icon name="binFilled" type="danger" />
      <ModalDefault.Title>{t('common_accept_remove')}</ModalDefault.Title>
      <ModalDefault.Description>{t('than_you_remove_selected_feedbacks')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" onClick={onRemove}>
          {t('common_remove')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ModalRemove;
