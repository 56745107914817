import { Dayjs } from 'dayjs';
import { t } from 'tools/i18n';

import { EVENT_GENDER } from 'types/event.types';

export const EVENT_FORM_NAME = 'EVENT_FORM_NAME';
export const MAX_CATEGORIES_PER_PAGE = 500;

export enum EVENT_FORM_ENUM {
  CATEGORIES = 'CATEGORIES',
  DESCRIPTION = 'DESCRIPTION',
  COVER = 'COVER',
  CITY = 'CITY',
  LOCATION = 'LOCATION',
  DOCS = 'DOCS',
  EVENT_DATE = 'EVENT_DATE',
  EVENT_TIME = 'EVENT_TIME',
  PARTICIPANTS = 'PARTICIPANTS',
  UNLIMITED_PARTICIPATION = 'UNLIMITED_PARTICIPATION',
  REGISTRATION_AVAILABLE = 'REGISTRATION_AVAILABLE',
  TITLE = 'TITLE',

  SEND_NOTIFICATION = 'SEND_NOTIFICATION',
  NOTIFICATION_TITLE = 'NOTIFICATION_TITLE',
  NOTIFICATION_TEXT = 'NOTIFICATION_TEXT',
  TARGET_GROUP_GENDER = 'TARGET_GROUP_GENDER',
  TARGET_GROUP = 'TARGET_GROUP',
  INITIAL_TARGET_TREE = 'INITIAL_TARGET_TREE',

  // ORGANIZATIONS = 'ORGANIZATIONS',
  // GROUPS = 'GROUPS',
  // MAIL = 'EMAIL',
}

export const FORM_LABELS: Record<EVENT_FORM_ENUM, string> = {
  [EVENT_FORM_ENUM.CATEGORIES]: t('events_category'),
  [EVENT_FORM_ENUM.DESCRIPTION]: t('events_description'),
  [EVENT_FORM_ENUM.COVER]: t('events_cover'),
  [EVENT_FORM_ENUM.EVENT_DATE]: t('common_date_and_time'),
  [EVENT_FORM_ENUM.EVENT_TIME]: t(' '),
  [EVENT_FORM_ENUM.INITIAL_TARGET_TREE]: t(' '),
  [EVENT_FORM_ENUM.TARGET_GROUP]: t('events_target_group'),
  [EVENT_FORM_ENUM.CITY]: t('events_city'),
  [EVENT_FORM_ENUM.LOCATION]: t('events_location'),
  [EVENT_FORM_ENUM.PARTICIPANTS]: t('events_participants'),
  [EVENT_FORM_ENUM.UNLIMITED_PARTICIPATION]: t('events_unlimited_participation'),
  [EVENT_FORM_ENUM.REGISTRATION_AVAILABLE]: t('events_registration_available'),
  [EVENT_FORM_ENUM.TITLE]: t('events_title'),
  [EVENT_FORM_ENUM.DOCS]: t('common_docs_required'),
  [EVENT_FORM_ENUM.TARGET_GROUP_GENDER]: t('events_target_group'),
  [EVENT_FORM_ENUM.SEND_NOTIFICATION]: t('events_notification_send'),
  [EVENT_FORM_ENUM.NOTIFICATION_TITLE]: t('events_notification_theme'),
  [EVENT_FORM_ENUM.NOTIFICATION_TEXT]: t('events_notification_text'),
};

export interface EventFormResponse {
  [EVENT_FORM_ENUM.TITLE]: string;
  [EVENT_FORM_ENUM.DESCRIPTION]: string;
  [EVENT_FORM_ENUM.COVER]: string;
  [EVENT_FORM_ENUM.CATEGORIES]: string[];
  [EVENT_FORM_ENUM.EVENT_DATE]?: Dayjs;
  [EVENT_FORM_ENUM.EVENT_TIME]?: Dayjs;
  [EVENT_FORM_ENUM.CITY]: string;
  [EVENT_FORM_ENUM.LOCATION]: string;
  [EVENT_FORM_ENUM.PARTICIPANTS]?: string;
  [EVENT_FORM_ENUM.UNLIMITED_PARTICIPATION]?: boolean;
  [EVENT_FORM_ENUM.REGISTRATION_AVAILABLE]?: boolean;
  [EVENT_FORM_ENUM.DOCS]?: boolean;
  [EVENT_FORM_ENUM.TARGET_GROUP_GENDER]: EVENT_GENDER;
  [EVENT_FORM_ENUM.TARGET_GROUP]: {
    checked: string[];
    halfChecked: string[];
  };
  [EVENT_FORM_ENUM.NOTIFICATION_TITLE]?: string;
  [EVENT_FORM_ENUM.NOTIFICATION_TEXT]?: string;
  [EVENT_FORM_ENUM.SEND_NOTIFICATION]?: boolean;
}

export const EVENT_MAIN_FILED_LIST: EVENT_FORM_ENUM[] = [
  EVENT_FORM_ENUM.TITLE,
  EVENT_FORM_ENUM.DESCRIPTION,
  EVENT_FORM_ENUM.COVER,
  EVENT_FORM_ENUM.CATEGORIES,
  EVENT_FORM_ENUM.EVENT_DATE,
  EVENT_FORM_ENUM.EVENT_TIME,
  EVENT_FORM_ENUM.CITY,
  EVENT_FORM_ENUM.LOCATION,
  EVENT_FORM_ENUM.PARTICIPANTS,
  EVENT_FORM_ENUM.UNLIMITED_PARTICIPATION,
  EVENT_FORM_ENUM.REGISTRATION_AVAILABLE,
  EVENT_FORM_ENUM.DOCS,
];
