import { Dispatch, SetStateAction } from 'react';

import { useParams } from 'react-router-dom';
import { prepareDateAndTime } from 'utils/date.utils';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import {
  usePublishEventMutation,
  useUnpublishEventMutation,
  useUpdateEventMutation,
} from 'services/meet/meetApiService';
import { UpdateEventPropsType } from 'services/meet/meetApiService.types';

import { FormInstance, handleRequest } from 'gazprom-ui-lib';

import {
  EVENT_FORM_ENUM,
  EventFormResponse,
  FORM_LABELS,
} from 'containers/event-form/eventForm.utils';

import { ARTICLE_TYPE_ENUM } from 'types/article.types';
import { EventType } from 'types/event.types';

interface UseHandleUpdateProps {
  data?: EventType;
  form: FormInstance;
  setIsModalOpened: Dispatch<SetStateAction<boolean>>;
  setErrorFields: Dispatch<SetStateAction<string>>;
}

const useHandleUpdate = (props: UseHandleUpdateProps) => {
  const { data, form, setErrorFields, setIsModalOpened } = props;

  const params = useParams<{ eventId: string }>();

  const [updateEvent, { isLoading: updateEventIsFetching }] = useUpdateEventMutation();
  const [publishEvent, { isLoading: publishEventIsFetching }] = usePublishEventMutation();
  const [unpublishEvent, { isLoading: unpublishEventFetching }] = useUnpublishEventMutation();

  const handleValidate = (onSuccess: (formResponse: EventFormResponse) => void) => {
    return form
      .validateFields()
      .then(onSuccess)
      .catch((err) => {
        if ('errorFields' in err) {
          setIsModalOpened(false);
          const errorFields = err.errorFields.map(
            (e: { name: EVENT_FORM_ENUM[] }) => FORM_LABELS[e.name[0]],
          );

          setErrorFields(errorFields.join(', '));
        } else {
          console.error(err);
        }

        return Promise.reject(err);
      });
  };

  const publishUnpublishEvent = async () => {
    const isPublish = !!data?.article?.isPublish;
    const id = data?.meeting?.id;

    if (id) {
      if (isPublish) {
        unpublishEvent(id).then(
          handleRequest({
            onSuccess: () => setIsModalOpened(false),
          }),
        );
      } else {
        await handleSave();
        setIsModalOpened(false);
        publishEvent(id).then();
      }
    }
  };

  const handleEdit = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });

  const handleSave = (): Promise<unknown> => {
    return handleValidate(handleSubmit);
  };

  const handleSubmit = (form: EventFormResponse): Promise<unknown> => {
    if (data) {
      const articleUpdateDto: UpdateEventPropsType['articleUpdateDto'] = {
        ...data.article,
        type: ARTICLE_TYPE_ENUM.EVENT,
        text: form[EVENT_FORM_ENUM.DESCRIPTION],
        title: form[EVENT_FORM_ENUM.TITLE],
        categories: form[EVENT_FORM_ENUM.CATEGORIES],
        cover: { link: form[EVENT_FORM_ENUM.COVER] },
      };

      const meetingUpdateDto: UpdateEventPropsType['meetingUpdateDto'] = {
        ...data.meeting,
        meetDate: prepareDateAndTime(
          form[EVENT_FORM_ENUM.EVENT_DATE],
          form[EVENT_FORM_ENUM.EVENT_TIME],
        )!,
        maxEmployees: form[EVENT_FORM_ENUM.UNLIMITED_PARTICIPATION]
          ? 0
          : Number(form[EVENT_FORM_ENUM.PARTICIPANTS]),
        city: form[EVENT_FORM_ENUM.CITY],
        location: form[EVENT_FORM_ENUM.LOCATION],
        requiresDocs: !!form[EVENT_FORM_ENUM.DOCS],
        registrationActive: !!form[EVENT_FORM_ENUM.REGISTRATION_AVAILABLE],
        gender: form[EVENT_FORM_ENUM.TARGET_GROUP_GENDER],
        group: {
          // filter in case when BE send invalid data
          partialChecked: form[EVENT_FORM_ENUM.TARGET_GROUP].halfChecked
            .filter((e) => !!e)
            .map(transformGroup),
          checked: form[EVENT_FORM_ENUM.TARGET_GROUP].checked
            .filter((e) => !!e)
            .map(transformGroup),
        },
        isNotify: !!form[EVENT_FORM_ENUM.SEND_NOTIFICATION],
      };

      if (form[EVENT_FORM_ENUM.SEND_NOTIFICATION]) {
        meetingUpdateDto.titleNotice = form[EVENT_FORM_ENUM.NOTIFICATION_TITLE];
        meetingUpdateDto.bodyNotice = form[EVENT_FORM_ENUM.NOTIFICATION_TEXT];
      }
      return updateEvent({ articleUpdateDto, meetingUpdateDto, id: params.eventId! }).then(
        handleEdit,
      );
    }

    return Promise.reject('No data available');
  };

  return {
    updateEventIsFetching:
      updateEventIsFetching || publishEventIsFetching || unpublishEventFetching,
    publishUnpublishEvent,
    handleValidate,
    handleSave,
  };
};

const transformGroup = (idWithType: string) => {
  const [id, type] = idWithType.split('.');

  return {
    id,
    type,
  };
};

export default useHandleUpdate;
