import { FC, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
  ACCESS_CONTROL_USERS_ROUTE,
  ACCESS_CONTROL_USER_ROLES_ROUTE,
} from 'routes/access-control/list';
import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import { formRules, normalizeTextField } from 'utils/formRules';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useGetRolesByCodeQuery, useUpdateRoleMutation } from 'services/roles/rolesApiService';

import { Button, Form, Input, TextArea, Typography } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import UploadImitation from 'components/upload-imitation';
import ImageModal from 'containers/image-modal';
import WithLoader from 'containers/wrappers/with-loader';

import s from './AccessControlRolesDetails.module.scss';
import { EDIT_ROLE_FORM_NAME, ROLE_FORM_ENUM } from './accessControlRolesDetails.utils';
import Header from './containers/header';

const AccessControlRolesDetails: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);

  const [updateRole, { isLoading }] = useUpdateRoleMutation();
  const { data: role, isFetching } = useGetRolesByCodeQuery(params.code!);

  const description = Form.useWatch(ROLE_FORM_ENUM.DESCRIPTION, form);
  const instruction = Form.useWatch(ROLE_FORM_ENUM.INSTRUCTION, form);

  useEffect(() => {
    if (role && form)
      form.setFieldsValue({
        [ROLE_FORM_ENUM.NAME]: role.name,
        [ROLE_FORM_ENUM.DESCRIPTION]: role.description,
        [ROLE_FORM_ENUM.INSTRUCTION]: role.instruction
          ? {
              fileKey: role.instruction?.fileKey,
              name: role.instruction?.name,
            }
          : null,
      });
  }, [role, form]);

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });
  const handleSubmit = (values: {
    name: string;
    description: string;
    instruction: { fileKey: string; name: string };
  }) => {
    if (role) {
      const updateRoleProps = {
        code: role.code,
        name: values.name,
        description: values.description,
        instruction: values.instruction,
      };

      updateRole(updateRoleProps).then(handleUpdate);
    }
  };

  const handleNavigateUsersList = () => {
    navigate(ACCESS_CONTROL_USERS_ROUTE, {
      state: {
        role: role?.code,
      },
    });
  };

  return (
    <WithLoader isLoading={isFetching}>
      <Header
        code={role?.code}
        updatedAt={role?.updatedAt}
        editor={role?.editor}
        isLoading={isLoading}
      />
      <div className={s.content}>
        <Form form={form} className={s.form} onFinish={handleSubmit} id={EDIT_ROLE_FORM_NAME}>
          <div>
            <div className={s.label}>
              <Typography.Title level={5}>{t('common_name')}</Typography.Title>
            </div>
            <Form.Item
              normalize={normalizeTextField}
              name={ROLE_FORM_ENUM.NAME}
              rules={[formRules.required, { max: 255 }]}>
              <Input placeholder={t('common_name')} size="middle" />
            </Form.Item>
          </div>
          <div>
            <div className={s.label}>
              <Typography.Title level={5}>{t('common_description')}</Typography.Title>
              <Typography.Text size="12" weight="400" type="secondary">
                {t('common_symbol_count', { current: description?.length ?? 0, total: 240 })}
              </Typography.Text>
            </div>
            <Form.Item
              normalize={normalizeTextField}
              name={ROLE_FORM_ENUM.DESCRIPTION}
              rules={[formRules.required, { max: 240 }]}>
              <TextArea placeholder={t('common_enter_text')} rows={4} maxLength={240} />
            </Form.Item>
          </div>
          <div>
            <div className={s.label}>
              <Typography.Title level={5}>{t('access_control_instruction')}</Typography.Title>
            </div>
            <Form.Item name={ROLE_FORM_ENUM.INSTRUCTION}>
              {instruction ? (
                <ImgPreview
                  iconName="image"
                  type="icon"
                  url={createMediaDownloadUrl(instruction.fileKey)}
                  title={instruction.name}
                  resetImage={() => {
                    form.setFieldValue(ROLE_FORM_ENUM.INSTRUCTION, null);
                  }}
                  showDownload
                />
              ) : (
                <UploadImitation
                  handleClick={() => setIsUploadModalVisible(true)}
                  title={t('common_upload_a_file')}
                />
              )}
            </Form.Item>
          </div>
          <div>
            <div className={s.label}>
              <Typography.Title level={5}>{t('common_users')}</Typography.Title>
            </div>
            <Button
              htmlType="button"
              leftIcon="groupsFilled"
              type="link"
              className={s.usersButton}
              size="small"
              onClick={handleNavigateUsersList}>
              {t('common_view_users_list')}
            </Button>
          </div>
          <div className={s.actions}>
            <Button leftIcon="ok" fullWidth type="primary" htmlType="submit" loading={isLoading}>
              {t('common_save')}
            </Button>
            <Button
              type="secondary"
              fullWidth
              onClick={() => {
                navigate(ACCESS_CONTROL_USER_ROLES_ROUTE);
              }}>
              {t('common_close')}
            </Button>
          </div>
        </Form>
      </div>
      <ImageModal
        isOpen={isUploadModalVisible}
        onSubmit={(fileKey, image) => {
          form.setFieldValue(ROLE_FORM_ENUM.INSTRUCTION, {
            fileKey,
            name: image.FULL_INFO.name,
          });
          setIsUploadModalVisible(false);
        }}
        onClose={() => setIsUploadModalVisible(false)}
      />
    </WithLoader>
  );
};

export default AccessControlRolesDetails;
