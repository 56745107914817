import { useParams } from 'react-router-dom';

import {
  usePostBPMNProcessMutation,
  useUpdateDeclarationTypesMutation,
} from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';

import { DeclarationType, PROCESS_PARAM_TYPE, ParamValueType } from 'types/documents.types';

import { EXCLUDED_PARAMETERS_KEYS } from './documentsDeclarationsParameters.utils';

interface useParametersFormSubmitHandlerProps {
  isCancellable?: boolean;
  hasDigitalForm?: boolean;
  declarationData?: DeclarationType;
}

//TODO: update logic of sending process parameters
const useParametersFormSubmitHandler = (props: useParametersFormSubmitHandlerProps) => {
  const { isCancellable, hasDigitalForm, declarationData } = props;

  const { id } = useParams();
  const [setParamsDeclaration, { isLoading: setParamsIsLoading }] = usePostBPMNProcessMutation();
  const [updateDeclaration, { isLoading: updateIsLoading }] = useUpdateDeclarationTypesMutation();

  return {
    isLoading: setParamsIsLoading || updateIsLoading,
    handleFormSubmit: (
      formValues: Record<DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS | string, string>,
    ) => {
      if (formValues[DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS]) {
        setParamsDeclaration({
          id: id!,
          body: {
            bpmnProcess: formValues[DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS],
            paramValues: getParamValues(formValues),
          },
        });
      }

      if (id && declarationData) {
        updateDeclaration({ id, body: { ...declarationData, isCancellable, hasDigitalForm } });
      }
    },
  };
};

const getParamValues = (
  formValues: Record<DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS | string, string>,
) => {
  const map = new Map<number, ParamValueType>();

  Object.keys(formValues).forEach((key, ind) => {
    const shouldAddParameterValue = !EXCLUDED_PARAMETERS_KEYS.includes(key);

    if (shouldAddParameterValue) {
      const [code, type] = key.split('.');
      const value = type === 'STRING' && !formValues[key]?.length ? null : formValues[key];

      const mapValue = {
        code,
        type: type as PROCESS_PARAM_TYPE,
        value: value as string | boolean | null,
      };

      map.set(ind, mapValue);
    }
  });

  return Array.from(map.values());
};

export default useParametersFormSubmitHandler;
