import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Modal, TextArea, Typography } from 'gazprom-ui-lib';

import s from './EditModal.module.scss';

interface EditModalProps {
  isOpen: boolean;
  handleClose: () => void;
  config: {
    value: string;
    handleSave: (value: string) => Promise<void>;
    title: string;
    label: string;
    length?: number;
    isLoading: boolean;
  };
}

const EditModal: FC<EditModalProps> = (props) => {
  const { isOpen, handleClose, config } = props;
  const { value, label, handleSave, title, length = 80, isLoading } = config;
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Modal open={isOpen} onCancel={handleClose} className={s.modal} closable>
      <div className={s.header}>
        <Typography.Title level={4}>{title}</Typography.Title>
      </div>

      <div className={s.body}>
        <div className={s.bodyTexts}>
          <Typography.Text size="14" weight="500" type="primary">
            {label}
          </Typography.Text>
          <Typography.Text size="12" type="secondary">
            {t('common_symbol_count', {
              current: localValue.length ?? 0,
              total: length,
            })}
          </Typography.Text>
        </div>
        <TextArea
          placeholder={t('common_enter_text')}
          value={localValue}
          maxLength={length}
          onChange={(e) => setLocalValue(e.target.value)}
        />
      </div>
      <div className={s.buttons}>
        <Button
          type="primary"
          leftIcon="ok"
          htmlType="button"
          disabled={localValue.length < 1}
          onClick={() => {
            handleSave(localValue).then(handleClose);
          }}
          loading={isLoading}>
          {t('common_save')}
        </Button>
        <Button type="secondary" onClick={handleClose}>
          {t('common_close')}
        </Button>
      </div>
    </Modal>
  );
};

export default EditModal;
