import { FC, useEffect } from 'react';

import { formRules } from 'utils/formRules';

import { Form, Input, Typography } from 'gazprom-ui-lib';

import s from './CommonInput.module.scss';

interface CommonInputProps {
  title: string;
  name: string;
  placeholder: string;
  maxWidth: number;
  initialValue?: string;
}

const CommonInput: FC<CommonInputProps> = (props) => {
  const { title, name, placeholder, maxWidth, initialValue } = props;
  const form = Form.useFormInstance();

  useEffect(() => {
    initialValue && form.setFieldValue(name, initialValue);
  }, [form, initialValue, name]);

  return (
    <div className={s.item}>
      <Typography.Title level={5}>{title}</Typography.Title>
      <Form.Item name={name} rules={[formRules.required]}>
        <Input placeholder={placeholder} max={maxWidth} />
      </Form.Item>
    </div>
  );
};

export default CommonInput;
