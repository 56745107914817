import { useNavigate } from 'react-router-dom';
import { EMPLOYEE_GROUPS_CREATE_ROUTE } from 'routes/employee-groups/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

const Header = () => {
  const navigate = useNavigate();

  const handleAdd = () => {
    navigate(EMPLOYEE_GROUPS_CREATE_ROUTE);
  };

  return (
    <ContentHeader>
      <ContentHeader.Title>{t('employee_groups_title')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="plus" onClick={handleAdd}>
          {t('employee_groups_add')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
