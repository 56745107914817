import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetGuideCategoriesQuery } from 'services/settlement/settlementApiService';
import { GetGuideCategoriesPropsType } from 'services/settlement/settlementApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './guideCategories.utils';

interface UseRequestProps {
  form: FormInstance<FormResponseType>;
}
const useRequest = (props: UseRequestProps) => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const [getCategories, { isFetching, data, isLoading, isUninitialized }] =
    useLazyGetGuideCategoriesQuery();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody) {
      const preparedBody: GetGuideCategoriesPropsType = {
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        name: debouncedRequestBody.search,
      };

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        preparedBody.sort = [
          {
            property: debouncedRequestBody.property,
            order: debouncedRequestBody.order,
          },
        ];
      }

      getCategories(preparedBody);
    }
  }, [debouncedRequestBody, getCategories]);

  return {
    isLoading: isLoading || isUninitialized,
    isFetching: isDebouncing || isFetching,
    data,
  };
};

export default useRequest;
