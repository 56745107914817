import { CreateExpertCommissionPropsType } from 'services/expert-commissions/expertCommissionsApiService.types';

import {
  EXPERT_COMMISSION_FORM_ENUM,
  ExpertCommissionFormResponseType,
} from 'containers/expert-commission-form/expertCommissionForm.utils';

export const prepareRequestBody = (
  values: ExpertCommissionFormResponseType,
): CreateExpertCommissionPropsType => ({
  name: values[EXPERT_COMMISSION_FORM_ENUM.NAME],
  headId: values[EXPERT_COMMISSION_FORM_ENUM.HEAD],
  employerId: values[EXPERT_COMMISSION_FORM_ENUM.ORGANIZATION],
  members: values[EXPERT_COMMISSION_FORM_ENUM.MEMBERS],
});
