import { Dispatch, SetStateAction, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { EMPLOYEE_GROUPS_ROUTE } from 'routes/employee-groups/list';
import { t } from 'tools/i18n';

import { GetEmployeeGroupByIdResponse } from 'services/employee-groups-new/employeeGroupsNewApiService.types';
import { useAppSelector } from 'store';

import { Button, Flex, Icon } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import LastChange from 'containers/last-change';

import DraftDescription from '../draft-description';
import PersonsCounter from '../persons-counter';
import s from './Header.module.scss';
import useHeader from './useHeader.hook';

interface HeaderProps {
  isButtonDisabled: boolean;
  setIsButtonDisabled: Dispatch<SetStateAction<boolean>>;
  setModalTitle: Dispatch<SetStateAction<string>>;
  groupData?: GetEmployeeGroupByIdResponse;
}

const Header = (props: HeaderProps) => {
  const { isButtonDisabled, groupData, setIsButtonDisabled, setModalTitle } = props;

  const { id } = useParams<{ id: string }>();

  const { isChanged } = useAppSelector((store) => store.employeeGroupsSlice);

  const { countValue, date, editorId, editorName, title, initialTitle } = useHeader({ groupData });

  const handleModalOpen = () => setModalTitle(initialTitle ?? '');

  const description = id ? (
    <Flex gap="16" align="center">
      <PersonsCounter value={countValue ?? 0} />
      <LastChange date={date} editorId={editorId} editorName={editorName} />
    </Flex>
  ) : (
    <Flex vertical>
      <DraftDescription />
      <PersonsCounter value={countValue} />
    </Flex>
  );

  useEffect(() => {
    if (isChanged) {
      setIsButtonDisabled(false);
    }
  }, [isChanged, setIsButtonDisabled]);

  return (
    <ContentHeader align="flex-start">
      <ContentHeader.Title goBackLink={EMPLOYEE_GROUPS_ROUTE} description={description}>
        {title}
        {id && (
          <button className={s.edit} type="button" onClick={handleModalOpen}>
            <Icon name="edit" />
          </button>
        )}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="ok" disabled={isButtonDisabled} htmlType="submit">
          {t('common_save')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
