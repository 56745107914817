import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { CurrentKedoStatusType } from 'types/documents.types';
import { TaskAssignmentType } from 'types/taskAssignment.types';

const useColumns = (): ColumnsType<TaskAssignmentType> => {
  const employeeRenderer = (employee: TaskAssignmentType['employee']) => {
    const { person, employer } = employee;
    const { firstName, lastName } = person;
    const { name } = employer;

    return <TablePerson firstName={firstName} lastName={lastName} department={name} />;
  };

  const titleRenderer = (title: TaskAssignmentType['title']) => {
    return (
      <Typography.Text size="12" weight="500">
        {title}
      </Typography.Text>
    );
  };
  const statusRenderer = (currentKedoStatus: TaskAssignmentType['status']) => {
    const { code, description, name, state } = currentKedoStatus;

    const currentKedoStatusImitation: CurrentKedoStatusType = {
      comment: null,
      createdAt: null,
      description,
      state,
      status: code,
      title: name,
      titleAlt: null,
    };

    return <KedoStatusTag currentKedoStatus={currentKedoStatusImitation} size="24" />;
  };

  const dateRenderer = (
    date: TaskAssignmentType['createdAt'] | TaskAssignmentType['finishDate'],
  ) => <TableDate date={date} />;
  return [
    {
      title: t('common_employee'),
      dataIndex: 'employee',
      render: employeeRenderer,
    },
    {
      title: t('common_document_name'),
      dataIndex: 'title',
      render: titleRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      width: 210,
      render: statusRenderer,
    },
    {
      title: t('common_created'),
      dataIndex: 'createdAt',
      align: 'center',
      render: dateRenderer,
      width: 130,
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: t('common_signed'),
      dataIndex: 'finishDate',
      align: 'center',
      render: dateRenderer,
      width: 130,
    },
  ];
};

export default useColumns;
