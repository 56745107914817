import { Fragment } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

export type FileType = {
  fileKey: string;
  fileName: string;
  signs:
    | null
    | {
        date: string;
        key: string;
        owner: string;
      }[];
};

export const renderSign = (sign: { date: string; key: string; owner: string }) => (
  <Fragment key={sign.key}>
    <Typography.Text size="12" type="secondary">
      {t('common_certificate_owner', { certificateOwner: sign.owner })}
    </Typography.Text>
    <Typography.Text size="12" type="secondary">
      {t('common_sign_date', { date: dayjs(sign.date).local().format('DD.MM.YYYY, hh:mm') })}
    </Typography.Text>
  </Fragment>
);
