import { Fragment, Suspense, lazy } from 'react';

import { MappedFreeQuestionType, QuestionStatsType, RequestBodyType } from 'types/surveys.types';

import { QUESTION_TYPES } from '../../statsTab.utils';

const SurveysSingleStatsMatrix = lazy(() => import('../stats-matrix'));
const SurveysSingleStatsFree = lazy(() => import('../stats-free'));
const SurveysSingleStatsScale = lazy(() => import('../stats-scale'));
const SurveysSingleStatsSelect = lazy(() => import('../stats-select'));

interface Props {
  question: QuestionStatsType | MappedFreeQuestionType;
  index: number;
  requestBody: RequestBodyType;
}

const Renderer = (props: Props) => {
  const { question, index, requestBody } = props;
  const { FREE, SCALE, MATRIX, SELECT } = QUESTION_TYPES;
  const currentIndex = index + 1;

  if (question.type === MATRIX) {
    const { title, rows } = question;

    return (
      <Suspense fallback={<Fragment />}>
        <SurveysSingleStatsMatrix title={title} rows={rows} number={currentIndex} />
      </Suspense>
    );
  }

  if (question.type === FREE) {
    return (
      <Suspense fallback={<Fragment />}>
        <SurveysSingleStatsFree
          {...(question as MappedFreeQuestionType)}
          number={currentIndex}
          requestBody={requestBody}
        />
      </Suspense>
    );
  }

  if (question.type === SCALE) {
    return (
      <Suspense fallback={<Fragment />}>
        <SurveysSingleStatsScale {...question} number={currentIndex} requestBody={requestBody} />
      </Suspense>
    );
  }

  if (question.type === SELECT) {
    const { title, options, questionId } = question;

    return (
      <Suspense fallback={<Fragment />}>
        <SurveysSingleStatsSelect
          title={title}
          options={options}
          number={currentIndex}
          questionId={questionId}
        />
      </Suspense>
    );
  }

  return null;
};

export default Renderer;
