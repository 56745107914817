import { t } from 'tools/i18n';

import { useGetEmployerSocialProgramQuery } from 'services/social-program-category/socialProgramCategoryApiService';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import OptionalLabel from 'components/optional-label';

import { FORM_ENUM } from '../../faqQuestionForm.utils';
import s from './Organization.module.scss';

const Organization = () => {
  const { data: employersOptions, isLoading: isEmployersOptionsLoading } =
    useGetEmployerSocialProgramQuery({
      page: 1,
      size: 500,
    });

  return (
    <Form.Item
      name={FORM_ENUM.ORGANIZATION}
      label={
        <OptionalLabel>
          <Typography.Title level={5}>{t('common_organization')}</Typography.Title>{' '}
        </OptionalLabel>
      }
      extra={
        <Typography.Text className={s.selectHint} size="12" type="secondary">
          {t('faq_organization_select_hint')}
        </Typography.Text>
      }>
      <Select
        allowClear
        placeholder={t('common_choose_organization_from_list')}
        options={employersOptions}
        loading={isEmployersOptionsLoading}
      />
    </Form.Item>
  );
};

export default Organization;
