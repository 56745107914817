import { t } from 'tools/i18n';

import { Flex, Modal, Typography } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';

import s from './DownloadProgressModal.module.scss';

interface Props {
  isOpen: boolean;
}

const DownloadProgressModal = (props: Props) => {
  const { isOpen } = props;

  return (
    <Modal open={isOpen}>
      <Flex vertical gap="16" align="center" className={s.loaderModal}>
        <UniversalLoader className={s.loader} />
        <Typography.Text size="14" type="secondary">
          {t('common_download_title')}
        </Typography.Text>
      </Flex>
    </Modal>
  );
};

export default DownloadProgressModal;
