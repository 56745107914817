import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetRecruitsQuery } from 'services/recruit/recruitApiService';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './documentsRecruits.utils';

interface UseGetDataProps {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProps) => {
  const { form } = props;

  const formData = Form.useWatch([], form);

  const { debouncedValue: debouncedFormData, isDebouncing } = useDebounce(formData);

  const { order, property } = debouncedFormData ?? {};

  const sort = order && property ? [{ order, property: `recruit.${property}` }] : undefined;

  const { data, isFetching, isLoading, isUninitialized } = useGetRecruitsQuery(
    debouncedFormData
      ? {
          sort,
          page: debouncedFormData.page,
          size: debouncedFormData.size,
        }
      : skipToken,
  );

  return {
    data,
    isLoading: isUninitialized || isLoading,
    isFetching: isFetching || isDebouncing,
  };
};

export default useGetData;
