import { useCallback, useState } from 'react';

import { t } from 'tools/i18n';

import { ImportChannelPropsType } from 'services/import/importApiService.types';

import { Button, Flex, Icon, TableProps, Typography } from 'gazprom-ui-lib';

import TableIsPublish from 'components/table/table-is-publish';
import TableText from 'components/table/table-text';
import UniversalLoader from 'components/universal-loader';

import { TelegramChannelType } from 'types/telegramChannel.types';

import s from './Table.module.scss';

type UseColumnsPropsType = {
  handleStartImport: (links: ImportChannelPropsType) => Promise<void>;
  importIsLoading: boolean;
  selectedChannels: TelegramChannelType['id'][];
  channels?: TelegramChannelType[];
};

const useColumns = (props: UseColumnsPropsType): TableProps<TelegramChannelType>['columns'] => {
  const { handleStartImport, importIsLoading, selectedChannels, channels } = props;

  const [importingChannels, setImportingChannels] = useState<ImportChannelPropsType>([]);

  const handleClear = () => setImportingChannels([]);

  const onClick = useCallback(
    (e: { stopPropagation: () => void }, link: ImportChannelPropsType) => {
      e.stopPropagation();
      setImportingChannels(link);
      handleStartImport(link).then(handleClear);
    },
    [handleStartImport],
  );

  const selectedImportingChannels =
    channels?.reduce((acc: TelegramChannelType['link'][], channel) => {
      if (selectedChannels.includes(channel.id)) {
        acc.push(channel.link);
      }
      return acc;
    }, []) ?? [];

  const selectedChannelsLink = [...selectedImportingChannels, ...importingChannels];

  return [
    {
      title: t('common_channels'),
      dataIndex: 'telegramTitle',
      key: 'telegramTitle',
      sorter: true,
      width: 240,
      render: (_, { link, telegramTitle }) => (
        <Flex gap="8" align="center">
          <Flex align="center" justify="center" className={s.icon}>
            <Icon size={24} name="telegram" />
          </Flex>
          <Flex vertical>
            <TableText size="12" type="secondary">
              {`@${link}`}
            </TableText>
            <TableText size="14" weight="500">
              {telegramTitle}
            </TableText>
          </Flex>
        </Flex>
      ),
    },
    {
      title: t('common_status'),
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      sorter: true,
      width: 80,
      render: (value: boolean) => <TableIsPublish isPublish={value} />,
    },
    {
      title: t('common_publish_category'),
      dataIndex: 'categories',
      key: 'categories',
      render: (categories: TelegramChannelType['categories']) => (
        <Typography.Text weight="500" size="14" type="secondary">
          {categories?.map(({ name }) => name).join(', ')}
        </Typography.Text>
      ),
    },
    {
      title: t('common_import'),
      dataIndex: 'link',
      key: 'link',
      width: 220,
      render: (link: TelegramChannelType['link']) => {
        const isLoading = importIsLoading && selectedChannelsLink?.includes(link);
        return (
          <Button
            onClick={(e) => onClick(e, [link])}
            size="small"
            type="link"
            leftIcon={isLoading ? undefined : 'importStart'}>
            {isLoading && <UniversalLoader className={s.loader} />}
            {t(isLoading ? 'common_importing' : 'common_start_import')}
          </Button>
        );
      },
    },
  ];
};

export default useColumns;
