import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
}

const ModalUnpublish = (props: Props) => {
  const { open, handleClose, handleSubmit, isLoading } = props;

  return (
    <ModalDefault open={open} onCancel={handleClose} width={320} closable>
      <ModalDefault.Icon name="error" type="danger" />
      <ModalDefault.Title>{t('events_unpublish_accept')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('events_unpublish_accept_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button fullWidth leftIcon="bin" loading={isLoading} onClick={handleSubmit}>
          {t('common_confirm')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ModalUnpublish;
