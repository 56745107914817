import { Fragment, Suspense, lazy } from 'react';

import { useDispatch } from 'react-redux';
import { t } from 'tools/i18n';

import { resetQoa } from 'slices/surveysSlice';

import { Button, Flex, Tooltip } from 'gazprom-ui-lib';

import DownloadProgressModal from 'components/download-progress-modal';
import RangePicker, { OnDateChangeValueType } from 'components/range-picker';
import CompanyFilters from 'containers/company-filters';
import WithLoader from 'containers/wrappers/with-loader';

import { MappedFreeQuestionType, QuestionStatsType } from 'types/surveys.types';

import s from './StatsTab.module.scss';
import Empty from './containers/empty';
import Renderer from './containers/renderer';
import { RequestBody } from './statsTab.utils';
import useStatsTab from './useStatsTab.hook';

const SurveysSingleRespondents = lazy(() => import('./containers/respondents'));

const StatsTab = () => {
  const dispatch = useDispatch();
  const {
    questionStatsResult,
    isDataLoading,
    requestBody,
    qoa,
    isDownloadingInProgress,
    setRequestBody,
    handleDownLoadReport,
  } = useStatsTab();

  const hasData = questionStatsResult.length > 0;

  const handleChange = (key: keyof RequestBody) => (e: string) => {
    setRequestBody((prevState) => ({ ...prevState, [key]: e }));
  };

  const handleCompanyChange = (e: string) => {
    setRequestBody((prevState) => ({ ...prevState, company: e, staff: null, division: null }));
  };

  const renderQuestions = (question: QuestionStatsType | MappedFreeQuestionType, index: number) => (
    <Renderer
      question={question}
      index={index}
      requestBody={requestBody}
      key={question.freeQuestionId + question.title}
    />
  );

  const onDateChange = (value: OnDateChangeValueType) => {
    const [from, to] = value;

    setRequestBody((prevState) => ({ ...prevState, from, to }));
  };

  return (
    <>
      <Flex gap="16" vertical align="flex-start" className={s.filters}>
        <Flex gap="16">
          <RangePicker onDateChange={onDateChange} />
          <Button
            type="link"
            leftIcon="download"
            className={s.download}
            onClick={handleDownLoadReport}>
            Скачать отчёт по опросу
          </Button>
        </Flex>

        <CompanyFilters
          company={requestBody.company}
          position={requestBody.staff}
          division={requestBody.division}
          handleCompanyChange={handleCompanyChange}
          handleDivisionChange={handleChange('division')}
          handleStaffChange={handleChange('staff')}
        />
      </Flex>
      <div className={s.container}>
        <WithLoader isLoading={isDataLoading}>
          {hasData ? questionStatsResult.map(renderQuestions) : <Empty />}
          {hasData && (
            <Suspense fallback={<Fragment />}>
              <SurveysSingleRespondents />
            </Suspense>
          )}
          {Boolean(qoa.length) && (
            <Tooltip
              placement="rightTop"
              title={t('surveys_remove_segments')}
              className={s.removeSegments}>
              <Button onlyIcon leftIcon="delete" onClick={() => dispatch(resetQoa())} />
            </Tooltip>
          )}
        </WithLoader>
      </div>
      <DownloadProgressModal isOpen={isDownloadingInProgress} />
    </>
  );
};

export default StatsTab;
