import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateTelegramChannelPropsType,
  CreateTelegramChannelResponseType,
  DeleteTelegramChannelPropsType,
  DeleteTelegramChannelResponseType,
  EditTelegramChannelPropsType,
  EditTelegramChannelResponseType,
  GetTelegramChannelByIdPropsType,
  GetTelegramChannelByIdResponseType,
  GetTelegramChannelPropsType,
  GetTelegramChannelResponseType,
} from './telegramChannelApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createTelegramChannelUrl } from './utils';

const telegramChannelApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getTelegramChannels: builder.query<GetTelegramChannelResponseType, GetTelegramChannelPropsType>(
      {
        query: (params) => ({
          url: createTelegramChannelUrl(prepareQueryParams(params)),
        }),
        providesTags: [SERVICE_TAGS.GET_TELEGRAM_CHANNELS],
      },
    ),
    getTelegramChannelById: builder.query<
      GetTelegramChannelByIdResponseType,
      GetTelegramChannelByIdPropsType
    >({
      query: (id) => ({
        url: createTelegramChannelUrl(`/${id}`),
      }),
      providesTags: [SERVICE_TAGS.GET_TELEGRAM_CHANNEL_BY_ID],
    }),
    createTelegramChannel: builder.mutation<
      CreateTelegramChannelResponseType,
      CreateTelegramChannelPropsType
    >({
      query: (body) => ({
        url: createTelegramChannelUrl(''),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_TELEGRAM_CHANNELS],
    }),
    editTelegramChannel: builder.mutation<
      EditTelegramChannelResponseType,
      EditTelegramChannelPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createTelegramChannelUrl(`/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        SERVICE_TAGS.GET_TELEGRAM_CHANNELS,
        SERVICE_TAGS.GET_TELEGRAM_CHANNEL_BY_ID,
      ],
    }),
    deleteTelegramChannel: builder.mutation<
      DeleteTelegramChannelResponseType,
      DeleteTelegramChannelPropsType
    >({
      query: (body) => ({
        url: createTelegramChannelUrl(''),
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_TELEGRAM_CHANNELS],
    }),
  }),
});

export const {
  useGetTelegramChannelsQuery,
  useLazyGetTelegramChannelsQuery,
  useLazyGetTelegramChannelByIdQuery,
  useCreateTelegramChannelMutation,
  useEditTelegramChannelMutation,
  useDeleteTelegramChannelMutation,
} = telegramChannelApiService;
