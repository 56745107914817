import { FC, MouseEvent } from 'react';

import cn from 'classnames';

import { Icon } from 'gazprom-ui-lib';

import FileContent, { FileContentProps } from '../file-content';
import s from './FileDownload.module.scss';

interface FileDownloadProps extends FileContentProps {
  handleDownload: () => void;
  deleteHandler: () => void;
  resetHandler?: () => void;
}

const FileDownload: FC<FileDownloadProps> = (props) => {
  const { icon, title, description, handleDownload, deleteHandler, isError, resetHandler } = props;

  const handleActionClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (isError && resetHandler) {
      return resetHandler();
    }

    deleteHandler();
  };

  return (
    <button
      onClick={handleDownload}
      className={cn(s.wrapper, { [s.errorStatus]: isError })}
      type="button">
      <FileContent icon={icon} title={title} description={description} isError={isError} />
      <button type="button" className={s.remove} onClick={handleActionClick}>
        <Icon name={isError ? 'refresh' : 'delete'} />
      </button>
    </button>
  );
};

export default FileDownload;
