import { memo } from 'react';

import { useNavigate } from 'react-router-dom';
import { getNotificationTemplateWithIdRoute } from 'routes/notification/list';

import FiltersV2 from 'containers/filters-v2';

import { NotificationTemplateType } from 'types/notification.types';

import s from './NotificationTemplateTable.module.scss';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const NotificationTemplateTable = () => {
  const navigate = useNavigate();

  const columns = useColumns();

  const onRow = ({ id }: NotificationTemplateType) => ({
    onClick: () => {
      navigate(getNotificationTemplateWithIdRoute(id));
    },
  });

  const { data, isLoading, isFetching } = useGetData();

  return (
    <FiltersV2.Table
      className={s.table}
      isInitialLoading={isLoading}
      rowKey="id"
      dataSource={data?.items}
      total={data?.totalCount}
      columns={columns}
      showSorterTooltip={false}
      onRow={onRow}
      loading={isFetching}
    />
  );
};

export default memo(NotificationTemplateTable);
