import { useGetFaqCategoriesQuery } from 'services/faq/faqApiService';
import { GetFaqCategoriesResponseType } from 'services/faq/faqApiService.types';

import { SelectProps } from 'gazprom-ui-lib';

import { FaqCategoryType } from 'types/faq.types';

interface UseDateProps {
  currentId?: string;
}

const useData = (props: UseDateProps) => {
  const { currentId } = props;

  const selectFromResult = ({
    data,
    ...otherProps
  }: {
    data?: GetFaqCategoriesResponseType;
    isFetching: boolean;
  }) => ({
    // Родительская категория - не обязательное поле- список элементов ответа метода GET /api/v1/faq-category,
    // у которых parrentCategory == NULL
    data: data?.categories.reduce(transformResponseIntoOptions, [] as SelectProps['options']) ?? [],
    ...otherProps,
  });

  const transformResponseIntoOptions = (prev: SelectProps['options'], curr: FaqCategoryType) => {
    const { id, nameCategory, parentCategory } = curr;

    if (!parentCategory.id && currentId !== id) {
      prev!.push({ label: nameCategory, value: id });
    }

    return prev;
  };
  const { data: options, isFetching: faqCategoriesIsFetching } = useGetFaqCategoriesQuery(
    {
      page: 1,
      size: 500,
    },
    { selectFromResult },
  );

  return {
    options,
    faqCategoriesIsFetching,
  };
};

export default useData;
