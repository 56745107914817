import { FC } from 'react';

import { Icon, TIconsLiteral } from 'gazprom-ui-lib';

import s from 'pages/social-program/social-program-form/containers/category-select/CategorySelect.module.scss';

import { KedoCategoryType } from 'types/documents.types';

interface RequirementsSelectIconProps extends Pick<KedoCategoryType, 'name' | 'icon'> {}
const RequirementsSelectIcon: FC<RequirementsSelectIconProps> = (props) => {
  const { icon, name } = props;
  return (
    <div className={s.icon}>
      <Icon name={icon as TIconsLiteral} />
      {name}
    </div>
  );
};

export default RequirementsSelectIcon;
