import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useGetEmployeeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Form, Select } from 'gazprom-ui-lib';

import { EXPERT_COMMISSION_FORM_ENUM } from '../../expertCommissionForm.utils';

const DEFAULT_REQUEST_BODY = {
  page: 1,
  size: 500,
};

const Head = () => {
  const { id } = useParams<{ id: string }>();

  const form = Form.useFormInstance();
  const organizationId = Form.useWatch(EXPERT_COMMISSION_FORM_ENUM.ORGANIZATION);

  const { data: employeeData, isLoading } = useGetEmployeeQuery({
    ...DEFAULT_REQUEST_BODY,
    employerId: organizationId,
  });

  useEffect(() => {
    if (!id && organizationId) {
      form.setFieldValue(EXPERT_COMMISSION_FORM_ENUM.HEAD, null);
    }
  }, [form, id, organizationId]);

  const options = employeeData?.employees?.map((employee) => ({
    label: `${employee.person.firstName} ${employee.person.lastName?.slice(0, 1)}. ${
      employee.staff.name
    }`,
    value: employee.id,
  }));

  return (
    <Form.Item
      label={t('suggestions_commission_head_name')}
      name={EXPERT_COMMISSION_FORM_ENUM.HEAD}
      rules={[formRules.required]}>
      <Select
        placeholder={t('suggestions_commission_head_choose_placeholder')}
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        loading={isLoading}
        disabled={!organizationId}
      />
    </Form.Item>
  );
};

export default Head;
