import { SORT_ENUM } from 'types/sort.types';
import { TFeedback } from 'types/thankYou.types';

export const MAX_EMPLOYERS_PER_PAGE = 500;

export const fieldsAssociationsForSort: Record<string, string> = {
  sender: 'feedback.author_person_id',
  recipient: 'feedback.recipient_person_id',
  category: 'category',
  date: 'feedback.createDate',
  status: 'status',
  thankText: 'text',
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  property: 'date',
  order: SORT_ENUM.DESC,
  search: '',
};

export type FormFiltersType = {
  status?: string[];
  recipientEmployers?: string[];
  authorEmployers?: string[];
};

export type FormResponseType = {
  page: number;
  size: number;
  property?: string;
  order?: SORT_ENUM;
  search: string;
  filters?: FormFiltersType;
};

export type ModifiedFeedback = {
  key: string;
  sender: {
    firstName: string;
    lastName: string | null;
    department: string | null;
    position: string | null;
  };
  recipient: {
    firstName: string;
    lastName: string | null;
    department: string | null;
    position: string | null;
  };
  thankText: string;
  status: string;
  category: string;
  date: string;
};

export const mapFeedBacks = (feedbacks?: TFeedback[]): ModifiedFeedback[] => {
  if (!feedbacks) {
    return [];
  }

  return feedbacks.map((feedback) => {
    const {
      id,
      authorEmployee,
      authorPerson,
      recipientEmployee,
      recipientPerson,
      text,
      category,
      createDate,
      status,
    } = feedback;

    return {
      key: id,
      sender: {
        firstName: authorPerson.firstName,
        lastName: authorPerson.lastName,
        department: '',
        position: authorEmployee.position,
      },
      recipient: {
        firstName: recipientPerson.firstName,
        lastName: recipientPerson.lastName,
        department: '',
        position: recipientEmployee.position,
      },
      thankText: text,
      status: status,
      category,
      date: createDate,
    };
  });
};
