type ConditionType = { value: string; label: string };

export const CONDITION_OPTIONS: ConditionType[] = [
  {
    value: '7DAYS',
    label: '7 дней с начала трудоустройства',
  },
  {
    value: '14DAYS',
    label: '14 дней с начала трудоустройства',
  },
  {
    value: '45DAYS',
    label: '45 дней с начала трудоустройства',
  },
  {
    value: 'SHIFT_END',
    label: 'Выезд с вахты',
  },
];
