export enum FORM_ENUM {
  TITLE,
  TEXT,
  ORGANIZATION,
  PARENT,
  PUBLISH,
}

export type FormResponseType = {
  [FORM_ENUM.TITLE]: string;
  [FORM_ENUM.TEXT]: string;
  [FORM_ENUM.ORGANIZATION]: string;
  [FORM_ENUM.PARENT]: string;
  [FORM_ENUM.PUBLISH]?: boolean;
};

export const FAQ_QUESTION_FORM = 'FAQ_QUESTION_FORM';
