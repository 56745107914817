import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import NotificationTemplateTable from 'containers/notification-template-table';

import { DEFAULT_FORM_STATE, FormResponseType } from './notificationTemplate.utils';

const NotificationTemplate = () => {
  const [form] = Form.useForm<FormResponseType>();

  return (
    <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
      <FiltersV2.TableFilters>
        <FiltersV2.Search />
      </FiltersV2.TableFilters>
      <NotificationTemplateTable />
    </FiltersV2>
  );
};

export default NotificationTemplate;
