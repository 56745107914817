import { FC, PropsWithChildren } from 'react';

import { Flex, FlexProps } from 'gazprom-ui-lib';

interface ContentHeaderActionsProps extends PropsWithChildren, FlexProps {}

const ContentHeaderActions: FC<ContentHeaderActionsProps> = (props) => {
  const { children, gap = '12', ...otherProps } = props;

  return (
    <Flex gap={gap} justify="center" align="center" {...otherProps}>
      {children}
    </Flex>
  );
};

export default ContentHeaderActions;
