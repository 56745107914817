import { t } from 'tools/i18n';

import ContentHeader from 'containers/content-header';

const Header = () => {
  return (
    <ContentHeader>
      <ContentHeader.Title>{t('notification_system_notifications')}</ContentHeader.Title>
    </ContentHeader>
  );
};

export default Header;
