import { kedoRoles } from 'roles/documents.roles';

import SuggestionCommissions from 'pages/suggestions/suggestions-commissions';
import SuggestionsCommissionsCreate from 'pages/suggestions/suggestions-commissions/suggestions-commissions-create';
import SuggestionsCommissionsDetails from 'pages/suggestions/suggestions-commissions/suggestions-commissions-details';
import SuggestionsDecisions from 'pages/suggestions/suggestions-decisions';
import SuggestionDetails from 'pages/suggestions/suggestions-details';
import SuggestionsList from 'pages/suggestions/suggestions-list';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import {
  SUGGESTIONS_COMMISSION_CREATE_ROUTE,
  SUGGESTIONS_COMMISSION_ROUTE,
  SUGGESTIONS_DECISIONS_DETAILS_ROUTE,
  SUGGESTIONS_DECISIONS_ROUTE,
  SUGGESTIONS_LIST_ROUTE,
  SUGGESTION_COMMISSION_DETAILS_ROUTE,
  SUGGESTION_DETAILS_ROUTE,
} from './list';

export const suggestionsRoutes: RouteConfig[] = [
  { path: SUGGESTIONS_COMMISSION_ROUTE, element: <SuggestionCommissions />, ...kedoRoles },
  {
    path: SUGGESTIONS_COMMISSION_CREATE_ROUTE,
    element: <SuggestionsCommissionsCreate />,
    ...kedoRoles,
  },
  {
    path: SUGGESTION_COMMISSION_DETAILS_ROUTE,
    element: <SuggestionsCommissionsDetails />,
    ...kedoRoles,
  },
  {
    path: SUGGESTIONS_LIST_ROUTE,
    element: <SuggestionsList />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: SUGGESTION_DETAILS_ROUTE,
    element: <SuggestionDetails />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: SUGGESTIONS_DECISIONS_ROUTE,
    element: <SuggestionsDecisions />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
  {
    path: SUGGESTIONS_DECISIONS_DETAILS_ROUTE,
    element: <SuggestionDetails />,
    roles: [
      GLOBAL_ROLES.MODERATOR_SOCIAL,
      GLOBAL_ROLES.MODERATOR,
      GLOBAL_ROLES.ADMIN_MOBILE,
      GLOBAL_ROLES.ADMIN,
    ],
  },
];
