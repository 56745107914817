import { t } from 'tools/i18n';

import { TableProps, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { transformBusinessTripPeriod } from 'pages/documents/documents-business-trips/documentsBusinessTrips.utils';

import { BusinessTripType } from 'types/businessTrip.types';

import s from './DocumentsBusinessTrips.module.scss';

const useColumns = (): TableProps<BusinessTripType>['columns'] => {
  const employeeRenderer = (employee: BusinessTripType['employee']) => {
    const { firstName, lastName } = employee.person;

    return (
      <TablePerson
        firstName={firstName}
        lastName={lastName}
        position={null}
        department={employee.division.name}
      />
    );
  };
  const periodRenderer = (businessTrip: BusinessTripType) => {
    const { dateFrom, dateTo } = businessTrip;

    return (
      <Typography.Text weight="500" size="12">
        {transformBusinessTripPeriod(dateFrom, dateTo)}
      </Typography.Text>
    );
  };

  const destinationRenderer = (destination: BusinessTripType['destination']) => {
    return (
      <Typography.Text
        weight="500"
        size="12"
        type="secondary"
        className={s.destination}
        title={destination}>
        {destination}
      </Typography.Text>
    );
  };

  const statusRenderer = (currentKedoStatus: BusinessTripType['currentKedoStatus']) => {
    return <KedoStatusTag currentKedoStatus={currentKedoStatus} size="24" />;
  };

  const createdUpdatedRenderer = (date: 'string') => {
    return <TableDate date={date} />;
  };
  return [
    {
      title: t('common_employee'),
      dataIndex: 'employee',
      width: 180,
      align: 'left',
      render: employeeRenderer,
    },
    {
      title: t('documents_business_trip_period'),
      align: 'center',
      render: periodRenderer,
    },
    {
      title: t('documents_destination'),
      dataIndex: 'destination',
      align: 'center',
      render: destinationRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'currentKedoStatus',
      align: 'left',
      render: statusRenderer,
    },
    {
      title: t('common_created'),
      dataIndex: 'createdAt',
      align: 'center',
      sorter: true,
      defaultSortOrder: 'descend',
      render: createdUpdatedRenderer,
    },
    {
      title: t('common_updated'),
      dataIndex: 'updatedAt',
      align: 'center',
      sorter: true,
      render: createdUpdatedRenderer,
    },
  ];
};

export default useColumns;
