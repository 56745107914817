import { t } from 'tools/i18n';

import NewsTables from './news-tables';

export const NEWS_NEWS_TAB = 'NEWS_NEWS_TAB';

export const TABS = [
  {
    key: NEWS_NEWS_TAB,
    children: <NewsTables />,
    label: t('news_added'),
  },
];
