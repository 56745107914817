import { t } from 'tools/i18n';

import { ColumnsType, Flex, Icon } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';

import { SurveyType } from 'types/surveys.types';

import IsPublish from '../containers/is-publish';
import Receivers from '../containers/receivers';
import TitleWithTags from '../containers/title-with-tags';
import s from './SurveysEvent.module.scss';

const useColumns = (): ColumnsType<SurveyType> => {
  const infoRenderer = (props: SurveyType) => {
    return (
      <TitleWithTags
        text={props.name}
        primaryTag={t('surveys_table_anketa_id', { id: props.anketologId })}
        secondaryTag={t('surveys_table_revision_id', { id: props.revisionId })}
      />
    );
  };

  const receiversRenderer = (props: SurveyType['receivers']) =>
    props.length ? <Receivers receivers={props[0].receivers} /> : null;

  const isMandatoryRenderer = (value: SurveyType['isMandatory']) =>
    value ? <Icon name="ok" className={s.checkIcon} /> : null;

  const activeRenderer = (value: SurveyType['active']) => {
    return (
      <Flex vertical align="center">
        <IsPublish isActive={value} />
      </Flex>
    );
  };

  const createdAtRenderer = (date: SurveyType['createdAt']) => <TableDate date={date} />;

  return [
    {
      title: t('surveys_column_info'),
      render: infoRenderer,
      width: 280,
    },
    {
      title: t('surveys_column_group'),
      dataIndex: 'receivers',
      render: receiversRenderer,
    },
    {
      title: t('surveys_column_required'),
      dataIndex: 'isMandatory',
      align: 'center',
      render: isMandatoryRenderer,
      sorter: true,
    },
    {
      title: t('surveys_active'),
      dataIndex: 'active',
      align: 'center',
      render: activeRenderer,
      sorter: true,
    },
    {
      title: t('surveys_column_created'),
      dataIndex: 'createdAt',
      align: 'center',
      width: 100,
      render: createdAtRenderer,
      sorter: true,
      defaultSortOrder: 'descend',
    },
  ];
};

export default useColumns;
