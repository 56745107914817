import { Dispatch, SetStateAction } from 'react';

import { useNavigate } from 'react-router-dom';
import { getFaqEditWithIdRoute } from 'routes/faq/list';
import { t } from 'tools/i18n';

import { Button, Flex, TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableIsPublish from 'components/table/table-is-publish';

import { FaqTreeDataType, RequestBodyType } from '../../faq.utils';

interface UseColumnsProps {
  setDeleteData: Dispatch<SetStateAction<null | FaqTreeDataType>>;
  setEditData: Dispatch<SetStateAction<null | FaqTreeDataType>>;
  requestBody: RequestBodyType;
}

const useColumns = (props: UseColumnsProps): TableProps<FaqTreeDataType>['columns'] => {
  const { setDeleteData, setEditData, requestBody } = props;

  const navigate = useNavigate();

  const handleDelete = (data: FaqTreeDataType) => () => {
    setDeleteData(data);
  };
  const handleEdit = (data: FaqTreeDataType) => () => {
    if (data.type === 'faq') {
      navigate(getFaqEditWithIdRoute(data.key), { state: { requestBody } });
    } else {
      setEditData(data);
    }
  };

  const organizationRenderer = (organization?: { name: string }) => {
    return (
      <Typography.Text size="12" weight="500" type="secondary">
        {organization?.name}
      </Typography.Text>
    );
  };

  const isPublishRenderer = (isPublish: boolean) => {
    return <TableIsPublish isPublish={isPublish} />;
  };

  const updatedAtRenderer = (updatedAt: string) => {
    return <TableDate date={updatedAt} />;
  };

  const actionsRenderer = (rowData: FaqTreeDataType) => {
    return (
      <Flex justify="flex-end" align="center">
        <Button type="link" leftIcon="edit" size="small" onClick={handleEdit(rowData)}>
          {t('common_edit_short')}
        </Button>
        <Button type="link" danger leftIcon="bin" size="small" onClick={handleDelete(rowData)}>
          {t('common_delete')}
        </Button>
      </Flex>
    );
  };

  return [
    {
      title: t('common_category'),
    },
    {
      title: t('common_organization'),
      dataIndex: 'organization',
      align: 'center',
      width: 240,
      render: organizationRenderer,
    },
    {
      title: t('common_publication'),
      dataIndex: 'isPublish',
      align: 'center',
      width: 120,
      render: isPublishRenderer,
    },
    {
      title: t('common_updated'),
      dataIndex: 'updatedAt',
      align: 'center',
      width: 120,
      render: updatedAtRenderer,
    },
    {
      title: ' ',
      align: 'right',
      width: 240,
      render: actionsRenderer,
    },
  ];
};

export default useColumns;
