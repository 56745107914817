import { ReactElement } from 'react';

import { t } from 'tools/i18n';

import { GetTaskByIdResponseType } from 'services/1c/cApiService.types';

import { TabsTypes } from 'containers/document-view/containers/tabs/tabs.utils';

import DocumentsViewFiles from './documents-view-files';
import DocumentsViewParticipants from './documents-view-participants';

const DEFAULT_TABS = [
  {
    key: 'attached' as TabsTypes,
    label: t('common_attached_files'),
    children: <DocumentsViewFiles />,
  },
];

export const getTabItems = (
  approvers?: GetTaskByIdResponseType['approvers'],
): { key: TabsTypes; label: string; children: ReactElement }[] => {
  if (!approvers) {
    return DEFAULT_TABS;
  }

  return [
    ...DEFAULT_TABS,
    {
      key: 'additional' as TabsTypes,
      label: t('common_process_participants'),
      children: <DocumentsViewParticipants />,
    },
  ];
};
