import { useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useLazyGetEmployer1cQuery } from 'services/1c/cApiService';

import { Form, Select } from 'gazprom-ui-lib';

import { EXPERT_COMMISSION_FORM_ENUM } from '../../expertCommissionForm.utils';
import { prepareEmployersOptions } from './organization.utils';

interface Props {
  disabled?: boolean;
}

const DEFAULT_REQUEST_BODY = {
  page: 1,
  size: 500,
};

const Organization = (props: Props) => {
  const { disabled } = props;

  const [getEmployer1c, { data: employersOptions, isLoading: isEmployersOptionsLoading }] =
    useLazyGetEmployer1cQuery();

  useEffect(() => {
    getEmployer1c(DEFAULT_REQUEST_BODY);
  }, [getEmployer1c]);

  const options = prepareEmployersOptions(employersOptions?.employers);

  return (
    <Form.Item
      label={t('suggestions_commissions_organization')}
      name={EXPERT_COMMISSION_FORM_ENUM.ORGANIZATION}
      rules={[formRules.required]}>
      <Select
        disabled={disabled}
        placeholder={t('common_choose_organization_from_list')}
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        loading={isEmployersOptionsLoading}
      />
    </Form.Item>
  );
};

export default Organization;
