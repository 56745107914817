import { userManager } from './keycloak';

type downloadHandlerParamsType = {
  url: string;
  fileName: string;
  body?: BodyInit;
  method?: 'GET' | 'POST';
  contentType?: string;
  headersFileNameKey?: string;
};

const getFileNameFromHeaders = (response: Response, headersFileNameKey?: string) => {
  if (headersFileNameKey) {
    const disposition = response.headers.get('Content-Disposition');

    if (disposition?.includes('attachment')) {
      const filenameRegex = /filename\*=UTF-8''([^;]+)/;
      const matches = filenameRegex.exec(disposition);
      if (matches?.[1]) {
        const filename = decodeURIComponent(matches[1]);

        return filename;
      }
    }
  }
};

const downloadHandler = async (params: downloadHandlerParamsType) => {
  const {
    url,
    fileName,
    headersFileNameKey,
    body,
    method = 'GET',
    contentType = 'application/json',
  } = params;

  const user = await userManager.getUser();
  const token = user?.access_token as string;

  try {
    const response = await fetch(url, {
      method: method,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentType,
      },
      body,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const downloadFileName = getFileNameFromHeaders(response, headersFileNameKey) || fileName;

    a.href = blobUrl;
    a.download = downloadFileName;
    a.click();

    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Download failed', error);
  }
};

export default downloadHandler;
