import { Dispatch, FC, SetStateAction, memo } from 'react';

import { t } from 'tools/i18n';

import {
  usePublishHandbookMutation,
  useUnpublishHandbookMutation,
} from 'services/settlement/settlementApiService';

import { Button, Flex } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import UploadActions from '../upload-actions';
import s from './Filters.module.scss';

interface GuideHandbookFiltersProps {
  selectedRowKeys: string[];
  setSelectedRowKeys: Dispatch<SetStateAction<string[]>>;
}

const GuideHandbookFilters: FC<GuideHandbookFiltersProps> = (props) => {
  const { selectedRowKeys, setSelectedRowKeys } = props;

  const [unpublishHandbook, { isLoading: unpublishIsFetching }] = useUnpublishHandbookMutation();
  const [publishHandbook, { isLoading: publishIsFetching }] = usePublishHandbookMutation();

  const areRowsSelected = !!selectedRowKeys.length;

  const handlePublish = () => {
    publishHandbook(selectedRowKeys);
  };
  const handleUnpublish = () => {
    unpublishHandbook(selectedRowKeys);
  };

  const handleClearSelections = () => setSelectedRowKeys([]);

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search hidden={areRowsSelected} />
      <Flex align="center" wrap="wrap" justify="space-between" fullWidth={areRowsSelected}>
        {areRowsSelected && (
          <Flex align="center">
            <Button
              onClick={handleClearSelections}
              size="small"
              ghost
              leftIcon="delete"
              type="link">
              {t('common_remove_selection')}
            </Button>
            <Button
              className={s.publish}
              onClick={handlePublish}
              size="small"
              type="link"
              leftIcon="publishFilled"
              loading={publishIsFetching}>
              {t('common_publish')}
            </Button>
            <Button
              onClick={handleUnpublish}
              size="small"
              type="link"
              danger
              leftIcon="unpublishFilled"
              loading={unpublishIsFetching}>
              {t('common_unpublish')}
            </Button>
          </Flex>
        )}
        <UploadActions selectedRowKeys={selectedRowKeys} />
      </Flex>
    </FiltersV2.TableFilters>
  );
};

export default memo(GuideHandbookFilters);
