import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, TextArea } from 'gazprom-ui-lib';

import { FORM_ENUM } from '../../faqQuestionForm.utils';

const Text = () => {
  return (
    <Form.Item name={FORM_ENUM.TEXT} label={t('common_answer_text')} rules={[formRules.required]}>
      <TextArea placeholder={t('common_enter_text')} maxLength={5000} showCount />
    </Form.Item>
  );
};

export default Text;
