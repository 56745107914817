import { t } from 'i18next';

import FiltersWrapper from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import { FilterCheckboxGroupOptions } from 'containers/filters/filter-checkbox-group/filterCheckboxGroup';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { modifyTaskStatuses } from 'pages/documents/documents.utils';
import useDocumentsFilters from 'pages/documents/useDocumentsFilters';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

const Filters = () => {
  const {
    taskStatuses,
    employersResult,
    categoriesResult,
    employersSearchHandler,
    categoriesSearchHandler,
  } = useDocumentsFilters({ categoryType: KEDO_TYPE_ENUM.KEDO, types: [KEDO_TYPE_ENUM.KEDO] });

  const items = [
    {
      key: 0,
      label: <FilterHeader valueKey="employerIds">{t('common_organization')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="employerIds"
          optionFilterProp="value"
          options={employersResult.employersOptions}
          showSearch
          searchProps={{
            placeholder: t('common_organization_search'),
            onChange: employersSearchHandler,
          }}
        />
      ),
    },
    {
      key: 1,
      label: <FilterHeader valueKey="categoryIds">{t('common_category')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="categoryIds"
          optionFilterProp="value"
          options={categoriesResult.data ?? []}
          showSearch
          searchProps={{
            placeholder: t('common_categories_search'),
            onChange: categoriesSearchHandler,
          }}
        />
      ),
    },
  ];
  const preparedFilters = taskStatuses?.reduce(
    modifyTaskStatuses,
    [] as FilterCheckboxGroupOptions[],
  );
  return (
    <FiltersV2.TableFilters>
      <FiltersV2.RangePicker />
      <FiltersWrapper
        showClearAll
        overlay={
          <>
            <FilterCollapse items={items} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="statuses">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="statuses"
                options={preparedFilters}
              />
            </FilterCustomWrapper>
          </>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
