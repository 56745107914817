import { Navigate, RouteObject } from 'react-router-dom';

import Error from 'pages/error';
import NoPermission from 'pages/no-permission';

import { GLOBAL_ROLES } from 'types/roles.types';

import { accessControlRoutes } from './access-control/config';
import { documentsRoutes } from './documents/config';
import { employeeGroupsRoutes } from './employee-groups/config';
import { eventsRoutes } from './events/config';
import { faqRoutes } from './faq/config';
import { guideRoutes } from './guide/config';
import { loginRoutes } from './login/config';
import { LOGIN_ROUTE } from './login/list';
import { newsRoutes } from './news/config';
import { notificationRoutes } from './notification/config';
import { settingsRoutes } from './settings/config';
import { signRoutes } from './sign/config';
import { socialProgramRoutes } from './social-program/config';
import { suggestionsRoutes } from './suggestions/config';
import { surveysRoutes } from './surveys/config';
import { thankYouRoutes } from './thank-you/config';

export type RouteConfig = RouteObject & {
  children?: RouteConfig[];
  roles?: GLOBAL_ROLES[];
};

export const UNKNOWN_ROUTE = '*';
export const NO_PERMISSION_ROUTE = '/403';
export const HOME_NONE_AUTH_ROUTE = LOGIN_ROUTE;
export let HOME_ROUTE = '';

export const ERROR_NONE_AUTH_ROUTE = '/error';

export const initRoutes = (isAuth: boolean, roles?: string[]) => {
  if (isAuth) {
    const routes: RouteObject[] = [];
    const userHasNecessaryRole = (item: RouteConfig): boolean => {
      return !!item.roles?.some((role: GLOBAL_ROLES) => {
        return roles?.includes(role);
      });
    };

    const initConfigRoutes = (elements: RouteConfig[]) => {
      elements.forEach((item: RouteConfig) => {
        const userHasRights = userHasNecessaryRole(item);
        const routeDoesntRequireAnyRights = !item.roles;

        const userCanAccessRoute = userHasRights || routeDoesntRequireAnyRights;

        if (userCanAccessRoute) {
          const filteredChildren = item.children?.filter((child: RouteConfig) => {
            const childHasRights = userHasNecessaryRole(child);
            const childDoesntRequireAnyRights = !child.roles;

            return childHasRights || childDoesntRequireAnyRights;
          });

          routes.push({ path: item.path, children: filteredChildren, element: item.element });
        }
      });
    };

    initConfigRoutes([
      ...employeeGroupsRoutes,
      ...eventsRoutes,
      ...guideRoutes,
      ...newsRoutes,
      ...notificationRoutes,
      ...socialProgramRoutes,
      ...suggestionsRoutes,
      ...surveysRoutes,
      ...thankYouRoutes,
      ...documentsRoutes,
      ...accessControlRoutes,
      ...signRoutes,
      ...settingsRoutes,
      ...faqRoutes,
      ...suggestionsRoutes,
    ]);

    HOME_ROUTE = routes?.[0]?.path ?? NO_PERMISSION_ROUTE;

    routes.push({
      path: UNKNOWN_ROUTE,
      element: <Navigate replace to={HOME_ROUTE} />,
    });
    routes.push({ path: ERROR_NONE_AUTH_ROUTE, element: <Error /> });
    routes.push({ path: NO_PERMISSION_ROUTE, element: <NoPermission /> });

    return routes;
  }

  return [
    { path: UNKNOWN_ROUTE, element: <Navigate replace to={HOME_NONE_AUTH_ROUTE} /> },
    { path: ERROR_NONE_AUTH_ROUTE, element: <Error /> },
    ...loginRoutes,
  ];
};
