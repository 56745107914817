import { FC } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { t } from 'i18next';

import { Avatar, Button, Icon, Modal, Typography } from 'gazprom-ui-lib';

import { NotificationType } from 'types/notification.types';

import s from './NotificationNewsletterEditStatsModal.module.scss';

interface NotificationNewsletterEditStatsModalProps {
  notification: NotificationType | null;
  handleClose: () => void;
}

const NotificationNewsletterEditStatsModal: FC<NotificationNewsletterEditStatsModalProps> = (
  props,
) => {
  const { notification, handleClose } = props;

  const readTag = !!notification?.viewed && (
    <div className={cn(s.tag, s.read)}>
      <Icon name="ok" />
      {t('common_read')}
    </div>
  );

  const callTag = !!notification?.callBack && (
    <div className={cn(s.tag, s.callback)}>
      <Icon name="time" />
      {t('notification_waiting_for_call')}
    </div>
  );

  const showFeedback = !!notification?.feedback?.body;
  const showCallback = !!notification?.callBack?.contacts;

  const firstName = notification?.person?.firstName ?? '';
  const lastName = notification?.person?.lastName ?? '';

  return (
    <Modal open={!!notification} onCancel={handleClose} closable width={600} className={s.wrapper}>
      <div className={s.header}>
        {readTag}
        {callTag}
        <Typography.Text weight="500" size="14" type="primary">
          {`${t('notification_sent')}: ${dayjs(notification?.createdAt).format('DD.MM.YYYY')} ${t(
            'common_at_time',
          )} ${dayjs(notification?.createdAt).format('HH:mm')}`}
        </Typography.Text>
      </div>
      <div className={s.content}>
        <Typography.Text weight="500" size="14" type="primary">
          {t('common_receiver')}:
        </Typography.Text>
        <div className={s.avatar}>
          <Avatar firstName={firstName} lastName={lastName} alt="аватар пользователя" size="40" />
          <Typography.Text weight="500" size="14">{`${firstName} ${lastName[0]}.`}</Typography.Text>
        </div>
      </div>
      {showCallback && (
        <div className={s.content}>
          <Typography.Text weight="500" size="14" type="primary">
            {t('common_callback')}:
          </Typography.Text>
          <Typography.Text size="14">{notification?.callBack?.contacts}</Typography.Text>
        </div>
      )}
      {showFeedback && (
        <div className={s.content}>
          <Typography.Text weight="500" size="14" type="primary">
            {t('notification_message_text')}:
          </Typography.Text>
          <Typography.Text size="14">{notification?.feedback?.body}</Typography.Text>
        </div>
      )}
      <Button onClick={handleClose} type="secondary" className={s.close}>
        {t('common_close')}
      </Button>
    </Modal>
  );
};

export default NotificationNewsletterEditStatsModal;
