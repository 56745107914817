import cInitialApiService from 'services/1c/cApiService';

import { SignFilePropsType, SignFileResponseType } from './signApiService.types';
import { C_SERVICE_TAGS } from 'constants/serviceTags';

import { createSignUrl } from './utils';

const signApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    signFile: builder.mutation<SignFileResponseType, SignFilePropsType>({
      query: ({ id, ...body }) => ({
        url: createSignUrl(`/attach/kedo-task-assignment/${id}`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.STATEMENT_TASK_BY_ID, C_SERVICE_TAGS.ASSIGNMENT_BY_ID],
    }),
  }),
});

export const { useSignFileMutation } = signApiService;
