import { t } from 'tools/i18n';

import { Button, Flex, Typography } from 'gazprom-ui-lib';

import { SuggestionFileType } from 'types/suggestion.types';

import Files from '../files';
import s from './FilesFiels.module.scss';

interface Props {
  title: string;
  files?: SuggestionFileType[];
  withArchive?: boolean;
}

const FilesField = (props: Props) => {
  const { title, files, withArchive } = props;

  return (
    <Flex vertical gap="4">
      <Typography.Title level={5}>{title}</Typography.Title>
      <Files files={files} />
      {withArchive && (
        <Button
          className={s.downloadArchiveButton}
          loading={false}
          // TODO ждем пока бэк починит файлы по идеям
          // onClick={handleDownloadFile}
          leftIcon="download"
          fullWidth={false}>
          {t('common_download_archive')}
        </Button>
      )}
    </Flex>
  );
};

export default FilesField;
