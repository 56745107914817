import { FC, memo } from 'react';

import { t } from 'tools/i18n';

import { Button, ButtonProps } from 'gazprom-ui-lib';

import s from './NewsDetailsActions.module.scss';

interface NewsDetailsActionsProps {
  saveButtonConfig: ButtonProps;
  resetButtonConfig: ButtonProps;
}

const NewsDetailsActions: FC<NewsDetailsActionsProps> = (props) => {
  const { saveButtonConfig, resetButtonConfig } = props;

  return (
    <div className={s.wrapper}>
      <Button leftIcon="ok" fullWidth type="primary" {...saveButtonConfig}>
        {t('common_save')}
      </Button>
      <Button type="secondary" fullWidth {...resetButtonConfig}>
        {t('common_close')}
      </Button>
    </div>
  );
};

export default memo(NewsDetailsActions);
