export type RequestBody = {
  page: number;
  size: number;
  instance1cId?: string;
};

export const INITIAL_REQUEST_BODY = {
  page: 1,
  size: 10,
};
