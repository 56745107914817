import { Dispatch, FC, SetStateAction, useState } from 'react';

import { t } from 'tools/i18n';

import { useDeleteEventsMutation } from 'services/meet/meetApiService';

import { Icon, Typography, handleRequest } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';

import { EventType } from 'types/event.types';

import s from './Actions.module.scss';

interface NewsActionsProps {
  selectedEvents: Array<EventType['meeting']['id']>;
  setSelectedEvents: Dispatch<SetStateAction<Array<EventType['meeting']['id']>>>;
}

const Actions: FC<NewsActionsProps> = (props) => {
  const { setSelectedEvents, selectedEvents } = props;

  const [deleteEvents, { isLoading: deleteEventsIsFetching }] = useDeleteEventsMutation();

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);

  const handleClear = () => setSelectedEvents([]);
  const onSuccess = () => {
    setSelectedEvents([]);
    setIsRemoveModalOpen((prevState) => !prevState);
  };

  const handleDeleteSelectedNews = () => {
    deleteEvents({ ids: selectedEvents }).then(
      handleRequest({
        onSuccess,
      }),
    );
  };

  const handleModalOpenClose = () => {
    setIsRemoveModalOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className={s.container}>
        <button className={s.button} onClick={handleClear}>
          <Icon name="delete" size={12} color="var(--color-primary-6)" />
          <Typography.Text size="14" weight="500" type="primary">
            {t('news_deselect')}
          </Typography.Text>
        </button>
        <button className={s.button} onClick={handleModalOpenClose}>
          <Icon name="binFilled" size={16} color="var(--color-error)" />
          <Typography.Text size="14" weight="500" type="danger">
            {t('common_remove')}
          </Typography.Text>
        </button>
      </div>
      <RemoveConfirm
        isLoading={deleteEventsIsFetching}
        type="danger"
        isOpen={isRemoveModalOpen}
        onCancel={handleModalOpenClose}
        title={t('common_accept_remove')}
        subtitle={t('events_selected_will_be_removed')}
        onConfirm={handleDeleteSelectedNews}
        buttonText={t('common_remove')}
      />
    </>
  );
};

export default Actions;
