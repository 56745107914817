import { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';

import { useGetDeclarationTypeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Tabs } from 'gazprom-ui-lib';

import WithOverflow from 'containers/wrappers/with-overflow';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import usePopoverHook from '../usePopover.hook';
import useTemplateTab from '../useTemplateTab.hook';
import s from './DocumentsDeclarationsEdo.module.scss';
import Header from './containers/header';
import { INITIAL_TAB, TABS } from './documentsDeclarationsEdo.utils';

const DocumentsDeclarationsEdo = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(INITIAL_TAB.key);
  const { popoverIsOpen, popoverHandler } = usePopoverHook({ activeTab });
  const templateTab = useTemplateTab({ popoverIsOpen, onClose: popoverHandler });
  const { data } = useGetDeclarationTypeQuery(id ?? skipToken);

  let tabs = [...TABS, templateTab];

  if (data?.type === KEDO_TYPE_ENUM.KEDO) {
    tabs.splice(-1);
  }

  return (
    <WithOverflow>
      <Header activeTab={activeTab} />
      <Tabs
        className={s.tabs}
        items={id ? tabs : [INITIAL_TAB]}
        type="card"
        onTabClick={setActiveTab}
        activeKey={activeTab}
      />
    </WithOverflow>
  );
};

export default DocumentsDeclarationsEdo;
