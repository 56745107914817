import { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import FileDownload from 'pages/documents/containers/file-download';
import FileInfo from 'pages/documents/containers/file-info';
import { renderSign } from 'pages/documents/documents.utils';

import { FileType } from 'types/file.types';
import { RenewFormItemType } from 'types/topManagement.types';

import s from './DocumentsView.module.scss';
import { ARCHIVE_NAME, createDownloadArchiveUrl, createDownloadUrl } from './documentsView.utils';

interface DocumentsViewProps {
  taskId: string;
  files?: FileType[];
  formData?: RenewFormItemType;
}

const DocumentsView: FC<DocumentsViewProps> = (props) => {
  const { taskId, files, formData } = props;

  if (!files) {
    return null;
  }

  const handleDownloadArchive = () => {
    downloadHandler({ url: createDownloadArchiveUrl(taskId), fileName: ARCHIVE_NAME });
  };

  const renderFile = (file: FileType) => {
    const { fileKey, fileName, signs, type, dateDoc, numberDocPrint } = file;
    const isSigned = !!signs?.length;
    const isOrderType = type === 'ORDER';

    const fileDownloadUrl = createDownloadUrl(taskId, fileKey);

    const renderSigns = () => {
      const signerId = formData?.signer?.id ?? '';

      if (isOrderType) {
        return signs?.filter(({ key }) => key.includes(signerId))?.map(renderSign);
      }

      return signs?.map(renderSign);
    };

    return (
      <Flex key={fileKey} vertical>
        <Flex
          justify="space-between"
          align="center"
          gap="12"
          className={cn(s.download, { [s.signed]: isSigned })}>
          <Flex align="center" gap="12">
            <Icon name="image" className={s.icon} />
            <Flex vertical gap="4" className={s.fileDescription}>
              <Typography.Text weight="500" size="14" type={isSigned ? 'success' : 'primary'}>
                {fileName}
              </Typography.Text>
              <FileInfo
                dateDoc={dateDoc}
                numberDocPrint={numberDocPrint}
                isOrderType={isOrderType}
              />
              {renderSigns()}
            </Flex>
          </Flex>
          <FileDownload isSigned={isSigned} fileName={fileName} fileDownloadUrl={fileDownloadUrl} />
        </Flex>
      </Flex>
    );
  };

  return (
    <div className={s.fileWrapper}>
      {files.map(renderFile)}
      <Button onClick={handleDownloadArchive} leftIcon="download" className={s.archiveButton}>
        {t('common_download_archive')}
      </Button>
    </div>
  );
};

export default DocumentsView;
