import { FC, PropsWithChildren, ReactElement } from 'react';

import Loader from 'components/loader';

export type WithLoaderProps = PropsWithChildren<{
  isLoading?: boolean;
  loader?: ReactElement;
}>;

const WithLoader: FC<WithLoaderProps> = (props): ReactElement => {
  const { children, isLoading, loader = <Loader /> } = props;

  if (isLoading && loader) {
    return loader;
  }

  return <>{children}</>;
};

export default WithLoader;
