import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Flex, Form, Input, Typography } from 'gazprom-ui-lib';

import { EMPLOYEE_GROUPS_FORM_ENUM } from '../../employeeGroupsCreate.utils';
import s from './DraftDescription.module.scss';

const DraftDescription = () => {
  const form = Form.useFormInstance();
  const textField = Form.useWatch(EMPLOYEE_GROUPS_FORM_ENUM.NAME, form);

  return (
    <Flex vertical gap="4" className={s.container}>
      <div className={s.textContainer}>
        <Typography.Title level={5}>{t('employee_groups_column_group_name')}</Typography.Title>
        <Typography.Text size="12" type="secondary">
          {t('common_symbol_count', { current: textField?.length ?? 0, total: 100 })}
        </Typography.Text>
      </div>
      <Form.Item name={EMPLOYEE_GROUPS_FORM_ENUM.NAME} rules={[formRules.required]}>
        <Input placeholder={t('employee_groups_enter_text')} maxLength={100} />
      </Form.Item>
    </Flex>
  );
};

export default DraftDescription;
