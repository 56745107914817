import { t } from 'tools/i18n';

import { TIconsLiteral, TagType } from 'gazprom-ui-lib';

import { RenewFormItemStatusType } from 'types/topManagement.types';

export const getFormDetailsStatusConfig = (
  status?: RenewFormItemStatusType,
): { type: TagType; title: string | null; icon: TIconsLiteral } | null => {
  switch (status) {
    case 'ACCEPT': {
      return {
        type: 'success',
        icon: 'ok',
        title: t('common_single_confirmed'),
      };
    }
    case 'SENT': {
      return {
        type: 'warning',
        icon: 'time',
        title: t('common_under_inspection'),
      };
    }
    default: {
      return null;
    }
  }
};
