import { useNavigate } from 'react-router-dom';
import { SURVEYS_ADD_EVENT_ROUTE, getSurveyEventByIdRoute } from 'routes/surveys/list';
import { t } from 'tools/i18n';

import { Button, Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { SurveyType } from 'types/surveys.types';

import { DEFAULT_FORM_RESPONSE, FormResponseType } from './surveysEvent.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const SurveysEvent = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<FormResponseType>();

  const columns = useColumns();
  const { data, isFetching, isLoading } = useGetData({ form });

  const handleAdd = () => navigate(SURVEYS_ADD_EVENT_ROUTE);
  const handleOnRow = (rowData: SurveyType) => {
    const { id, anketologId, revisionId } = rowData;

    return {
      onClick: () => navigate(getSurveyEventByIdRoute(id, anketologId, revisionId)),
    };
  };
  const totalCount = data?.totalCount;

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title total={totalCount}>{t('surveys_regular')}</ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="plus" onClick={handleAdd}>
            {t('surveys_add')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_RESPONSE}>
        <FiltersV2.TableFilters>
          <FiltersV2.Search />
        </FiltersV2.TableFilters>
        <FiltersV2.Table
          onRow={handleOnRow}
          isInitialLoading={isLoading}
          total={totalCount}
          dataSource={data?.items}
          columns={columns}
          loading={isFetching}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default SurveysEvent;
