import { FC } from 'react';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './ModalHeader.module.scss';

interface ModalHeaderProps {
  text: string;
  onClose: () => void;
}

const ModalHeader: FC<ModalHeaderProps> = (props) => {
  const { onClose, text } = props;
  return (
    <div className={s.header}>
      <Typography.Title level={5}>{text}</Typography.Title>
      <button onClick={onClose}>
        <Icon name="delete" size={15} />
      </button>
    </div>
  );
};

export default ModalHeader;
