import { TIconsLiteral, TagType } from 'gazprom-ui-lib';

type ConfigType = {
  type: TagType;
  icon: TIconsLiteral;
};

export const activeConfig: ConfigType = {
  type: 'success',
  icon: 'ok',
};

export const neutralConfig: ConfigType = {
  type: 'neutral',
  icon: 'ok',
};
