import { useState } from 'react';

import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';

import { Flex, Form, Typography } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import UploadImitation from 'components/upload-imitation';
import { HANDBOOK_INSTRUCTION_ENUM } from 'containers/handbook-instruction-form/handbookInstructionForm.utils';
import ImageModal from 'containers/image-modal';
import { FormImageResponse } from 'containers/image-modal/imageModal.utils';

import s from './ImageFile.module.scss';

const ImageFile = () => {
  const form = Form.useFormInstance();
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const cover = Form.useWatch(HANDBOOK_INSTRUCTION_ENUM.FILE);

  const coverSize = cover?.size ? (Number(cover.size) / (1024 * 1024)).toFixed(2) : 0;

  const handleModalOpenClose = () => {
    setShowUploadModal((prevState) => !prevState);
  };

  const onImgUpload = (_: string, data: FormImageResponse) => {
    form.setFieldValue(HANDBOOK_INSTRUCTION_ENUM.FILE, {
      ...data[HANDBOOK_INSTRUCTION_ENUM.FULL_INFO],
      link: data[HANDBOOK_INSTRUCTION_ENUM.FULL_INFO]?.url ?? data[HANDBOOK_INSTRUCTION_ENUM.URL],
    });
    handleModalOpenClose();
  };

  const resetImage = () => {
    form.resetFields([HANDBOOK_INSTRUCTION_ENUM.FILE]);
  };

  return (
    <>
      <Flex vertical gap="8">
        <Typography.Text strong size="16">
          {t('common_file')}
        </Typography.Text>
        <Form.Item name={HANDBOOK_INSTRUCTION_ENUM.FILE}>
          {cover ? (
            <ImgPreview
              iconName="image"
              className={s.preview}
              resetImage={resetImage}
              showDownload
              type="icon"
              url={createMediaDownloadUrl(cover?.link)}
              title={cover?.name ?? createMediaDownloadUrl(cover?.link)}
              description={t('common_mb', { count: coverSize })}
            />
          ) : (
            <UploadImitation
              title={t('common_upload_a_file')}
              handleClick={handleModalOpenClose}
              description={t('validation_upload_an_image_restrictions_guide')}
            />
          )}
        </Form.Item>
      </Flex>
      <ImageModal
        isOpen={showUploadModal}
        onSubmit={onImgUpload}
        onClose={handleModalOpenClose}
        uploadTitle={t('common_upload_a_file')}
        uploadDescription={t('validation_upload_an_image_restrictions_guide')}
        availableTypes={AVAILABLE_TYPES}
        maxLimitSizeMB={20}
      />
    </>
  );
};

const AVAILABLE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export default ImageFile;
