import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import { CInstanceLnaType } from 'types/cInstances.types';

const useColumns = (): ColumnsType<CInstanceLnaType> => {
  const instanceNameRenderer = (instanceName: string) => (
    <Typography.Text size="14" weight="500">
      {instanceName}
    </Typography.Text>
  );

  const lnaRenderer = (lna: string) => (
    <Typography.Text weight="500" type="secondary">
      {lna}
    </Typography.Text>
  );

  return [
    {
      width: 300,
      title: t('common_organization'),
      dataIndex: 'instanceName',
      render: instanceNameRenderer,
    },
    {
      title: t('1c_lna_active'),
      dataIndex: 'lna',
      render: lnaRenderer,
    },
  ];
};

export default useColumns;
