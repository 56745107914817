import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetKedoCategoriesQuery } from 'services/kedo/kedoApiService';

import { Empty } from 'components/empty';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterHeader from 'containers/filters/filter-header';

import useDocumentsFilters from 'pages/documents/useDocumentsFilters';

import { KedoCategoryType } from 'types/documents.types';
import { KEDO_TYPE_ENUM } from 'types/kedo.types';

const useFilterItems = () => {
  const [categoryName, setCategoryName] = useState<string>();

  const { debouncedValue: debouncedCategory } = useDebounce(categoryName);

  const { employersResult, employersSearchHandler } = useDocumentsFilters({
    types: [KEDO_TYPE_ENUM.SOCIAL],
  });

  const { socialProgramCategories } = useGetKedoCategoriesQuery(
    { type: KEDO_TYPE_ENUM.SOCIAL, name: debouncedCategory },
    { selectFromResult },
  );

  return useMemo(
    () => [
      {
        key: 0,
        label: <FilterHeader valueKey="organizations">{t('common_organization')}</FilterHeader>,
        children: (
          <FilterCheckboxGroup
            valueKey="organizations"
            options={employersResult.employersOptions}
            showSearch
            searchProps={{
              placeholder: t('common_organization_search'),
              onChange: employersSearchHandler,
            }}
            noData={
              <Empty
                title="common_no_records_found"
                description="common_change_the_search_query"
                showIcon={false}
              />
            }
          />
        ),
      },
      {
        key: 1,
        label: <FilterHeader valueKey="categories">{t('common_categories')}</FilterHeader>,
        children: (
          <FilterCheckboxGroup
            valueKey="categories"
            options={socialProgramCategories}
            showSearch
            searchProps={{
              placeholder: t('common_categories_search'),
              value: categoryName,
              onChange: (e) => setCategoryName(e.target.value || undefined),
            }}
            noData={
              <Empty
                title="common_no_records_found"
                description="common_change_the_search_query"
                showIcon={false}
              />
            }
          />
        ),
      },
    ],
    [employersResult, employersSearchHandler, socialProgramCategories, categoryName],
  );
};

const selectFromResult = ({ data }: { data?: KedoCategoryType[] }) => ({
  socialProgramCategories: data?.map(({ name, id }) => ({ value: id, label: name })) ?? [],
});

export default useFilterItems;
