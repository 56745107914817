import { prepareQueryParams } from 'utils/prepareQueryParams';

import { createWorkTourUrl } from 'services/1c/work-tour/utils';
import { downloadFileUsingRtk } from 'services/utils';

import {
  GetBusinessTripArchivePropsType,
  GetBusinessTripArchiveResponseType,
  GetBusinessTripByIdPropsType,
  GetBusinessTripByIdResponseType,
  GetBusinessTripStatusesPropsType,
  GetBusinessTripStatusesResponseType,
  GetBusinessTripsPropsType,
  GetBusinessTripsResponseType,
} from './workTourApiService.types';

import cInitialApiService from '../cApiService';

const workTourApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessTrips: builder.query<GetBusinessTripsResponseType, GetBusinessTripsPropsType>({
      query: (params) => ({
        url: createWorkTourUrl(prepareQueryParams(params)),
      }),
    }),
    getBusinessTripById: builder.query<
      GetBusinessTripByIdResponseType,
      GetBusinessTripByIdPropsType
    >({
      query: (id) => ({
        url: createWorkTourUrl(`/${id}`),
      }),
    }),
    getBusinessTripArchive: builder.mutation<
      GetBusinessTripArchiveResponseType,
      GetBusinessTripArchivePropsType
    >({
      query(params) {
        const { fileName, id } = params;

        return {
          url: createWorkTourUrl(`/archive?workTourId=${id}`),
          method: 'GET',
          responseHandler: downloadFileUsingRtk(fileName, 'zip'),
          cache: 'no-cache',
        };
      },
    }),
    getBusinessTripStatuses: builder.query<
      GetBusinessTripStatusesResponseType,
      GetBusinessTripStatusesPropsType
    >({
      query: () => ({
        url: createWorkTourUrl('/status'),
      }),
      transformResponse: (statuses: GetBusinessTripStatusesResponseType) => {
        const statusesMap = new Map();
        statuses.forEach((status) => {
          if (statusesMap.has(status.name)) {
            const existedStatus = statusesMap.get(status.name);

            status.code = `${existedStatus.code},${status.code}`;
            statusesMap.set(status.name, status);
          } else {
            statusesMap.set(status.name, status);
          }
        });
        return Array.from(statusesMap.values());
      },
    }),
  }),
});

export const {
  useGetBusinessTripsQuery,
  useGetBusinessTripStatusesQuery,
  useGetBusinessTripByIdQuery,
  useGetBusinessTripArchiveMutation,
} = workTourApiService;
