import { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { DOCUMENTS_BYPASS_SHEET_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { useGetBypassSheetByIdQuery } from 'services/bypass-sheet/bypassSheetApiService';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import ContentHeader from 'containers/content-header';
import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { ExecutionType } from 'types/bypassSheet.types';

import ExecutionModal from './containers/execution-modal';
import ExecutionsTable from './containers/executions-table';

const DocumentsBypassSheetDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [executionModalData, setExecutionModalData] = useState<null | ExecutionType>(null);

  const {
    data: bypassSheet,
    isFetching: bypassSheetIsFetching,
    isError: bypassSheetIsError,
  } = useGetBypassSheetByIdQuery(id ?? skipToken);

  const { employee, dismissDate, replacingEmployee, executions } = bypassSheet ?? {};

  const description = bypassSheet?.fullFinishDate && (
    <>
      <Flex gap="4" align="center">
        <Icon name="okFilled" color="var(--color-success)" />
        <Typography.Text type="success" size="14">
          {t('common_signed')}:{' '}
          {dayjs.utc(bypassSheet.fullFinishDate).local().format('DD MMMM YYYY, HH:mm')}
        </Typography.Text>
      </Flex>
    </>
  );

  return (
    <WithOverflow>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title description={description} goBackLink={DOCUMENTS_BYPASS_SHEET_ROUTE}>
          {t('bypass_sheet_details_title')}
        </ContentHeader.Title>
      </ContentHeader>
      <WithLoader isLoading={bypassSheetIsFetching}>
        <DocumentView isError={bypassSheetIsError}>
          <DocumentView.Content>
            <Flex vertical gap="24" fullWidth>
              {employee && (
                <Flex vertical gap="4">
                  <Typography.Title level={5}>
                    {t('bypass_sheet_resigning_employee')}
                  </Typography.Title>
                  <Bio
                    firstName={employee.person.firstName}
                    lastName={employee.person.lastName}
                    patronymic={employee.person.patronymic}
                    staffName={employee.position.name}
                  />
                </Flex>
              )}
              {dismissDate && (
                <Flex vertical gap="4">
                  <Typography.Title level={5}>{t('bypass_sheet_dismissal_date')}</Typography.Title>
                  <Typography.Text size="14">
                    {dayjs(dismissDate).format('DD.MM.YYYY')}
                  </Typography.Text>
                </Flex>
              )}
              {replacingEmployee && (
                <Flex vertical gap="4">
                  <Typography.Title level={5}>
                    {t('bypass_sheet_replacing_employee')}
                  </Typography.Title>
                  <Bio
                    firstName={replacingEmployee.person.firstName}
                    lastName={replacingEmployee.person.lastName}
                    patronymic={replacingEmployee.person.patronymic}
                    staffName={replacingEmployee.position.name}
                  />
                </Flex>
              )}
              {executions && (
                <>
                  <Flex vertical gap="16">
                    <Typography.Title level={4}>{t('bypass_sheet_execution')}</Typography.Title>
                    <ExecutionsTable
                      data={executions}
                      handleRowClick={(execution) => {
                        setExecutionModalData(execution);
                      }}
                    />
                  </Flex>
                  <ExecutionModal
                    data={executionModalData}
                    handleClose={() => setExecutionModalData(null)}
                  />
                </>
              )}
            </Flex>
          </DocumentView.Content>
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default DocumentsBypassSheetDetails;
