import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { FAQ_ADD_ROUTE } from 'routes/faq/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FaqCategoryModal from 'containers/faq-category-modal';

const Header = () => {
  const navigate = useNavigate();

  const [addModalOpened, setAddModalOpened] = useState<boolean>(false);

  const handleAddQuestion = () => {
    navigate(FAQ_ADD_ROUTE);
  };

  const handleOpenCloseAddCategory = () => {
    setAddModalOpened((prevState) => !prevState);
  };

  return (
    <>
      <ContentHeader>
        <ContentHeader.Title>{t('common_faq')}</ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="plus" onClick={handleAddQuestion}>
            {t('common_add_question')}
          </Button>
          <Button leftIcon="plus" ghost onClick={handleOpenCloseAddCategory}>
            {t('common_add_category')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <FaqCategoryModal open={addModalOpened} onClose={handleOpenCloseAddCategory} />
    </>
  );
};

export default Header;
