import { useCallback, useEffect, useRef, useState } from 'react';

export const SCREEN_SIZE = {
  PHONE_END: 767,
  TABLET_END: 991,
  SMALL_SCREEN_END: 1279,
  NORMAL_SCREEN_START: 1280,
};

export enum SCREEN_SIZE_ENUM {
  PHONE = 'PHONE',
  TABLET = 'TABLET',
  SMALL_SCREEN = 'SMALL_SCREEN',
  NORMAL_SCREEN = 'NORMAL_SCREEN',
}
const getNewScreenSize = (width: number): SCREEN_SIZE_ENUM => {
  if (width <= SCREEN_SIZE.PHONE_END) {
    return SCREEN_SIZE_ENUM.PHONE;
  } else if (width <= SCREEN_SIZE.TABLET_END) {
    return SCREEN_SIZE_ENUM.TABLET;
  } else if (width <= SCREEN_SIZE.SMALL_SCREEN_END) {
    return SCREEN_SIZE_ENUM.SMALL_SCREEN;
  } else {
    return SCREEN_SIZE_ENUM.NORMAL_SCREEN;
  }
};

export const useScreenSize = (): {
  screenSize: SCREEN_SIZE_ENUM | null;
  isInitialised: boolean;
} => {
  const [screenSize, setScreenSize] = useState<SCREEN_SIZE_ENUM | null>(null);

  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  const handleResize = useCallback(() => {
    const debouncedResize = () => {
      const newScreenSize = getNewScreenSize(window.innerWidth);
      if (screenSize !== newScreenSize) {
        setScreenSize(newScreenSize);
      }
    };

    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(debouncedResize, 500);
  }, [screenSize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Handles initial size
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return { screenSize, isInitialised: !!screenSize };
};
