import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import TablePerson from 'components/table/table-person';

const useColumns = () => {
  return useMemo(
    () => [
      {
        title: t('common_employee'),
        dataIndex: 'personFio',
        render: (person: { firstName: string; lastName: string }) => <TablePerson {...person} />,
        sorter: true,
      },
      {
        title: t('common_organization'),
        dataIndex: 'employer',
        sorter: true,
        render: (employer: { name: string }) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {employer.name}
          </Typography.Text>
        ),
      },
      {
        title: t('common_division'),
        dataIndex: 'division',
        render: (division: { name: string }) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {division.name}
          </Typography.Text>
        ),
      },
      {
        title: t('common_phone'),
        dataIndex: 'phone',
        render: (phone: string) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {phone}
          </Typography.Text>
        ),
      },
      {
        title: t('common_email'),
        dataIndex: 'email',
        render: (email: string) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {email}
          </Typography.Text>
        ),
      },
    ],
    [],
  );
};

export default useColumns;
