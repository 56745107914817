import { FC, ReactNode } from 'react';

import cn from 'classnames';

import { Flex } from 'gazprom-ui-lib';

import s from './EventFormMain.module.scss';
import Categories from './containers/categories';
import Cover from './containers/cover';
import DateAndTime from './containers/date-and-time';
import Description from './containers/description';
import Docs from './containers/docs';
import Participants from './containers/participants';
import Place from './containers/place';
import Registration from './containers/registration';
import Title from './containers/title';

export interface EventFormMainProps {
  actions?: ReactNode;
  className?: string;
}

const EventFormMain: FC<EventFormMainProps> = (props) => {
  const { actions, className } = props;

  return (
    <Flex vertical className={cn(s.wrapper, className)}>
      <Title />
      <Description />
      <Cover />
      <Categories />
      <DateAndTime />
      <Place />
      <Participants />
      <Registration />
      <Docs />
      {actions}
    </Flex>
  );
};

export default EventFormMain;
